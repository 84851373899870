// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../../../styleguide/icons/IconSelect.res.js";
import * as AgencyAgent from "../../../../../models/AgencyAgent.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardDropdownOptionsCss from "../../../../../styleguide/dashboard/components/options/DashboardDropdownOptionsCss.res.js";

function DashboardAgencyAgentsOptions(props) {
  var page = AgencyAgent.Dashboard.Index.page(props.pageNum);
  var query = AgencyAgent.Dashboard.Index.query(props.query);
  var sortBy = AgencyAgent.Dashboard.Index.sortBy(props.sortBy);
  var url = "/api/v1/dashboard/agency_agents/export.csv?" + page + query + sortBy;
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsxs(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: DashboardDropdownOptionsCss.popoverBody,
                      children: [
                        JsxRuntime.jsxs(A.make, {
                              href: "#",
                              className: DashboardDropdownOptionsCss.popoverBodyLink,
                              onClick: props.toggleNewAgentModal,
                              children: [
                                "New Agent",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: DashboardDropdownOptionsCss.popoverBodyLinkIcon
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(A.make, {
                              href: url,
                              className: DashboardDropdownOptionsCss.popoverBodyLink,
                              onClick: props.toggleCsvModal,
                              children: [
                                "Download CSV",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: DashboardDropdownOptionsCss.popoverBodyLinkIcon
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var Css;

var make = DashboardAgencyAgentsOptions;

export {
  Css ,
  make ,
}
/* A Not a pure module */
