// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var icons = Cx.cx([
      "pt-8",
      "pb-4",
      "lg:px-20",
      "px-10"
    ]);

export {
  icons ,
}
/* icons Not a pure module */
