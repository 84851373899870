// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "flex",
      "flex-row",
      "gap-2",
      "items-center",
      "py-2"
    ]);

var container = Cx.cx([
      "flex",
      "md:flex-row",
      "flex-col",
      "gap-2",
      "items-start",
      "md:items-center",
      "text-sm"
    ]);

var dateWrapper = "min-w-36";

export {
  wrapper ,
  container ,
  dateWrapper ,
}
/* wrapper Not a pure module */
