// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LazyImage from "../../../../../../../styleguide/components/LazyImage/LazyImage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronBoxCss from "./HomeJumbotronBoxCss.res.js";

function HomeJumbotronBox(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LazyImage.make, {
                      src: props.icon,
                      height: 60,
                      offset: 100,
                      className: HomeJumbotronBoxCss.icon
                    }),
                JsxRuntime.jsx("div", {
                      children: props.title,
                      className: HomeJumbotronBoxCss.title
                    }),
                JsxRuntime.jsx("div", {
                      children: props.message,
                      className: HomeJumbotronBoxCss.message
                    })
              ],
              className: HomeJumbotronBoxCss.box
            });
}

var Css;

var make = HomeJumbotronBox;

export {
  Css ,
  make ,
}
/* LazyImage Not a pure module */
