// extracted by mini-css-extract-plugin
export var breadcrumbContainer = "LocationsIndexHeader__breadcrumbContainer__rddhf";
export var column = "LocationsIndexHeader__column__fBfx7";
export var downloadIcon = "LocationsIndexHeader__downloadIcon__xsW4D";
export var dropdownBody = "LocationsIndexHeader__dropdownBody__ejxV7";
export var dropdownBodyRow = "LocationsIndexHeader__dropdownBodyRow__kzPPx";
export var dropdownTrigger = "LocationsIndexHeader__dropdownTrigger__yWfaT";
export var editIcon = "LocationsIndexHeader__editIcon__K2yQt";
export var filtersContainer = "LocationsIndexHeader__filtersContainer__kwUD3";
export var flex = "LocationsIndexHeader__flex__ItFWo";
export var flexColumn = "LocationsIndexHeader__flexColumn__iekUa";
export var gap1 = "LocationsIndexHeader__gap1__FxqrX";
export var gap2 = "LocationsIndexHeader__gap2__KtlCK";
export var gap3 = "LocationsIndexHeader__gap3__h0zBG";
export var gap4 = "LocationsIndexHeader__gap4__tdsLF";
export var gap5 = "LocationsIndexHeader__gap5__jCdEf";
export var headerContainer = "LocationsIndexHeader__headerContainer__Nyfx5";
export var label = "LocationsIndexHeader__label__b2_yy";
export var locationsCount = "LocationsIndexHeader__locationsCount__H8RZM";
export var providersDropdown = "LocationsIndexHeader__providersDropdown__koGgR";
export var providersSearch = "LocationsIndexHeader__providersSearch__GbZWZ";
export var reset = "LocationsIndexHeader__reset__xxz8M";
export var row = "LocationsIndexHeader__row__Q80Ys";
export var searchField = "LocationsIndexHeader__searchField__gPA7x";
export var title = "LocationsIndexHeader__title__YYW0o";
export var titleContainer = "LocationsIndexHeader__titleContainer__zjfmJ";