// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as Badge from "../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Table from "../../../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Routes_Tsd from "../../../../../../routes/common/Routes_Tsd.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../../../styleguide/icons/IconSorting.res.js";
import * as TableHeader from "../../../../../../styleguide/components/Table/TableHeader.res.js";
import * as Api_Provider from "../../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as TableHeaderCell from "../../../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as DashboardIndexCss from "../../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardDestroyModal from "../../../../../../styleguide/dashboard/components/modals/destroy/DashboardDestroyModal.res.js";
import * as DashboardProviderProviderTsdNew from "./components/DashboardProviderProviderTsdNew.res.js";
import * as DashboardProviderProviderTsdEdit from "./components/DashboardProviderProviderTsdEdit.res.js";
import * as DashboardProviderProviderTsdsOptions from "./components/DashboardProviderProviderTsdsOptions.res.js";

var initialState = {
  status: "FetchingProviderTsds",
  currentPage: 1,
  totalPages: 1,
  sortBy: {
    NAME: "TsdName",
    VAL: "Asc"
  },
  newProviderTsdModalOpen: false,
  editProviderTsdModalOpen: false,
  destroyProviderTsdModalOpen: false,
  providerTsd: undefined
};

function ProviderTsdSettings(props) {
  var provider = props.provider;
  var container = React.useRef(null);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProviderTsds" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api_Provider.Dashboard.Edit.ProviderTsd.index(provider.id, state.currentPage, state.sortBy), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProviderTsdsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderTsdSettings",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderTsdSettings.make"
                                          }, "Dashboard::FetchProviderTsds::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProviderTsdsFetch");
                                    }));
                            })
                        };
              case "FailProviderTsdsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedProviderTsdsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            newProviderTsdModalOpen: state.newProviderTsdModalOpen,
                            editProviderTsdModalOpen: state.editProviderTsdModalOpen,
                            destroyProviderTsdModalOpen: state.destroyProviderTsdModalOpen,
                            providerTsd: state.providerTsd
                          }
                        };
              case "ToggleNewProviderTsdModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            newProviderTsdModalOpen: !state.newProviderTsdModalOpen,
                            editProviderTsdModalOpen: state.editProviderTsdModalOpen,
                            destroyProviderTsdModalOpen: state.destroyProviderTsdModalOpen,
                            providerTsd: state.providerTsd
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProviderTsdsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.providerTsds
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            newProviderTsdModalOpen: state.newProviderTsdModalOpen,
                            editProviderTsdModalOpen: state.editProviderTsdModalOpen,
                            destroyProviderTsdModalOpen: state.destroyProviderTsdModalOpen,
                            providerTsd: state.providerTsd
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingProviderTsds") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProviderTsds",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              newProviderTsdModalOpen: state.newProviderTsdModalOpen,
                              editProviderTsdModalOpen: state.editProviderTsdModalOpen,
                              destroyProviderTsdModalOpen: state.destroyProviderTsdModalOpen,
                              providerTsd: state.providerTsd
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviderTsds");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingProviderTsds") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "CreatedAt") {
                    var match$2 = state.sortBy;
                    tmp = typeof match$2 === "object" && match$2.NAME === "CreatedAt" && match$2.VAL === "Desc" ? ({
                          NAME: "CreatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "CreatedAt",
                          VAL: "Desc"
                        });
                  } else if (sort === "Preferred") {
                    var match$3 = state.sortBy;
                    tmp = typeof match$3 === "object" && match$3.NAME === "Preferred" && match$3.VAL === "Desc" ? ({
                          NAME: "Preferred",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Preferred",
                          VAL: "Desc"
                        });
                  } else if (sort === "UpdatedAt") {
                    var match$4 = state.sortBy;
                    tmp = typeof match$4 === "object" && match$4.NAME === "UpdatedAt" && match$4.VAL === "Desc" ? ({
                          NAME: "UpdatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "UpdatedAt",
                          VAL: "Desc"
                        });
                  } else {
                    var match$5 = state.sortBy;
                    tmp = typeof match$5 === "object" && match$5.NAME === "TsdName" && match$5.VAL === "Desc" ? ({
                          NAME: "TsdName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "TsdName",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProviderTsds",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            newProviderTsdModalOpen: state.newProviderTsdModalOpen,
                            editProviderTsdModalOpen: state.editProviderTsdModalOpen,
                            destroyProviderTsdModalOpen: state.destroyProviderTsdModalOpen,
                            providerTsd: state.providerTsd
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderTsds");
                            })
                        };
              case "ToggleEditProviderTsdModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            newProviderTsdModalOpen: state.newProviderTsdModalOpen,
                            editProviderTsdModalOpen: !state.editProviderTsdModalOpen,
                            destroyProviderTsdModalOpen: state.destroyProviderTsdModalOpen,
                            providerTsd: action._0
                          }
                        };
              case "ToggleDestroyProviderTsdModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            newProviderTsdModalOpen: state.newProviderTsdModalOpen,
                            editProviderTsdModalOpen: state.editProviderTsdModalOpen,
                            destroyProviderTsdModalOpen: !state.destroyProviderTsdModalOpen,
                            providerTsd: action._0
                          }
                        };
              case "DestroyProviderTsd" :
                  var providerTsd = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Provider.Dashboard.Edit.ProviderTsd.destroy(ID.toString(provider.id), ID.toString(providerTsd.id)), (function (x) {
                                      if (x.TAG !== "Ok") {
                                        return SentryLogger.error1({
                                                    rootModule: "ProviderTsdSettings",
                                                    subModulePath: /* [] */0,
                                                    value: "make",
                                                    fullPath: "ProviderTsdSettings.make"
                                                  }, "Dashboard::DestroyProviderTsd::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                      }
                                      dispatch({
                                            TAG: "SucceedProviderTsdsFetch",
                                            _0: x._0
                                          });
                                      dispatch({
                                            TAG: "ToggleDestroyProviderTsdModalOpen",
                                            _0: undefined
                                          });
                                    }));
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchProviderTsds");
        }), []);
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var providerTsds = state.status;
  var tmp;
  if (typeof providerTsds !== "object") {
    tmp = providerTsds === "FetchingProviderTsds" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardIndexCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var providerTsds$1 = providerTsds._0;
    tmp = providerTsds$1.length !== 0 ? Belt_Array.mapWithIndex(providerTsds$1, (function (index, providerTsd) {
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: providerTsd.tsdName,
                                        className: DashboardIndexCss.tableLink,
                                        href: Routes_Tsd.Dashboard.show(providerTsd.tsdId)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: providerTsd.preferred ? JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Green",
                                          children: "Preferred"
                                        }) : JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Blue",
                                          children: "Alternate"
                                        })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format(new Date(providerTsd.createdAt), "MMM dd, yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format(new Date(providerTsd.updatedAt), "MMM dd, yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardIndexCss.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "View TSD",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: Routes_Tsd.Dashboard.show(providerTsd.tsdId),
                                                                    target: "_blank"
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: "#",
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "ToggleEditProviderTsdModalOpen",
                                                                              _0: providerTsd
                                                                            });
                                                                      })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Delete",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: "#",
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "ToggleDestroyProviderTsdModalOpen",
                                                                              _0: providerTsd
                                                                            });
                                                                      })
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No TSDS were found."
                })
          });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(Container.make, {
                      className: DashboardIndexCss.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H3.make, {
                                      className: DashboardIndexCss.title,
                                      children: "Manage TSDs"
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(DashboardProviderProviderTsdsOptions.make, {
                                            toggleNewProviderTsdModal: (function (param) {
                                                dispatch("ToggleNewProviderTsdModalOpen");
                                              })
                                          })
                                    })
                              ],
                              className: DashboardIndexCss.subHeader
                            }),
                        JsxRuntime.jsxs(Table.make, {
                              className: DashboardIndexCss.table,
                              children: [
                                JsxRuntime.jsx(TableHeader.make, {
                                      children: JsxRuntime.jsxs(TableRow.make, {
                                            children: [
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "TsdName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "TSD",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$1 === "object" && match$1.NAME === "TsdName" ? match$1.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Preferred"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Preferred?",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$2 === "object" && match$2.NAME === "Preferred" ? match$2.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "CreatedAt"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Created At",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$3 === "object" && match$3.NAME === "CreatedAt" ? match$3.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "UpdatedAt"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Updated At",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$4 === "object" && match$4.NAME === "UpdatedAt" ? match$4.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: ""
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(TableBody.make, {
                                      children: tmp
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: DashboardIndexCss.pagination
                            })
                      ]
                    }),
                state.newProviderTsdModalOpen ? JsxRuntime.jsx(DashboardProviderProviderTsdNew.make, {
                        providerId: provider.id,
                        toggleModal: (function () {
                            dispatch("ToggleNewProviderTsdModalOpen");
                          }),
                        updateProviderTsds: (function (index) {
                            dispatch({
                                  TAG: "SucceedProviderTsdsFetch",
                                  _0: index
                                });
                          }),
                        onClick: (function (param) {
                            dispatch("ToggleNewProviderTsdModalOpen");
                          })
                      }) : null,
                state.editProviderTsdModalOpen ? JsxRuntime.jsx(DashboardProviderProviderTsdEdit.make, {
                        providerTsd: Belt_Option.getExn(state.providerTsd),
                        toggleModal: (function () {
                            dispatch({
                                  TAG: "ToggleEditProviderTsdModalOpen",
                                  _0: undefined
                                });
                          }),
                        updateProviderTsds: (function (index) {
                            dispatch({
                                  TAG: "SucceedProviderTsdsFetch",
                                  _0: index
                                });
                          }),
                        onClick: (function (param) {
                            dispatch({
                                  TAG: "ToggleEditProviderTsdModalOpen",
                                  _0: undefined
                                });
                          })
                      }) : null,
                state.destroyProviderTsdModalOpen ? JsxRuntime.jsx(DashboardDestroyModal.make, {
                        title: "Delete TSD?",
                        message: "Are you sure you want to delete the TSD?",
                        close: (function (param) {
                            dispatch({
                                  TAG: "ToggleDestroyProviderTsdModalOpen",
                                  _0: undefined
                                });
                          }),
                        destroy: (function (param) {
                            dispatch({
                                  TAG: "DestroyProviderTsd",
                                  _0: Belt_Option.getExn(state.providerTsd)
                                });
                          })
                      }) : null
              ],
              className: Cx.cx([
                    "pt-10",
                    DashboardIndexCss.pageContainer
                  ])
            });
}

var Css;

var make = ProviderTsdSettings;

export {
  Css ,
  initialState ,
  make ,
}
/* H3 Not a pure module */
