// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Toggle from "../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditActiveButton from "./components/active-button/ProviderEditActiveButton.res.js";
import * as ProviderEditStickyBarCss from "./ProviderEditStickyBarCss.res.js";
import * as ProviderEditApprovalStatus from "./components/approval-status/ProviderEditApprovalStatus.res.js";
import * as ProviderEditApproveChanges from "./components/approval-button/ProviderEditApproveChanges.res.js";

function ProviderEditStickyBar(props) {
  var editStatus = props.editStatus;
  var userRole = props.userRole;
  var live = props.live;
  var provider = props.provider;
  var tmp;
  tmp = userRole === "Admin" ? provider.isApproved : true;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(ProviderEditActiveButton.make, {
                            active: Provider.Active.fromBool(provider.active),
                            provider: provider,
                            userRole: userRole
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("p", {
                                          children: Provider.Edit.Live.viewToString(live),
                                          className: ProviderEditStickyBarCss.inactiveText
                                        }),
                                    JsxRuntime.jsx(Toggle.make, {
                                          on: live === "Live",
                                          size: "SM",
                                          onChange: props.toggleLive
                                        })
                                  ],
                                  className: ProviderEditStickyBarCss.toggleContainer
                                }),
                            className: ProviderEditStickyBarCss.actionButtonsContainer
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(ProviderEditApprovalStatus.make, {
                                    isApproved: provider.isApproved,
                                    lastApprovedOn: provider.lastApprovedOn,
                                    changesSaved: provider.currentDraft.updatedAt,
                                    editStatus: editStatus
                                  }),
                              JsxRuntime.jsx(ProviderEditApproveChanges.make, {
                                    userRole: userRole,
                                    approved: provider.isApproved,
                                    editStatus: editStatus,
                                    approve: props.approve,
                                    disabled: tmp
                                  })
                            ],
                            className: ProviderEditStickyBarCss.approvalContainer
                          })
                    ],
                    className: ProviderEditStickyBarCss.innerContainer
                  }),
              className: ProviderEditStickyBarCss.container,
              id: "provider-sticky-bar"
            });
}

var Css;

var make = ProviderEditStickyBar;

export {
  Css ,
  make ,
}
/* Toggle Not a pure module */
