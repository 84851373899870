// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../../../styleguide/components/Heading/H2.res.js";
import * as ID from "../../../../../../../../libs/ID.res.js";
import * as React from "react";
import * as Button from "../../../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderFeature from "../../../../../../../../api/provider-features/Api_ProviderFeature.res.js";
import * as ProviderEditFeatureForm from "./ProviderEditFeatureForm.res.js";
import * as ProviderEditContainersCss from "../../../../common/ProviderEditContainersCss.res.js";

function reducer(_state, action) {
  return {
          timeout: action._0
        };
}

function ProviderEditFeatures(props) {
  var updateProvider = props.updateProvider;
  var live = props.live;
  var provider = props.provider;
  var match = React.useReducer(reducer, {
        timeout: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var featureCount = provider.features.length;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      children: "Add Provider Features"
                    }),
                JsxRuntime.jsx("div", {
                      children: "Add up to four features of that make this provider stand out.",
                      className: ProviderEditContainersCss.note
                    }),
                Belt_Array.mapWithIndex(provider.features, (function (index, feature) {
                        return JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(ProviderEditFeatureForm.make, {
                                          live: live,
                                          feature: feature,
                                          updateProvider: updateProvider,
                                          updateTimeout: (function (timeout) {
                                              var match = state.timeout;
                                              if (match !== undefined) {
                                                clearTimeout(Belt_Option.getExn(state.timeout));
                                                return dispatch({
                                                            TAG: "SetTimeOut",
                                                            _0: Caml_option.some(timeout)
                                                          });
                                              } else {
                                                return dispatch({
                                                            TAG: "SetTimeOut",
                                                            _0: Caml_option.some(timeout)
                                                          });
                                              }
                                            }),
                                          index: index
                                        }, ID.toString(feature.id)),
                                    className: ProviderEditContainersCss.featureWrapper
                                  }, "provider-feature--" + String(index) + ID.toString(feature.id));
                      })),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              visuallyDisabled: featureCount >= 4,
                              onClick: (function (param) {
                                  $$Promise.wait(Api_ProviderFeature.create({
                                            id: provider.id
                                          }), (function (x) {
                                          if (x.TAG === "Ok") {
                                            return updateProvider(x._0);
                                          } else {
                                            return SentryLogger.error1({
                                                        rootModule: "ProviderEditFeatures",
                                                        subModulePath: /* [] */0,
                                                        value: "make",
                                                        fullPath: "ProviderEditFeatures.make"
                                                      }, "ProviderEditFeatures::Create::Error", [
                                                        "Error",
                                                        x._0
                                                      ]);
                                          }
                                        }));
                                }),
                              children: "Add Feature"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "4 features max",
                              className: ProviderEditContainersCss.note
                            })
                      ],
                      className: ProviderEditContainersCss.featuresButton
                    })
              ],
              className: ProviderEditContainersCss.generalContainer
            });
}

var Css;

var make = ProviderEditFeatures;

export {
  Css ,
  reducer ,
  make ,
}
/* H2 Not a pure module */
