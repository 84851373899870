// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as RestBrowser from "../../RestBrowser.res.js";
import * as ActiveModelError from "../../../models/ActiveModelError.res.js";

function exec(agencyAgentId, userId, agencyId, active, isPrimary) {
  var formData = new FormData();
  formData.append("user_id", userId);
  formData.append("agency_id", agencyId);
  formData.append("active", active);
  formData.append("is_primary", isPrimary);
  return RestBrowser.$$fetch("/dashboard/agency_agents/" + ID.toString(agencyAgentId), formData, "Empty", {
              NAME: "Message",
              VAL: ActiveModelError.Errors.decoder
            }, "Patch", undefined);
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  exec ,
}
/* ID Not a pure module */
