// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as Form from "../../../../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as Picture from "../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Uploader from "../../../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Provider from "../../../../../../api/providers/Api_Provider.res.js";
import * as ErrorMessage from "../../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as IconAdditionalUpload from "../../../../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as ProviderEditContainersCss from "../../common/ProviderEditContainersCss.res.js";

function reducer(_state, action) {
  return {
          timeout: action._0
        };
}

var validators_name = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.name.length;
      if (x !== 0) {
        if (x < 5) {
          return {
                  TAG: "Error",
                  _0: "Name must be longer than 5 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.name
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Name is required."
              };
      }
    })
};

var validators_companyType = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var match = input.companyType;
      switch (match) {
        case "Private" :
        case "Public" :
            return {
                    TAG: "Ok",
                    _0: input.companyType
                  };
        default:
          return {
                  TAG: "Error",
                  _0: "Please select Public or Private"
                };
      }
    })
};

var validators = {
  name: validators_name,
  companyType: validators_companyType,
  tickerSymbol: undefined,
  numberEmployees: undefined,
  yearFounded: undefined,
  hqFullAddress: undefined,
  displayDialCode: undefined,
  displayPhoneNumber: undefined,
  supportPhoneNumber: undefined,
  websiteUrl: undefined,
  logo: undefined,
  metaTitle: undefined,
  metaDescription: undefined
};

function initialFieldsStatuses(_input) {
  return {
          name: "Pristine",
          companyType: "Pristine",
          tickerSymbol: "Pristine",
          numberEmployees: "Pristine",
          yearFounded: "Pristine",
          hqFullAddress: "Pristine",
          displayDialCode: "Pristine",
          displayPhoneNumber: "Pristine",
          supportPhoneNumber: "Pristine",
          websiteUrl: "Pristine",
          logo: "Pristine",
          metaTitle: "Pristine",
          metaDescription: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.name;
  var tmp;
  tmp = typeof match !== "object" ? validators.name.validate(input) : match._0;
  var match$1 = fieldsStatuses.companyType;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.companyType.validate(input) : match$1._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.tickerSymbol
  };
  var match_0$1 = {
    TAG: "Ok",
    _0: input.numberEmployees
  };
  var match_0$2 = {
    TAG: "Ok",
    _0: input.yearFounded
  };
  var match_0$3 = {
    TAG: "Ok",
    _0: input.hqFullAddress
  };
  var match_0$4 = {
    TAG: "Ok",
    _0: input.displayDialCode
  };
  var match_0$5 = {
    TAG: "Ok",
    _0: input.displayPhoneNumber
  };
  var match_0$6 = {
    TAG: "Ok",
    _0: input.supportPhoneNumber
  };
  var match_0$7 = {
    TAG: "Ok",
    _0: input.websiteUrl
  };
  var match_0$8 = {
    TAG: "Ok",
    _0: input.logo
  };
  var match_0$9 = {
    TAG: "Ok",
    _0: input.metaTitle
  };
  var match_0$10 = {
    TAG: "Ok",
    _0: input.metaDescription
  };
  var nameResult = tmp;
  var nameResult$1;
  if (nameResult.TAG === "Ok") {
    var companyTypeResult = tmp$1;
    if (companyTypeResult.TAG === "Ok") {
      var tickerSymbolResult = match_0;
      if (tickerSymbolResult.TAG === "Ok") {
        var numberEmployeesResult = match_0$1;
        if (numberEmployeesResult.TAG === "Ok") {
          var yearFoundedResult = match_0$2;
          if (yearFoundedResult.TAG === "Ok") {
            var hqFullAddressResult = match_0$3;
            if (hqFullAddressResult.TAG === "Ok") {
              var displayDialCodeResult = match_0$4;
              if (displayDialCodeResult.TAG === "Ok") {
                var displayPhoneNumberResult = match_0$5;
                if (displayPhoneNumberResult.TAG === "Ok") {
                  var supportPhoneNumberResult = match_0$6;
                  if (supportPhoneNumberResult.TAG === "Ok") {
                    var websiteUrlResult = match_0$7;
                    if (websiteUrlResult.TAG === "Ok") {
                      var logoResult = match_0$8;
                      if (logoResult.TAG === "Ok") {
                        var metaTitleResult = match_0$9;
                        if (metaTitleResult.TAG === "Ok") {
                          var metaDescriptionResult = match_0$10;
                          if (metaDescriptionResult.TAG === "Ok") {
                            return {
                                    TAG: "Valid",
                                    output: {
                                      name: nameResult._0,
                                      companyType: companyTypeResult._0,
                                      tickerSymbol: tickerSymbolResult._0,
                                      numberEmployees: numberEmployeesResult._0,
                                      yearFounded: yearFoundedResult._0,
                                      hqFullAddress: hqFullAddressResult._0,
                                      displayDialCode: displayDialCodeResult._0,
                                      displayPhoneNumber: displayPhoneNumberResult._0,
                                      supportPhoneNumber: supportPhoneNumberResult._0,
                                      websiteUrl: websiteUrlResult._0,
                                      logo: logoResult._0,
                                      metaTitle: metaTitleResult._0,
                                      metaDescription: metaDescriptionResult._0
                                    },
                                    fieldsStatuses: {
                                      name: {
                                        TAG: "Dirty",
                                        _0: nameResult,
                                        _1: "Shown"
                                      },
                                      companyType: {
                                        TAG: "Dirty",
                                        _0: companyTypeResult,
                                        _1: "Shown"
                                      },
                                      tickerSymbol: {
                                        TAG: "Dirty",
                                        _0: tickerSymbolResult,
                                        _1: "Hidden"
                                      },
                                      numberEmployees: {
                                        TAG: "Dirty",
                                        _0: numberEmployeesResult,
                                        _1: "Hidden"
                                      },
                                      yearFounded: {
                                        TAG: "Dirty",
                                        _0: yearFoundedResult,
                                        _1: "Hidden"
                                      },
                                      hqFullAddress: {
                                        TAG: "Dirty",
                                        _0: hqFullAddressResult,
                                        _1: "Hidden"
                                      },
                                      displayDialCode: {
                                        TAG: "Dirty",
                                        _0: displayDialCodeResult,
                                        _1: "Hidden"
                                      },
                                      displayPhoneNumber: {
                                        TAG: "Dirty",
                                        _0: displayPhoneNumberResult,
                                        _1: "Hidden"
                                      },
                                      supportPhoneNumber: {
                                        TAG: "Dirty",
                                        _0: supportPhoneNumberResult,
                                        _1: "Hidden"
                                      },
                                      websiteUrl: {
                                        TAG: "Dirty",
                                        _0: websiteUrlResult,
                                        _1: "Hidden"
                                      },
                                      logo: {
                                        TAG: "Dirty",
                                        _0: logoResult,
                                        _1: "Hidden"
                                      },
                                      metaTitle: {
                                        TAG: "Dirty",
                                        _0: metaTitleResult,
                                        _1: "Hidden"
                                      },
                                      metaDescription: {
                                        TAG: "Dirty",
                                        _0: metaDescriptionResult,
                                        _1: "Hidden"
                                      }
                                    },
                                    collectionsStatuses: undefined
                                  };
                          }
                          nameResult$1 = nameResult;
                        } else {
                          nameResult$1 = nameResult;
                        }
                      } else {
                        nameResult$1 = nameResult;
                      }
                    } else {
                      nameResult$1 = nameResult;
                    }
                  } else {
                    nameResult$1 = nameResult;
                  }
                } else {
                  nameResult$1 = nameResult;
                }
              } else {
                nameResult$1 = nameResult;
              }
            } else {
              nameResult$1 = nameResult;
            }
          } else {
            nameResult$1 = nameResult;
          }
        } else {
          nameResult$1 = nameResult;
        }
      } else {
        nameResult$1 = nameResult;
      }
    } else {
      nameResult$1 = nameResult;
    }
  } else {
    nameResult$1 = nameResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            name: {
              TAG: "Dirty",
              _0: nameResult$1,
              _1: "Shown"
            },
            companyType: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            tickerSymbol: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            },
            numberEmployees: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            },
            yearFounded: {
              TAG: "Dirty",
              _0: match_0$2,
              _1: "Hidden"
            },
            hqFullAddress: {
              TAG: "Dirty",
              _0: match_0$3,
              _1: "Hidden"
            },
            displayDialCode: {
              TAG: "Dirty",
              _0: match_0$4,
              _1: "Hidden"
            },
            displayPhoneNumber: {
              TAG: "Dirty",
              _0: match_0$5,
              _1: "Hidden"
            },
            supportPhoneNumber: {
              TAG: "Dirty",
              _0: match_0$6,
              _1: "Hidden"
            },
            websiteUrl: {
              TAG: "Dirty",
              _0: match_0$7,
              _1: "Hidden"
            },
            logo: {
              TAG: "Dirty",
              _0: match_0$8,
              _1: "Hidden"
            },
            metaTitle: {
              TAG: "Dirty",
              _0: match_0$9,
              _1: "Hidden"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: match_0$10,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurNameField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: status,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurCompanyTypeField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.companyType, validators_companyType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: status,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurTickerSymbolField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.tickerSymbol, state.fieldsStatuses.tickerSymbol, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: status,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurNumberEmployeesField" :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.numberEmployees, state.fieldsStatuses.numberEmployees, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: status,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurYearFoundedField" :
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.yearFounded, state.fieldsStatuses.yearFounded, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: status,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurHqFullAddressField" :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.hqFullAddress, state.fieldsStatuses.hqFullAddress, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: status,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDisplayDialCodeField" :
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.displayDialCode, state.fieldsStatuses.displayDialCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: status,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDisplayPhoneNumberField" :
                  var result$7 = Formality.validateFieldOnBlurWithoutValidator(state.input.displayPhoneNumber, state.fieldsStatuses.displayPhoneNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: status,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurSupportPhoneNumberField" :
                  var result$8 = Formality.validateFieldOnBlurWithoutValidator(state.input.supportPhoneNumber, state.fieldsStatuses.supportPhoneNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: status,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurWebsiteUrlField" :
                  var result$9 = Formality.validateFieldOnBlurWithoutValidator(state.input.websiteUrl, state.fieldsStatuses.websiteUrl, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: status,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurLogoField" :
                  var result$10 = Formality.validateFieldOnBlurWithoutValidator(state.input.logo, state.fieldsStatuses.logo, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: status,
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaTitleField" :
                  var result$11 = Formality.validateFieldOnBlurWithoutValidator(state.input.metaTitle, state.fieldsStatuses.metaTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: status,
                                  metaDescription: init.metaDescription
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$12 = Formality.validateFieldOnBlurWithoutValidator(state.input.metaDescription, state.fieldsStatuses.metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  name: init.name,
                                  companyType: init.companyType,
                                  tickerSymbol: init.tickerSymbol,
                                  numberEmployees: init.numberEmployees,
                                  yearFounded: init.yearFounded,
                                  hqFullAddress: init.hqFullAddress,
                                  displayDialCode: init.displayDialCode,
                                  displayPhoneNumber: init.displayPhoneNumber,
                                  supportPhoneNumber: init.supportPhoneNumber,
                                  websiteUrl: init.websiteUrl,
                                  logo: init.logo,
                                  metaTitle: init.metaTitle,
                                  metaDescription: status
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateNameField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: status,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateCompanyTypeField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.companyType, state.submissionStatus, validators_companyType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: status,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateTickerSymbolField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.tickerSymbol, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: status,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateNumberEmployeesField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.numberEmployees, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: status,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateYearFoundedField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.yearFounded, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: status,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateHqFullAddressField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.hqFullAddress, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: status,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDisplayDialCodeField" :
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.displayDialCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: status,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDisplayPhoneNumberField" :
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$7.displayPhoneNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: status,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateSupportPhoneNumberField" :
                  var nextInput$8 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$8.supportPhoneNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: status,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateWebsiteUrlField" :
                  var nextInput$9 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$9.websiteUrl, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: status,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateLogoField" :
                  var nextInput$10 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$10.logo, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: status,
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaTitleField" :
                  var nextInput$11 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$11.metaTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: status,
                                            metaDescription: init.metaDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$12 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$12.metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            name: init.name,
                                            companyType: init.companyType,
                                            tickerSymbol: init.tickerSymbol,
                                            numberEmployees: init.numberEmployees,
                                            yearFounded: init.yearFounded,
                                            hqFullAddress: init.hqFullAddress,
                                            displayDialCode: init.displayDialCode,
                                            displayPhoneNumber: init.displayPhoneNumber,
                                            supportPhoneNumber: init.supportPhoneNumber,
                                            websiteUrl: init.websiteUrl,
                                            logo: init.logo,
                                            metaTitle: init.metaTitle,
                                            metaDescription: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateCompanyType: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateCompanyTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateTickerSymbol: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTickerSymbolField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateNumberEmployees: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNumberEmployeesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateYearFounded: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateYearFoundedField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateHqFullAddress: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateHqFullAddressField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDisplayDialCode: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDisplayDialCodeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDisplayPhoneNumber: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDisplayPhoneNumberField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateSupportPhoneNumber: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateSupportPhoneNumberField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateWebsiteUrl: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateWebsiteUrlField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateLogo: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateLogoField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurName: (function () {
              dispatch("BlurNameField");
            }),
          blurCompanyType: (function () {
              dispatch("BlurCompanyTypeField");
            }),
          blurTickerSymbol: (function () {
              dispatch("BlurTickerSymbolField");
            }),
          blurNumberEmployees: (function () {
              dispatch("BlurNumberEmployeesField");
            }),
          blurYearFounded: (function () {
              dispatch("BlurYearFoundedField");
            }),
          blurHqFullAddress: (function () {
              dispatch("BlurHqFullAddressField");
            }),
          blurDisplayDialCode: (function () {
              dispatch("BlurDisplayDialCodeField");
            }),
          blurDisplayPhoneNumber: (function () {
              dispatch("BlurDisplayPhoneNumberField");
            }),
          blurSupportPhoneNumber: (function () {
              dispatch("BlurSupportPhoneNumberField");
            }),
          blurWebsiteUrl: (function () {
              dispatch("BlurWebsiteUrlField");
            }),
          blurLogo: (function () {
              dispatch("BlurLogoField");
            }),
          blurMetaTitle: (function () {
              dispatch("BlurMetaTitleField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          companyTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.companyType),
          tickerSymbolResult: Formality.exposeFieldResult(state.fieldsStatuses.tickerSymbol),
          numberEmployeesResult: Formality.exposeFieldResult(state.fieldsStatuses.numberEmployees),
          yearFoundedResult: Formality.exposeFieldResult(state.fieldsStatuses.yearFounded),
          hqFullAddressResult: Formality.exposeFieldResult(state.fieldsStatuses.hqFullAddress),
          displayDialCodeResult: Formality.exposeFieldResult(state.fieldsStatuses.displayDialCode),
          displayPhoneNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.displayPhoneNumber),
          supportPhoneNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.supportPhoneNumber),
          websiteUrlResult: Formality.exposeFieldResult(state.fieldsStatuses.websiteUrl),
          logoResult: Formality.exposeFieldResult(state.fieldsStatuses.logo),
          metaTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.metaTitle),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var exit = 0;
              var tmp = match.name;
              if (typeof tmp !== "object") {
                var tmp$1 = match.companyType;
                if (typeof tmp$1 !== "object") {
                  var tmp$2 = match.tickerSymbol;
                  if (typeof tmp$2 !== "object") {
                    var tmp$3 = match.numberEmployees;
                    if (typeof tmp$3 !== "object") {
                      var tmp$4 = match.yearFounded;
                      if (typeof tmp$4 !== "object") {
                        var tmp$5 = match.hqFullAddress;
                        if (typeof tmp$5 !== "object") {
                          var tmp$6 = match.displayDialCode;
                          if (typeof tmp$6 !== "object") {
                            var tmp$7 = match.displayPhoneNumber;
                            if (typeof tmp$7 !== "object") {
                              var tmp$8 = match.supportPhoneNumber;
                              if (typeof tmp$8 !== "object") {
                                var tmp$9 = match.websiteUrl;
                                if (typeof tmp$9 !== "object") {
                                  var tmp$10 = match.logo;
                                  if (typeof tmp$10 !== "object") {
                                    var tmp$11 = match.metaTitle;
                                    if (typeof tmp$11 !== "object") {
                                      var tmp$12 = match.metaDescription;
                                      if (typeof tmp$12 !== "object") {
                                        return false;
                                      }
                                      exit = 1;
                                    } else {
                                      exit = 1;
                                    }
                                  } else {
                                    exit = 1;
                                  }
                                } else {
                                  exit = 1;
                                }
                              } else {
                                exit = 1;
                              }
                            } else {
                              exit = 1;
                            }
                          } else {
                            exit = 1;
                          }
                        } else {
                          exit = 1;
                        }
                      } else {
                        exit = 1;
                      }
                    } else {
                      exit = 1;
                    }
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    return true;
                  }
                  
                }
                
              }
              
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ProviderForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditGeneralInfo(props) {
  var updateEditStatus = props.updateEditStatus;
  var updateProvider = props.updateProvider;
  var userRole = props.userRole;
  var live = props.live;
  var provider = props.provider;
  var match = React.useReducer(reducer, {
        timeout: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var logoClass = ProviderEditContainersCss.logoUploader;
  var initialInput = React.useMemo((function () {
          return {
                  name: provider.currentDraft.name,
                  companyType: provider.currentDraft.companyType,
                  tickerSymbol: provider.currentDraft.tickerSymbol,
                  numberEmployees: provider.currentDraft.numberEmployees,
                  yearFounded: provider.currentDraft.yearFounded,
                  hqFullAddress: provider.currentDraft.hqFullAddress,
                  displayDialCode: provider.currentDraft.displayDialCode,
                  displayPhoneNumber: provider.currentDraft.displayPhoneNumber,
                  supportPhoneNumber: provider.currentDraft.supportPhoneNumber,
                  websiteUrl: provider.currentDraft.websiteUrl,
                  logo: provider.currentDraft.logo,
                  metaTitle: provider.currentDraft.metaTitle,
                  metaDescription: provider.currentDraft.metaDescription
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          var init = provider.currentDraft;
          $$Promise.wait(Api_Provider.updateDraft(provider.currentDraft.id, {
                    id: init.id,
                    name: output.name.trim(),
                    companyType: output.companyType.trim(),
                    tickerSymbol: output.tickerSymbol.trim(),
                    numberEmployees: output.numberEmployees,
                    yearFounded: output.yearFounded,
                    hqFullAddress: output.hqFullAddress,
                    displayDialCode: output.displayDialCode,
                    displayPhoneNumber: output.displayPhoneNumber,
                    supportPhoneNumber: output.supportPhoneNumber,
                    websiteUrl: output.websiteUrl,
                    description: init.description,
                    logo: Belt_Option.getExn(output.logo),
                    projectWebhookUrl: init.projectWebhookUrl,
                    updatedAt: init.updatedAt,
                    metaTitle: output.metaTitle,
                    metaDescription: output.metaDescription
                  }), (function (x) {
                  if (x.TAG === "Ok") {
                    var res = x._0;
                    updateProvider(res);
                    updateEditStatus("Editing");
                    return cb.notifyOnSuccess({
                                name: res.currentDraft.name.trim(),
                                companyType: res.currentDraft.companyType,
                                tickerSymbol: res.currentDraft.tickerSymbol.trim(),
                                numberEmployees: res.currentDraft.numberEmployees,
                                yearFounded: res.currentDraft.yearFounded,
                                hqFullAddress: res.currentDraft.hqFullAddress,
                                displayDialCode: res.currentDraft.displayDialCode,
                                displayPhoneNumber: res.currentDraft.displayPhoneNumber,
                                supportPhoneNumber: res.currentDraft.supportPhoneNumber,
                                websiteUrl: res.currentDraft.websiteUrl,
                                logo: res.currentDraft.logo,
                                metaTitle: res.currentDraft.metaTitle,
                                metaDescription: res.currentDraft.metaDescription
                              });
                  }
                  SentryLogger.error1({
                        rootModule: "ProviderEditGeneralInfo",
                        subModulePath: /* [] */0,
                        value: "make",
                        fullPath: "ProviderEditGeneralInfo.make"
                      }, "UpdateLocationDraft::Error", [
                        "Error",
                        cb.notifyOnFailure()
                      ]);
                  updateEditStatus("Error");
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var submitForm = function (param) {
    var match = state.timeout;
    if (match !== undefined) {
      clearTimeout(Belt_Option.getExn(state.timeout));
      dispatch({
            TAG: "SetTimeOut",
            _0: Caml_option.some(setTimeout((function () {
                        form.submit();
                      }), 1200))
          });
      return updateEditStatus("Updating");
    } else {
      updateEditStatus("Updating");
      return dispatch({
                  TAG: "SetTimeOut",
                  _0: Caml_option.some(setTimeout((function () {
                              form.submit();
                            }), 1200))
                });
    }
  };
  var tmp;
  tmp = userRole === "Admin" ? JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(Label.make, {
                  forId: "edit-provider-form--displayPhone",
                  children: "Phone Number"
                }),
            JsxRuntime.jsx(TextField.make, {
                  id: "edit-provider-form--displayPhonenumber",
                  value: live === "Live" ? provider.displayPhoneNumber : form.input.displayPhoneNumber,
                  placeholder: "1-555-555-5555",
                  disabled: live === "Live" || form.submitting ? true : false,
                  className: provider.displayPhoneNumber === form.input.displayPhoneNumber ? "" : ProviderEditContainersCss.fieldChanged,
                  onChange: (function ($$event) {
                      form.updateDisplayDialCode((function (input, value) {
                              return {
                                      name: input.name,
                                      companyType: input.companyType,
                                      tickerSymbol: input.tickerSymbol,
                                      numberEmployees: input.numberEmployees,
                                      yearFounded: input.yearFounded,
                                      hqFullAddress: input.hqFullAddress,
                                      displayDialCode: input.displayDialCode,
                                      displayPhoneNumber: value,
                                      supportPhoneNumber: input.supportPhoneNumber,
                                      websiteUrl: input.websiteUrl,
                                      logo: input.logo,
                                      metaTitle: input.metaTitle,
                                      metaDescription: input.metaDescription
                                    };
                            }), $$event.target.value);
                      submitForm();
                    })
                })
          ],
          className: ProviderEditContainersCss.fourColumn
        }) : null;
  var match$1 = form.input.logo;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "General Information"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs(Form.make, {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-provider-form--name",
                                                    children: "Company Name"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-provider-form--name",
                                                    value: live === "Live" ? provider.name : form.input.name,
                                                    placeholder: "Company Name",
                                                    disabled: live === "Live" || form.submitting ? true : false,
                                                    className: provider.name === form.input.name ? "" : ProviderEditContainersCss.fieldChanged,
                                                    onChange: (function ($$event) {
                                                        form.updateName((function (input, value) {
                                                                return {
                                                                        name: value,
                                                                        companyType: input.companyType,
                                                                        tickerSymbol: input.tickerSymbol,
                                                                        numberEmployees: input.numberEmployees,
                                                                        yearFounded: input.yearFounded,
                                                                        hqFullAddress: input.hqFullAddress,
                                                                        displayDialCode: input.displayDialCode,
                                                                        displayPhoneNumber: input.displayPhoneNumber,
                                                                        supportPhoneNumber: input.supportPhoneNumber,
                                                                        websiteUrl: input.websiteUrl,
                                                                        logo: input.logo,
                                                                        metaTitle: input.metaTitle,
                                                                        metaDescription: input.metaDescription
                                                                      };
                                                              }), $$event.target.value);
                                                        submitForm();
                                                      })
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "This is how your company will be listed in search results.",
                                                      JsxRuntime.jsx("br", {}),
                                                      "Company URL: https://www.datacenters.com/providers/" + provider.slug
                                                    ],
                                                    className: ProviderEditContainersCss.subText
                                                  }),
                                              fieldError(form.nameResult)
                                            ],
                                            className: ProviderEditContainersCss.fieldRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "edit-provider-form--companyType",
                                                            children: "Company Type"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: "edit-provider-form--companyType",
                                                            value: live === "Live" ? provider.companyType : form.input.companyType,
                                                            placeholder: "Public or Private",
                                                            disabled: live === "Live" || form.submitting ? true : false,
                                                            className: provider.companyType === form.input.companyType ? "" : ProviderEditContainersCss.fieldChanged,
                                                            onChange: (function ($$event) {
                                                                form.updateCompanyType((function (input, value) {
                                                                        return {
                                                                                name: input.name,
                                                                                companyType: value,
                                                                                tickerSymbol: input.tickerSymbol,
                                                                                numberEmployees: input.numberEmployees,
                                                                                yearFounded: input.yearFounded,
                                                                                hqFullAddress: input.hqFullAddress,
                                                                                displayDialCode: input.displayDialCode,
                                                                                displayPhoneNumber: input.displayPhoneNumber,
                                                                                supportPhoneNumber: input.supportPhoneNumber,
                                                                                websiteUrl: input.websiteUrl,
                                                                                logo: input.logo,
                                                                                metaTitle: input.metaTitle,
                                                                                metaDescription: input.metaDescription
                                                                              };
                                                                      }), $$event.target.value);
                                                                submitForm();
                                                              })
                                                          }),
                                                      fieldError(form.companyTypeResult)
                                                    ],
                                                    className: ProviderEditContainersCss.fourColumn
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "edit-provider-form--tickerSymbol",
                                                            children: "Stock Symbol"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: "edit-provider-form--tickerSymbol",
                                                            value: live === "Live" ? provider.tickerSymbol : form.input.tickerSymbol,
                                                            placeholder: "Stock Symbol",
                                                            disabled: live === "Live" || form.submitting ? true : false,
                                                            className: provider.tickerSymbol === form.input.tickerSymbol ? "" : ProviderEditContainersCss.fieldChanged,
                                                            onChange: (function ($$event) {
                                                                form.updateTickerSymbol((function (input, value) {
                                                                        return {
                                                                                name: input.name,
                                                                                companyType: input.companyType,
                                                                                tickerSymbol: value,
                                                                                numberEmployees: input.numberEmployees,
                                                                                yearFounded: input.yearFounded,
                                                                                hqFullAddress: input.hqFullAddress,
                                                                                displayDialCode: input.displayDialCode,
                                                                                displayPhoneNumber: input.displayPhoneNumber,
                                                                                supportPhoneNumber: input.supportPhoneNumber,
                                                                                websiteUrl: input.websiteUrl,
                                                                                logo: input.logo,
                                                                                metaTitle: input.metaTitle,
                                                                                metaDescription: input.metaDescription
                                                                              };
                                                                      }), $$event.target.value);
                                                                submitForm();
                                                              })
                                                          })
                                                    ],
                                                    className: ProviderEditContainersCss.fourColumn
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "edit-provider-form--numberEmployees",
                                                            children: "Number of Employees"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: "edit-provider-form--numberEmployees",
                                                            value: live === "Live" ? provider.numberEmployees : form.input.numberEmployees,
                                                            placeholder: "0",
                                                            disabled: live === "Live" || form.submitting ? true : false,
                                                            className: provider.numberEmployees === form.input.numberEmployees ? "" : ProviderEditContainersCss.fieldChanged,
                                                            onChange: (function ($$event) {
                                                                form.updateNumberEmployees((function (input, value) {
                                                                        return {
                                                                                name: input.name,
                                                                                companyType: input.companyType,
                                                                                tickerSymbol: input.tickerSymbol,
                                                                                numberEmployees: value,
                                                                                yearFounded: input.yearFounded,
                                                                                hqFullAddress: input.hqFullAddress,
                                                                                displayDialCode: input.displayDialCode,
                                                                                displayPhoneNumber: input.displayPhoneNumber,
                                                                                supportPhoneNumber: input.supportPhoneNumber,
                                                                                websiteUrl: input.websiteUrl,
                                                                                logo: input.logo,
                                                                                metaTitle: input.metaTitle,
                                                                                metaDescription: input.metaDescription
                                                                              };
                                                                      }), $$event.target.value);
                                                                submitForm();
                                                              })
                                                          })
                                                    ],
                                                    className: ProviderEditContainersCss.fourColumn
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "edit-provider-form--yearFounded",
                                                            children: "Year Founded"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: "edit-provider-form--yearFounded",
                                                            value: live === "Live" ? provider.yearFounded : form.input.yearFounded,
                                                            placeholder: String(new Date().getFullYear()),
                                                            disabled: live === "Live" || form.submitting ? true : false,
                                                            className: provider.yearFounded === form.input.yearFounded ? "" : ProviderEditContainersCss.fieldChanged,
                                                            onChange: (function ($$event) {
                                                                form.updateYearFounded((function (input, value) {
                                                                        return {
                                                                                name: input.name,
                                                                                companyType: input.companyType,
                                                                                tickerSymbol: input.tickerSymbol,
                                                                                numberEmployees: input.numberEmployees,
                                                                                yearFounded: value,
                                                                                hqFullAddress: input.hqFullAddress,
                                                                                displayDialCode: input.displayDialCode,
                                                                                displayPhoneNumber: input.displayPhoneNumber,
                                                                                supportPhoneNumber: input.supportPhoneNumber,
                                                                                websiteUrl: input.websiteUrl,
                                                                                logo: input.logo,
                                                                                metaTitle: input.metaTitle,
                                                                                metaDescription: input.metaDescription
                                                                              };
                                                                      }), $$event.target.value);
                                                                submitForm();
                                                              })
                                                          })
                                                    ],
                                                    className: ProviderEditContainersCss.fourColumn
                                                  })
                                            ],
                                            className: ProviderEditContainersCss.fourInRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-provider-form--hqFullAddress",
                                                    children: "Headquarters Address"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-provider-form--hqFullAddress",
                                                    value: live === "Live" ? provider.hqFullAddress : form.input.hqFullAddress,
                                                    placeholder: "12345 Main Street, Denver, CO 80112",
                                                    disabled: live === "Live" || form.submitting ? true : false,
                                                    className: provider.hqFullAddress === form.input.hqFullAddress ? "" : ProviderEditContainersCss.fieldChanged,
                                                    onChange: (function ($$event) {
                                                        form.updateHqFullAddress((function (input, value) {
                                                                return {
                                                                        name: input.name,
                                                                        companyType: input.companyType,
                                                                        tickerSymbol: input.tickerSymbol,
                                                                        numberEmployees: input.numberEmployees,
                                                                        yearFounded: input.yearFounded,
                                                                        hqFullAddress: value,
                                                                        displayDialCode: input.displayDialCode,
                                                                        displayPhoneNumber: input.displayPhoneNumber,
                                                                        supportPhoneNumber: input.supportPhoneNumber,
                                                                        websiteUrl: input.websiteUrl,
                                                                        logo: input.logo,
                                                                        metaTitle: input.metaTitle,
                                                                        metaDescription: input.metaDescription
                                                                      };
                                                              }), $$event.target.value);
                                                        submitForm();
                                                      })
                                                  })
                                            ],
                                            className: ProviderEditContainersCss.fieldRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              tmp,
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(Label.make, {
                                                            forId: "edit-provider-form--supportPhone",
                                                            children: "Support Phone Number"
                                                          }),
                                                      JsxRuntime.jsx(TextField.make, {
                                                            id: "edit-provider-form--supportPhone",
                                                            value: live === "Live" ? provider.supportPhoneNumber : form.input.supportPhoneNumber,
                                                            placeholder: "1-555-555-5555",
                                                            disabled: live === "Live" || form.submitting ? true : false,
                                                            className: provider.supportPhoneNumber === form.input.supportPhoneNumber ? "" : ProviderEditContainersCss.fieldChanged,
                                                            onChange: (function ($$event) {
                                                                form.updateSupportPhoneNumber((function (input, value) {
                                                                        return {
                                                                                name: input.name,
                                                                                companyType: input.companyType,
                                                                                tickerSymbol: input.tickerSymbol,
                                                                                numberEmployees: input.numberEmployees,
                                                                                yearFounded: input.yearFounded,
                                                                                hqFullAddress: input.hqFullAddress,
                                                                                displayDialCode: input.displayDialCode,
                                                                                displayPhoneNumber: input.displayPhoneNumber,
                                                                                supportPhoneNumber: value,
                                                                                websiteUrl: input.websiteUrl,
                                                                                logo: input.logo,
                                                                                metaTitle: input.metaTitle,
                                                                                metaDescription: input.metaDescription
                                                                              };
                                                                      }), $$event.target.value);
                                                                submitForm();
                                                              })
                                                          })
                                                    ],
                                                    className: ProviderEditContainersCss.fourColumn
                                                  })
                                            ],
                                            className: ProviderEditContainersCss.fourInRow
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-provider-form--websiteUrl",
                                                    children: "Website Address"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-provider-form--websiteUrl",
                                                    value: live === "Live" ? provider.websiteUrl : form.input.websiteUrl,
                                                    placeholder: "Add your website here.",
                                                    disabled: live === "Live" || form.submitting ? true : false,
                                                    className: provider.websiteUrl === form.input.websiteUrl ? "" : ProviderEditContainersCss.fieldChanged,
                                                    onChange: (function ($$event) {
                                                        form.updateWebsiteUrl((function (input, value) {
                                                                return {
                                                                        name: input.name,
                                                                        companyType: input.companyType,
                                                                        tickerSymbol: input.tickerSymbol,
                                                                        numberEmployees: input.numberEmployees,
                                                                        yearFounded: input.yearFounded,
                                                                        hqFullAddress: input.hqFullAddress,
                                                                        displayDialCode: input.displayDialCode,
                                                                        displayPhoneNumber: input.displayPhoneNumber,
                                                                        supportPhoneNumber: input.supportPhoneNumber,
                                                                        websiteUrl: value,
                                                                        logo: input.logo,
                                                                        metaTitle: input.metaTitle,
                                                                        metaDescription: input.metaDescription
                                                                      };
                                                              }), $$event.target.value);
                                                        submitForm();
                                                      })
                                                  })
                                            ],
                                            className: Cx.cx([
                                                  ProviderEditContainersCss.fieldRow,
                                                  ProviderEditContainersCss.twoColumn
                                                ])
                                          })
                                    ]
                                  })
                            })
                      ],
                      className: ProviderEditContainersCss.generalContainer
                    }),
                userRole === "Admin" ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(H3.make, {
                                children: "SEO Overrides"
                              }),
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(Label.make, {
                                        forId: "edit-provider-form--metaTitle",
                                        children: "Meta Title"
                                      }),
                                  JsxRuntime.jsx(TextField.make, {
                                        id: "edit-provider-form--metaTitle",
                                        value: live === "Live" ? provider.metaTitle : form.input.metaTitle,
                                        placeholder: "Meta Title Override",
                                        disabled: live === "Live" || form.submitting ? true : false,
                                        className: provider.metaTitle === form.input.metaTitle ? "" : ProviderEditContainersCss.fieldChanged,
                                        onChange: (function ($$event) {
                                            form.updateMetaTitle((function (input, value) {
                                                    return {
                                                            name: input.name,
                                                            companyType: input.companyType,
                                                            tickerSymbol: input.tickerSymbol,
                                                            numberEmployees: input.numberEmployees,
                                                            yearFounded: input.yearFounded,
                                                            hqFullAddress: input.hqFullAddress,
                                                            displayDialCode: input.displayDialCode,
                                                            displayPhoneNumber: input.displayPhoneNumber,
                                                            supportPhoneNumber: input.supportPhoneNumber,
                                                            websiteUrl: input.websiteUrl,
                                                            logo: input.logo,
                                                            metaTitle: value,
                                                            metaDescription: input.metaDescription
                                                          };
                                                  }), $$event.target.value);
                                            submitForm();
                                          })
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: "This will override the default Meta Title tag for SEO purposes.",
                                        className: ProviderEditContainersCss.subText
                                      })
                                ],
                                className: ProviderEditContainersCss.fieldRow
                              }),
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(Label.make, {
                                        forId: "edit-provider-form--metaDescription",
                                        children: "Meta Description"
                                      }),
                                  JsxRuntime.jsx(TextField.make, {
                                        id: "edit-provider-form--metaDescription",
                                        value: live === "Live" ? provider.metaDescription : form.input.metaDescription,
                                        placeholder: "Meta Description Override",
                                        disabled: live === "Live" || form.submitting ? true : false,
                                        className: provider.metaDescription === form.input.metaDescription ? "" : ProviderEditContainersCss.fieldChanged,
                                        onChange: (function ($$event) {
                                            form.updateMetaDescription((function (input, value) {
                                                    return {
                                                            name: input.name,
                                                            companyType: input.companyType,
                                                            tickerSymbol: input.tickerSymbol,
                                                            numberEmployees: input.numberEmployees,
                                                            yearFounded: input.yearFounded,
                                                            hqFullAddress: input.hqFullAddress,
                                                            displayDialCode: input.displayDialCode,
                                                            displayPhoneNumber: input.displayPhoneNumber,
                                                            supportPhoneNumber: input.supportPhoneNumber,
                                                            websiteUrl: input.websiteUrl,
                                                            logo: input.logo,
                                                            metaTitle: input.metaTitle,
                                                            metaDescription: value
                                                          };
                                                  }), $$event.target.value);
                                            submitForm();
                                          })
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: "This will override the default Meta Description tag for SEO purposes.",
                                        className: ProviderEditContainersCss.subText
                                      })
                                ],
                                className: ProviderEditContainersCss.fieldRow
                              })
                        ],
                        className: ProviderEditContainersCss.generalContainer
                      }) : null,
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Add Company Logo"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Form.make, {
                                    children: JsxRuntime.jsx(Uploader.make, {
                                          allowed: [
                                            "Jpg",
                                            "Png"
                                          ],
                                          multiple: false,
                                          upload: (function (files) {
                                              Belt_Array.map(files, (function (file) {
                                                      var match = Uploader.FileType.fromMime(file.type);
                                                      switch (match) {
                                                        case "Jpg" :
                                                        case "Png" :
                                                            break;
                                                        default:
                                                          return ;
                                                      }
                                                      $$Promise.wait(Api_Provider.updateDraftLogo(provider.id, file), (function (x) {
                                                              if (x.TAG !== "Ok") {
                                                                return SentryLogger.error1({
                                                                            rootModule: "ProviderEditGeneralInfo",
                                                                            subModulePath: /* [] */0,
                                                                            value: "make",
                                                                            fullPath: "ProviderEditGeneralInfo.make"
                                                                          }, "BlogPostsDashboardAnalytics::FetchBlogPosts::Error", [
                                                                            "Error",
                                                                            x._0
                                                                          ]);
                                                              }
                                                              var res = x._0;
                                                              updateProvider(res);
                                                              form.updateLogo((function (input, value) {
                                                                      return {
                                                                              name: input.name,
                                                                              companyType: input.companyType,
                                                                              tickerSymbol: input.tickerSymbol,
                                                                              numberEmployees: input.numberEmployees,
                                                                              yearFounded: input.yearFounded,
                                                                              hqFullAddress: input.hqFullAddress,
                                                                              displayDialCode: input.displayDialCode,
                                                                              displayPhoneNumber: input.displayPhoneNumber,
                                                                              supportPhoneNumber: input.supportPhoneNumber,
                                                                              websiteUrl: input.websiteUrl,
                                                                              logo: value,
                                                                              metaTitle: input.metaTitle,
                                                                              metaDescription: input.metaDescription
                                                                            };
                                                                    }), res.currentDraft.logo);
                                                            }));
                                                    }));
                                            }),
                                          setUploadHandler: (function (prim) {
                                              
                                            }),
                                          children: match$1 !== undefined ? JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(Picture.make, {
                                                                src: live === "Live" ? provider.logo : Belt_Option.getExn(form.input.logo),
                                                                large: [
                                                                  120,
                                                                  120
                                                                ],
                                                                crop: "Fit"
                                                              }),
                                                          className: ProviderEditContainersCss.logo
                                                        }),
                                                    JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsxs(Button.make, {
                                                                size: "SM",
                                                                color: "Teal",
                                                                disabled: live === "Live",
                                                                visuallyDisabled: live === "Live" || form.submitting ? true : false,
                                                                children: [
                                                                  JsxRuntime.jsx(IconAdditionalUpload.make, {
                                                                        size: "MD",
                                                                        color: "White"
                                                                      }),
                                                                  "Upload New Image"
                                                                ]
                                                              })
                                                        })
                                                  ],
                                                  className: logoClass
                                                }) : JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx("img", {
                                                                src: "https://robohash.org/eosdelectusea.png?size=120x120&set=set1"
                                                              }),
                                                          className: ProviderEditContainersCss.logo
                                                        }),
                                                    JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsxs(Button.make, {
                                                                size: "SM",
                                                                color: "Teal",
                                                                children: [
                                                                  JsxRuntime.jsx(IconAdditionalUpload.make, {
                                                                        size: "MD",
                                                                        color: "White"
                                                                      }),
                                                                  "Upload Image"
                                                                ]
                                                              })
                                                        })
                                                  ],
                                                  className: logoClass
                                                })
                                        })
                                  })
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Requirements: PNG, JPG format, white background or \"knocked out\" with no background.",
                              className: ProviderEditContainersCss.subText
                            })
                      ],
                      className: ProviderEditContainersCss.generalContainer
                    })
              ],
              className: ProviderEditContainersCss.container
            });
}

var Css;

var ReactTelInputData;

var make = ProviderEditGeneralInfo;

export {
  Css ,
  ReactTelInputData ,
  reducer ,
  ProviderForm ,
  make ,
}
/* H3 Not a pure module */
