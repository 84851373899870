// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as Api_SuspiciousIp from "../../../../api/suspicious-ips/Api_SuspiciousIp.res.js";
import * as DashboardIndexCss from "../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_SuspiciousIp from "../../../../routes/common/Routes_SuspiciousIp.res.js";
import * as DashboardSuspiciousIpsNavbar from "../common/navbar/DashboardSuspiciousIpsNavbar.res.js";
import * as DashboardSuspiciousIpsOptions from "./components/options/DashboardSuspiciousIpsOptions.res.js";

var initialState = {
  status: "FetchingSuspiciousIps",
  currentPage: 1,
  totalPages: 1,
  sortBy: {
    NAME: "IpAddress",
    VAL: "Asc"
  },
  search: ""
};

function DashboardSuspiciousIpsIndex$default(props) {
  var container = React.useRef(null);
  var searchSuspiciousIps = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchSuspiciousIps") {
              return {
                      TAG: "SideEffects",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          var state = param.state;
                          var match = state.search;
                          var tmp = match === "" ? undefined : state.search;
                          $$Promise.wait(Api_SuspiciousIp.Dashboard.index(state.currentPage, state.sortBy, tmp), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "SucceedSuspiciousIpsFetch",
                                                _0: x._0
                                              });
                                  }
                                  SentryLogger.error1({
                                        rootModule: "DashboardSuspiciousIpsIndex",
                                        subModulePath: /* [] */0,
                                        value: "default",
                                        fullPath: "DashboardSuspiciousIpsIndex.default"
                                      }, "Dashboard::FetchSuspiciousIps::Error", [
                                        "Error",
                                        x._0
                                      ]);
                                  dispatch("FailSuspiciousIpsFetch");
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: "FailedSuspiciousIpsFetch",
                        currentPage: state.currentPage,
                        totalPages: state.totalPages,
                        sortBy: state.sortBy,
                        search: state.search
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "SucceedSuspiciousIpsFetch" :
                var res = action._0;
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: {
                            TAG: "Ready",
                            _0: res.suspiciousIps
                          },
                          currentPage: res.currentPage,
                          totalPages: res.totalPages,
                          sortBy: state.sortBy,
                          search: state.search
                        },
                        _1: (function (param) {
                            Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                    return prim.scrollTop;
                                  }));
                          })
                      };
            case "UpdatePage" :
                var nextPage = action._0;
                var match = state.status;
                if (typeof match !== "object" && match === "FetchingSuspiciousIps") {
                  return "NoUpdate";
                }
                if (state.currentPage !== nextPage) {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingSuspiciousIps",
                            currentPage: nextPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search
                          },
                          _1: (function (param) {
                              param.dispatch("FetchSuspiciousIps");
                            })
                        };
                } else {
                  return "NoUpdate";
                }
            case "SortBy" :
                var sort = action._0;
                var match$1 = state.status;
                if (typeof match$1 !== "object" && match$1 === "FetchingSuspiciousIps") {
                  return "NoUpdate";
                }
                var tmp;
                if (sort === "InvalidAttempts") {
                  var match$2 = state.sortBy;
                  tmp = typeof match$2 === "object" && match$2.NAME === "InvalidAttempts" && match$2.VAL === "Desc" ? ({
                        NAME: "InvalidAttempts",
                        VAL: "Asc"
                      }) : ({
                        NAME: "InvalidAttempts",
                        VAL: "Desc"
                      });
                } else if (sort === "IpAddress") {
                  var match$3 = state.sortBy;
                  tmp = typeof match$3 === "object" && match$3.NAME === "IpAddress" && match$3.VAL === "Desc" ? ({
                        NAME: "IpAddress",
                        VAL: "Asc"
                      }) : ({
                        NAME: "IpAddress",
                        VAL: "Desc"
                      });
                } else if (sort === "Status") {
                  var match$4 = state.sortBy;
                  tmp = typeof match$4 === "object" && match$4.NAME === "Status" && match$4.VAL === "Desc" ? ({
                        NAME: "Status",
                        VAL: "Asc"
                      }) : ({
                        NAME: "Status",
                        VAL: "Desc"
                      });
                } else {
                  var match$5 = state.sortBy;
                  tmp = typeof match$5 === "object" && match$5.NAME === "CreatedAt" && match$5.VAL === "Desc" ? ({
                        NAME: "CreatedAt",
                        VAL: "Asc"
                      }) : ({
                        NAME: "CreatedAt",
                        VAL: "Desc"
                      });
                }
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: "FetchingSuspiciousIps",
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: tmp,
                          search: state.search
                        },
                        _1: (function (param) {
                            param.dispatch("FetchSuspiciousIps");
                          })
                      };
            case "UpdateSearchInput" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: state.status,
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          search: action._0
                        },
                        _1: searchSuspiciousIps
                      };
            case "PerformSearch" :
                var match$6 = state.status;
                if (typeof match$6 !== "object" && match$6 === "FetchingSuspiciousIps") {
                  return "NoUpdate";
                }
                if (action._0 !== state.search) {
                  return "NoUpdate";
                } else {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingSuspiciousIps",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search
                          },
                          _1: (function (param) {
                              param.dispatch("FetchSuspiciousIps");
                            })
                        };
                }
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchSuspiciousIps");
        }), []);
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var suspiciousIps = state.status;
  var tmp$1;
  if (typeof suspiciousIps !== "object") {
    tmp$1 = suspiciousIps === "FetchingSuspiciousIps" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardTableCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var suspiciousIps$1 = suspiciousIps._0;
    tmp$1 = suspiciousIps$1.length !== 0 ? Belt_Array.mapWithIndex(suspiciousIps$1, (function (index, suspiciousIp) {
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: suspiciousIp.ipAddress,
                                        className: DashboardTableCss.tableLink,
                                        href: Routes_SuspiciousIp.Dashboard.edit(suspiciousIp.id)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: suspiciousIp.status
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(suspiciousIp.invalidAttempts)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format(new Date(suspiciousIp.createdAt), "MMMM dd, yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsx(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardIndexCss.dropdownBody,
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            className: DashboardIndexCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx("a", {
                                                                  children: "Edit",
                                                                  className: DashboardIndexCss.dropdownBodyLink,
                                                                  href: Routes_SuspiciousIp.Dashboard.edit(suspiciousIp.id)
                                                                })
                                                          })
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No suspicious IPs were found."
                })
          });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(DashboardSuspiciousIpsNavbar.make, {
                                currentLink: "Index"
                              }),
                          JsxRuntime.jsxs(Container.make, {
                                className: DashboardIndexCss.layout,
                                setRef: Caml_option.some(container),
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(H1.make, {
                                              className: DashboardIndexCss.title,
                                              children: "Manage Suspicious IPs"
                                            }),
                                        className: DashboardIndexCss.header
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(SearchField.make, {
                                                id: "suspiciousIps-search",
                                                value: state.search,
                                                placeholder: "Search by IP address",
                                                inputClassName: DashboardIndexCss.searchField,
                                                onChange: (function ($$event) {
                                                    dispatch({
                                                          TAG: "UpdateSearchInput",
                                                          _0: $$event.target.value
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(DashboardSuspiciousIpsOptions.make, {
                                                      pageNum: state.currentPage,
                                                      query: tmp,
                                                      sortBy: state.sortBy
                                                    })
                                              })
                                        ],
                                        className: DashboardIndexCss.subHeader
                                      }),
                                  JsxRuntime.jsx("section", {
                                        children: JsxRuntime.jsxs(Table.make, {
                                              className: DashboardTableCss.table,
                                              children: [
                                                JsxRuntime.jsx(TableHeader.make, {
                                                      children: JsxRuntime.jsxs(TableRow.make, {
                                                            children: [
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "IpAddress"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "IP Address",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$2 === "object" && match$2.NAME === "IpAddress" ? match$2.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "Status"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Status",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$3 === "object" && match$3.NAME === "Status" ? match$3.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "InvalidAttempts"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Invalid Attempts",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$4 === "object" && match$4.NAME === "InvalidAttempts" ? match$4.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "CreatedAt"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Created At",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$5 === "object" && match$5.NAME === "CreatedAt" ? match$5.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: ""
                                                                  })
                                                            ]
                                                          })
                                                    }),
                                                JsxRuntime.jsx(TableBody.make, {
                                                      children: tmp$1
                                                    })
                                              ]
                                            }),
                                        className: DashboardTableCss.tableWrapper
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Pagination.make, {
                                              currentPage: state.currentPage,
                                              totalPages: state.totalPages,
                                              onPageClick: (function (x) {
                                                  dispatch({
                                                        TAG: "UpdatePage",
                                                        _0: x
                                                      });
                                                })
                                            }),
                                        className: DashboardTableCss.pagination
                                      })
                                ]
                              })
                        ],
                        className: DashboardIndexCss.pageContainer
                      }))
            });
}

var Css;

var TableCss;

var $$default = DashboardSuspiciousIpsIndex$default;

export {
  Css ,
  TableCss ,
  initialState ,
  $$default as default,
}
/* H1 Not a pure module */
