// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "flex",
      "justify-between",
      "text-sm",
      "border-b",
      "border-solid",
      "border-gray-200",
      "pb-2.5"
    ]);

var name = Cx.cx([
      "max-w-[150px]",
      "overflow-hidden",
      "text-ellipsis",
      "whitespace-nowrap"
    ]);

var totalLocations = Cx.cx(["text-gray"]);

var link = Cx.cx([
      "text-black",
      "font-semibold",
      "hover:text-purple"
    ]);

var container = Cx.cx([
      "flex",
      "flex-col",
      "p-7",
      "sm:p-0"
    ]);

var BookACallSection;

var Content;

var FetchResult;

var Header;

var PreloadedSearchLocations;

var PreloadedSearchLocation;

var TopList;

var ViewButtons;

export {
  wrapper ,
  name ,
  totalLocations ,
  link ,
  container ,
  BookACallSection ,
  Content ,
  FetchResult ,
  Header ,
  PreloadedSearchLocations ,
  PreloadedSearchLocation ,
  TopList ,
  ViewButtons ,
}
/* wrapper Not a pure module */
