// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateAgencyDashboardRequest from "./requests/CreateAgencyDashboardRequest.res.js";
import * as UpdateAgencyDashboardRequest from "./requests/UpdateAgencyDashboardRequest.res.js";
import * as FetchAgenciesDashboardRequest from "./requests/FetchAgenciesDashboardRequest.res.js";
import * as FetchAgencyAgencyAgentsDashboardRequest from "./requests/FetchAgencyAgencyAgentsDashboardRequest.res.js";

var AgencyAgent = {
  index: FetchAgencyAgencyAgentsDashboardRequest.exec
};

var Show = {
  AgencyAgent: AgencyAgent
};

var Dashboard = {
  index: FetchAgenciesDashboardRequest.exec,
  create: CreateAgencyDashboardRequest.exec,
  update: UpdateAgencyDashboardRequest.exec,
  Show: Show
};

export {
  Dashboard ,
}
/* CreateAgencyDashboardRequest Not a pure module */
