// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Role from "../../../models/Role.res.js";
import * as User from "../../../models/User.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              users: field.required("users", Json_Decode$JsonCombinators.array(User.Dashboard.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalUsers: field.required("totalUsers", Json_Decode$JsonCombinators.$$int),
              currentUserId: field.required("currentUserId", ID.decoder)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, query, sortBy, roles) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "CreatedAt" ? "&sort_by=users.created_at&sort_direction=" + toString(sortBy.VAL) : (
      variant === "Approved" ? "&sort_by=users.approved&sort_direction=" + toString(sortBy.VAL) : (
          variant === "Email" ? "&sort_by=users.email&sort_direction=" + toString(sortBy.VAL) : (
              variant === "FirstName" ? "&sort_by=users.first_name&sort_direction=" + toString(sortBy.VAL) : (
                  variant === "LastName" ? "&sort_by=users.last_name&sort_direction=" + toString(sortBy.VAL) : (
                      variant === "Role" ? "&sort_by=users.role&sort_direction=" + toString(sortBy.VAL) : "&sort_by=users.api_enabled&sort_direction=" + toString(sortBy.VAL)
                    )
                )
            )
        )
    );
  var roles$1 = roles.map(function (role) {
          return "&roles[]=" + Role.toString(role);
        }).join("");
  return Rest.$$fetch("/dashboard/users?" + (page + (query$1 + (sortBy$1 + roles$1))), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
