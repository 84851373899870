// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-5",
      "[&_a]:text-teal",
      "[&_a]:hover:underline",
      "[&_p]:text-gray",
      "[&_h2]:text-black",
      "[&_h3]:text-black",
      "[&_h3]:font-bold",
      "[&_h2]:font-bold",
      "[&_h3]:text-3xl",
      "[&_h2]:text-4xl"
    ]);

var locationsContentContainer = Cx.cx([
      "grid",
      "lg:grid-cols-3",
      "lg:grid-flow-col",
      "grid-flow-row",
      "grid-cols-1",
      "gap-10",
      "xl:px-32",
      "xl:py-16",
      "sm:px-7",
      "py-7"
    ]);

var aboutContainer = Cx.cx([
      "lg:col-span-2",
      "flex",
      "flex-col"
    ]);

var topProvidersContainer = Cx.cx([
      "lg:col-span-1",
      "flex",
      "flex-col",
      "gap-5"
    ]);

var serviceCategory = Cx.cx([
      "text-gray",
      "font-medium"
    ]);

var localitiesTitle = Cx.cx([
      "text-black",
      "text-4xl"
    ]);

var localitiesSubtitle = Cx.cx([
      "text-gray",
      "text-2xl"
    ]);

var statsContainer = Cx.cx([
      "flex",
      "flex-wrap",
      "gap-[10px]",
      "justify-between",
      "mt-10",
      "mb-10"
    ]);

var stat = Cx.cx([
      "flex",
      "flex-col",
      "flex-grow",
      "min-h-[100px]",
      "bg-gray-50",
      "rounded",
      "px-[30px]",
      "py-5"
    ]);

var statNumber = Cx.cx([
      "font-semibold",
      "text-4xl"
    ]);

var statName = Cx.cx([
      "font-medium",
      "text-gray",
      "text-sm"
    ]);

var container$1 = Cx.cx([
      "lg:col-span-2",
      "flex",
      "flex-col"
    ]);

var title = Cx.cx([
      "font-semibold",
      "text-black",
      "text-2xl",
      "md:text-4xl"
    ]);

var subtitle = Cx.cx([
      "text-gray",
      "md:text-2xl"
    ]);

var header = Cx.cx([
      "flex",
      "flex-col",
      "gap-2"
    ]);

var stats = Cx.cx([
      "grid",
      "grid-cols-2",
      "md:grid-cols-4",
      "gap-2.5",
      "justify-between",
      "mt-10",
      "mb-10"
    ]);

var container$2 = Cx.cx([
      "flex",
      "flex-col",
      "flex-grow",
      "min-h-[100px]",
      "bg-gray-50",
      "rounded",
      "px-7",
      "py-5"
    ]);

var number = Cx.cx([
      "font-semibold",
      "text-4xl"
    ]);

var name = Cx.cx([
      "font-medium",
      "text-gray",
      "text-xs",
      "xl:text-sm"
    ]);

var Stat = {
  container: container$2,
  number: number,
  name: name
};

var About = {
  container: container$1,
  title: title,
  subtitle: subtitle,
  header: header,
  stats: stats,
  Stat: Stat
};

var container$3 = Cx.cx([
      "lg:col-span-1",
      "flex",
      "flex-col",
      "gap-5"
    ]);

var container$4 = Cx.cx([
      "flex",
      "flex-col",
      "gap-5",
      "w-full",
      "p-7",
      "border",
      "border-gray-200",
      "rounded"
    ]);

var title$1 = Cx.cx([
      "font-semibold",
      "text-3xl",
      "mt-[-14px]"
    ]);

var localityName = Cx.cx([
      "text-gray",
      "font-medium",
      "text-sm"
    ]);

var container$5 = Cx.cx([
      "flex",
      "flex-col"
    ]);

var container$6 = Cx.cx([
      "flex",
      "flex-row",
      "items-center",
      "gap-4",
      "border-t",
      "border-dotted",
      "border-gray-200",
      "py-2",
      "min-h-12"
    ]);

var logo = Cx.cx([
      "flex",
      "items-center",
      "max-w-12"
    ]);

var image = Cx.cx(["max-w-12"]);

var data = Cx.cx([
      "flex",
      "flex-col",
      "text-sm"
    ]);

var name$1 = Cx.cx([
      "font-medium",
      "hover:text-teal"
    ]);

var totalLocations = Cx.cx(["text-gray"]);

var Provider = {
  container: container$6,
  logo: logo,
  image: image,
  data: data,
  name: name$1,
  totalLocations: totalLocations
};

var Providers = {
  container: container$5,
  Provider: Provider
};

var Sidebar = {
  container: container$4,
  title: title$1,
  localityName: localityName,
  Providers: Providers
};

var TopProviders = {
  container: container$3,
  Sidebar: Sidebar
};

export {
  container ,
  locationsContentContainer ,
  aboutContainer ,
  topProvidersContainer ,
  serviceCategory ,
  localitiesTitle ,
  localitiesSubtitle ,
  statsContainer ,
  stat ,
  statNumber ,
  statName ,
  About ,
  TopProviders ,
}
/* container Not a pure module */
