// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          providerId: js.providerId,
          tsdId: js.tsdId,
          preferred: js.preferred,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              tsdId: field.required("tsdId", ID.decoder),
              preferred: field.required("preferred", Json_Decode$JsonCombinators.bool),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          id: js.id,
          providerId: js.providerId,
          providerName: js.providerName,
          tsdId: js.tsdId,
          tsdName: js.tsdName,
          preferred: js.preferred,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              tsdId: field.required("tsdId", ID.decoder),
              tsdName: field.required("tsdName", Json_Decode$JsonCombinators.string),
              preferred: field.required("preferred", Json_Decode$JsonCombinators.bool),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          providerTsds: Belt_Array.map(js.providerTsds, fromJs$1),
          totalProviderTsds: js.totalProviderTsds,
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          perPage: js.perPage
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providerTsds: field.required("providerTsds", Json_Decode$JsonCombinators.array(decoder$1)),
              totalProviderTsds: field.required("totalProviderTsds", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

function page(pageNum) {
  return "page=" + String(pageNum);
}

function query(query$1) {
  return Belt_Option.getWithDefault(Belt_Option.map(query$1, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function sortBy(sortBy$1) {
  var variant = sortBy$1.NAME;
  if (variant === "CreatedAt") {
    return "&sort_by=provider_tsds.created_at&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "ProviderName") {
    return "&sort_by=providers.name&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "Preferred") {
    return "&sort_by=provider_tsds.preferred&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "UpdatedAt") {
    return "&sort_by=provider_tsds.updated_at&sort_direction=" + toString(sortBy$1.VAL);
  } else {
    return "&sort_by=tsds.name&sort_direction=" + toString(sortBy$1.VAL);
  }
}

var Index = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2,
  SortDirection: SortDirection,
  SortBy: SortBy,
  page: page,
  query: query,
  sortBy: sortBy
};

var Dashboard = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1,
  Index: Index
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  Dashboard ,
}
/* decoder Not a pure module */
