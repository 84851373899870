// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../../libs/ID.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Table from "../../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableRow from "../../../../../styleguide/components/Table/TableRow.res.js";
import * as TableBody from "../../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../../styleguide/components/Table/TableHeader.res.js";
import * as Api_Location from "../../../../../api/locations/Api_Location.res.js";
import * as Api_Provider from "../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as ProviderEditTab from "../../ProviderEditTab.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as TableHeaderCell from "../../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardIndexCss from "../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as ProviderEditLocationNew from "../location/new/ProviderEditLocationNew.res.js";
import * as ProviderEditContainersCss from "../common/ProviderEditContainersCss.res.js";
import * as ProviderEditLocationAbout from "../location/edit/about/ProviderEditLocationAbout.res.js";
import * as ProviderEditLocationAssets from "../location/edit/assets/ProviderEditLocationAssets.res.js";
import * as ProviderEditLocationSettings from "../location/edit/settings/ProviderEditLocationSettings.res.js";
import * as ProviderEditLocationsOptions from "./components/ProviderEditLocationsOptions.res.js";
import * as ProviderEditLocationStickyBar from "../location/stickybar/ProviderEditLocationStickyBar.res.js";
import * as ProviderEditLocationsActionBar from "./components/ProviderEditLocationsActionBar.res.js";
import * as ProviderEditLocationGeneralInfo from "../location/edit/general-info/ProviderEditLocationGeneralInfo.res.js";
import * as ProviderEditLocationCertifications from "../location/edit/certifications/ProviderEditLocationCertifications.res.js";

function ProviderEditLocations(props) {
  var userRole = props.userRole;
  var provider = props.provider;
  var searchLocations = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var initialState_sortBy = {
    NAME: "Name",
    VAL: "Asc"
  };
  var initialState_selection = ID.$$Set.make();
  var initialState_entityUpdates = ID.$$Map.make();
  var initialState_currentLocationId = props.locationId;
  var initialState = {
    status: "FetchingLocations",
    currentPage: 1,
    totalPages: 1,
    sortBy: initialState_sortBy,
    search: "",
    selection: initialState_selection,
    entityUpdates: initialState_entityUpdates,
    batchUpdate: undefined,
    batchUpdateError: false,
    openDestroyConfirm: false,
    currentLocationId: initialState_currentLocationId,
    openNewLocation: false,
    editLocation: undefined,
    live: "Draft",
    selectedOption: "GeneralInfo"
  };
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "RemoveLocation" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: undefined,
                            live: state.live,
                            selectedOption: "GeneralInfo"
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "FetchLocations" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_Provider.locationsDashboard(provider.id, state.currentPage, tmp, state.sortBy), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedLocationsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderEditLocations",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderEditLocations.make"
                                          }, "LocationsDashboard::FetchLocations::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLocationsFetch");
                                    }));
                            })
                        };
              case "FailLocationsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedLocationsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "ToggleSelectionOfAllItems" :
                  var match = state.status;
                  var match$1 = state.batchUpdate;
                  if (typeof match !== "object") {
                    return "NoUpdate";
                  }
                  if (match$1 !== undefined) {
                    return "NoUpdate";
                  }
                  var locations = match._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: locations.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(locations, (function ($$location) {
                                          return $$location.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "DeselectAll" :
                  var match$2 = state.status;
                  var match$3 = state.batchUpdate;
                  if (typeof match$2 !== "object" || match$3 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              openNewLocation: state.openNewLocation,
                              editLocation: state.editLocation,
                              live: state.live,
                              selectedOption: state.selectedOption
                            }
                          };
                  }
              case "ActivateInBatch" :
                  var locations$1 = state.status;
                  if (typeof locations$1 !== "object") {
                    return "NoUpdate";
                  }
                  var locations$2 = locations$1._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Activating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var locationIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function ($$location) {
                                                          return Caml_obj.equal(id, $$location.id);
                                                        }), __x);
                                          })(locations$2);
                                      if (match !== undefined && !match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_Location.activateInBatch({
                                        locationIds: locationIds,
                                        active: true
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedActivateInBatch",
                                                    _0: locationIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderEditLocations",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderEditLocations.make"
                                          }, "ProviderEditLocations" + "::ActivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "DeactivateInBatch" :
                  var locations$3 = state.status;
                  if (typeof locations$3 !== "object") {
                    return "NoUpdate";
                  }
                  var locations$4 = locations$3._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Deactivating",
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var locationIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function ($$location) {
                                                          return Caml_obj.equal(id, $$location.id);
                                                        }), __x);
                                          })(locations$4);
                                      if (match !== undefined && match.active) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api_Location.activateInBatch({
                                        locationIds: locationIds,
                                        active: false
                                      }), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedDeactivateInBatch",
                                                    _0: locationIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderEditLocations",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderEditLocations.make"
                                          }, "ProviderEditLocations" + "::DeactivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "FailInBatchAction" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "CloseDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: false,
                            currentLocationId: undefined,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "ToggleNewLocation" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: !state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "ToggleLive" :
                  var match$4 = state.live;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: match$4 === "Live" ? "Draft" : "Live",
                            selectedOption: state.selectedOption
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "ToggleOptions" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: action._0
                          }
                        };
              case "FetchLocation" :
                  var id = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Location.fetchLocationDashboard(id, provider.id), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedFetchLocation",
                                                    _0: x._0
                                                  });
                                      }
                                      dispatch("FetchLocations");
                                      SentryLogger.error1({
                                            rootModule: "ProviderEditLocations",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderEditLocations.make"
                                          }, "LocationsDashboard::FetchLocation::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                    }));
                            })
                        };
              case "SucceedFetchLocation" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: action._0,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "SucceedLocationsFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.locations
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$5 = state.status;
                  if (typeof match$5 !== "object" && match$5 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              openNewLocation: state.openNewLocation,
                              editLocation: state.editLocation,
                              live: state.live,
                              selectedOption: state.selectedOption
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$6 = state.status;
                  if (typeof match$6 !== "object" && match$6 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "State") {
                    var match$7 = state.sortBy;
                    tmp = typeof match$7 === "object" && match$7.NAME === "State" && match$7.VAL !== "Desc" ? ({
                          NAME: "State",
                          VAL: "Desc"
                        }) : ({
                          NAME: "State",
                          VAL: "Asc"
                        });
                  } else if (sort === "City") {
                    var match$8 = state.sortBy;
                    tmp = typeof match$8 === "object" && match$8.NAME === "City" && match$8.VAL === "Desc" ? ({
                          NAME: "City",
                          VAL: "Asc"
                        }) : ({
                          NAME: "City",
                          VAL: "Desc"
                        });
                  } else if (sort === "Name") {
                    var match$9 = state.sortBy;
                    tmp = typeof match$9 === "object" && match$9.NAME === "Name" && match$9.VAL === "Desc" ? ({
                          NAME: "Name",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Name",
                          VAL: "Desc"
                        });
                  } else {
                    var match$10 = state.sortBy;
                    tmp = typeof match$10 === "object" && match$10.NAME === "Country" && match$10.VAL === "Desc" ? ({
                          NAME: "Country",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Country",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocations",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          },
                          _1: searchLocations
                        };
              case "PerformSearch" :
                  var match$11 = state.status;
                  if (typeof match$11 !== "object" && match$11 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              openNewLocation: state.openNewLocation,
                              editLocation: state.editLocation,
                              live: state.live,
                              selectedOption: state.selectedOption
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id$1 = action._0;
                  var match$12 = state.batchUpdate;
                  if (match$12 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id$1) ? Belt_Set.remove(state.selection, id$1) : Belt_Set.add(state.selection, id$1),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              openNewLocation: state.openNewLocation,
                              editLocation: state.editLocation,
                              live: state.live,
                              selectedOption: state.selectedOption
                            }
                          };
                  }
              case "UpdateActive" :
                  var action$1 = action._1;
                  var locationId = action._0;
                  var match$13 = state.status;
                  var match$14 = state.batchUpdate;
                  var match$15 = Belt_Map.get(state.entityUpdates, locationId);
                  if (typeof match$13 !== "object") {
                    match$13 === "FetchingLocations";
                  } else if (match$14 === undefined && match$15 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$13._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, locationId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Active" ? true : false;
                                        return {
                                                id: x.id,
                                                name: x.name,
                                                city: x.city,
                                                state: x.state,
                                                country: x.country,
                                                active: tmp,
                                                isApproved: x.isApproved,
                                                url: x.url,
                                                providerId: x.providerId,
                                                providerName: x.providerName,
                                                providerSlug: x.providerSlug,
                                                lastApprovedOn: x.lastApprovedOn,
                                                createdAt: x.createdAt
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              entityUpdates: Belt_Map.set(state.entityUpdates, locationId, {
                                    TAG: "ActiveUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              openNewLocation: state.openNewLocation,
                              editLocation: state.editLocation,
                              live: state.live,
                              selectedOption: state.selectedOption
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Active") {
                                  return $$Promise.wait(Api_Location.activate(locationId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: locationId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ProviderEditLocations",
                                                      subModulePath: /* [] */0,
                                                      value: "make",
                                                      fullPath: "ProviderEditLocations.make"
                                                    }, "ProviderEditLocations" + "::Activate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: locationId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_Location.deactivate(locationId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActiveUpdate",
                                                              _0: locationId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ProviderEditLocations",
                                                      subModulePath: /* [] */0,
                                                      value: "make",
                                                      fullPath: "ProviderEditLocations.make"
                                                    }, "ProviderEditLocations" + "::Deactivate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActiveUpdate",
                                                      _0: locationId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedActiveUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "FailActiveUpdate" :
                  var locationId$1 = action._0;
                  var match$16 = state.status;
                  var match$17 = Belt_Map.get(state.entityUpdates, locationId$1);
                  if (typeof match$16 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$17 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$17._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$16._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, locationId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Active" ? true : false;
                                      return {
                                              id: x.id,
                                              name: x.name,
                                              city: x.city,
                                              state: x.state,
                                              country: x.country,
                                              active: tmp,
                                              isApproved: x.isApproved,
                                              url: x.url,
                                              providerId: x.providerId,
                                              providerName: x.providerName,
                                              providerSlug: x.providerSlug,
                                              lastApprovedOn: x.lastApprovedOn,
                                              createdAt: x.createdAt
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, locationId$1),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentLocationId: state.currentLocationId,
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "SucceedActivateInBatch" :
                  var ids = action._0;
                  var locations$5 = state.status;
                  if (typeof locations$5 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(locations$5._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  city: x.city,
                                                  state: x.state,
                                                  country: x.country,
                                                  active: true,
                                                  isApproved: x.isApproved,
                                                  url: x.url,
                                                  providerId: x.providerId,
                                                  providerName: x.providerName,
                                                  providerSlug: x.providerSlug,
                                                  lastApprovedOn: x.lastApprovedOn,
                                                  createdAt: x.createdAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              openNewLocation: state.openNewLocation,
                              editLocation: state.editLocation,
                              live: state.live,
                              selectedOption: state.selectedOption
                            }
                          };
                  }
              case "SucceedDeactivateInBatch" :
                  var ids$1 = action._0;
                  var locations$6 = state.status;
                  if (typeof locations$6 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(locations$6._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$1)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  city: x.city,
                                                  state: x.state,
                                                  country: x.country,
                                                  active: false,
                                                  isApproved: x.isApproved,
                                                  url: x.url,
                                                  providerId: x.providerId,
                                                  providerName: x.providerName,
                                                  providerSlug: x.providerSlug,
                                                  lastApprovedOn: x.lastApprovedOn,
                                                  createdAt: x.createdAt
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentLocationId: state.currentLocationId,
                              openNewLocation: state.openNewLocation,
                              editLocation: state.editLocation,
                              live: state.live,
                              selectedOption: state.selectedOption
                            }
                          };
                  }
              case "OpenDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            openDestroyConfirm: true,
                            currentLocationId: Caml_option.some(action._0),
                            openNewLocation: state.openNewLocation,
                            editLocation: state.editLocation,
                            live: state.live,
                            selectedOption: state.selectedOption
                          }
                        };
              case "DestroyLocation" :
                  var id$2 = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Provider.destroyLocation(provider.id, id$2), (function (x) {
                                      if (x.TAG === "Ok") {
                                        dispatch("CloseDestroyConfirm");
                                        return dispatch({
                                                    TAG: "SucceedLocationsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderEditLocations",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderEditLocations.make"
                                          }, "Destroy::FetchLocations::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLocationsFetch");
                                    }));
                            })
                        };
              case "Approve" :
                  var $$location = action._0;
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Location.approve($$location.currentDraft.id), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "FetchLocation",
                                                    _0: x._0.id
                                                  });
                                      } else {
                                        return SentryLogger.error1({
                                                    rootModule: "ProviderEditLocations",
                                                    subModulePath: /* [] */0,
                                                    value: "make",
                                                    fullPath: "ProviderEditLocations.make"
                                                  }, "EditLocation::Approve::Error", [
                                                    "Error",
                                                    x._0
                                                  ]);
                                      }
                                    }));
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var x = state.currentLocationId;
          if (x !== undefined) {
            dispatch({
                  TAG: "FetchLocation",
                  _0: Caml_option.valFromOption(x)
                });
          } else {
            dispatch("FetchLocations");
          }
        }), []);
  var x = state.editLocation;
  var match$1 = state.editLocation;
  var match$2 = state.editLocation;
  var x$1 = state.editLocation;
  var match$3 = state.editLocation;
  var tmp;
  if (match$3 !== undefined) {
    var match$4 = state.selectedOption;
    tmp = match$4 === "Certifications" ? JsxRuntime.jsx(ProviderEditLocationCertifications.make, {
            location: Belt_Option.getExn(state.editLocation),
            live: state.live,
            updateLocation: (function ($$location) {
                dispatch({
                      TAG: "SucceedFetchLocation",
                      _0: $$location
                    });
              })
          }) : (
        match$4 === "GeneralInfo" ? JsxRuntime.jsx(ProviderEditLocationGeneralInfo.make, {
                liveProvider: provider,
                location: Belt_Option.getExn(state.editLocation),
                live: state.live,
                userRole: userRole,
                updateLocation: (function ($$location) {
                    dispatch({
                          TAG: "SucceedFetchLocation",
                          _0: $$location
                        });
                  })
              }) : (
            match$4 === "Assets" ? JsxRuntime.jsx(ProviderEditLocationAssets.make, {
                    location: Belt_Option.getExn(state.editLocation),
                    live: state.live,
                    updateLocation: (function ($$location) {
                        dispatch({
                              TAG: "SucceedFetchLocation",
                              _0: $$location
                            });
                      })
                  }) : (
                match$4 === "About" ? JsxRuntime.jsx(ProviderEditLocationAbout.make, {
                        location: Belt_Option.getExn(state.editLocation),
                        live: state.live,
                        updateLocation: (function ($$location) {
                            dispatch({
                                  TAG: "SucceedFetchLocation",
                                  _0: $$location
                                });
                          })
                      }) : JsxRuntime.jsx(ProviderEditLocationSettings.make, {
                        location: Belt_Option.getExn(state.editLocation),
                        updateLocation: (function ($$location) {
                            dispatch({
                                  TAG: "SucceedFetchLocation",
                                  _0: $$location
                                });
                          })
                      })
              )
          )
      );
  } else {
    var match$5 = state.search;
    var tmp$1 = match$5 === "" ? undefined : state.search;
    var locations = state.status;
    var tmp$2;
    if (typeof locations !== "object") {
      tmp$2 = false;
    } else {
      var locations$1 = locations._0;
      tmp$2 = Belt_Set.size(state.selection) === locations$1.length && locations$1.length !== 0;
    }
    var match$6 = state.sortBy;
    var match$7 = state.sortBy;
    var match$8 = state.sortBy;
    var match$9 = state.sortBy;
    var locations$2 = state.status;
    var tmp$3;
    if (typeof locations$2 !== "object") {
      tmp$3 = locations$2 === "FetchingLocations" ? JsxRuntime.jsx(TableRow.make, {
              children: JsxRuntime.jsx(TableCell.make, {
                    colSpan: 7,
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx(Spinner.make, {
                                size: "XL",
                                color: "Teal"
                              }),
                          className: DashboardTableCss.spinner
                        })
                  })
            }) : JsxRuntime.jsx(TableRow.make, {
              className: DashboardTableCss.fetchError,
              children: JsxRuntime.jsx(TableCell.make, {
                    colSpan: 7,
                    children: "Something went wrong during the search"
                  })
            });
    } else {
      var locations$3 = locations$2._0;
      tmp$3 = locations$3.length !== 0 ? Belt_Array.mapWithIndex(locations$3, (function (index, $$location) {
                var active = $$location.active;
                return JsxRuntime.jsxs(TableRow.make, {
                            children: [
                              JsxRuntime.jsx(TableCell.make, {
                                    children: JsxRuntime.jsx(Checkbox.make, {
                                          id: "location--" + (ID.toString($$location.id) + "--selected"),
                                          size: "SM",
                                          checked: Belt_Set.has(state.selection, $$location.id),
                                          onChange: (function (param) {
                                              dispatch({
                                                    TAG: "ToggleSelectionOfItem",
                                                    _0: $$location.id
                                                  });
                                            })
                                        })
                                  }),
                              JsxRuntime.jsx(TableCell.make, {
                                    className: DashboardTableCss.tableCellWithMinWidth,
                                    children: JsxRuntime.jsx("a", {
                                          children: $$location.name,
                                          className: DashboardTableCss.tableLink,
                                          href: "#",
                                          onClick: (function (param) {
                                              dispatch({
                                                    TAG: "FetchLocation",
                                                    _0: $$location.id
                                                  });
                                            })
                                        })
                                  }),
                              JsxRuntime.jsx(TableCell.make, {
                                    children: $$location.city
                                  }),
                              JsxRuntime.jsx(TableCell.make, {
                                    children: $$location.state
                                  }),
                              JsxRuntime.jsx(TableCell.make, {
                                    children: $$location.country
                                  }),
                              JsxRuntime.jsx(TableCell.make, {
                                    children: JsxRuntime.jsx(Toggle.make, {
                                          on: active,
                                          size: "MD",
                                          onChange: (function () {
                                              dispatch({
                                                    TAG: "UpdateActive",
                                                    _0: $$location.id,
                                                    _1: active ? "Inactive" : "Active"
                                                  });
                                            })
                                        })
                                  }),
                              JsxRuntime.jsx(TableCell.make, {
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs(Popover.make, {
                                                children: [
                                                  JsxRuntime.jsx(Popover.Trigger.make, {
                                                        children: JsxRuntime.jsx(Control.make, {
                                                              children: JsxRuntime.jsx(IconMenu.make, {
                                                                    size: "MD",
                                                                    color: "Teal"
                                                                  })
                                                            })
                                                      }),
                                                  JsxRuntime.jsxs(Dropdown.Body.make, {
                                                        position: {
                                                          TAG: "Below",
                                                          _0: "RightEdge"
                                                        },
                                                        className: DashboardFilterCss.dropdownBody,
                                                        children: [
                                                          JsxRuntime.jsx(Control.make, {
                                                                className: DashboardFilterCss.dropdownBodyRow,
                                                                children: JsxRuntime.jsx("a", {
                                                                      children: "Edit",
                                                                      className: DashboardFilterCss.dropdownBodyLink,
                                                                      href: "#",
                                                                      onClick: (function (param) {
                                                                          dispatch({
                                                                                TAG: "FetchLocation",
                                                                                _0: $$location.id
                                                                              });
                                                                        })
                                                                    })
                                                              }),
                                                          JsxRuntime.jsx(Control.make, {
                                                                className: DashboardFilterCss.dropdownBodyRow,
                                                                children: JsxRuntime.jsx("a", {
                                                                      children: "Delete",
                                                                      className: DashboardFilterCss.dropdownBodyLink,
                                                                      href: "#",
                                                                      onClick: (function (param) {
                                                                          dispatch({
                                                                                TAG: "OpenDestroyConfirm",
                                                                                _0: $$location.id
                                                                              });
                                                                        })
                                                                    })
                                                              })
                                                        ]
                                                      })
                                                ]
                                              })
                                        })
                                  })
                            ]
                          }, String(index));
              })) : JsxRuntime.jsx(TableRow.make, {
              className: DashboardTableCss.fetchError,
              children: JsxRuntime.jsx(TableCell.make, {
                    colSpan: 7,
                    children: "No locations were found."
                  })
            });
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx(SearchField.make, {
                          id: "locations-search",
                          value: state.search,
                          placeholder: "Search by name, city, state, or country",
                          inputClassName: DashboardIndexCss.searchField,
                          onChange: (function ($$event) {
                              dispatch({
                                    TAG: "UpdateSearchInput",
                                    _0: $$event.target.value
                                  });
                            })
                        }),
                    JsxRuntime.jsx(ProviderEditLocationsOptions.make, {
                          providerId: provider.id,
                          pageNum: state.currentPage,
                          query: tmp$1,
                          sortBy: state.sortBy,
                          openNewLocation: (function (param) {
                              dispatch("ToggleNewLocation");
                            })
                        })
                  ],
                  className: DashboardIndexCss.subHeader
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsxs(Table.make, {
                        className: DashboardTableCss.table,
                        children: [
                          JsxRuntime.jsx(TableHeader.make, {
                                children: JsxRuntime.jsxs(TableRow.make, {
                                      children: [
                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                              children: JsxRuntime.jsx(Checkbox.make, {
                                                    id: "location--all--selected",
                                                    size: "SM",
                                                    checked: tmp$2,
                                                    onChange: (function (param) {
                                                        dispatch("ToggleSelectionOfAllItems");
                                                      })
                                                  })
                                            }),
                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                              children: JsxRuntime.jsx(Control.make, {
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SortBy",
                                                              _0: "Name"
                                                            });
                                                      }),
                                                    children: JsxRuntime.jsxs("span", {
                                                          children: [
                                                            "Location Name",
                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                  size: "XXS",
                                                                  direction: typeof match$6 === "object" && match$6.NAME === "Name" ? match$6.VAL : undefined
                                                                })
                                                          ],
                                                          className: DashboardTableCss.controlCell
                                                        })
                                                  })
                                            }),
                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                              children: JsxRuntime.jsx(Control.make, {
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SortBy",
                                                              _0: "City"
                                                            });
                                                      }),
                                                    children: JsxRuntime.jsxs("span", {
                                                          children: [
                                                            "City",
                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                  size: "XXS",
                                                                  direction: typeof match$7 === "object" && match$7.NAME === "City" ? match$7.VAL : undefined
                                                                })
                                                          ],
                                                          className: DashboardTableCss.controlCell
                                                        })
                                                  })
                                            }),
                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                              children: JsxRuntime.jsx(Control.make, {
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SortBy",
                                                              _0: "State"
                                                            });
                                                      }),
                                                    children: JsxRuntime.jsxs("span", {
                                                          children: [
                                                            "State",
                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                  size: "XXS",
                                                                  direction: typeof match$8 === "object" && match$8.NAME === "State" ? match$8.VAL : undefined
                                                                })
                                                          ],
                                                          className: DashboardTableCss.controlCell
                                                        })
                                                  })
                                            }),
                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                              children: JsxRuntime.jsx(Control.make, {
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SortBy",
                                                              _0: "Country"
                                                            });
                                                      }),
                                                    children: JsxRuntime.jsxs("span", {
                                                          children: [
                                                            "Country",
                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                  size: "XXS",
                                                                  direction: typeof match$9 === "object" && match$9.NAME === "Country" ? match$9.VAL : undefined
                                                                })
                                                          ],
                                                          className: DashboardTableCss.controlCell
                                                        })
                                                  })
                                            }),
                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                              children: "Active"
                                            }),
                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                              children: ""
                                            })
                                      ]
                                    })
                              }),
                          JsxRuntime.jsx(TableBody.make, {
                                children: tmp$3
                              })
                        ]
                      }),
                  className: DashboardTableCss.tableWrapper
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(Pagination.make, {
                        currentPage: state.currentPage,
                        totalPages: state.totalPages,
                        onPageClick: (function (x) {
                            dispatch({
                                  TAG: "UpdatePage",
                                  _0: x
                                });
                          })
                      }),
                  className: DashboardTableCss.pagination
                }),
            state.openDestroyConfirm ? JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("div", {
                                  children: "Are you sure?",
                                  className: ProviderEditContainersCss.deleteConfirmationText
                                }),
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Button.make, {
                                          size: "SM",
                                          color: "Gray",
                                          onClick: (function (param) {
                                              dispatch("CloseDestroyConfirm");
                                            }),
                                          children: "Cancel"
                                        }),
                                    JsxRuntime.jsx(Button.make, {
                                          size: "SM",
                                          color: "Pink",
                                          onClick: (function (param) {
                                              dispatch({
                                                    TAG: "DestroyLocation",
                                                    _0: Belt_Option.getExn(state.currentLocationId)
                                                  });
                                            }),
                                          children: "Yes, delete it"
                                        })
                                  ],
                                  className: ProviderEditContainersCss.deleteConfirmationButtons
                                })
                          ],
                          className: ProviderEditContainersCss.deleteConfirmationDialog
                        }),
                    className: ProviderEditContainersCss.deleteConfirmationOverlay,
                    onClick: (function ($$event) {
                        $$event.stopPropagation();
                      })
                  }) : null,
            state.openNewLocation ? JsxRuntime.jsx(ProviderEditLocationNew.make, {
                    providerId: provider.id,
                    closeNewLocation: (function (param) {
                        dispatch("ToggleNewLocation");
                      }),
                    updateLocation: (function ($$location) {
                        dispatch({
                              TAG: "SucceedFetchLocation",
                              _0: $$location
                            });
                        dispatch("ToggleNewLocation");
                      })
                  }) : null
          ]
        });
  }
  var locations$4 = state.status;
  var tmp$4;
  if (typeof locations$4 !== "object") {
    tmp$4 = null;
  } else {
    var locations$5 = locations$4._0;
    tmp$4 = JsxRuntime.jsx(ProviderEditLocationsActionBar.make, {
          selectionCount: Belt_Set.toArray(state.selection).length,
          activeSelection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function ($$location) {
                                                    return Caml_obj.equal(id, $$location.id);
                                                  }), __x);
                                    })(locations$5), (function ($$location) {
                                    return $$location.active;
                                  }));
                    })), [], (function (acc, active) {
                  if (active !== undefined) {
                    return Belt_Array.concat(acc, [active]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          activate: (function (param) {
              dispatch("ActivateInBatch");
            }),
          deactivate: (function (param) {
              dispatch("DeactivateInBatch");
            })
        });
  }
  var match$10 = state.editLocation;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(H1.make, {
                                          className: DashboardIndexCss.title,
                                          children: x !== undefined ? "Edit Location: " + x.name + ", " + provider.name : "Locations: " + provider.name
                                        }),
                                    className: DashboardIndexCss.header
                                  }),
                              className: DashboardNavbarCss.headerContainer
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(A.make, {
                                                  href: "#",
                                                  className: DashboardNavbarCss.underlinedLinkItem,
                                                  children: "Data Centers"
                                                }),
                                            className: Cx.cx([match$1 !== undefined ? DashboardNavbarCss.linkContainer : DashboardNavbarCss.underlinedLinkContainer]),
                                            onClick: (function (param) {
                                                dispatch("RemoveLocation");
                                                dispatch("FetchLocations");
                                              })
                                          }),
                                      match$2 !== undefined ? Belt_Array.mapWithIndex(ProviderEditTab.LocationNav.Tab.tabs, (function (index, tab) {
                                                var match = tab.tab;
                                                if (match === "Settings") {
                                                  if (userRole === "Admin") {
                                                    return JsxRuntime.jsx("div", {
                                                                children: JsxRuntime.jsx(A.make, {
                                                                      href: "#",
                                                                      className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                                      children: tab.title
                                                                    }),
                                                                className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "ToggleOptions",
                                                                          _0: tab.tab
                                                                        });
                                                                  })
                                                              }, String(index));
                                                  } else {
                                                    return null;
                                                  }
                                                } else {
                                                  return JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsx(A.make, {
                                                                    href: "#",
                                                                    className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                                    children: tab.title
                                                                  }),
                                                              className: Cx.cx([state.selectedOption === tab.tab ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                                              onClick: (function (param) {
                                                                  dispatch({
                                                                        TAG: "ToggleOptions",
                                                                        _0: tab.tab
                                                                      });
                                                                })
                                                            }, String(index));
                                                }
                                              })) : null,
                                      JsxRuntime.jsx("div", {
                                            children: x$1 !== undefined ? JsxRuntime.jsx(A.make, {
                                                    href: x$1.slug,
                                                    className: DashboardNavbarCss.tabItem,
                                                    children: "View Live Location Page"
                                                  }) : JsxRuntime.jsx(A.make, {
                                                    href: Routes_Provider.show(provider.slug),
                                                    className: DashboardNavbarCss.tabItem,
                                                    children: "View Live Provider Page"
                                                  }),
                                            className: DashboardNavbarCss.viewLive
                                          })
                                    ],
                                    className: DashboardNavbarCss.barContainer
                                  }),
                              className: DashboardNavbarCss.barWrapperGeneral
                            })
                      ],
                      className: DashboardNavbarCss.subnavContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: tmp,
                              className: ProviderEditContainersCss.generalContainer
                            }),
                        tmp$4
                      ],
                      className: ProviderEditContainersCss.container
                    }),
                match$10 !== undefined ? JsxRuntime.jsx(ProviderEditLocationStickyBar.make, {
                        location: Belt_Option.getExn(state.editLocation),
                        live: state.live,
                        userRole: userRole,
                        toggleLive: (function () {
                            dispatch("ToggleLive");
                          }),
                        approve: (function (param) {
                            dispatch({
                                  TAG: "Approve",
                                  _0: Belt_Option.getExn(state.editLocation)
                                });
                          })
                      }) : null
              ]
            });
}

var TableCss;

var Css;

var Filters;

var make = ProviderEditLocations;

export {
  TableCss ,
  Css ,
  Filters ,
  make ,
}
/* A Not a pure module */
