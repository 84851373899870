// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "justify-between",
      "gap-5",
      "p-8",
      "rounded",
      "lg:px-32",
      "md:flex-row",
      "bg-blue-900",
      "sm:rounded-none"
    ]);

var heading = Cx.cx([
      "text-white",
      "font-semibold",
      "text-base",
      "lg:text-xl"
    ]);

export {
  container ,
  heading ,
}
/* container Not a pure module */
