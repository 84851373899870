// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var companyLogo = Cx.cx([
      "mb-8",
      "[&>img]:h-8"
    ]);

var text = Cx.cx([
      "text-gray-700",
      "italic",
      "leading-5",
      "mb-5",
      "lg:text-base",
      "text-sm",
      "text-start"
    ]);

var link = Cx.cx([
      "text-teal",
      "hover:underline",
      "text-sm",
      "border-b",
      "border-dotted",
      "border-solid",
      "font-medium"
    ]);

var profile = Cx.cx(["flex"]);

var avatar = Cx.cx([
      "h-12",
      "w-12",
      "mr-4",
      "rounded-full",
      "relative",
      "overflow-hidden"
    ]);

var detail = Cx.cx([
      "flex",
      "flex-col",
      "self-center",
      "text-start"
    ]);

var name = Cx.cx([
      "text-sm",
      "font-medium",
      "text-black"
    ]);

var from = Cx.cx([
      "text-sm",
      "text-gray-500"
    ]);

export {
  companyLogo ,
  text ,
  link ,
  profile ,
  avatar ,
  detail ,
  name ,
  from ,
}
/* companyLogo Not a pure module */
