// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tsd from "../../../models/Tsd.res.js";
import * as Rest from "../../Rest.res.js";

function exec(pageNum, sortBy, query) {
  var page = Tsd.Dashboard.Index.page(pageNum);
  var query$1 = Tsd.Dashboard.Index.query(query);
  var sortBy$1 = Tsd.Dashboard.Index.sortBy(sortBy);
  return Rest.$$fetch("/dashboard/tsds?" + page + query$1 + sortBy$1, "Get", {
              NAME: "Json",
              VAL: Tsd.Dashboard.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Tsd Not a pure module */
