// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as AgencyAgent from "../../../models/AgencyAgent.res.js";

function exec(query) {
  var query$1 = AgencyAgent.Dashboard.Agency.Filter.query(query);
  return Rest.$$fetch("/dashboard/agency_agents/agencies?" + query$1, "Get", {
              NAME: "Json",
              VAL: AgencyAgent.Dashboard.Agency.Filter.Agencies.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
