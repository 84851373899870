// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../components/Button/Button.res.js";
import * as IconDownload from "../../../../icons/IconDownload.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardExportModalCss from "./DashboardExportModalCss.res.js";
import * as DashboardModalContainer from "../container/DashboardModalContainer.res.js";

function DashboardExportModal(props) {
  return JsxRuntime.jsxs(DashboardModalContainer.make, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(IconDownload.make, {
                                    size: "XXL",
                                    color: "Teal"
                                  }),
                              className: DashboardExportModalCss.iconContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: props.title,
                                      className: DashboardExportModalCss.dialogTitle
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("p", {
                                            children: props.message,
                                            className: DashboardExportModalCss.message
                                          }),
                                      className: DashboardExportModalCss.messageTextContainer
                                    })
                              ],
                              className: DashboardExportModalCss.messageContainer
                            })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Teal",
                            onClick: props.onClick,
                            children: props.buttonText
                          }),
                      className: DashboardExportModalCss.buttonContainer
                    })
              ]
            });
}

var Css;

var make = DashboardExportModal;

export {
  Css ,
  make ,
}
/* Button Not a pure module */
