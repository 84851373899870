// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "container",
      "md:px-20",
      "p-0"
    ]);

var postsContainer = Cx.cx([
      "flex",
      "md:flex-row",
      "flex-col",
      "justify-center"
    ]);

var gridSection = Cx.cx([
      "grid",
      "lg:grid-cols-4",
      "md:grid-cols-2",
      "grid-cols-1",
      "gap-5"
    ]);

export {
  container ,
  postsContainer ,
  gridSection ,
}
/* container Not a pure module */
