// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var formContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-3"
    ]);

var title = Cx.cx([
      "text-xl",
      "font-bold"
    ]);

var buttonContainer = Cx.cx([
      "flex",
      "justify-end"
    ]);

var fields = Cx.cx([
      "flex",
      "flex-col",
      "gap-2"
    ]);

var dropdownBody = Cx.cx([
      "!w-full",
      "flex",
      "flex-col",
      "gap-2"
    ]);

var searchInput = Cx.cx([
      "!pl-2",
      "!w-full"
    ]);

var dropdownItem = Cx.cx([
      "text-gray",
      "pl-2",
      "border-b",
      "border-gray-200",
      "last:border-none",
      "pb-2",
      "last:pb-0",
      "text-sm"
    ]);

var dropdownUser = Cx.cx([
      "hover:text-teal",
      "cursor-pointer"
    ]);

var toggles = Cx.cx([
      "flex",
      "gap-8"
    ]);

var dropdownTrigger = "!text-sm";

var search = "!w-full";

export {
  formContainer ,
  title ,
  buttonContainer ,
  fields ,
  dropdownTrigger ,
  dropdownBody ,
  search ,
  searchInput ,
  dropdownItem ,
  dropdownUser ,
  toggles ,
}
/* formContainer Not a pure module */
