// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateTsdDashboardRequest from "./requests/CreateTsdDashboardRequest.res.js";
import * as FetchTsdsDashboardRequest from "./requests/FetchTsdsDashboardRequest.res.js";
import * as UpdateTsdDashboardRequest from "./requests/UpdateTsdDashboardRequest.res.js";
import * as FetchTsdAgenciesDashboardRequest from "./requests/FetchTsdAgenciesDashboardRequest.res.js";
import * as FetchTsdAgencyAgentsDashboardRequest from "./requests/FetchTsdAgencyAgentsDashboardRequest.res.js";
import * as FetchTsdProviderTsdsDashboardRequest from "./requests/FetchTsdProviderTsdsDashboardRequest.res.js";

var Agency = {
  index: FetchTsdAgenciesDashboardRequest.exec
};

var AgencyAgent = {
  index: FetchTsdAgencyAgentsDashboardRequest.exec
};

var ProviderTsd = {
  index: FetchTsdProviderTsdsDashboardRequest.exec
};

var Show = {
  Agency: Agency,
  AgencyAgent: AgencyAgent,
  ProviderTsd: ProviderTsd
};

var Dashboard = {
  index: FetchTsdsDashboardRequest.exec,
  create: CreateTsdDashboardRequest.exec,
  update: UpdateTsdDashboardRequest.exec,
  Show: Show
};

export {
  Dashboard ,
}
/* CreateTsdDashboardRequest Not a pure module */
