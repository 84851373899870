// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "gap-2.5",
      "w-full",
      "max-w-[1180px]",
      "mb-12",
      "md:flex-row",
      "flex-col",
      "items-center"
    ]);

var search = Cx.cx(["w-full"]);

var searchButton = Cx.cx([
      "md:w-[200px]",
      "w-full"
    ]);

export {
  container ,
  search ,
  searchButton ,
}
/* container Not a pure module */
