// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "lg:flex-row",
      "overflow-auto"
    ]);

var Filters;

var Projects;

var Project;

var Search;

var Subheader;

export {
  container ,
  Filters ,
  Projects ,
  Project ,
  Search ,
  Subheader ,
}
/* container Not a pure module */
