// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../models/Role.res.js";
import * as Hooks from "../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Provider from "../../models/Provider.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Provider from "../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as ProviderEditTabs from "./tabs/ProviderEditTabs.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditStickyBar from "./stickybar/ProviderEditStickyBar.res.js";
import * as ReactCloudinaryUploadWidget from "react-cloudinary-upload-widget";

function ProviderEdit$ProviderEdit(props) {
  var locationId = props.locationId;
  var userRole = props.userRole;
  var provider = props.provider;
  var container = React.useRef(null);
  var initialState_providerUsers = provider.providerUsers;
  var initialState_apiKeys = provider.apiKeys;
  var initialState_locationId = locationId === 0 ? undefined : Caml_option.some(locationId);
  var initialState = {
    live: "Draft",
    provider: provider,
    providerUsers: initialState_providerUsers,
    apiKeys: initialState_apiKeys,
    locationId: initialState_locationId,
    editStatus: "Editing"
  };
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            var match = state.live;
            return {
                    TAG: "Update",
                    _0: {
                      live: match === "Live" ? "Draft" : "Live",
                      provider: state.provider,
                      providerUsers: state.providerUsers,
                      apiKeys: state.apiKeys,
                      locationId: state.locationId,
                      editStatus: state.editStatus
                    }
                  };
          }
          switch (action.TAG) {
            case "UpdateProvider" :
                return {
                        TAG: "Update",
                        _0: {
                          live: state.live,
                          provider: action._0,
                          providerUsers: state.providerUsers,
                          apiKeys: state.apiKeys,
                          locationId: state.locationId,
                          editStatus: state.editStatus
                        }
                      };
            case "UpdateProviderUsers" :
                return {
                        TAG: "Update",
                        _0: {
                          live: state.live,
                          provider: state.provider,
                          providerUsers: action._0.providerUsers,
                          apiKeys: state.apiKeys,
                          locationId: state.locationId,
                          editStatus: state.editStatus
                        }
                      };
            case "UpdateApiKeys" :
                return {
                        TAG: "Update",
                        _0: {
                          live: state.live,
                          provider: state.provider,
                          providerUsers: state.providerUsers,
                          apiKeys: action._0,
                          locationId: state.locationId,
                          editStatus: state.editStatus
                        }
                      };
            case "UpdateEditStatus" :
                return {
                        TAG: "Update",
                        _0: {
                          live: state.live,
                          provider: state.provider,
                          providerUsers: state.providerUsers,
                          apiKeys: state.apiKeys,
                          locationId: state.locationId,
                          editStatus: action._0
                        }
                      };
            case "Approve" :
                var provider = action._0;
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          live: state.live,
                          provider: state.provider,
                          providerUsers: state.providerUsers,
                          apiKeys: state.apiKeys,
                          locationId: state.locationId,
                          editStatus: "Updating"
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api_Provider.approve(provider.currentDraft.id), (function (x) {
                                    if (x.TAG === "Ok") {
                                      dispatch({
                                            TAG: "UpdateProvider",
                                            _0: x._0
                                          });
                                      return dispatch({
                                                  TAG: "UpdateEditStatus",
                                                  _0: "Editing"
                                                });
                                    }
                                    SentryLogger.error1({
                                          rootModule: "ProviderEdit",
                                          subModulePath: {
                                            hd: "ProviderEdit",
                                            tl: /* [] */0
                                          },
                                          value: "make",
                                          fullPath: "ProviderEdit.ProviderEdit.make"
                                        }, "EditProvider::Approve::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch({
                                          TAG: "UpdateEditStatus",
                                          _0: "Error"
                                        });
                                  }));
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.locationId;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ReactCloudinaryUploadWidget.WidgetLoader, {}),
                JsxRuntime.jsx(ProviderEditTabs.make, {
                      provider: state.provider,
                      live: state.live,
                      providerUsers: state.providerUsers,
                      apiKeys: state.apiKeys,
                      userRole: userRole,
                      updateEditStatus: (function (editStatus) {
                          dispatch({
                                TAG: "UpdateEditStatus",
                                _0: editStatus
                              });
                        }),
                      updateApiKeys: (function (apiKeys) {
                          dispatch({
                                TAG: "UpdateApiKeys",
                                _0: apiKeys
                              });
                        }),
                      updateProviderUsers: (function (providerUsers) {
                          dispatch({
                                TAG: "UpdateProviderUsers",
                                _0: providerUsers
                              });
                        }),
                      updateProvider: (function (provider) {
                          dispatch({
                                TAG: "UpdateProvider",
                                _0: provider
                              });
                        }),
                      selectedOption: match$1 !== undefined ? "Locations" : "ProviderDetails",
                      locationId: state.locationId,
                      children: Caml_option.some(JsxRuntime.jsx(ProviderEditStickyBar.make, {
                                provider: state.provider,
                                live: state.live,
                                userRole: userRole,
                                editStatus: state.editStatus,
                                toggleLive: (function () {
                                    dispatch("ToggleLive");
                                  }),
                                approve: (function (param) {
                                    dispatch({
                                          TAG: "Approve",
                                          _0: provider
                                        });
                                  })
                              }))
                    })
              ],
              ref: Caml_option.some(container)
            });
}

var ProviderEdit = {
  make: ProviderEdit$ProviderEdit
};

function ProviderEdit$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(ProviderEdit$ProviderEdit, {
              provider: Provider.Edit.fromJs(props$1.provider),
              userRole: Role.fromString(props$1.userRole),
              locationId: props$1.locationId
            });
}

var $$default = ProviderEdit$default;

export {
  ProviderEdit ,
  $$default as default,
}
/* Role Not a pure module */
