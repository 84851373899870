// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ScheduleModalServices from "../../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as UnderscoreFancyHeader from "../../../../../../styleguide/components/UnderscoreFancy/UnderscoreFancyHeader.res.js";
import * as JumbotronHivelocityCss from "./JumbotronHivelocityCss.res.js";
import * as JumbotronProviderIcons from "../../../../../../styleguide/components/JumbotronProviderIcons/JumbotronProviderIcons.res.js";
import HivelocityPng from "../../assets/hivelocity.png";

var hivelocityIcon = HivelocityPng;

function JumbotronHivelocity(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            className: JumbotronHivelocityCss.icon,
                            alt: "Hivelocity Logo",
                            src: hivelocityIcon
                          })
                    }),
                JsxRuntime.jsx(H1.make, {
                      className: JumbotronHivelocityCss.heading,
                      children: "Hivelocity Bare Metal Cloud"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(UnderscoreFancyHeader.make, {
                            children: "Get 1 Month Free!",
                            color: "Red"
                          }),
                      className: JumbotronHivelocityCss.subheading
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "RedDarker",
                            expanded: true,
                            onClick: (function (_event) {
                                setIsScheduleDemoModalOpen(function (param) {
                                      return true;
                                    });
                              }),
                            children: "Request a Demo"
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(JumbotronProviderIcons.make, {
                                  context: "Hivelocity"
                                }),
                            className: "container"
                          }),
                      className: JumbotronHivelocityCss.providerIcons
                    }),
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule a Hivelocity Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about Hivelocity bare metal solutions.",
                        buttonTitle: "Submit",
                        leadType: "HivelocityBareMetal"
                      }) : null
              ],
              className: JumbotronHivelocityCss.wrapper
            });
}

var Css;

var make = JumbotronHivelocity;

export {
  hivelocityIcon ,
  Css ,
  make ,
}
/* hivelocityIcon Not a pure module */
