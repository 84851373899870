// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../../models/Role.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Location from "../../../api/locations/Api_Location.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as LocationsIndexCss from "./styles/LocationsIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexHeaderNew from "./components/header/LocationsIndexHeaderNew.res.js";
import * as LocationsIndexContentNew from "./components/content/LocationsIndexContentNew.res.js";
import * as LocationsIndexFetchResults from "./components/fetch-result/LocationsIndexFetchResults.res.js";
import * as LocationsIndexTopCountries from "./components/top-countries/LocationsIndexTopCountries.res.js";
import * as LocationsIndexViewButtonsNew from "./components/view-buttons/LocationsIndexViewButtonsNew.res.js";
import * as LocationsIndexTopProvidersNew from "./components/top-providers/LocationsIndexTopProvidersNew.res.js";
import * as LocationsIndexBookACallSection from "./components/LocationsIndexBookACallSection.res.js";
import * as LocationsIndexPreloadedSearchLocations from "./components/preloaded-search-locations/LocationsIndexPreloadedSearchLocations.res.js";

function LocationsIndex$default(props) {
  var index = props.index;
  var initialState = React.useMemo((function () {
          return {
                  status: {
                    TAG: "Ready",
                    _0: index
                  },
                  currentPage: 1,
                  totalPages: 1,
                  sortBy: undefined,
                  search: "",
                  mapEnabled: false,
                  view: "Grid",
                  totalLocalities: 0,
                  totalLocations: index.totalPreloadedSearchLocations,
                  totalProviders: index.totalProviders,
                  currentTotalLocations: index.totalPreloadedSearchLocations,
                  providerIds: [],
                  userRole: Role.fromString(index.userRole)
                };
        }), []);
  var container = React.useRef(null);
  var searchLocations = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var scrollToTop = function () {
    Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
            prim.scrollIntoView();
          }));
  };
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchLocations" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api_Location.fetchLocationsIndexNew(state.currentPage, state.sortBy, state.search, state.providerIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedLocationsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "LocationsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "LocationsIndex.default"
                                          }, "LocationsIndex::FetchLocations::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLocationsFetch");
                                    }));
                            })
                        };
              case "FailLocationsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedLocationsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: "",
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: [],
                            userRole: state.userRole
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedLocationsFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: res.totalPreloadedSearchLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  scrollToTop();
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              mapEnabled: state.mapEnabled,
                              view: state.view,
                              totalLocalities: state.totalLocalities,
                              totalLocations: state.totalLocations,
                              totalProviders: state.totalProviders,
                              currentTotalLocations: state.currentTotalLocations,
                              providerIds: state.providerIds,
                              userRole: state.userRole
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  scrollToTop();
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocations",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: action._0,
                            search: state.search,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          },
                          _1: searchLocations
                        };
              case "PerformSearch" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingLocations") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocations",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              mapEnabled: state.mapEnabled,
                              view: state.view,
                              totalLocalities: state.totalLocalities,
                              totalLocations: state.totalLocations,
                              totalProviders: state.totalProviders,
                              currentTotalLocations: state.currentTotalLocations,
                              providerIds: state.providerIds,
                              userRole: state.userRole
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocations");
                              })
                          };
                  }
              case "ToggleView" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            mapEnabled: state.mapEnabled,
                            view: action._0,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "UpdateTotalProviders" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: action._0,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "UpdateTotalLocalities" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: action._0,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: state.providerIds,
                            userRole: state.userRole
                          }
                        };
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            mapEnabled: state.mapEnabled,
                            view: state.view,
                            totalLocalities: state.totalLocalities,
                            totalLocations: state.totalLocations,
                            totalProviders: state.totalProviders,
                            currentTotalLocations: state.currentTotalLocations,
                            providerIds: action._0,
                            userRole: state.userRole
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocations");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var res = state.status;
  var tmp;
  if (typeof res !== "object") {
    tmp = res === "FetchingLocations" ? JsxRuntime.jsx(LocationsIndexFetchResults.make, {
            status: "Fetching",
            reset: (function (param) {
                dispatch("Reset");
              })
          }) : JsxRuntime.jsx(LocationsIndexFetchResults.make, {
            status: "Error",
            reset: (function (param) {
                dispatch("Reset");
              })
          });
  } else {
    var res$1 = res._0;
    var match$1 = res$1.totalPreloadedSearchLocations;
    tmp = match$1 !== 0 ? JsxRuntime.jsx(LocationsIndexPreloadedSearchLocations.make, {
            index: res$1,
            updatePage: (function (pageNum) {
                dispatch({
                      TAG: "UpdatePage",
                      _0: pageNum
                    });
              })
          }) : JsxRuntime.jsx(LocationsIndexFetchResults.make, {
            status: "Empty",
            reset: (function (param) {
                dispatch("Reset");
              })
          });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationsIndexHeaderNew.make, {
                      title: "Global Data Centers Locations",
                      locationsCount: state.currentTotalLocations,
                      updateSearch: (function (search) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateSearchInput",
                                  _0: search
                                });
                          };
                        }),
                      updateProviderIds: (function (ids) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateProviderIds",
                                  _0: ids
                                });
                          };
                        }),
                      userRole: Role.fromString(index.userRole),
                      reset: (function () {
                          dispatch("Reset");
                        })
                    }),
                JsxRuntime.jsx(LocationsIndexViewButtonsNew.make, {
                      view: state.view,
                      updateView: (function (view) {
                          dispatch({
                                TAG: "ToggleView",
                                _0: view
                              });
                        })
                    }),
                tmp,
                JsxRuntime.jsx(LocationsIndexContentNew.make, {
                      totalLocations: index.totalPreloadedSearchLocations,
                      totalProviders: index.totalProviders,
                      totalCountries: index.totalCountries,
                      totalCities: index.totalCities,
                      topProviders: Belt_Array.slice(index.providers, 0, 10)
                    }),
                JsxRuntime.jsx(LocationsIndexTopCountries.make, {
                      countries: index.countries
                    }),
                JsxRuntime.jsx(LocationsIndexTopProvidersNew.make, {
                      providers: index.providers
                    }),
                JsxRuntime.jsx(LocationsIndexBookACallSection.make, {})
              ],
              ref: Caml_option.some(container),
              className: LocationsIndexCss.container
            });
}

var Css;

var $$default = LocationsIndex$default;

export {
  Css ,
  $$default as default,
}
/* Role Not a pure module */
