// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as BlogPostTile from "../../../../../blog-posts/common/blog-post-tile/BlogPostTile.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeBlogPostGridCss from "./HomeBlogPostGridCss.res.js";

function HomeBlogPostGrid(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: Belt_Array.map(props.blogPosts, (function (blogPost) {
                                  return JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(BlogPostTile.make, {
                                                    blogPost: blogPost
                                                  })
                                            }, ID.toString(blogPost.id));
                                })),
                          className: HomeBlogPostGridCss.gridSection
                        }),
                    className: HomeBlogPostGridCss.postsContainer
                  }),
              className: HomeBlogPostGridCss.container
            });
}

var Css;

var make = HomeBlogPostGrid;

export {
  Css ,
  make ,
}
/* ID Not a pure module */
