// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchDashboardProviderTsdsIndexRequest from "./requests/FetchDashboardProviderTsdsIndexRequest.res.js";

var Dashboard = {
  index: FetchDashboardProviderTsdsIndexRequest.exec
};

export {
  Dashboard ,
}
/* FetchDashboardProviderTsdsIndexRequest Not a pure module */
