// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var pageContainer = Cx.cx([
      "flex",
      "flex-col"
    ]);

var container = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "py-10"
    ]);

var bodyContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-5",
      "md:w-3/4",
      "w-full",
      "px-5"
    ]);

var Form;

var Pdf;

var Show;

var Edit;

export {
  pageContainer ,
  container ,
  bodyContainer ,
  Form ,
  Pdf ,
  Show ,
  Edit ,
}
/* pageContainer Not a pure module */
