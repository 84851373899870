// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var actionBarInner = Cx.cx([
      "flex",
      "gap-1",
      "flex-row",
      "items-center",
      "justify-between",
      "px-7",
      "w-full"
    ]);

var actionBarInfo = Cx.cx([
      "flex",
      "flex-wrap",
      "flex-col",
      "text-white",
      "text-sm",
      "md:text-base",
      "items-start"
    ]);

var actionBarDropdown = Cx.cx([
      "bg-white",
      "text-white",
      "border",
      "border-solid",
      "!border-white"
    ]);

var actionBarSpinner = Cx.cx([
      "flex",
      "relative",
      "min-w-16"
    ]);

var actionBarError = Cx.cx([
      "grid",
      "justify-end",
      "text-red-300",
      "font-bold"
    ]);

var actionBar = Cx.cx([
      "fixed",
      "bottom-0",
      "left-0",
      "right-0",
      "h-20",
      "bg-teal-600",
      "transition-transform",
      "z-[9999]",
      "flex"
    ]);

var dropdownBody = Cx.cx([
      "grid",
      "grid-cols-[minmax(160px,1fr)]",
      "grid-flow-row",
      "auto-rows-max",
      "flex-col",
      "gap-1",
      "pt-0",
      "pb-0",
      "max-h-64",
      "overflow-y-auto",
      "min-w-72",
      "text-sm"
    ]);

var dropdownBodyRow = Cx.cx([
      "grid",
      "grid-cols-[max-content_1fr]",
      "gap-1",
      "items-center",
      "py-1",
      "border-b",
      "border-gray-100",
      "last:border-b-0"
    ]);

var shown = "translate-y-0";

var hidden = "translate-y-full";

export {
  actionBarInner ,
  actionBarInfo ,
  actionBarDropdown ,
  actionBarSpinner ,
  actionBarError ,
  actionBar ,
  shown ,
  hidden ,
  dropdownBody ,
  dropdownBodyRow ,
}
/* actionBarInner Not a pure module */
