// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as ServicesSeparatorBlockCss from "./ServicesSeparatorBlockCss.res.js";

function ServicesSeparatorBlock(props) {
  var title = props.title;
  if (title !== undefined) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("div", {
                        className: ServicesSeparatorBlockCss.separator
                      }),
                  JsxRuntime.jsx("div", {
                        children: title,
                        className: ServicesSeparatorBlockCss.separatorBox
                      }),
                  JsxRuntime.jsx("div", {
                        className: ServicesSeparatorBlockCss.separator
                      })
                ],
                className: ServicesSeparatorBlockCss.separatorWithTitle
              });
  } else {
    return JsxRuntime.jsx("div", {
                className: ServicesSeparatorBlockCss.separator
              });
  }
}

var Css;

var make = ServicesSeparatorBlock;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
