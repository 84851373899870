// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchProjectsDashboardRequest from "./requests/FetchProjectsDashboardRequest.res.js";
import * as FetchProjectsDashboardOwnersRequest from "./requests/FetchProjectsDashboardOwnersRequest.res.js";
import * as FetchProjectsDashboardProvidersRequest from "./requests/FetchProjectsDashboardProvidersRequest.res.js";
import * as FetchProjectsDashboardConciergesRequest from "./requests/FetchProjectsDashboardConciergesRequest.res.js";

var Dashboard = {
  index: FetchProjectsDashboardRequest.exec,
  owners: FetchProjectsDashboardOwnersRequest.exec,
  providers: FetchProjectsDashboardProvidersRequest.exec,
  concierges: FetchProjectsDashboardConciergesRequest.exec
};

export {
  Dashboard ,
}
/* FetchProjectsDashboardRequest Not a pure module */
