// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Country from "../../../models/Country.res.js";
import * as BlogPost from "../../../models/BlogPost.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as HomeJumbotron from "./components/jumbotron/HomeJumbotron.res.js";
import * as HomeProviderIcons from "./components/provider-icons/HomeProviderIcons.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeBlogPostSection from "./components/blog-post-section/HomeBlogPostSection.res.js";
import * as HomeBareMetalSection from "./components/bare-metal-section/HomeBareMetalSection.res.js";
import * as HomeColocationSection from "./components/colocation-section/HomeColocationSection.res.js";
import * as HomeTestimonialsSection from "./components/testimonials-section/HomeTestimonialsSection.res.js";
import * as HomeTopCountriesSection from "./components/top-countries-section/HomeTopCountriesSection.res.js";
import * as HomeTopProvidersSection from "./components/top-providers-section/HomeTopProvidersSection.res.js";

function HomeIndex$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(HomeJumbotron.make, {
                      totalLocations: props$1.totalLocations
                    }),
                JsxRuntime.jsx(HomeProviderIcons.make, {}),
                JsxRuntime.jsx(HomeColocationSection.make, {}),
                JsxRuntime.jsx(HomeBareMetalSection.make, {}),
                JsxRuntime.jsx(HomeTopCountriesSection.make, {
                      countries: Belt_Array.map(props$1.countries, Country.ListItem.fromJs)
                    }),
                JsxRuntime.jsx(HomeTopProvidersSection.make, {
                      providers: Belt_Array.map(props$1.providers, Provider.ListItem.fromJs)
                    }),
                JsxRuntime.jsx(HomeBlogPostSection.make, {
                      blogPosts: Belt_Array.map(props$1.blogPosts, BlogPost.Simple.fromJs)
                    }),
                JsxRuntime.jsx(HomeTestimonialsSection.make, {})
              ]
            });
}

var $$default = HomeIndex$default;

export {
  $$default as default,
}
/* Country Not a pure module */
