// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var box = Cx.cx([
      "flex",
      "flex-col",
      "md:w-[380px]",
      "w-full",
      "items-center",
      "justify-center",
      "gap-8",
      "p-10",
      "rounded"
    ]);

var title = Cx.cx(["text-base"]);

var description = Cx.cx(["text-sm"]);

var white = Cx.cx(["bg-white"]);

var gray = Cx.cx(["bg-gray-100"]);

export {
  box ,
  title ,
  description ,
  white ,
  gray ,
}
/* box Not a pure module */
