// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderBrochureNewCss from "./ProviderBrochureNewCss.res.js";
import * as ProviderBrochureNewForm from "./components/ProviderBrochureNewForm.res.js";
import * as DashboardProvidersIndexNavbar from "../../../providers/dashboard/index/components/navbar/DashboardProvidersIndexNavbar.res.js";

function ProviderBrochureNew$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardProvidersIndexNavbar.make, {
                      selectedCategory: "NewBrochure"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: ProviderBrochureNewCss.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: ProviderBrochureNewCss.pageTitle,
                              children: "New Provider Brochure"
                            }),
                        JsxRuntime.jsx(ProviderBrochureNewForm.make, {
                              providers: props.providers
                            })
                      ]
                    })
              ]
            });
}

var Css;

var $$default = ProviderBrochureNew$default;

export {
  Css ,
  $$default as default,
}
/* H1 Not a pure module */
