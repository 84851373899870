// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              locationId: field.required("locationId", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              mainImageUrl: field.required("mainImageUrl", Json_Decode$JsonCombinators.string),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              providerAgreement: field.required("providerAgreement", Json_Decode$JsonCombinators.bool),
              sponsoredGlobal: field.required("sponsoredGlobal", Json_Decode$JsonCombinators.bool),
              sponsoredCountry: field.required("sponsoredCountry", Json_Decode$JsonCombinators.bool),
              sponsoredState: field.required("sponsoredState", Json_Decode$JsonCombinators.bool),
              sponsoredCity: field.required("sponsoredCity", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var PreloadedSearchLocation = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string)
            };
    });

var Provider = {
  decoder: decoder$1
};

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

var Country = {
  decoder: decoder$2
};

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              preloadedSearchLocations: field.required("preloadedSearchLocations", Json_Decode$JsonCombinators.array(decoder)),
              totalPreloadedSearchLocations: field.required("totalPreloadedSearchLocations", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(decoder$1)),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.$$int),
              countries: field.required("countries", Json_Decode$JsonCombinators.array(decoder$2)),
              totalCountries: field.required("totalCountries", Json_Decode$JsonCombinators.$$int),
              totalCities: field.required("totalCities", Json_Decode$JsonCombinators.$$int),
              userRole: field.required("userRole", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Index = {
  PreloadedSearchLocation: PreloadedSearchLocation,
  Provider: Provider,
  Country: Country,
  decoder: decoder$3,
  fromJson: fromJson$1
};

export {
  Index ,
}
/* decoder Not a pure module */
