// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ProviderSetting from "../../../models/ProviderSetting.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(Provider.Dashboard.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, query, sortBy, permissions, approvalStatuses) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "Locations" ? "&sort_by=locations_count&sort_direction=" + toString(sortBy.VAL) : (
      variant === "CreatedAt" ? "&sort_by=providers.created_at&sort_direction=" + toString(sortBy.VAL) : (
          variant === "IsApproved" ? "&sort_by=is_approved&sort_direction=" + toString(sortBy.VAL) : (
              variant === "Managers" ? "&sort_by=provider_users_counter_cache&sort_direction=" + toString(sortBy.VAL) : (
                  variant === "Name" ? "&sort_by=name&sort_direction=" + toString(sortBy.VAL) : (
                      variant === "ProviderTsdsCount" ? "&sort_by=providers.provider_tsds_count&sort_direction=" + toString(sortBy.VAL) : "&sort_by=active&sort_direction=" + toString(sortBy.VAL)
                    )
                )
            )
        )
    );
  var permissions$1 = permissions.map(function (permission) {
          return "&settings[]=" + ProviderSetting.Field.toFieldName(permission);
        }).join("");
  var approvalStatuses$1 = approvalStatuses.map(function (status) {
          return "&is_approved[]=" + Provider.ApprovalStatus.toBoolString(status);
        }).join("");
  return Rest.$$fetch("/dashboard/providers?" + page + query$1 + sortBy$1 + permissions$1 + approvalStatuses$1, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
