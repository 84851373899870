// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Role from "../../../../../../models/Role.res.js";
import * as Badge from "../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as React from "react";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Api_Project from "../../../../../../api/projects/Api_Project.res.js";
import * as SearchField from "../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as React$1 from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as Solid from "@heroicons/react/16/solid";
import * as Solid$1 from "@heroicons/react/24/solid";
import * as Outline from "@heroicons/react/24/outline";
import * as DashboardProjectsIndexFiltersCss from "../../styles/shared/DashboardProjectsIndexFiltersCss.res.js";
import * as DashboardProjectsIndexFilterSection from "./components/section/DashboardProjectsIndexFilterSection.res.js";
import * as DashboardProjectsIndexFiltersCheckbox from "./components/checkbox/DashboardProjectsIndexFiltersCheckbox.res.js";

var initialState = {
  categories: true,
  statuses: true,
  agencies: false,
  owners: false,
  providers: false,
  concierges: false,
  userRoles: false
};

function reducer(state, action) {
  switch (action) {
    case "ToggleCategories" :
        return {
                categories: !state.categories,
                statuses: state.statuses,
                agencies: state.agencies,
                owners: state.owners,
                providers: state.providers,
                concierges: state.concierges,
                userRoles: state.userRoles
              };
    case "ToggleStatuses" :
        return {
                categories: state.categories,
                statuses: !state.statuses,
                agencies: state.agencies,
                owners: state.owners,
                providers: state.providers,
                concierges: state.concierges,
                userRoles: state.userRoles
              };
    case "ToggleAgencies" :
        return {
                categories: state.categories,
                statuses: state.statuses,
                agencies: !state.agencies,
                owners: state.owners,
                providers: state.providers,
                concierges: state.concierges,
                userRoles: state.userRoles
              };
    case "ToggleOwners" :
        return {
                categories: state.categories,
                statuses: state.statuses,
                agencies: state.agencies,
                owners: !state.owners,
                providers: state.providers,
                concierges: state.concierges,
                userRoles: state.userRoles
              };
    case "ToggleProviders" :
        return {
                categories: state.categories,
                statuses: state.statuses,
                agencies: state.agencies,
                owners: state.owners,
                providers: !state.providers,
                concierges: state.concierges,
                userRoles: state.userRoles
              };
    case "ToggleConcierges" :
        return {
                categories: state.categories,
                statuses: state.statuses,
                agencies: state.agencies,
                owners: state.owners,
                providers: state.providers,
                concierges: !state.concierges,
                userRoles: state.userRoles
              };
    case "ToggleUserRoles" :
        return {
                categories: state.categories,
                statuses: state.statuses,
                agencies: state.agencies,
                owners: state.owners,
                providers: state.providers,
                concierges: state.concierges,
                userRoles: !state.userRoles
              };
    
  }
}

var Expand = {
  initialState: initialState,
  reducer: reducer
};

var initialState_categories = [];

var initialState_statuses = [];

var initialState_userRoles = [];

var initialState_ownersStatus = {
  TAG: "ReadyOwners",
  _0: {
    owners: []
  }
};

var initialState_owners = [];

var initialState_ownerIds = [];

var initialState_selectedOwners = [];

var initialState_providersStatus = {
  TAG: "ReadyProviders",
  _0: {
    providers: []
  }
};

var initialState_providers = [];

var initialState_providerIds = [];

var initialState_selectedProviders = [];

var initialState_conciergesStatus = {
  TAG: "ReadyConcierges",
  _0: {
    projectConcierges: []
  }
};

var initialState_concierges = [];

var initialState_conciergeIds = [];

var initialState_selectedConcierges = [];

var initialState$1 = {
  colocation: false,
  bareMetal: false,
  cloud: false,
  internet: false,
  network: false,
  active: false,
  inReview: false,
  inactive: false,
  finalized: false,
  draft: false,
  user: false,
  agent: false,
  provider: false,
  admin: false,
  concierge: false,
  filtersCount: 0,
  categories: initialState_categories,
  statuses: initialState_statuses,
  userRoles: initialState_userRoles,
  ownersStatus: initialState_ownersStatus,
  owners: initialState_owners,
  ownerIds: initialState_ownerIds,
  ownersSearch: "",
  selectedOwners: initialState_selectedOwners,
  ownersCount: 0,
  providersStatus: initialState_providersStatus,
  providers: initialState_providers,
  providerIds: initialState_providerIds,
  providersSearch: "",
  selectedProviders: initialState_selectedProviders,
  providersCount: 0,
  conciergesStatus: initialState_conciergesStatus,
  concierges: initialState_concierges,
  conciergeIds: initialState_conciergeIds,
  conciergesSearch: "",
  selectedConcierges: initialState_selectedConcierges,
  conciergesCount: 0,
  userRolesCount: 0
};

var Filters = {
  initialState: initialState$1
};

function DashboardProjectsIndexFilters(props) {
  var onClick = props.onClick;
  var updateConciergeIds = props.updateConciergeIds;
  var updateProviderIds = props.updateProviderIds;
  var updateOwnerIds = props.updateOwnerIds;
  var updateUserRoles = props.updateUserRoles;
  var updateCategories = props.updateCategories;
  var updateStatuses = props.updateStatuses;
  var enableReset = props.enableReset;
  var resetCounter = props.resetCounter;
  var filtersOpen = props.filtersOpen;
  var searchOwners = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformOwnersSearch",
                _0: param.state.ownersSearch
              });
        }));
  var searchProviders = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformProvidersSearch",
                _0: param.state.providersSearch
              });
        }));
  var searchConcierges = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformConciergesSearch",
                _0: param.state.conciergesSearch
              });
        }));
  var match = React.useReducer(reducer, initialState);
  var expandDispatch = match[1];
  var expandState = match[0];
  var match$1 = Hooks.useReducer(initialState$1, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "ToggleColocation" :
                  var newrecord = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord.filtersCount = state.colocation ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord.colocation = !state.colocation, newrecord)
                        };
              case "ToggleBareMetal" :
                  var newrecord$1 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$1.filtersCount = state.bareMetal ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$1.bareMetal = !state.bareMetal, newrecord$1)
                        };
              case "ToggleCloud" :
                  var newrecord$2 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$2.filtersCount = state.cloud ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$2.cloud = !state.cloud, newrecord$2)
                        };
              case "ToggleInternet" :
                  var newrecord$3 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$3.filtersCount = state.internet ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$3.internet = !state.internet, newrecord$3)
                        };
              case "ToggleNetwork" :
                  var newrecord$4 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$4.filtersCount = state.network ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$4.network = !state.network, newrecord$4)
                        };
              case "ToggleActive" :
                  var newrecord$5 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$5.filtersCount = state.active ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$5.active = !state.active, newrecord$5)
                        };
              case "ToggleInReview" :
                  var newrecord$6 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$6.filtersCount = state.inReview ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$6.inReview = !state.inReview, newrecord$6)
                        };
              case "ToggleInactive" :
                  var newrecord$7 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$7.filtersCount = state.inactive ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$7.inactive = !state.inactive, newrecord$7)
                        };
              case "ToggleFinalized" :
                  var newrecord$8 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$8.filtersCount = state.finalized ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$8.finalized = !state.finalized, newrecord$8)
                        };
              case "ToggleDraft" :
                  var newrecord$9 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$9.filtersCount = state.draft ? state.filtersCount - 1 | 0 : state.filtersCount + 1 | 0, newrecord$9.draft = !state.draft, newrecord$9)
                        };
              case "ToggleUser" :
                  var newrecord$10 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$10.userRolesCount = state.user ? state.userRolesCount - 1 | 0 : state.userRolesCount + 1 | 0, newrecord$10.user = !state.user, newrecord$10)
                        };
              case "ToggleAgent" :
                  var newrecord$11 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$11.userRolesCount = state.agent ? state.userRolesCount - 1 | 0 : state.userRolesCount + 1 | 0, newrecord$11.agent = !state.agent, newrecord$11)
                        };
              case "ToggleAdmin" :
                  var newrecord$12 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$12.userRolesCount = state.admin ? state.userRolesCount - 1 | 0 : state.userRolesCount + 1 | 0, newrecord$12.admin = !state.admin, newrecord$12)
                        };
              case "ToggleProvider" :
                  var newrecord$13 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$13.userRolesCount = state.provider ? state.userRolesCount - 1 | 0 : state.userRolesCount + 1 | 0, newrecord$13.provider = !state.provider, newrecord$13)
                        };
              case "ToggleConcierge" :
                  var newrecord$14 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$14.userRolesCount = state.concierge ? state.userRolesCount - 1 | 0 : state.userRolesCount + 1 | 0, newrecord$14.concierge = !state.concierge, newrecord$14)
                        };
              case "Reset" :
                  var newrecord$15 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$15.filtersCount = 0, newrecord$15.concierge = false, newrecord$15.admin = false, newrecord$15.provider = false, newrecord$15.agent = false, newrecord$15.user = false, newrecord$15.draft = false, newrecord$15.finalized = false, newrecord$15.inactive = false, newrecord$15.inReview = false, newrecord$15.active = false, newrecord$15.network = false, newrecord$15.internet = false, newrecord$15.cloud = false, newrecord$15.bareMetal = false, newrecord$15.colocation = false, newrecord$15)
                        };
              case "ResetStatuses" :
                  var newrecord$16 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$16.statuses = [], newrecord$16),
                          _1: updateStatuses([])
                        };
              case "ResetCategories" :
                  var newrecord$17 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$17.categories = [], newrecord$17),
                          _1: updateCategories([])
                        };
              case "ResetUserRoles" :
                  var newrecord$18 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$18.userRolesCount = 0, newrecord$18.userRoles = [], newrecord$18),
                          _1: updateUserRoles([])
                        };
              case "ResetOwnerIds" :
                  var newrecord$19 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$19.ownersCount = 0, newrecord$19.selectedOwners = [], newrecord$19.ownersSearch = "", newrecord$19.ownerIds = [], newrecord$19),
                          _1: updateOwnerIds([])
                        };
              case "ResetProviderIds" :
                  var newrecord$20 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$20.providersCount = 0, newrecord$20.selectedProviders = [], newrecord$20.providersSearch = "", newrecord$20.providerIds = [], newrecord$20),
                          _1: updateProviderIds([])
                        };
              case "ResetConciergeIds" :
                  var newrecord$21 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$21.conciergesCount = 0, newrecord$21.selectedConcierges = [], newrecord$21.conciergesSearch = "", newrecord$21.conciergeIds = [], newrecord$21),
                          _1: updateConciergeIds([])
                        };
              case "FetchOwners" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Project.Dashboard.owners(state.ownersSearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedOwnersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProjectsIndexFilters",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "DashboardProjectsIndexFilters.make"
                                          }, "FailOwnersFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailOwnersFetch");
                                    }));
                            })
                        };
              case "FailOwnersFetch" :
                  var newrecord$22 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$22.ownersStatus = "FailedOwnersFetch", newrecord$22)
                        };
              case "FetchProviders" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Project.Dashboard.providers(state.providersSearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProvidersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProjectsIndexFilters",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "DashboardProjectsIndexFilters.make"
                                          }, "FailProvidersFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProvidersFetch");
                                    }));
                            })
                        };
              case "FailProvidersFetch" :
                  var newrecord$23 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$23.providersStatus = "FailedProvidersFetch", newrecord$23)
                        };
              case "FetchConcierges" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Project.Dashboard.concierges(state.conciergesSearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedConciergesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProjectsIndexFilters",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "DashboardProjectsIndexFilters.make"
                                          }, "FailConciergesFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailConciergesFetch");
                                    }));
                            })
                        };
              case "FailConciergesFetch" :
                  var newrecord$24 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$24.conciergesStatus = "FailedConciergesFetch", newrecord$24)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateCategories" :
                  var category = action._0;
                  var categories = state.categories.includes(category) ? (function (__x) {
                          return __x.filter(function (cat) {
                                      return cat !== category;
                                    });
                        })(state.categories) : state.categories.concat([category]);
                  var newrecord$25 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$25.categories = categories, newrecord$25),
                          _1: updateCategories(categories)
                        };
              case "UpdateStatuses" :
                  var status = action._0;
                  var statuses = state.statuses.includes(status) ? (function (__x) {
                          return __x.filter(function (stat) {
                                      return stat !== status;
                                    });
                        })(state.statuses) : state.statuses.concat([status]);
                  var newrecord$26 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$26.statuses = statuses, newrecord$26),
                          _1: updateStatuses(statuses)
                        };
              case "UpdateUserRoles" :
                  var role = action._0;
                  var userRoles = state.userRoles.includes(role) ? (function (__x) {
                          return __x.filter(function (r) {
                                      return r !== role;
                                    });
                        })(state.userRoles) : state.userRoles.concat([role]);
                  var newrecord$27 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$27.userRoles = userRoles, newrecord$27),
                          _1: updateUserRoles(userRoles)
                        };
              case "UpdateOwnerIds" :
                  var userId = action._0;
                  var ownerIds = state.ownerIds.includes(userId) ? (function (__x) {
                          return __x.filter(function (id) {
                                      return Caml_obj.notequal(id, userId);
                                    });
                        })(state.ownerIds) : state.ownerIds.concat([userId]);
                  var newrecord$28 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$28.ownersSearch = "", newrecord$28.ownerIds = ownerIds, newrecord$28),
                          _1: updateOwnerIds(ownerIds)
                        };
              case "UpdateProviderIds" :
                  var providerId = action._0;
                  var providerIds = state.providerIds.includes(providerId) ? (function (__x) {
                          return __x.filter(function (id) {
                                      return Caml_obj.notequal(id, providerId);
                                    });
                        })(state.providerIds) : state.providerIds.concat([providerId]);
                  var newrecord$29 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$29.providersSearch = "", newrecord$29.providerIds = providerIds, newrecord$29),
                          _1: updateProviderIds(providerIds)
                        };
              case "UpdateConciergeIds" :
                  var conciergeId = action._0;
                  var conciergeIds = state.conciergeIds.includes(conciergeId) ? (function (__x) {
                          return __x.filter(function (id) {
                                      return Caml_obj.notequal(id, conciergeId);
                                    });
                        })(state.conciergeIds) : state.conciergeIds.concat([conciergeId]);
                  var newrecord$30 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$30.conciergesSearch = "", newrecord$30.conciergeIds = conciergeIds, newrecord$30),
                          _1: updateConciergeIds(conciergeIds)
                        };
              case "SucceedOwnersFetch" :
                  var res = action._0;
                  var newrecord$31 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$31.owners = res.owners, newrecord$31.ownersStatus = {
                              TAG: "ReadyOwners",
                              _0: res
                            }, newrecord$31)
                        };
              case "UpdateOwnersSearchInput" :
                  var newrecord$32 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$32.ownersSearch = action._0, newrecord$32),
                          _1: searchOwners
                        };
              case "PerformOwnersSearch" :
                  var match = state.ownersStatus;
                  if (typeof match !== "object" && match === "FetchingOwners") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.ownersSearch) {
                    return "NoUpdate";
                  }
                  var newrecord$33 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$33.ownersStatus = "FetchingOwners", newrecord$33),
                          _1: (function (param) {
                              param.dispatch("FetchOwners");
                            })
                        };
                  break;
              case "UpdateSelectedOwners" :
                  var owner = action._0;
                  var owners = state.selectedOwners.includes(owner) ? state.selectedOwners.filter(function (own) {
                          return Caml_obj.notequal(own.id, owner.id);
                        }) : state.selectedOwners.concat([owner]);
                  var newrecord$34 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$34.ownersCount = owners.length, newrecord$34.selectedOwners = owners, newrecord$34),
                          _1: (function (param) {
                              param.dispatch({
                                    TAG: "UpdateOwnerIds",
                                    _0: owner.id
                                  });
                            })
                        };
              case "SucceedProvidersFetch" :
                  var res$1 = action._0;
                  var newrecord$35 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$35.providers = res$1.providers, newrecord$35.providersStatus = {
                              TAG: "ReadyProviders",
                              _0: res$1
                            }, newrecord$35)
                        };
              case "UpdateProvidersSearchInput" :
                  var newrecord$36 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$36.providersSearch = action._0, newrecord$36),
                          _1: searchProviders
                        };
              case "PerformProvidersSearch" :
                  var match$1 = state.providersStatus;
                  if (typeof match$1 !== "object" && match$1 === "FetchingProviders") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.providersSearch) {
                    return "NoUpdate";
                  }
                  var newrecord$37 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$37.providersStatus = "FetchingProviders", newrecord$37),
                          _1: (function (param) {
                              param.dispatch("FetchProviders");
                            })
                        };
                  break;
              case "UpdateSelectedProviders" :
                  var provider = action._0;
                  var providers = state.selectedProviders.includes(provider) ? state.selectedProviders.filter(function (prov) {
                          return Caml_obj.notequal(prov.id, provider.id);
                        }) : state.selectedProviders.concat([provider]);
                  var newrecord$38 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$38.providersCount = providers.length, newrecord$38.selectedProviders = providers, newrecord$38),
                          _1: (function (param) {
                              param.dispatch({
                                    TAG: "UpdateProviderIds",
                                    _0: provider.id
                                  });
                            })
                        };
              case "SucceedConciergesFetch" :
                  var res$2 = action._0;
                  var newrecord$39 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "Update",
                          _0: (newrecord$39.concierges = res$2.projectConcierges, newrecord$39.conciergesStatus = {
                              TAG: "ReadyConcierges",
                              _0: res$2
                            }, newrecord$39)
                        };
              case "UpdateConciergesSearchInput" :
                  var newrecord$40 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$40.conciergesSearch = action._0, newrecord$40),
                          _1: searchConcierges
                        };
              case "PerformConciergesSearch" :
                  var match$2 = state.conciergesStatus;
                  if (typeof match$2 !== "object" && match$2 === "FetchingConcierges") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.conciergesSearch) {
                    return "NoUpdate";
                  }
                  var newrecord$41 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$41.conciergesStatus = "FetchingConcierges", newrecord$41),
                          _1: (function (param) {
                              param.dispatch("FetchConcierges");
                            })
                        };
                  break;
              case "UpdateSelectedConcierges" :
                  var concierge = action._0;
                  var concierges = state.selectedConcierges.includes(concierge) ? state.selectedConcierges.filter(function (con) {
                          return Caml_obj.notequal(con.id, concierge.id);
                        }) : state.selectedConcierges.concat([concierge]);
                  var newrecord$42 = Caml_obj.obj_dup(state);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: (newrecord$42.conciergesCount = concierges.length, newrecord$42.selectedConcierges = concierges, newrecord$42),
                          _1: (function (param) {
                              param.dispatch({
                                    TAG: "UpdateConciergeIds",
                                    _0: concierge.id
                                  });
                            })
                        };
              
            }
          }
        }));
  var filtersDispatch = match$1[1];
  var filtersState = match$1[0];
  React.useEffect((function () {
          if (resetCounter > 0) {
            filtersDispatch("Reset");
            filtersDispatch("ResetCategories");
            filtersDispatch("ResetStatuses");
            filtersDispatch("ResetUserRoles");
            filtersDispatch("ResetOwnerIds");
            filtersDispatch("ResetProviderIds");
            filtersDispatch("ResetConciergeIds");
          }
          
        }), [resetCounter]);
  var match$2 = filtersState.ownersStatus;
  var tmp;
  var exit = 0;
  if (typeof match$2 !== "object" && match$2 !== "FetchingOwners") {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsxs(Popover.make, {
          className: DashboardProjectsIndexFiltersCss.searchContainer,
          children: [
            JsxRuntime.jsx(Popover.Trigger.make, {
                  children: JsxRuntime.jsx(SearchField.make, {
                        id: "owners-search",
                        value: filtersState.ownersSearch,
                        placeholder: "Search Project Owners",
                        inputClassName: "",
                        onChange: (function ($$event) {
                            filtersDispatch({
                                  TAG: "UpdateOwnersSearchInput",
                                  _0: $$event.target.value
                                });
                          }),
                        showSearchIcon: false
                      })
                }),
            JsxRuntime.jsx(Popover.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardProjectsIndexFiltersCss.searchBody,
                  children: filtersState.owners.length !== 0 ? Belt_Array.map(filtersState.owners, (function (owner) {
                            var id = "project-owners-search-filter--owner-" + ID.toString(owner.id);
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: owner.fullName,
                                                        className: DashboardProjectsIndexFiltersCss.ownerName
                                                      }),
                                                  JsxRuntime.jsx(Badge.User.Role.make, {
                                                        role: Role.fromString(owner.role),
                                                        size: "XS"
                                                      })
                                                ],
                                                className: DashboardProjectsIndexFiltersCss.ownerNameContainer
                                              }),
                                          JsxRuntime.jsx("span", {
                                                children: owner.email,
                                                className: DashboardProjectsIndexFiltersCss.ownerEmail
                                              })
                                        ],
                                        className: DashboardProjectsIndexFiltersCss.searchItem,
                                        onClick: (function (param) {
                                            enableReset();
                                            filtersDispatch({
                                                  TAG: "UpdateSelectedOwners",
                                                  _0: owner
                                                });
                                          })
                                      }, id);
                          })) : JsxRuntime.jsx("div", {
                          children: "No project owner found.",
                          className: DashboardProjectsIndexFiltersCss.searchItem
                        }, "no-owners")
                })
          ]
        });
  }
  var match$3 = filtersState.conciergesStatus;
  var tmp$1;
  var exit$1 = 0;
  if (typeof match$3 !== "object" && match$3 !== "FetchingConcierges") {
    tmp$1 = null;
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsxs(Popover.make, {
          className: DashboardProjectsIndexFiltersCss.searchContainer,
          children: [
            JsxRuntime.jsx(Popover.Trigger.make, {
                  children: JsxRuntime.jsx(SearchField.make, {
                        id: "concierges-search",
                        value: filtersState.conciergesSearch,
                        placeholder: "Search Project Concierges",
                        inputClassName: "",
                        onChange: (function ($$event) {
                            filtersDispatch({
                                  TAG: "UpdateConciergesSearchInput",
                                  _0: $$event.target.value
                                });
                          }),
                        showSearchIcon: false
                      })
                }),
            JsxRuntime.jsx(Popover.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardProjectsIndexFiltersCss.searchBody,
                  children: filtersState.concierges.length !== 0 ? Belt_Array.map(filtersState.concierges, (function (concierge) {
                            var id = "project-concierges-search-filter--concierge-" + ID.toString(concierge.id);
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: concierge.fullName,
                                                        className: DashboardProjectsIndexFiltersCss.ownerName
                                                      }),
                                                  JsxRuntime.jsx(Badge.User.Role.make, {
                                                        role: Role.fromString(concierge.role),
                                                        size: "XS"
                                                      })
                                                ],
                                                className: DashboardProjectsIndexFiltersCss.ownerNameContainer
                                              }),
                                          JsxRuntime.jsx("span", {
                                                children: concierge.email,
                                                className: DashboardProjectsIndexFiltersCss.ownerEmail
                                              })
                                        ],
                                        className: DashboardProjectsIndexFiltersCss.searchItem,
                                        onClick: (function (param) {
                                            enableReset();
                                            filtersDispatch({
                                                  TAG: "UpdateSelectedConcierges",
                                                  _0: concierge
                                                });
                                          })
                                      }, id);
                          })) : JsxRuntime.jsx("div", {
                          children: "No project owner found.",
                          className: DashboardProjectsIndexFiltersCss.searchItem
                        }, "no-owners")
                })
          ]
        });
  }
  var match$4 = filtersState.providersStatus;
  var tmp$2;
  var exit$2 = 0;
  if (typeof match$4 !== "object" && match$4 !== "FetchingProviders") {
    tmp$2 = null;
  } else {
    exit$2 = 1;
  }
  if (exit$2 === 1) {
    tmp$2 = JsxRuntime.jsxs(Popover.make, {
          className: DashboardProjectsIndexFiltersCss.searchContainer,
          children: [
            JsxRuntime.jsx(Popover.Trigger.make, {
                  children: JsxRuntime.jsx(SearchField.make, {
                        id: "providers-search",
                        value: filtersState.providersSearch,
                        placeholder: "Search Providers",
                        inputClassName: "",
                        onChange: (function ($$event) {
                            filtersDispatch({
                                  TAG: "UpdateProvidersSearchInput",
                                  _0: $$event.target.value
                                });
                          }),
                        showSearchIcon: false
                      })
                }),
            JsxRuntime.jsx(Popover.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardProjectsIndexFiltersCss.searchBody,
                  children: filtersState.providers.length !== 0 ? Belt_Array.map(filtersState.providers, (function (provider) {
                            var id = "project-providers-search-filter--provider-" + ID.toString(provider.id);
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("span", {
                                                    children: provider.name,
                                                    className: DashboardProjectsIndexFiltersCss.ownerName
                                                  }),
                                              className: DashboardProjectsIndexFiltersCss.ownerNameContainer
                                            }),
                                        className: DashboardProjectsIndexFiltersCss.searchItem,
                                        onClick: (function (param) {
                                            enableReset();
                                            filtersDispatch({
                                                  TAG: "UpdateSelectedProviders",
                                                  _0: provider
                                                });
                                          })
                                      }, id);
                          })) : JsxRuntime.jsx("div", {
                          children: "No providers found.",
                          className: DashboardProjectsIndexFiltersCss.searchItem
                        }, "no-providers")
                })
          ]
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(React$1.Transition, {
                      show: !filtersOpen,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Outline.AdjustmentsHorizontalIcon, {
                                    className: DashboardProjectsIndexFiltersCss.Icon.icon
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: String(filtersState.filtersCount),
                                    className: DashboardProjectsIndexFiltersCss.Icon.filtersCount
                                  }),
                              JsxRuntime.jsx(Solid$1.UserCircleIcon, {
                                    className: DashboardProjectsIndexFiltersCss.Icon.icon
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: String(filtersState.ownersCount),
                                    className: DashboardProjectsIndexFiltersCss.Icon.filtersCount
                                  }),
                              JsxRuntime.jsx(Solid$1.UsersIcon, {
                                    className: DashboardProjectsIndexFiltersCss.Icon.icon
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: String(filtersState.conciergesCount),
                                    className: DashboardProjectsIndexFiltersCss.Icon.filtersCount
                                  }),
                              JsxRuntime.jsx(Solid$1.BuildingOfficeIcon, {
                                    className: DashboardProjectsIndexFiltersCss.Icon.icon
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: String(filtersState.providersCount),
                                    className: DashboardProjectsIndexFiltersCss.Icon.filtersCount
                                  }),
                              JsxRuntime.jsx(Solid$1.IdentificationIcon, {
                                    className: DashboardProjectsIndexFiltersCss.Icon.icon
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: String(filtersState.userRolesCount),
                                    className: DashboardProjectsIndexFiltersCss.Icon.filtersCount
                                  })
                            ],
                            className: DashboardProjectsIndexFiltersCss.Icon.container,
                            onClick: onClick
                          })
                    }),
                JsxRuntime.jsx(React$1.Transition, {
                      show: filtersOpen,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("div", {
                                                    children: "Project Filters"
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx(Outline.AdjustmentsHorizontalIcon, {
                                                          className: DashboardProjectsIndexFiltersCss.Icon.icon
                                                        }),
                                                    onClick: onClick
                                                  })
                                            ],
                                            className: DashboardProjectsIndexFiltersCss.title
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: "Select from the filters below to narrow the list of projects.",
                                            className: DashboardProjectsIndexFiltersCss.description
                                          })
                                    ],
                                    className: DashboardProjectsIndexFiltersCss.header
                                  }),
                              JsxRuntime.jsxs(DashboardProjectsIndexFilterSection.make, {
                                    title: "Categories",
                                    isExpanded: expandState.categories,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleCategories");
                                      }),
                                    children: [
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "colocation",
                                            title: "Colocation",
                                            checked: filtersState.colocation,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleColocation");
                                                filtersDispatch({
                                                      TAG: "UpdateCategories",
                                                      _0: "colocation"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "bare-metal",
                                            title: "Bare Metal",
                                            checked: filtersState.bareMetal,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleBareMetal");
                                                filtersDispatch({
                                                      TAG: "UpdateCategories",
                                                      _0: "bare_metal"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "Cloud",
                                            title: "Cloud",
                                            checked: filtersState.cloud,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleCloud");
                                                filtersDispatch({
                                                      TAG: "UpdateCategories",
                                                      _0: "cloud"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "internet",
                                            title: "Internet",
                                            checked: filtersState.internet,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleInternet");
                                                filtersDispatch({
                                                      TAG: "UpdateCategories",
                                                      _0: "internet"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "network",
                                            title: "Network",
                                            checked: filtersState.network,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleNetwork");
                                                filtersDispatch({
                                                      TAG: "UpdateCategories",
                                                      _0: "network"
                                                    });
                                              })
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(DashboardProjectsIndexFilterSection.make, {
                                    title: "Statuses",
                                    isExpanded: expandState.statuses,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleStatuses");
                                      }),
                                    children: [
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "active",
                                            title: "Active",
                                            checked: filtersState.active,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleActive");
                                                filtersDispatch({
                                                      TAG: "UpdateStatuses",
                                                      _0: "active"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "in-review",
                                            title: "In Review",
                                            checked: filtersState.inReview,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleInReview");
                                                filtersDispatch({
                                                      TAG: "UpdateStatuses",
                                                      _0: "review"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "draft",
                                            title: "Draft",
                                            checked: filtersState.draft,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleDraft");
                                                filtersDispatch({
                                                      TAG: "UpdateStatuses",
                                                      _0: "draft"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "inactive",
                                            title: "Inactive",
                                            checked: filtersState.inactive,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleInactive");
                                                filtersDispatch({
                                                      TAG: "UpdateStatuses",
                                                      _0: "inactive"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "finalized",
                                            title: "Finalized",
                                            checked: filtersState.finalized,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleFinalized");
                                                filtersDispatch({
                                                      TAG: "UpdateStatuses",
                                                      _0: "finalized"
                                                    });
                                              })
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(DashboardProjectsIndexFilterSection.make, {
                                    title: "Owners",
                                    isExpanded: expandState.owners,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleOwners");
                                      }),
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: tmp
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: Belt_Array.mapWithIndex(filtersState.selectedOwners, (function (index, owner) {
                                                    return JsxRuntime.jsxs(Badge.Span.make, {
                                                                color: "Gray",
                                                                size: "XS",
                                                                className: DashboardProjectsIndexFiltersCss.selectedOwner,
                                                                onClick: (function (param) {
                                                                    filtersDispatch({
                                                                          TAG: "UpdateSelectedOwners",
                                                                          _0: owner
                                                                        });
                                                                  }),
                                                                children: [
                                                                  JsxRuntime.jsx("span", {
                                                                        children: owner.fullName
                                                                      }),
                                                                  JsxRuntime.jsx(Solid.XMarkIcon, {
                                                                        className: "size-4"
                                                                      })
                                                                ]
                                                              }, "selected-owner-" + ID.toString(owner.id) + "-" + String(index));
                                                  })),
                                            className: DashboardProjectsIndexFiltersCss.selectedOwners
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(DashboardProjectsIndexFilterSection.make, {
                                    title: "Concierges",
                                    isExpanded: expandState.concierges,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleConcierges");
                                      }),
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: tmp$1
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: Belt_Array.mapWithIndex(filtersState.selectedConcierges, (function (index, concierge) {
                                                    return JsxRuntime.jsxs(Badge.Span.make, {
                                                                color: "Gray",
                                                                size: "XS",
                                                                className: DashboardProjectsIndexFiltersCss.selectedOwner,
                                                                onClick: (function (param) {
                                                                    filtersDispatch({
                                                                          TAG: "UpdateSelectedConcierges",
                                                                          _0: concierge
                                                                        });
                                                                  }),
                                                                children: [
                                                                  JsxRuntime.jsx("span", {
                                                                        children: concierge.fullName
                                                                      }),
                                                                  JsxRuntime.jsx(Solid.XMarkIcon, {
                                                                        className: "size-4"
                                                                      })
                                                                ]
                                                              }, "selected-owner-" + ID.toString(concierge.id) + "-" + String(index));
                                                  })),
                                            className: DashboardProjectsIndexFiltersCss.selectedOwners
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(DashboardProjectsIndexFilterSection.make, {
                                    title: "Providers",
                                    isExpanded: expandState.providers,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleProviders");
                                      }),
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: tmp$2
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: Belt_Array.mapWithIndex(filtersState.selectedProviders, (function (index, provider) {
                                                    return JsxRuntime.jsxs(Badge.Span.make, {
                                                                color: "Gray",
                                                                size: "XS",
                                                                className: DashboardProjectsIndexFiltersCss.selectedOwner,
                                                                onClick: (function (param) {
                                                                    filtersDispatch({
                                                                          TAG: "UpdateSelectedProviders",
                                                                          _0: provider
                                                                        });
                                                                  }),
                                                                children: [
                                                                  JsxRuntime.jsx("span", {
                                                                        children: provider.name
                                                                      }),
                                                                  JsxRuntime.jsx(Solid.XMarkIcon, {
                                                                        className: "size-4"
                                                                      })
                                                                ]
                                                              }, "selected-provider-" + ID.toString(provider.id) + "-" + String(index));
                                                  })),
                                            className: DashboardProjectsIndexFiltersCss.selectedOwners
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(DashboardProjectsIndexFilterSection.make, {
                                    title: "User Roles",
                                    isExpanded: expandState.userRoles,
                                    onClick: (function (param) {
                                        expandDispatch("ToggleUserRoles");
                                      }),
                                    children: [
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "user-role-user",
                                            title: "User",
                                            checked: filtersState.user,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleUser");
                                                filtersDispatch({
                                                      TAG: "UpdateUserRoles",
                                                      _0: "user"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "user-role-provider",
                                            title: "Provider",
                                            checked: filtersState.provider,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleProvider");
                                                filtersDispatch({
                                                      TAG: "UpdateUserRoles",
                                                      _0: "provider"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "user-role-agent",
                                            title: "Agent",
                                            checked: filtersState.agent,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleAgent");
                                                filtersDispatch({
                                                      TAG: "UpdateUserRoles",
                                                      _0: "agent"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "user-role-concierge",
                                            title: "Concierge",
                                            checked: filtersState.concierge,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleConcierge");
                                                filtersDispatch({
                                                      TAG: "UpdateUserRoles",
                                                      _0: "concierge"
                                                    });
                                              })
                                          }),
                                      JsxRuntime.jsx(DashboardProjectsIndexFiltersCheckbox.make, {
                                            id: "user-role-admin",
                                            title: "Admin",
                                            checked: filtersState.admin,
                                            className: DashboardProjectsIndexFiltersCss.Checkbox.container,
                                            onChange: (function (param) {
                                                enableReset();
                                                filtersDispatch("ToggleAdmin");
                                                filtersDispatch({
                                                      TAG: "UpdateUserRoles",
                                                      _0: "admin"
                                                    });
                                              })
                                          })
                                    ]
                                  })
                            ],
                            className: DashboardProjectsIndexFiltersCss.container
                          })
                    })
              ]
            });
}

var Css;

var ProjectsCss;

var Filter;

var FilterCheckbox;

var make = DashboardProjectsIndexFilters;

export {
  Css ,
  ProjectsCss ,
  Filter ,
  FilterCheckbox ,
  Expand ,
  Filters ,
  make ,
}
/* ID Not a pure module */
