// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Table from "../../../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Api_Lead from "../../../../../../api/leads/Api_Lead.res.js";
import * as Dropdown from "../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../../../styleguide/icons/IconSorting.res.js";
import * as Routes_Lead from "../../../../../../routes/common/Routes_Lead.res.js";
import * as SearchField from "../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as TableHeaderCell from "../../../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as DashboardIndexCss from "../../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as DashboardExportModal from "../../../../../../styleguide/dashboard/components/modals/export-csv/DashboardExportModal.res.js";
import * as DashboardLeadsLeadTypes from "../../../index/components/DashboardLeadsLeadTypes.res.js";
import * as DashboardLeadsIndexDateRanges from "../../../index/components/DashboardLeadsIndexDateRanges.res.js";
import * as DashboardLeadShowRelatedLeadsOptions from "./components/DashboardLeadShowRelatedLeadsOptions.res.js";

var initialState_sortBy = {
  NAME: "CreatedAt",
  VAL: "Desc"
};

var initialState_leadTypes = [];

var initialState = {
  status: "FetchingLeads",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  csvModalOpen: false,
  leadTypes: initialState_leadTypes,
  startDate: undefined,
  endDate: undefined
};

function DashboardLeadShowRelatedLeads(props) {
  var lead = props.lead;
  var container = React.useRef(null);
  var searchLeads = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchLeads" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_Lead.Dashboard.Show.User.Lead.index(lead.id, state.startDate, state.endDate, state.leadTypes, state.currentPage, state.sortBy, tmp), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedLeadsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardLeadShowRelatedLeads",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "DashboardLeadShowRelatedLeads.make"
                                          }, "Dashboard::FetchLeads::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLeadsFetch");
                                    }));
                            })
                        };
              case "FailLeadsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedLeadsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen,
                            leadTypes: state.leadTypes,
                            startDate: state.startDate,
                            endDate: state.endDate
                          }
                        };
              case "ToggleCsvModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: !state.csvModalOpen,
                            leadTypes: state.leadTypes,
                            startDate: state.startDate,
                            endDate: state.endDate
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedLeadsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.leads
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen,
                            leadTypes: state.leadTypes,
                            startDate: state.startDate,
                            endDate: state.endDate
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingLeads") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLeads",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvModalOpen: state.csvModalOpen,
                              leadTypes: state.leadTypes,
                              startDate: state.startDate,
                              endDate: state.endDate
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLeads");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingLeads") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "CreatedAt") {
                    var match$2 = state.sortBy;
                    tmp = typeof match$2 === "object" && match$2.NAME === "CreatedAt" && match$2.VAL === "Desc" ? ({
                          NAME: "CreatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "CreatedAt",
                          VAL: "Desc"
                        });
                  } else if (sort === "LeadType") {
                    var match$3 = state.sortBy;
                    tmp = typeof match$3 === "object" && match$3.NAME === "LeadType" && match$3.VAL === "Desc" ? ({
                          NAME: "LeadType",
                          VAL: "Asc"
                        }) : ({
                          NAME: "LeadType",
                          VAL: "Desc"
                        });
                  } else if (sort === "Email") {
                    var match$4 = state.sortBy;
                    tmp = typeof match$4 === "object" && match$4.NAME === "Email" && match$4.VAL === "Desc" ? ({
                          NAME: "Email",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Email",
                          VAL: "Desc"
                        });
                  } else if (sort === "Name") {
                    var match$5 = state.sortBy;
                    tmp = typeof match$5 === "object" && match$5.NAME === "Name" && match$5.VAL === "Desc" ? ({
                          NAME: "Name",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Name",
                          VAL: "Desc"
                        });
                  } else {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "Company" && match$6.VAL === "Desc" ? ({
                          NAME: "Company",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Company",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLeads",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen,
                            leadTypes: state.leadTypes,
                            startDate: state.startDate,
                            endDate: state.endDate
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLeads");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            csvModalOpen: state.csvModalOpen,
                            leadTypes: state.leadTypes,
                            startDate: state.startDate,
                            endDate: state.endDate
                          },
                          _1: searchLeads
                        };
              case "PerformSearch" :
                  var match$7 = state.status;
                  if (typeof match$7 !== "object" && match$7 === "FetchingLeads") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLeads",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvModalOpen: state.csvModalOpen,
                              leadTypes: state.leadTypes,
                              startDate: state.startDate,
                              endDate: state.endDate
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLeads");
                              })
                          };
                  }
              case "UpdateLeadTypes" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen,
                            leadTypes: action._0,
                            startDate: state.startDate,
                            endDate: state.endDate
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLeads");
                            })
                        };
              case "UpdateStartDate" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen,
                            leadTypes: state.leadTypes,
                            startDate: action._0,
                            endDate: state.endDate
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLeads");
                            })
                        };
              case "UpdateEndDate" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen,
                            leadTypes: state.leadTypes,
                            startDate: state.startDate,
                            endDate: action._0
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLeads");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchLeads");
        }), []);
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var match$6 = state.sortBy;
  var leads = state.status;
  var tmp$1;
  if (typeof leads !== "object") {
    tmp$1 = leads === "FetchingLeads" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardTableCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var leads$1 = leads._0;
    tmp$1 = leads$1.length !== 0 ? Belt_Array.mapWithIndex(leads$1, (function (index, lead) {
              var url = lead.pipedriveLeadUrl;
              var tmp;
              tmp = url !== undefined && url !== "" ? JsxRuntime.jsx(Control.make, {
                      className: DashboardFilterCss.dropdownBodyRow,
                      children: JsxRuntime.jsx("a", {
                            children: "Pipedrive Lead",
                            className: DashboardFilterCss.dropdownBodyLink,
                            href: url,
                            target: "_blank"
                          })
                    }) : null;
              var url$1 = lead.pipedriveDealUrl;
              var tmp$1;
              tmp$1 = url$1 !== undefined && url$1 !== "" ? JsxRuntime.jsx(Control.make, {
                      className: DashboardFilterCss.dropdownBodyRow,
                      children: JsxRuntime.jsx("a", {
                            children: "Pipedrive Deal",
                            className: DashboardFilterCss.dropdownBodyLink,
                            href: url$1,
                            target: "_blank"
                          })
                    }) : null;
              var referrer = lead.referrer;
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: lead.name,
                                        className: DashboardTableCss.tableLink,
                                        href: Routes_Lead.Dashboard.show(lead.id)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: lead.email
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: lead.company
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: lead.leadType
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format(new Date(lead.createdAt), "MMM dd, yyyy hh:mm aaa")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardFilterCss.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardFilterCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "View",
                                                                    className: DashboardFilterCss.dropdownBodyLink,
                                                                    href: Routes_Lead.Dashboard.show(lead.id)
                                                                  })
                                                            }),
                                                        tmp,
                                                        tmp$1,
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardFilterCss.dropdownBodyRow,
                                                              disabled: true,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Sync Pipedrive",
                                                                    className: DashboardFilterCss.dropdownBodyLinkDisabled,
                                                                    disabled: true,
                                                                    href: "#"
                                                                  })
                                                            }),
                                                        referrer !== undefined ? JsxRuntime.jsx(Control.make, {
                                                                className: DashboardFilterCss.dropdownBodyRow,
                                                                children: JsxRuntime.jsx("a", {
                                                                      children: "Source Page",
                                                                      className: DashboardIndexCss.dropdownBodyLink,
                                                                      href: referrer,
                                                                      target: "_blank"
                                                                    })
                                                              }) : null
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No leads were found."
                })
          });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsxs(Container.make, {
                                className: DashboardIndexCss.layout,
                                setRef: Caml_option.some(container),
                                children: [
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(H1.make, {
                                                className: DashboardIndexCss.title,
                                                children: "Related Leads"
                                              }),
                                          JsxRuntime.jsx(DashboardLeadsIndexDateRanges.make, {
                                                updateStartDate: (function (startDate) {
                                                    return function (param) {
                                                      dispatch({
                                                            TAG: "UpdateStartDate",
                                                            _0: startDate
                                                          });
                                                    };
                                                  }),
                                                updateEndDate: (function (endDate) {
                                                    return function (param) {
                                                      dispatch({
                                                            TAG: "UpdateEndDate",
                                                            _0: endDate
                                                          });
                                                    };
                                                  })
                                              })
                                        ],
                                        className: DashboardIndexCss.header
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(SearchField.make, {
                                                id: "leads-search",
                                                value: state.search,
                                                placeholder: "Search by Name, Email, Company",
                                                inputClassName: DashboardFilterCss.searchField,
                                                onChange: (function ($$event) {
                                                    dispatch({
                                                          TAG: "UpdateSearchInput",
                                                          _0: $$event.target.value
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(DashboardLeadsLeadTypes.make, {
                                                        leadTypes: props.leadTypes,
                                                        updateLeadTypes: (function (leadTypes) {
                                                            return function (param) {
                                                              dispatch({
                                                                    TAG: "UpdateLeadTypes",
                                                                    _0: leadTypes
                                                                  });
                                                            };
                                                          })
                                                      }),
                                                  JsxRuntime.jsx(DashboardLeadShowRelatedLeadsOptions.make, {
                                                        leadId: lead.id,
                                                        leadTypes: state.leadTypes,
                                                        pageNum: state.currentPage,
                                                        query: tmp,
                                                        sortBy: state.sortBy,
                                                        startDate: state.startDate,
                                                        endDate: state.endDate,
                                                        toggleCsvModal: (function (param) {
                                                            dispatch("ToggleCsvModalOpen");
                                                          })
                                                      })
                                                ],
                                                className: DashboardIndexCss.options
                                              })
                                        ],
                                        className: DashboardIndexCss.subHeader
                                      }),
                                  JsxRuntime.jsx("section", {
                                        children: JsxRuntime.jsxs(Table.make, {
                                              textSize: "XS",
                                              className: DashboardTableCss.table,
                                              children: [
                                                JsxRuntime.jsx(TableHeader.make, {
                                                      children: JsxRuntime.jsxs(TableRow.make, {
                                                            children: [
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "Name"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Name",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$2 === "object" && match$2.NAME === "Name" ? match$2.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "Email"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Email",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$3 === "object" && match$3.NAME === "Email" ? match$3.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "Company"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Company",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$4 === "object" && match$4.NAME === "Company" ? match$4.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "LeadType"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Lead Type",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$5 === "object" && match$5.NAME === "LeadType" ? match$5.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: JsxRuntime.jsx(Control.make, {
                                                                          onClick: (function (param) {
                                                                              dispatch({
                                                                                    TAG: "SortBy",
                                                                                    _0: "CreatedAt"
                                                                                  });
                                                                            }),
                                                                          children: JsxRuntime.jsxs("span", {
                                                                                children: [
                                                                                  "Created",
                                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                                        size: "XXS",
                                                                                        direction: typeof match$6 === "object" && match$6.NAME === "CreatedAt" ? match$6.VAL : undefined
                                                                                      })
                                                                                ],
                                                                                className: DashboardTableCss.controlCell
                                                                              })
                                                                        })
                                                                  }),
                                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                                    children: ""
                                                                  })
                                                            ]
                                                          })
                                                    }),
                                                JsxRuntime.jsx(TableBody.make, {
                                                      children: tmp$1
                                                    })
                                              ]
                                            }),
                                        className: DashboardTableCss.tableWrapper
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Pagination.make, {
                                              currentPage: state.currentPage,
                                              totalPages: state.totalPages,
                                              onPageClick: (function (x) {
                                                  dispatch({
                                                        TAG: "UpdatePage",
                                                        _0: x
                                                      });
                                                })
                                            }),
                                        className: DashboardTableCss.pagination
                                      })
                                ]
                              }),
                          state.csvModalOpen ? JsxRuntime.jsx(DashboardExportModal.make, {
                                  title: "Leads CSV Export",
                                  message: "Please check your email for your leads export.",
                                  buttonText: "Close",
                                  onClick: (function (param) {
                                      dispatch("ToggleCsvModalOpen");
                                    })
                                }) : null
                        ],
                        className: DashboardIndexCss.pageContainer
                      }))
            });
}

var Css;

var TableCss;

var Filters;

var make = DashboardLeadShowRelatedLeads;

export {
  Css ,
  TableCss ,
  Filters ,
  initialState ,
  make ,
}
/* H1 Not a pure module */
