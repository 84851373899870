// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as User from "../../../models/User.res.js";
import * as RestBrowser from "../../RestBrowser.res.js";
import * as ActiveModelError from "../../../models/ActiveModelError.res.js";

function exec(userId, agencyId, active, isPrimary) {
  var formData = new FormData();
  formData.append("agency_id", agencyId);
  formData.append("active", active);
  formData.append("is_primary", isPrimary);
  return RestBrowser.$$fetch("/dashboard/users/" + ID.toString(userId) + "/agency_agents", formData, {
              NAME: "Json",
              VAL: User.Dashboard.Show.AgencyAgents.decoder
            }, {
              NAME: "Message",
              VAL: ActiveModelError.Errors.decoder
            }, "Post", undefined);
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  exec ,
}
/* ID Not a pure module */
