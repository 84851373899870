// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as TextField from "../TextField/TextField.res.js";
import * as IconSearch from "../../icons/IconSearch.res.js";
import * as SearchFieldCss from "./SearchFieldCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function SearchField(props) {
  var __showSearchIcon = props.showSearchIcon;
  var __iconClassName = props.iconClassName;
  var __inputClassName = props.inputClassName;
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var inputClassName = __inputClassName !== undefined ? __inputClassName : "";
  var iconClassName = __iconClassName !== undefined ? __iconClassName : "";
  var showSearchIcon = __showSearchIcon !== undefined ? __showSearchIcon : true;
  var inputClassName$1 = showSearchIcon ? Cx.cx([
          inputClassName,
          SearchFieldCss.input
        ]) : inputClassName;
  return JsxRuntime.jsxs("div", {
              children: [
                showSearchIcon ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(IconSearch.make, {
                              size: "MD",
                              color: "LightGray"
                            }),
                        className: Cx.cx([
                              SearchFieldCss.icon,
                              iconClassName
                            ])
                      }) : null,
                JsxRuntime.jsx(TextField.make, {
                      id: props.id,
                      value: props.value,
                      placeholder: props.placeholder,
                      status: props.status,
                      autoFocus: props.autoFocus,
                      className: inputClassName$1,
                      onChange: props.onChange
                    })
              ],
              className: Cx.cx([
                    SearchFieldCss.wrapper,
                    className
                  ])
            });
}

var Css;

var make = SearchField;

export {
  Css ,
  make ,
}
/* TextField Not a pure module */
