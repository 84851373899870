// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../../../../styleguide/icons/IconSelect.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardDropdownOptionsCss from "../../../../../../styleguide/dashboard/components/options/DashboardDropdownOptionsCss.res.js";
import * as FetchTsdAgenciesDashboardRequest from "../../../../../../api/tsds/requests/FetchTsdAgenciesDashboardRequest.res.js";

function DashboardTsdShowAgencyAgentsOptions(props) {
  var sortBy = props.sortBy;
  var page = "page=" + String(props.pageNum);
  var query = Belt_Option.getWithDefault(Belt_Option.map(props.query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "Email" ? "&sort_by=users.email&sort_direction=" + FetchTsdAgenciesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
      variant === "AgencyName" ? "&sort_by=agencies.name&sort_direction=" + FetchTsdAgenciesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
          variant === "FirstName" ? "&sort_by=users.first_name&sort_direction=" + FetchTsdAgenciesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
              variant === "IsPrimary" ? "&sort_by=agency_agents.is_primary&sort_direction=" + FetchTsdAgenciesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
                  variant === "LastName" ? "&sort_by=users.last_name&sort_direction=" + FetchTsdAgenciesDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : "&sort_by=agency_agents.active&sort_direction=" + FetchTsdAgenciesDashboardRequest.Req.SortDirection.toString(sortBy.VAL)
                )
            )
        )
    );
  var url = "/api/v1/dashboard/tsds/" + ID.toString(props.tsdId) + "/agency_agents/export.csv?" + page + query + sortBy$1;
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsx(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: DashboardDropdownOptionsCss.popoverBody,
                      children: JsxRuntime.jsxs(A.make, {
                            href: url,
                            className: DashboardDropdownOptionsCss.popoverBodyLink,
                            onClick: props.onClick,
                            children: [
                              "Download CSV",
                              JsxRuntime.jsx(IconSelect.make, {
                                    size: "MD",
                                    color: "LightestGray",
                                    className: DashboardDropdownOptionsCss.popoverBodyLinkIcon
                                  })
                            ]
                          })
                    })
              ]
            });
}

var Css;

var make = DashboardTsdShowAgencyAgentsOptions;

export {
  Css ,
  make ,
}
/* A Not a pure module */
