// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Badge from "../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Table from "../../../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Api_Tsd from "../../../../../../api/tsds/Api_Tsd.res.js";
import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../../../styleguide/components/Table/TableRow.res.js";
import * as TableBody from "../../../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Agency from "../../../../../../routes/common/Routes_Agency.res.js";
import * as TableHeaderCell from "../../../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardTabsCss from "../../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as DashboardIndexCss from "../../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTsdShowAgenciesOptions from "./DashboardTsdShowAgenciesOptions.res.js";

var initialState = {
  status: "FetchingAgencies",
  currentPage: 1,
  totalPages: 1,
  sortBy: {
    NAME: "Name",
    VAL: "Asc"
  },
  search: ""
};

function DashboardTsdShowAgencies(props) {
  var tsd = props.tsd;
  var container = React.useRef(null);
  var searchAgencies = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchAgencies") {
              return {
                      TAG: "SideEffects",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          var state = param.state;
                          var match = state.search;
                          var tmp = match === "" ? undefined : state.search;
                          $$Promise.wait(Api_Tsd.Dashboard.Show.Agency.index(tsd.id, state.currentPage, state.sortBy, tmp), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "SucceedAgenciesFetch",
                                                _0: x._0
                                              });
                                  }
                                  SentryLogger.error1({
                                        rootModule: "DashboardTsdShowAgencies",
                                        subModulePath: /* [] */0,
                                        value: "make",
                                        fullPath: "DashboardTsdShowAgencies.make"
                                      }, "Dashboard::FetchAgencies::Error", [
                                        "Error",
                                        x._0
                                      ]);
                                  dispatch("FailAgenciesFetch");
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        status: "FailedAgenciesFetch",
                        currentPage: state.currentPage,
                        totalPages: state.totalPages,
                        sortBy: state.sortBy,
                        search: state.search
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "SucceedAgenciesFetch" :
                var res = action._0;
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: {
                            TAG: "Ready",
                            _0: res.agencies
                          },
                          currentPage: res.currentPage,
                          totalPages: res.totalPages,
                          sortBy: state.sortBy,
                          search: state.search
                        },
                        _1: (function (param) {
                            Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                    return prim.scrollTop;
                                  }));
                          })
                      };
            case "UpdatePage" :
                var nextPage = action._0;
                var match = state.status;
                if (typeof match !== "object" && match === "FetchingAgencies") {
                  return "NoUpdate";
                }
                if (state.currentPage !== nextPage) {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingAgencies",
                            currentPage: nextPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search
                          },
                          _1: (function (param) {
                              param.dispatch("FetchAgencies");
                            })
                        };
                } else {
                  return "NoUpdate";
                }
            case "SortBy" :
                var sort = action._0;
                var match$1 = state.status;
                if (typeof match$1 !== "object" && match$1 === "FetchingAgencies") {
                  return "NoUpdate";
                }
                var tmp;
                if (sort === "AgentsCount") {
                  var match$2 = state.sortBy;
                  tmp = typeof match$2 === "object" && match$2.NAME === "AgentsCount" && match$2.VAL === "Desc" ? ({
                        NAME: "AgentsCount",
                        VAL: "Asc"
                      }) : ({
                        NAME: "AgentsCount",
                        VAL: "Desc"
                      });
                } else if (sort === "Name") {
                  var match$3 = state.sortBy;
                  tmp = typeof match$3 === "object" && match$3.NAME === "Name" && match$3.VAL === "Desc" ? ({
                        NAME: "Name",
                        VAL: "Asc"
                      }) : ({
                        NAME: "Name",
                        VAL: "Desc"
                      });
                } else {
                  var match$4 = state.sortBy;
                  tmp = typeof match$4 === "object" && match$4.NAME === "Active" && match$4.VAL === "Desc" ? ({
                        NAME: "Active",
                        VAL: "Asc"
                      }) : ({
                        NAME: "Active",
                        VAL: "Desc"
                      });
                }
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: "FetchingAgencies",
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: tmp,
                          search: state.search
                        },
                        _1: (function (param) {
                            param.dispatch("FetchAgencies");
                          })
                      };
            case "UpdateSearchInput" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: state.status,
                          currentPage: state.currentPage,
                          totalPages: state.totalPages,
                          sortBy: state.sortBy,
                          search: action._0
                        },
                        _1: searchAgencies
                      };
            case "PerformSearch" :
                var match$5 = state.status;
                if (typeof match$5 !== "object" && match$5 === "FetchingAgencies") {
                  return "NoUpdate";
                }
                if (action._0 !== state.search) {
                  return "NoUpdate";
                } else {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingAgencies",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search
                          },
                          _1: (function (param) {
                              param.dispatch("FetchAgencies");
                            })
                        };
                }
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchAgencies");
        }), []);
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var agencies = state.status;
  var tmp$1;
  if (typeof agencies !== "object") {
    tmp$1 = agencies === "FetchingAgencies" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardIndexCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var agencies$1 = agencies._0;
    tmp$1 = agencies$1.length !== 0 ? Belt_Array.mapWithIndex(agencies$1, (function (index, agency) {
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: agency.name,
                                        className: DashboardIndexCss.tableLink,
                                        href: Routes_Agency.Dashboard.edit(agency.id)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: agency.active ? JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Green",
                                          children: "Active"
                                        }) : JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Gray",
                                          children: "Inactive"
                                        })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(agency.agencyAgentsCount)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardIndexCss.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Show",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: Routes_Agency.Dashboard.show(agency.id)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: Routes_Agency.Dashboard.edit(agency.id)
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No agencies were found."
                })
          });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(SearchField.make, {
                              id: "agencies-search",
                              value: state.search,
                              placeholder: "Search by Agency Name",
                              inputClassName: DashboardIndexCss.searchField,
                              onChange: (function ($$event) {
                                  dispatch({
                                        TAG: "UpdateSearchInput",
                                        _0: $$event.target.value
                                      });
                                })
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(DashboardTsdShowAgenciesOptions.make, {
                                    pageNum: state.currentPage,
                                    query: tmp,
                                    sortBy: state.sortBy
                                  })
                            })
                      ],
                      className: DashboardTabsCss.Tab.searchContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(Table.make, {
                              children: [
                                JsxRuntime.jsx(TableHeader.make, {
                                      children: JsxRuntime.jsxs(TableRow.make, {
                                            children: [
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Name"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Agency Name",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$2 === "object" && match$2.NAME === "Name" ? match$2.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Active"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Active?",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$3 === "object" && match$3.NAME === "Active" ? match$3.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "AgentsCount"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Total Agents",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$4 === "object" && match$4.NAME === "AgentsCount" ? match$4.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: ""
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(TableBody.make, {
                                      children: tmp$1
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: DashboardIndexCss.pagination
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    })
              ],
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var CssIndex;

var make = DashboardTsdShowAgencies;

export {
  Css ,
  CssIndex ,
  initialState ,
  make ,
}
/* Badge Not a pure module */
