// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "flex",
      "flex-col",
      "justify-start",
      "items-start",
      "gap-4",
      "w-full"
    ]);

var gridSection = Cx.cx([
      "grid",
      "lg:grid-cols-4",
      "sm:grid-cols-3",
      "grid-cols-1",
      "gap-x-10",
      "gap-y-2.5",
      "flex-wrap",
      "md:max-h-[580px]",
      "max-h-none",
      "mt-2.5",
      "w-full"
    ]);

var link = Cx.cx([
      "text-left",
      "underline",
      "hover:text-purple"
    ]);

var serviceSection = Cx.cx(["w-full"]);

export {
  wrapper ,
  gridSection ,
  link ,
  serviceSection ,
}
/* wrapper Not a pure module */
