// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var box = Cx.cx([
      "flex",
      "flex-col",
      "gap-2.5",
      "bg-blue-950",
      "items-center",
      "p-7",
      "text-white",
      "rounded-lg",
      "flex-[1_0_24%]",
      "lg:flex-[1_0_20%]",
      "max-md:min-w-[280px]"
    ]);

var icon = Cx.cx([
      "w-15",
      "h-15"
    ]);

var title = Cx.cx([
      "text-base",
      "font-medium"
    ]);

var message = Cx.cx([
      "text-sm",
      "text-gray-300"
    ]);

export {
  box ,
  icon ,
  title ,
  message ,
}
/* box Not a pure module */
