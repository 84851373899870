// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var jumbotron = Cx.cx([
      "relative",
      "bg-jumbotron-gradient",
      "lg:px-32",
      "md:px-20",
      "px-10"
    ]);

var backgroundImage = Cx.cx(["bg-no-repeat"]);

var wrapper = Cx.cx([
      "relative",
      "text-center",
      "flex",
      "flex-col",
      "items-center",
      "py-20"
    ]);

var heading = Cx.cx([
      "text-white!",
      "mb-12",
      "sm:px-0"
    ]);

export {
  jumbotron ,
  backgroundImage ,
  wrapper ,
  heading ,
}
/* jumbotron Not a pure module */
