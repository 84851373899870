// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../../../../styleguide/components/Heading/H4.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Checkbox from "../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditContainersCss from "../../common/ProviderEditContainersCss.res.js";
import * as Api_DirectoryProviderService from "../../../../../../api/directory-provider-services/Api_DirectoryProviderService.res.js";

function ProviderEditServices(props) {
  var updateProvider = props.updateProvider;
  var live = props.live;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(H3.make, {
                            children: "Select Directory Services"
                          }),
                      Belt_Array.mapWithIndex(props.provider.directories, (function (index, directory) {
                              var totalOptions = directory.services.length;
                              var numOfRows = totalOptions / 3 | 0;
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(H4.make, {
                                                  className: ProviderEditContainersCss.directoryTitle,
                                                  children: directory.title
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: Belt_Array.mapWithIndex(Belt_Array.range(0, numOfRows), (function (index, param) {
                                                          return JsxRuntime.jsx("div", {
                                                                      children: Belt_Array.map(Belt_Array.slice(directory.services, Math.imul(index, 3), 3), (function (service) {
                                                                              var match = React.useState(function () {
                                                                                    return service.requested;
                                                                                  });
                                                                              var setRequested = match[1];
                                                                              var requested = match[0];
                                                                              return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                                          id: "directory-service--" + ID.toString(service.id),
                                                                                          size: "SM",
                                                                                          checked: live === "Live" ? service.live : requested,
                                                                                          disabled: live === "Live",
                                                                                          containerClassName: ProviderEditContainersCss.directoryServiceOption,
                                                                                          onChange: (function (param) {
                                                                                              setRequested(function (_prev) {
                                                                                                    return !requested;
                                                                                                  });
                                                                                              $$Promise.wait(Api_DirectoryProviderService.update(service.directoryProviderServiceId, {
                                                                                                        approved: false,
                                                                                                        live: service.live,
                                                                                                        requested: !requested
                                                                                                      }), (function (x) {
                                                                                                      if (x.TAG === "Ok") {
                                                                                                        return updateProvider(x._0);
                                                                                                      } else {
                                                                                                        return SentryLogger.error1({
                                                                                                                    rootModule: "ProviderEditServices",
                                                                                                                    subModulePath: /* [] */0,
                                                                                                                    value: "make",
                                                                                                                    fullPath: "ProviderEditServices.make"
                                                                                                                  }, "ProviderEditServices::Update::Error", [
                                                                                                                    "Error",
                                                                                                                    x._0
                                                                                                                  ]);
                                                                                                      }
                                                                                                    }));
                                                                                            }),
                                                                                          children: JsxRuntime.jsx("div", {
                                                                                                children: service.title,
                                                                                                className: service.live === requested ? "" : ProviderEditContainersCss.serviceChanged
                                                                                              })
                                                                                        }, "directory-service--" + String(index) + "--" + service.title);
                                                                            })),
                                                                      className: ProviderEditContainersCss.servicesRow
                                                                    }, "services-row" + String(index));
                                                        }))
                                                })
                                          ],
                                          className: ProviderEditContainersCss.settings
                                        }, "directory--" + String(index));
                            }))
                    ],
                    className: ProviderEditContainersCss.generalContainer
                  }),
              className: ProviderEditContainersCss.container
            });
}

var Css;

var make = ProviderEditServices;

export {
  Css ,
  make ,
}
/* H3 Not a pure module */
