// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as User from "../../models/User.res.js";
import * as SignInData from "../../bundles/client/sign-in/SignInData.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              afterSignInPath: field.required("afterSignInPath", Json_Decode$JsonCombinators.string),
              user: field.required("user", User.decoder)
            };
    });

var UserWithRedirect = {
  decoder: decoder
};

var toJson = SignInData.Input.toJson;

var Req = {
  toJson: toJson
};

var decoder$1 = Json_Decode$JsonCombinators.oneOf([
      Json_Decode$JsonCombinators.map(decoder, (function (data) {
              return {
                      TAG: "UserObjectWithRedirect",
                      _0: data
                    };
            })),
      Json_Decode$JsonCombinators.map(User.decoder, (function (user) {
              return {
                      TAG: "UserObject",
                      _0: user
                    };
            }))
    ]);

var Res = {
  decoder: decoder$1
};

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              message: field.required("message", Json_Decode$JsonCombinators.string),
              errorCode: field.optional("errorCode", Json_Decode$JsonCombinators.string)
            };
    });

function fromJs(js) {
  return {
          message: js.message,
          errorCode: Caml_option.nullable_to_opt(js.errorCode)
        };
}

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Err = {
  decoder: decoder$2,
  fromJs: fromJs,
  fromJson: fromJson
};

function exec(input) {
  return Rest.$$fetch("/users/sign_in", {
              NAME: "Post",
              VAL: toJson(input)
            }, {
              NAME: "Json",
              VAL: decoder$1
            }, {
              NAME: "Message",
              VAL: decoder$2
            }, undefined);
}

export {
  UserWithRedirect ,
  Req ,
  Res ,
  Err ,
  exec ,
}
/* decoder Not a pure module */
