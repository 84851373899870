// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as DashboardShowCss from "../../../../styleguide/dashboard/components/views/show/DashboardShowCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UsersDashboardNavbar from "../common/navbar/UsersDashboardNavbar.res.js";
import * as UserDashboardEditForm from "./form/UserDashboardEditForm.res.js";

function UserDashboardEdit$default(props) {
  var user = props.user;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(UsersDashboardNavbar.make, {
                      selectedCategory: "User",
                      id: Caml_option.some(user.id)
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsxs("h1", {
                                                  children: [
                                                    JsxRuntime.jsx("span", {
                                                          children: "Edit User",
                                                          className: DashboardShowCss.titleSpan
                                                        }),
                                                    user.firstName + " " + user.lastName
                                                  ],
                                                  className: DashboardShowCss.title
                                                }),
                                            className: DashboardShowCss.heading
                                          }),
                                      JsxRuntime.jsx("a", {
                                            children: "View Profile",
                                            className: DashboardShowCss.editLink,
                                            href: Routes_User.Dashboard.show(user.id)
                                          })
                                    ],
                                    className: DashboardShowCss.header
                                  }),
                              JsxRuntime.jsx(UserDashboardEditForm.make, {
                                    user: user
                                  })
                            ],
                            className: DashboardCss.bodyContainer
                          })
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var $$default = UserDashboardEdit$default;

export {
  Css ,
  $$default as default,
}
/* Container Not a pure module */
