// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardModalContainerCss from "./DashboardModalContainerCss.res.js";

function DashboardModalContainer(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx("div", {
                                      children: props.children,
                                      className: DashboardModalContainerCss.dialogPanel
                                    }),
                                className: DashboardModalContainerCss.innerContainer
                              }),
                          className: DashboardModalContainerCss.container
                        }),
                    className: DashboardModalContainerCss.dialogBackdrop
                  }),
              className: DashboardModalContainerCss.dialog
            });
}

var Css;

var make = DashboardModalContainer;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
