// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../../../../styleguide/icons/IconSelect.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardDropdownOptionsCss from "../../../../../../styleguide/dashboard/components/options/DashboardDropdownOptionsCss.res.js";
import * as FetchLocationsDashboardRequest from "../../../../../../api/locations/requests/FetchLocationsDashboardRequest.res.js";

function ProviderEditLocationsOptions(props) {
  var sortBy = props.sortBy;
  var page = "page=" + String(props.pageNum);
  var query = Belt_Option.getWithDefault(Belt_Option.map(props.query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "State" ? "&sort_by=state&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
      variant === "City" ? "&sort_by=city&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
          variant === "Name" ? "&sort_by=name&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : "&sort_by=country&sort_direction=" + FetchLocationsDashboardRequest.Req.SortDirection.toString(sortBy.VAL)
        )
    );
  var url = "/api/v1/dashboard/providers/" + ID.toString(props.providerId) + "/locations.csv?" + (page + (query + sortBy$1));
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsxs(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: DashboardDropdownOptionsCss.popoverBody,
                      children: [
                        JsxRuntime.jsxs("a", {
                              children: [
                                "New Location",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: DashboardDropdownOptionsCss.popoverBodyLinkIcon
                                    })
                              ],
                              className: DashboardDropdownOptionsCss.popoverBodyLink,
                              href: "#",
                              onClick: props.openNewLocation
                            }),
                        JsxRuntime.jsxs(A.make, {
                              href: url,
                              className: DashboardDropdownOptionsCss.popoverBodyLink,
                              children: [
                                "Download CSV",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: DashboardDropdownOptionsCss.popoverBodyLinkIcon
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var Css;

var make = ProviderEditLocationsOptions;

export {
  Css ,
  make ,
}
/* A Not a pure module */
