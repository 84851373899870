// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var buttons = Cx.cx([
      "flex",
      "justify-center",
      "gap-4",
      "md:flex-row",
      "flex-col"
    ]);

var divider = Cx.cx([
      "w-full",
      "border-t",
      "border-blue-300"
    ]);

var providers = Cx.cx([
      "flex",
      "flex-col",
      "gap-5"
    ]);

var providersTitle = Cx.cx([
      "text-gray-200",
      "uppercase"
    ]);

var providerBoxes = Cx.cx([
      "flex",
      "flex-wrap",
      "justify-center",
      "gap-5",
      "md:flex-row",
      "flex-col"
    ]);

export {
  buttons ,
  divider ,
  providers ,
  providersTitle ,
  providerBoxes ,
}
/* buttons Not a pure module */
