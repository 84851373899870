// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as ProviderTsd from "../../../models/ProviderTsd.res.js";

function exec(pageNum, sortBy, query) {
  var page = ProviderTsd.Dashboard.Index.page(pageNum);
  var query$1 = ProviderTsd.Dashboard.Index.query(query);
  var sortBy$1 = ProviderTsd.Dashboard.Index.sortBy(sortBy);
  return Rest.$$fetch("/dashboard/provider_tsds?" + page + query$1 + sortBy$1, "Get", {
              NAME: "Json",
              VAL: ProviderTsd.Dashboard.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
