// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Provider from "../../../../../models/Provider.res.js";
import * as Api_Provider from "../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditActiveButtonCss from "./ProviderEditActiveButtonCss.res.js";

function reducer(_state, action) {
  var provider = action._0;
  return {
          active: Provider.Active.fromBool(provider.active),
          provider: provider
        };
}

function ProviderEditActiveButton(props) {
  var provider = props.provider;
  var initialState_active = props.active;
  var initialState = {
    active: initialState_active,
    provider: provider
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.active;
  var tmp;
  tmp = match$1 === "Active" ? true : false;
  var tmp$1;
  tmp$1 = props.userRole === "Admin" ? false : true;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: Provider.Active.toString(state.active),
                            className: ProviderEditActiveButtonCss.inactiveText
                          }),
                      JsxRuntime.jsx(Toggle.make, {
                            on: tmp,
                            size: "SM",
                            disabled: tmp$1,
                            onChange: (function () {
                                var match = state.active;
                                var tmp;
                                tmp = match === "Active" ? "Inactive" : "Active";
                                $$Promise.wait(Api_Provider.updateActive(provider.id, tmp), (function (x) {
                                        if (x.TAG === "Ok") {
                                          return dispatch({
                                                      TAG: "UpdateProvider",
                                                      _0: x._0.provider
                                                    });
                                        } else {
                                          return SentryLogger.error1({
                                                      rootModule: "ProviderEditActiveButton",
                                                      subModulePath: /* [] */0,
                                                      value: "make",
                                                      fullPath: "ProviderEditActiveButton.make"
                                                    }, "UpdateActive::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                        }
                                      }));
                              })
                          })
                    ],
                    className: ProviderEditActiveButtonCss.toggleContainer
                  }),
              className: ProviderEditActiveButtonCss.actionButtonsContainer
            });
}

var Css;

var make = ProviderEditActiveButton;

export {
  Css ,
  reducer ,
  make ,
}
/* react Not a pure module */
