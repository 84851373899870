// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";

function exec(providerId, query) {
  var query$1 = Provider.Edit.ProviderTsd.Tsd.Filter.query(query);
  return Rest.$$fetch("/dashboard/providers/" + providerId + "/tsds?" + query$1, "Get", {
              NAME: "Json",
              VAL: Provider.Edit.ProviderTsd.Tsd.Filter.Tsds.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
