// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Role from "./Role.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as ZenlayerAccountStatus from "./ZenlayerAccountStatus.res.js";
import * as ControlPlaneAccountStatus from "./ControlPlaneAccountStatus.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          firstName: js.firstName,
          lastName: js.lastName,
          role: Role.fromString(js.role),
          email: js.email,
          phoneNumber: js.phone,
          approved: js.approved,
          description: Caml_option.nullable_to_opt(js.description),
          dialCode: js.dialCode,
          dialCodeCountry: js.dialCodeCountry,
          jobTitle: js.jobTitle,
          newsletterSubscription: js.newsletterSubscription,
          profilePic: js.profilePic,
          providerNameRequested: js.providerNameRequested,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt,
          agency: js.agency,
          zenlayerAccountStatus: ZenlayerAccountStatus.fromString(js.zenlayerAccountStatus),
          controlPlaneAccountStatus: ControlPlaneAccountStatus.fromString(js.controlPlaneAccountStatus)
        };
}

function toJs(user) {
  return {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          role: Role.toString(user.role),
          email: user.email,
          phone: user.phoneNumber,
          approved: user.approved,
          description: Js_null_undefined.fromOption(user.description),
          dialCode: user.dialCode,
          dialCodeCountry: user.dialCodeCountry,
          jobTitle: user.jobTitle,
          newsletterSubscription: user.newsletterSubscription,
          profilePic: user.profilePic,
          providerNameRequested: user.providerNameRequested,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
          agency: user.agency,
          zenlayerAccountStatus: ZenlayerAccountStatus.toString(user.zenlayerAccountStatus),
          controlPlaneAccountStatus: ControlPlaneAccountStatus.toString(user.controlPlaneAccountStatus)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              role: field.required("role", Role.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              phoneNumber: field.required("phone", Json_Decode$JsonCombinators.string),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              dialCode: field.required("dialCode", Json_Decode$JsonCombinators.string),
              dialCodeCountry: field.required("dialCodeCountry", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.string),
              newsletterSubscription: field.required("newsletterSubscription", Json_Decode$JsonCombinators.bool),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.string),
              providerNameRequested: field.required("providerNameRequested", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string),
              agency: field.required("agency", Json_Decode$JsonCombinators.string),
              zenlayerAccountStatus: field.required("zenlayerAccountStatus", ZenlayerAccountStatus.decoder),
              controlPlaneAccountStatus: field.required("controlPlaneAccountStatus", ControlPlaneAccountStatus.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromBool(loginStatus) {
  if (loginStatus) {
    return "LoggedIn";
  } else {
    return "LoggedOut";
  }
}

var decoder$1 = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.bool, fromBool);

function fromJson$1(json) {
  return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(json, decoder$1));
}

var LoginStatus = {
  fromBool: fromBool,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function toJson(approved) {
  if (approved === "Approved") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Approved") {
    return "Approved";
  } else {
    return "Unapproved";
  }
}

function fromJs$1(approved) {
  if (approved) {
    return "Approved";
  } else {
    return "Unapproved";
  }
}

function fromBool$1(approved) {
  if (approved) {
    return "Approved";
  } else {
    return "Unapproved";
  }
}

var Approved = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs$1,
  fromBool: fromBool$1
};

function toJson$1(enabled) {
  if (enabled === "Enabled") {
    return true;
  } else {
    return false;
  }
}

function toString$1(value) {
  if (value === "Enabled") {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

function fromJs$2(enabled) {
  if (enabled) {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

function fromBool$2(enabled) {
  if (enabled) {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

var ApiEnabled = {
  toJson: toJson$1,
  toString: toString$1,
  fromJs: fromJs$2,
  fromBool: fromBool$2
};

function toJson$2(enabled) {
  if (enabled === "Enabled") {
    return true;
  } else {
    return false;
  }
}

function toString$2(value) {
  if (value === "Enabled") {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

function fromJs$3(enabled) {
  if (enabled) {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

function fromBool$3(enabled) {
  if (enabled) {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

var DailyLeads = {
  toJson: toJson$2,
  toString: toString$2,
  fromJs: fromJs$3,
  fromBool: fromBool$3
};

function toJson$3(enabled) {
  if (enabled === "Enabled") {
    return true;
  } else {
    return false;
  }
}

function toString$3(value) {
  if (value === "Enabled") {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

function fromJs$4(enabled) {
  if (enabled) {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

function fromBool$4(enabled) {
  if (enabled) {
    return "Enabled";
  } else {
    return "Disabled";
  }
}

var SignupDistribution = {
  toJson: toJson$3,
  toString: toString$3,
  fromJs: fromJs$4,
  fromBool: fromBool$4
};

function fromJs$5(js) {
  return {
          id: js.id,
          firstName: js.firstName,
          lastName: js.lastName,
          email: js.email,
          role: js.role,
          approved: js.approved,
          apiEnabled: js.apiEnabled,
          signupDistribution: js.signupDistribution,
          dailyLeads: js.dailyLeads,
          createdAt: js.createdAt
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              role: field.required("role", Json_Decode$JsonCombinators.string),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool),
              apiEnabled: field.required("apiEnabled", Json_Decode$JsonCombinators.bool),
              signupDistribution: field.required("signupDistribution", Json_Decode$JsonCombinators.bool),
              dailyLeads: field.required("dailyLeads", Json_Decode$JsonCombinators.bool),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

function fromJs$6(js) {
  return {
          id: js.id,
          email: js.email,
          role: js.role,
          dialCode: js.dialCode,
          dialCodeCountry: js.dialCodeCountry,
          phone: js.phone,
          firstName: js.firstName,
          lastName: js.lastName,
          description: js.description,
          company: js.company,
          jobTitle: js.jobTitle,
          apiEnabled: js.apiEnabled,
          approved: js.approved,
          superAdmin: js.superAdmin,
          editUsers: js.editUsers,
          profilePic: js.profilePic,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              role: field.required("role", Json_Decode$JsonCombinators.string),
              dialCode: field.required("dialCode", Json_Decode$JsonCombinators.string),
              dialCodeCountry: field.required("dialCodeCountry", Json_Decode$JsonCombinators.string),
              phone: field.required("phone", Json_Decode$JsonCombinators.string),
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              company: field.required("company", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.string),
              apiEnabled: field.required("apiEnabled", Json_Decode$JsonCombinators.bool),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool),
              superAdmin: field.required("superAdmin", Json_Decode$JsonCombinators.bool),
              editUsers: field.required("editUsers", Json_Decode$JsonCombinators.bool),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

function fromJs$7(js) {
  return {
          id: js.id,
          userId: js.userId,
          agencyId: js.agencyId,
          agencyName: js.agencyName,
          tsdId: js.tsdId,
          tsdName: js.tsdName,
          active: js.active,
          isPrimary: js.isPrimary
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              userId: field.required("userId", ID.decoder),
              agencyId: field.required("agencyId", ID.decoder),
              agencyName: field.required("agencyName", Json_Decode$JsonCombinators.string),
              tsdId: field.required("tsdId", ID.decoder),
              tsdName: field.required("tsdName", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              isPrimary: field.required("isPrimary", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var AgencyAgent = {
  fromJs: fromJs$7,
  decoder: decoder$4,
  fromJson: fromJson$4
};

function fromJs$8(js) {
  return {
          userId: js.userId,
          agencyAgents: Belt_Array.map(js.agencyAgents, fromJs$7),
          totalAgencyAgents: js.totalAgencyAgents,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              userId: field.required("userId", ID.decoder),
              agencyAgents: field.required("agencyAgents", Json_Decode$JsonCombinators.array(decoder$4)),
              totalAgencyAgents: field.required("totalAgencyAgents", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

function toString$4(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString$4
};

var SortBy = {};

function page(pageNum) {
  return "page=" + String(pageNum);
}

function query(query$1) {
  return Belt_Option.getWithDefault(Belt_Option.map(query$1, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function sortBy(sortBy$1) {
  var variant = sortBy$1.NAME;
  if (variant === "TsdName") {
    return "&sort_by=tsds.name&sort_direction=" + toString$4(sortBy$1.VAL);
  } else if (variant === "AgencyName") {
    return "&sort_by=agencies.name&sort_direction=" + toString$4(sortBy$1.VAL);
  } else if (variant === "IsPrimary") {
    return "&sort_by=agency_agents.is_primary&sort_direction=" + toString$4(sortBy$1.VAL);
  } else {
    return "&sort_by=agency_agents.active&sort_direction=" + toString$4(sortBy$1.VAL);
  }
}

var AgencyAgents = {
  fromJs: fromJs$8,
  decoder: decoder$5,
  fromJson: fromJson$5,
  SortDirection: SortDirection,
  SortBy: SortBy,
  page: page,
  query: query,
  sortBy: sortBy
};

var Show = {
  fromJs: fromJs$6,
  decoder: decoder$3,
  fromJson: fromJson$3,
  AgencyAgent: AgencyAgent,
  AgencyAgents: AgencyAgents
};

function fromJs$9(js) {
  return {
          id: js.id,
          firstName: js.firstName,
          lastName: js.lastName,
          email: js.email,
          role: js.role,
          approved: js.approved,
          apiEnabled: js.apiEnabled,
          signupDistribution: js.signupDistribution,
          dailyLeads: js.dailyLeads,
          phone: js.phone,
          description: js.description,
          dialCode: js.dialCode,
          dialCodeCountry: js.dialCodeCountry,
          jobTitle: js.jobTitle,
          profilePic: js.profilePic,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          agency: js.agency,
          providerNameRequested: js.providerNameRequested
        };
}

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              role: field.required("role", Json_Decode$JsonCombinators.string),
              approved: field.required("approved", Json_Decode$JsonCombinators.bool),
              apiEnabled: field.required("apiEnabled", Json_Decode$JsonCombinators.bool),
              signupDistribution: field.required("signupDistribution", Json_Decode$JsonCombinators.bool),
              dailyLeads: field.required("dailyLeads", Json_Decode$JsonCombinators.bool),
              phone: field.required("phone", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              dialCode: field.required("dialCode", Json_Decode$JsonCombinators.string),
              dialCodeCountry: field.required("dialCodeCountry", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.string),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              agency: field.required("agency", Json_Decode$JsonCombinators.string),
              providerNameRequested: field.required("providerNameRequested", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var Edit = {
  fromJs: fromJs$9,
  decoder: decoder$6,
  fromJson: fromJson$6
};

var Dashboard = {
  fromJs: fromJs$5,
  decoder: decoder$2,
  fromJson: fromJson$2,
  Show: Show,
  Edit: Edit
};

export {
  fromJs ,
  toJs ,
  decoder ,
  fromJson ,
  LoginStatus ,
  Approved ,
  ApiEnabled ,
  DailyLeads ,
  SignupDistribution ,
  Dashboard ,
}
/* decoder Not a pure module */
