// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../components/Button/Button.res.js";
import * as IconDelete from "../../../../icons/IconDelete.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardModalContainer from "../container/DashboardModalContainer.res.js";
import * as DashboardDestroyModalCss from "./DashboardDestroyModalCss.res.js";

function DashboardDestroyModal(props) {
  return JsxRuntime.jsxs(DashboardModalContainer.make, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(IconDelete.make, {
                                    size: "XL",
                                    color: "Red"
                                  }),
                              className: DashboardDestroyModalCss.iconContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: props.title,
                                      className: DashboardDestroyModalCss.dialogTitle
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("p", {
                                            children: props.message,
                                            className: DashboardDestroyModalCss.message
                                          }),
                                      className: DashboardDestroyModalCss.messageTextContainer
                                    })
                              ],
                              className: DashboardDestroyModalCss.messageContainer
                            })
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Gray",
                              onClick: props.close,
                              children: "Cancel"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Red",
                              onClick: props.destroy,
                              children: "Confirm"
                            })
                      ],
                      className: DashboardDestroyModalCss.buttonContainer
                    })
              ]
            });
}

var Css;

var make = DashboardDestroyModal;

export {
  Css ,
  make ,
}
/* Button Not a pure module */
