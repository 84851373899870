// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "grid",
      "grid-cols-1",
      "gap-4",
      "py-7",
      "border-b",
      "border-gray-300",
      "sm:p-7",
      "sm:grid-cols-2",
      "md:grid-cols-3",
      "lg:grid-cols-4",
      "xl:px-32",
      "2xl:grid-cols-5"
    ]);

var container$1 = Cx.cx([
      "flex",
      "flex-col",
      "gap-4",
      "items-center",
      "p-7",
      "col-span-full",
      "cursor-pointer",
      "border-2",
      "border-t-8",
      "sm:border-l-8",
      "sm:border-t-2",
      "sm:flex-row"
    ]);

var explanation = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "text-center",
      "sm:items-start",
      "sm:text-left"
    ]);

var size = "size-16";

var Icon = {
  size: size
};

var container$2 = Cx.cx([
      container$1,
      "border-red-200",
      "hover:border-red",
      "hover:shadow-lg",
      "hover:shadow-red-500/20",
      "rounded",
      "text-red"
    ]);

var title = Cx.cx([
      "text-lg",
      "font-semibold"
    ]);

var description = Cx.cx([
      "text-sm",
      "text-red-300"
    ]);

var icon = Cx.cx([
      size,
      "text-red-500"
    ]);

var $$Error = {
  container: container$2,
  title: title,
  description: description,
  icon: icon
};

var container$3 = Cx.cx([
      container$1,
      "border-gray-200",
      "hover:border-gray",
      "hover:shadow-lg",
      "hover:shadow-gray-500/20",
      "rounded",
      "text-gray-600"
    ]);

var title$1 = Cx.cx([
      "text-lg",
      "font-semibold"
    ]);

var description$1 = Cx.cx([
      "text-sm",
      "text-gray"
    ]);

var icon$1 = Cx.cx([
      size,
      "text-gray-600"
    ]);

var Empty = {
  container: container$3,
  title: title$1,
  description: description$1,
  icon: icon$1
};

var container$4 = Cx.cx([
      container$1,
      "border-teal-200",
      "hover:border-teal",
      "hover:shadow-lg",
      "hover:shadow-teal-500/20",
      "rounded",
      "text-teal",
      "animate-pulse"
    ]);

var title$2 = Cx.cx([
      "text-lg",
      "font-semibold"
    ]);

var description$2 = Cx.cx([
      "text-sm",
      "text-teal"
    ]);

var icon$2 = Cx.cx([
      size,
      "text-teal-600"
    ]);

var Fetching = {
  container: container$4,
  title: title$2,
  description: description$2,
  icon: icon$2
};

var Alert = {
  container: container$1,
  explanation: explanation,
  Icon: Icon,
  $$Error: $$Error,
  Empty: Empty,
  Fetching: Fetching
};

var container$5 = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "rounded",
      "p-2"
    ]);

var container$6 = Cx.cx([
      "border",
      "border-gray-100",
      "hover:border-purple-100",
      "hover:shadow-lg",
      "hover:shadow-purple-500/20"
    ]);

var Empty$1 = {
  container: container$6
};

var container$7 = Cx.cx([
      "border",
      "border-red-100",
      "hover:border-red-500",
      "hover:shadow-lg",
      "hover:shadow-red-500/20"
    ]);

var $$Error$1 = {
  container: container$7
};

var Tile = {
  container: container$5,
  Empty: Empty$1,
  $$Error: $$Error$1
};

export {
  container ,
  Alert ,
  Tile ,
}
/* container Not a pure module */
