// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as UserContact from "./UserContact.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, (function (prim) {
        return prim;
      }));

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Json = {
  decoder: decoder,
  fromJson: fromJson
};

function $eq(x1, x2) {
  return x1 === x2;
}

var Id = {
  Json: Json,
  $eq: $eq,
  decoder: decoder,
  fromJson: fromJson
};

function toJson(status) {
  switch (status) {
    case "Draft" :
        return "draft";
    case "Active" :
        return "active";
    case "Inactive" :
        return "inactive";
    case "Finalized" :
        return "finalized";
    case "Review" :
        return "review";
    
  }
}

function toString(value) {
  switch (value) {
    case "Draft" :
        return "Draft";
    case "Active" :
        return "Active";
    case "Inactive" :
        return "Inactive";
    case "Finalized" :
        return "Finalized";
    case "Review" :
        return "In Review";
    
  }
}

function fromString(status) {
  switch (status) {
    case "active" :
        return "Active";
    case "draft" :
        return "Draft";
    case "finalized" :
        return "Finalized";
    case "inactive" :
        return "Inactive";
    case "review" :
        return "Review";
    default:
      return "Active";
  }
}

var decoder$1 = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Status = {
  toJson: toJson,
  toString: toString,
  fromString: fromString,
  decoder: decoder$1,
  fromJson: fromJson$1
};

var DocumentId = ID.Int({});

function fromJs($$document) {
  return {
          id: $$document.id,
          fileName: $$document.filename,
          byteSize: $$document.byteSize,
          createdAt: $$Date.Utc.fromString($$document.createdAt)
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", DocumentId.decoder),
              fileName: field.required("filename", Json_Decode$JsonCombinators.string),
              byteSize: field.required("byteSize", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Utc.decoder)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

function toKiloBytes(bytes) {
  return bytes / 1000.0;
}

function toMegaBytes(bytes) {
  return bytes / 1000000.0;
}

function sizeText(bytes) {
  if (bytes < 1000000) {
    return (bytes / 1000.0).toFixed(1) + " KB";
  } else {
    return (bytes / 1000000.0).toFixed(1) + " MB";
  }
}

var $$Document = {
  fromJs: fromJs,
  decoder: decoder$2,
  fromJson: fromJson$2,
  toKiloBytes: toKiloBytes,
  toMegaBytes: toMegaBytes,
  sizeText: sizeText
};

function fromJs$1(js) {
  return {
          fullName: js.fullName,
          jobTitle: Caml_option.nullable_to_opt(js.jobTitle),
          companyName: js.companyName,
          profilePic: Caml_option.nullable_to_opt(js.profilePic)
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              fullName: field.required("fullName", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              companyName: field.required("companyName", Json_Decode$JsonCombinators.string),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Owner = {
  fromJs: fromJs$1,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function toString$1(x) {
  if (typeof x !== "object") {
    if (x === "Admin") {
      return "admin";
    } else {
      return "owner";
    }
  } else if (x._0.TAG === "Owner") {
    return "provider_owner";
  } else {
    return "provider_member";
  }
}

function toRole(x) {
  if (typeof x !== "object") {
    if (x === "Admin") {
      return "Admin";
    } else {
      return "User";
    }
  } else {
    return "Provider";
  }
}

function fromString$1(projectRole, currentProvider) {
  if (projectRole === undefined) {
    return PervasivesU.failwith("No user role");
  }
  switch (projectRole) {
    case "admin" :
        return "Admin";
    case "owner" :
        return "Owner";
    case "provider_member" :
        if (currentProvider !== undefined) {
          return {
                  TAG: "Provider",
                  _0: {
                    TAG: "Member",
                    _0: currentProvider.id
                  }
                };
        } else {
          return "Owner";
        }
    case "provider_owner" :
        if (currentProvider !== undefined) {
          return {
                  TAG: "Provider",
                  _0: {
                    TAG: "Owner",
                    _0: currentProvider.id
                  }
                };
        } else {
          return "Owner";
        }
    default:
      return PervasivesU.failwith("Unexpected user role: " + projectRole);
  }
}

function toJson$1(role) {
  return toString$1(role);
}

var Role = {
  toString: toString$1,
  toRole: toRole,
  fromString: fromString$1,
  toJson: toJson$1
};

var Colocation = {};

var BareMetal = {};

var Internet = {};

function fromString$2(x) {
  switch (x) {
    case "iaas_server" :
        return "IaasServer";
    case "iaas_storage" :
        return "IaasStorage";
    default:
      return PervasivesU.failwith("Unexpected cloud type: " + x);
  }
}

function slug(x) {
  if (x === "IaasServer") {
    return "iaas-server";
  } else {
    return "iaas-storage";
  }
}

function configurationSlug(x) {
  if (x === "IaasServer") {
    return "cloud-server-pricing";
  } else {
    return "cloud-storage-pricing";
  }
}

function toString$2(x) {
  if (x === "IaasServer") {
    return "IaaS Server";
  } else {
    return "IaaS Storage";
  }
}

var Cloud = {
  fromString: fromString$2,
  slug: slug,
  configurationSlug: configurationSlug,
  toString: toString$2
};

function fromString$3(x) {
  switch (x) {
    case "bare_metal" :
        return "BareMetal";
    case "cloud" :
        return "Cloud";
    case "colocation" :
        return "Colocation";
    case "internet" :
        return "Internet";
    case "network" :
        return "Network";
    default:
      return "Colocation";
  }
}

function toString$3(x) {
  switch (x) {
    case "Colocation" :
        return "colocation";
    case "Cloud" :
        return "cloud";
    case "Internet" :
        return "internet";
    case "Network" :
        return "network";
    case "BareMetal" :
        return "bare_metal";
    
  }
}

function toLabel(value) {
  switch (value) {
    case "Colocation" :
        return "Colocation";
    case "Cloud" :
        return "Cloud";
    case "Internet" :
        return "Internet";
    case "Network" :
        return "Network";
    case "BareMetal" :
        return "Bare Metal";
    
  }
}

function toJson$2(projectType) {
  return toString$3(projectType);
}

function toJsonString(category) {
  switch (category) {
    case "Colocation" :
        return "colocation";
    case "Cloud" :
        return "cloud";
    case "Internet" :
        return "internet";
    case "Network" :
        return "network";
    case "BareMetal" :
        return "bare_metal";
    
  }
}

function fromJs$2(category) {
  switch (category) {
    case "bare_metal" :
        return "BareMetal";
    case "cloud" :
        return "Cloud";
    case "colocation" :
        return "Colocation";
    case "internet" :
        return "Internet";
    case "network" :
        return "Network";
    default:
      return PervasivesU.failwith("Unknown category: {category: " + category + "}");
  }
}

var decoder$4 = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromJs$2);

var projectTypes = [
  "Colocation",
  "Cloud",
  "Internet",
  "Network",
  "BareMetal"
];

var ProjectType = {
  fromString: fromString$3,
  toString: toString$3,
  toLabel: toLabel,
  toJson: toJson$2,
  toJsonString: toJsonString,
  fromJs: fromJs$2,
  decoder: decoder$4,
  projectTypes: projectTypes
};

function fromString$4(x) {
  switch (x) {
    case "mpls" :
        return "Mpls";
    case "private_line" :
        return "PrivateLine";
    case "sd_wan" :
        return "SdWan";
    default:
      return PervasivesU.failwith("Unexpected network type: " + x);
  }
}

function configurationSlug$1(x) {
  switch (x) {
    case "SdWan" :
        return "sd-wan-pricing";
    case "PrivateLine" :
        return "private-line-pricing";
    case "Mpls" :
        return "mpls-pricing";
    
  }
}

function slug$1(x) {
  switch (x) {
    case "SdWan" :
        return "sd_wan";
    case "PrivateLine" :
        return "private_line";
    case "Mpls" :
        return "mpls";
    
  }
}

function toString$4(x) {
  switch (x) {
    case "SdWan" :
        return "SD-WAN";
    case "PrivateLine" :
        return "Private Line";
    case "Mpls" :
        return "MPLS";
    
  }
}

function toDescription(x) {
  switch (x) {
    case "SdWan" :
        return "Based on your configurations from the previous step, we've matched your project with the following SD-WAN providers. By default, all providers will be invited to provide solutions and pricing for your SD-WAN network. You may deselect any providers.";
    case "PrivateLine" :
        return "Based on your configurations from the previous step, we've matched your project with the following private line providers. By default, all providers will be invited to provide solutions and pricing for your private line. You may deselect any providers.";
    case "Mpls" :
        return "Based on your configurations from the previous step, we've matched your project with the following MPLS providers. By default, all providers will be invited to provide solutions and pricing for your MPLS network. You may deselect any providers.";
    
  }
}

function toH2(x) {
  switch (x) {
    case "SdWan" :
        return "Compare Top SD-WAN Service Providers and ISPs";
    case "PrivateLine" :
        return "Compare Top Private Line Service Providers";
    case "Mpls" :
        return "Compare Top MPLS Network Providers";
    
  }
}

var Network = {
  fromString: fromString$4,
  configurationSlug: configurationSlug$1,
  slug: slug$1,
  toString: toString$4,
  toDescription: toDescription,
  toH2: toH2
};

function fromString$5(category, subcategory) {
  switch (category) {
    case "bare_metal" :
        if (subcategory === "bare_metal_rfp") {
          return {
                  TAG: "BareMetal",
                  _0: "Rfp"
                };
        }
        break;
    case "cloud" :
        switch (subcategory) {
          case "iaas_server" :
              return {
                      TAG: "Cloud",
                      _0: "IaasServer"
                    };
          case "iaas_storage" :
              return {
                      TAG: "Cloud",
                      _0: "IaasStorage"
                    };
          default:
            
        }
        break;
    case "colocation" :
        if (subcategory === "rfp") {
          return {
                  TAG: "Colocation",
                  _0: "Rfp"
                };
        }
        break;
    case "internet" :
        if (subcategory === "internet_rfp") {
          return {
                  TAG: "Internet",
                  _0: "Rfp"
                };
        }
        break;
    case "network" :
        switch (subcategory) {
          case "mpls" :
              return {
                      TAG: "Network",
                      _0: "Mpls"
                    };
          case "private_line" :
              return {
                      TAG: "Network",
                      _0: "PrivateLine"
                    };
          case "sd_wan" :
              return {
                      TAG: "Network",
                      _0: "SdWan"
                    };
          default:
            
        }
        break;
    default:
      
  }
  return PervasivesU.failwith("Unknown category and subcategory combination: {category: " + category + ", subcategory: " + subcategory + "}");
}

function toString$5(x) {
  switch (x.TAG) {
    case "Colocation" :
        return "rfp";
    case "Cloud" :
        if (x._0 === "IaasServer") {
          return "iaas_server";
        } else {
          return "iaas_storage";
        }
    case "Internet" :
        return "internet_rfp";
    case "Network" :
        switch (x._0) {
          case "SdWan" :
              return "sd_wan";
          case "PrivateLine" :
              return "private_line";
          case "Mpls" :
              return "mpls";
          
        }
    case "BareMetal" :
        return "bare_metal_rfp";
    
  }
}

function toJson$3(subcategory) {
  return toString$5(subcategory);
}

var Category = {
  fromString: fromString$5,
  toString: toString$5,
  toJson: toJson$3
};

function toJson$4(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "title",
                input.title
              ],
              [
                "description",
                input.description
              ],
              [
                "isAgent",
                input.isAgent
              ],
              [
                "businessName",
                input.businessName
              ],
              [
                "businessContactName",
                input.businessContactName
              ],
              [
                "headquartersAddress",
                input.headquartersAddress
              ],
              [
                "businessPhone",
                input.businessPhone
              ],
              [
                "businessEmail",
                input.businessEmail
              ],
              [
                "contacts",
                Belt_Array.map(input.contacts, UserContact.toJson)
              ]
            ]);
}

var GeneralInfo = {
  toJson: toJson$4
};

function fromJs$3(js) {
  return {
          heading: js.heading,
          startDate: $$Date.Naive.fromString(js.startDate),
          contractLength: js.contractLength
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              heading: field.required("heading", Json_Decode$JsonCombinators.string),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

var Summary = {
  fromJs: fromJs$3,
  decoder: decoder$5,
  fromJson: fromJson$4
};

function fromJs$4(js) {
  return {
          startDate: $$Date.Naive.fromString(js.startDate),
          endDate: $$Date.Naive.fromString(js.endDate),
          summaries: Belt_Array.map(js.summaries, fromJs$3)
        };
}

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              summaries: field.required("summaries", Json_Decode$JsonCombinators.array(decoder$5))
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var EstimatedDates = {
  Summary: Summary,
  fromJs: fromJs$4,
  decoder: decoder$6,
  fromJson: fromJson$5
};

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              userId: field.required("userId", ID.decoder),
              fullName: field.required("fullName", Json_Decode$JsonCombinators.string)
            };
    });

var Concierge = {
  decoder: decoder$7
};

var decoder$8 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              userId: field.required("userId", ID.decoder),
              fullName: field.required("fullName", Json_Decode$JsonCombinators.string),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.string)
            };
    });

var Agent = {
  decoder: decoder$8
};

var decoder$9 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              fullName: field.required("fullName", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.string),
              company: field.required("company", Json_Decode$JsonCombinators.string),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.string),
              role: field.required("role", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$9));
}

var decoder$10 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              owners: field.required("owners", Json_Decode$JsonCombinators.array(decoder$9))
            };
    });

var Index = {
  decoder: decoder$10
};

var Owner$1 = {
  decoder: decoder$9,
  fromJson: fromJson$6,
  Index: Index
};

var decoder$11 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              agreement: field.required("agreement", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$7(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$11));
}

var decoder$12 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providers: field.required("providers", Json_Decode$JsonCombinators.array(decoder$11))
            };
    });

var Index$1 = {
  decoder: decoder$12
};

var Provider = {
  decoder: decoder$11,
  fromJson: fromJson$7,
  Index: Index$1
};

var decoder$13 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              fullName: field.required("fullName", Json_Decode$JsonCombinators.string),
              role: field.required("role", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$8(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$13));
}

var decoder$14 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              projectConcierges: field.required("projectConcierges", Json_Decode$JsonCombinators.array(decoder$13))
            };
    });

var Index$2 = {
  decoder: decoder$14
};

var ProjectConcierge = {
  decoder: decoder$13,
  fromJson: fromJson$8,
  Index: Index$2
};

var decoder$15 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              hashid: field.required("hashid", Json_Decode$JsonCombinators.string),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              startDate: field.required("startDate", Json_Decode$JsonCombinators.string),
              endDate: field.required("endDate", Json_Decode$JsonCombinators.string),
              status: field.required("status", decoder$1),
              redirectUrl: field.required("redirectUrl", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string),
              category: field.required("category", decoder$4),
              businessName: field.required("businessName", Json_Decode$JsonCombinators.string),
              proposalsCount: field.required("proposalsCount", Json_Decode$JsonCombinators.$$int),
              providersProjectsCount: field.required("providersProjectsCount", Json_Decode$JsonCombinators.$$int),
              providersProjectsRegisteredCount: field.required("providersProjectsRegisteredCount", Json_Decode$JsonCombinators.$$int),
              providersProjectsRegistrationDeniedCount: field.required("providersProjectsRegistrationDeniedCount", Json_Decode$JsonCombinators.$$int),
              providersProjectsRegistrationRequestedCount: field.required("providersProjectsRegistrationRequestedCount", Json_Decode$JsonCombinators.$$int),
              providersProjectsRegistrationOtherCount: field.required("providersProjectsRegistrationOtherCount", Json_Decode$JsonCombinators.$$int),
              locationsProjectsCount: field.required("locationsProjectsCount", Json_Decode$JsonCombinators.$$int),
              owner: field.required("owner", decoder$9),
              concierges: field.required("concierges", Json_Decode$JsonCombinators.array(decoder$7)),
              agent: field.optional("agent", decoder$8),
              pipedriveLeadUrl: field.required("pipedriveLeadUrl", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              pipedriveDealUrl: field.required("pipedriveDealUrl", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson$9(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$15));
}

var decoder$16 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              projects: field.required("projects", Json_Decode$JsonCombinators.array(decoder$15)),
              totalProjects: field.required("totalProjects", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$10(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$16));
}

function toString$6(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString$6
};

var SortBy = {};

function page(pageNum) {
  return "page=" + String(pageNum);
}

function query(queryString) {
  return Belt_Option.getWithDefault(Belt_Option.map(queryString, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function sortBy(sortBy$1) {
  return "&sort_by=projects.created_at&sort_direction=" + toString$6(sortBy$1.VAL);
}

function categories(categories$1) {
  if (categories$1.length !== 0) {
    return "&" + (function (__x) {
                return Js_array.joinWith("&", __x);
              })(Belt_Array.map(categories$1, (function (category) {
                      return "project_categories[]=" + category;
                    })));
  } else {
    return "";
  }
}

function statuses(statuses$1) {
  if (statuses$1.length !== 0) {
    return "&" + (function (__x) {
                return Js_array.joinWith("&", __x);
              })(Belt_Array.map(statuses$1, (function (status) {
                      return "project_statuses[]=" + status;
                    })));
  } else {
    return "";
  }
}

function userRoles(userRoles$1) {
  if (userRoles$1.length !== 0) {
    return "&" + (function (__x) {
                return Js_array.joinWith("&", __x);
              })(Belt_Array.map(userRoles$1, (function (role) {
                      return "user_roles[]=" + role;
                    })));
  } else {
    return "";
  }
}

function userIds(userIds$1) {
  if (userIds$1.length !== 0) {
    return "&" + (function (__x) {
                return Js_array.joinWith("&", __x);
              })(Belt_Array.map(userIds$1, (function (id) {
                      return "user_ids[]=" + ID.toString(id);
                    })));
  } else {
    return "";
  }
}

function providerIds(providerIds$1) {
  if (providerIds$1.length !== 0) {
    return "&" + (function (__x) {
                return Js_array.joinWith("&", __x);
              })(Belt_Array.map(providerIds$1, (function (id) {
                      return "provider_ids[]=" + ID.toString(id);
                    })));
  } else {
    return "";
  }
}

function conciergeIds(conciergeIds$1) {
  if (conciergeIds$1.length !== 0) {
    return "&" + (function (__x) {
                return Js_array.joinWith("&", __x);
              })(Belt_Array.map(conciergeIds$1, (function (id) {
                      return "concierge_ids[]=" + ID.toString(id);
                    })));
  } else {
    return "";
  }
}

function exportUrl(queryString, sortByQuery, categoryArray, statusArray, userIdArray, providerIdArray, conciergeIdArray, exportTypeOpt) {
  var exportType = exportTypeOpt !== undefined ? exportTypeOpt : "ByProject";
  var rootUrl;
  rootUrl = exportType === "ByProject" ? "/api/v1/dashboard/projects/export.csv?" : "/api/v1/dashboard/projects/providers_projects/export.csv?";
  return rootUrl + query(queryString) + sortBy(sortByQuery) + categories(categoryArray) + statuses(statusArray) + userIds(userIdArray) + providerIds(providerIdArray) + conciergeIds(conciergeIdArray);
}

var Index$3 = {
  decoder: decoder$16,
  fromJson: fromJson$10,
  SortDirection: SortDirection,
  SortBy: SortBy,
  page: page,
  query: query,
  sortBy: sortBy,
  categories: categories,
  statuses: statuses,
  userRoles: userRoles,
  userIds: userIds,
  providerIds: providerIds,
  conciergeIds: conciergeIds,
  exportUrl: exportUrl
};

var Dashboard = {
  Concierge: Concierge,
  Agent: Agent,
  Owner: Owner$1,
  Provider: Provider,
  ProjectConcierge: ProjectConcierge,
  decoder: decoder$15,
  fromJson: fromJson$9,
  Index: Index$3
};

export {
  Id ,
  Status ,
  DocumentId ,
  $$Document ,
  Owner ,
  Role ,
  Colocation ,
  BareMetal ,
  Internet ,
  Cloud ,
  ProjectType ,
  Network ,
  Category ,
  GeneralInfo ,
  EstimatedDates ,
  Dashboard ,
}
/* decoder Not a pure module */
