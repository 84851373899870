// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "rounded",
      "border",
      "w-full",
      "hover:shadow-lg"
    ]);

var project = Cx.cx([
      "flex",
      "flex-col",
      "lg:grid",
      "lg:grid-cols-12",
      "lg:gap-2"
    ]);

var innerContainer = "p-6";

var leftContainer = Cx.cx([
      innerContainer,
      "flex",
      "flex-col",
      "gap-4",
      "lg:flex-row",
      "lg:gap-2",
      "lg:col-span-9",
      "lg:grid",
      "lg:grid-cols-12"
    ]);

var rightContainer = Cx.cx([
      innerContainer,
      "lg:col-span-3",
      "bg-gray-100"
    ]);

var people = Cx.cx([
      "lg:col-span-4",
      "flex",
      "flex-col",
      "gap-2"
    ]);

var dateRange = Cx.cx([
      "text-gray",
      "text-sm"
    ]);

var estimatedDates = Cx.cx(["text-sm"]);

var details = Cx.cx([
      "lg:col-span-8",
      "flex",
      "flex-col",
      "gap-2"
    ]);

var title = Cx.cx([
      "text-black",
      "text-lg",
      "font-semibold",
      "hover:underline"
    ]);

var description = Cx.cx([
      "text-gray",
      "text-sm"
    ]);

var stats = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "text-xs"
    ]);

var stat = Cx.cx([
      "flex",
      "gap-2",
      "justify-between"
    ]);

var statLabel = Cx.cx(["font-semibold"]);

var container$1 = Cx.cx([
      "flex",
      "justify-between",
      "py-2",
      "px-6",
      "text-sm",
      "rounded-t",
      "items-center"
    ]);

function statusToClass(status) {
  switch (status) {
    case "Draft" :
        return "bg-yellow-200 text-yellow-800";
    case "Active" :
        return "bg-blue-200 text-blue-800";
    case "Inactive" :
        return "bg-gray-200";
    case "Finalized" :
        return "bg-green-200 text-green-800";
    case "Review" :
        return "bg-purple-100 text-purple-800";
    
  }
}

function border(status) {
  switch (status) {
    case "Draft" :
        return "border-yellow-200 hover:shadow-yellow-200/50";
    case "Active" :
        return "border-blue-200 hover:shadow-blue-200/50";
    case "Inactive" :
        return "border-gray-200 hover:shadow-gray-200/50";
    case "Finalized" :
        return "border-green-200 hover:shadow-green-200/50";
    case "Review" :
        return "border-purple-100 hover:shadow-purple-200/50";
    
  }
}

var Status = {
  container: container$1,
  dropdownContainer: "-mr-1",
  statusToClass: statusToClass,
  border: border
};

var users = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "lg:flex-row",
      "lg:justify-between",
      "lg:items-center",
      "border-t",
      "border-gray-200",
      "p-6",
      "lg:py-2",
      "lg:px-6"
    ]);

var image = Cx.cx([
      "rounded-full",
      "overflow-hidden"
    ]);

var container$2 = Cx.cx([
      "flex",
      "gap-2",
      "text-gray-700",
      "items-center"
    ]);

var details$1 = Cx.cx([
      "flex",
      "flex-col",
      "text-xs"
    ]);

var titleContainer = Cx.cx([
      "flex",
      "gap-2",
      "items-center"
    ]);

var title$1 = Cx.cx([
      "uppercase",
      "font-semibold"
    ]);

var name = Cx.cx([
      "flex",
      "gap-2",
      "items-center"
    ]);

var userLink = Cx.cx([
      "text-gray",
      "hover:text-purple"
    ]);

var Owner = {
  container: container$2,
  details: details$1,
  titleContainer: titleContainer,
  title: title$1,
  name: name,
  userLink: userLink
};

var container$3 = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "lg:flex-row",
      "lg:items-center",
      "text-gray-700"
    ]);

var icon = Cx.cx([
      "size-10",
      "lg:size-12",
      "text-purple"
    ]);

var details$2 = Cx.cx([
      "flex",
      "flex-col",
      "text-xs"
    ]);

var titleContainer$1 = Cx.cx([
      "flex",
      "gap-2",
      "items-center"
    ]);

var title$2 = Cx.cx([
      "uppercase",
      "font-semibold"
    ]);

var name$1 = Cx.cx([
      "flex",
      "gap-2",
      "items-center"
    ]);

var userLink$1 = Cx.cx([
      "text-gray",
      "hover:text-purple"
    ]);

var Concierge = {
  container: container$3,
  icon: icon,
  details: details$2,
  titleContainer: titleContainer$1,
  title: title$2,
  name: name$1,
  userLink: userLink$1
};

var Users = {
  users: users,
  image: image,
  Owner: Owner,
  Concierge: Concierge
};

var container$4 = Cx.cx([
      "flex",
      "flex-col",
      "rounded",
      "w-full",
      "border",
      "border-gray-200",
      "hover:shadow-lg",
      "hover:shadow-gray-200/50",
      "cursor-pointer"
    ]);

var title$3 = Cx.cx([
      "w-full",
      "h-4",
      "bg-gray-100",
      "rounded"
    ]);

var text = Cx.cx([
      "w-full",
      "h-4",
      "bg-gray-200",
      "rounded"
    ]);

var projectTitle = Cx.cx([
      "w-full",
      "h-8",
      "bg-gray-400",
      "rounded"
    ]);

var projectDates = Cx.cx([
      "w-1/2",
      "h-4",
      "bg-gray-200",
      "rounded"
    ]);

var projectDescription = Cx.cx([
      "w-full",
      "h-4",
      "bg-gray-100",
      "rounded"
    ]);

var container$5 = Cx.cx([
      "flex",
      "justify-between",
      "py-2",
      "px-6",
      "text-sm",
      "rounded-t",
      "bg-gray-200",
      "items-center"
    ]);

var Status$1 = {
  container: container$5
};

var container$6 = Cx.cx([
      "flex",
      "justify-between"
    ]);

var label = Cx.cx([
      "h-2",
      "w-1/3",
      "bg-gray-400",
      "rounded"
    ]);

var number = Cx.cx([
      "h-2",
      "w-3",
      "bg-gray-300",
      "rounded"
    ]);

var Stat = {
  container: container$6,
  label: label,
  number: number
};

var NoProjects = {
  container: container$4,
  title: title$3,
  text: text,
  projectTitle: projectTitle,
  projectDates: projectDates,
  projectDescription: projectDescription,
  Status: Status$1,
  Stat: Stat
};

var container$7 = Cx.cx([
      "flex",
      "flex-col",
      "rounded",
      "w-full",
      "border",
      "border-red-200",
      "hover:shadow-lg",
      "hover:shadow-red-200/50",
      "cursor-pointer"
    ]);

var rightContainer$1 = Cx.cx([
      "p-6",
      "col-span-3",
      "bg-red-100"
    ]);

var title$4 = Cx.cx([
      "w-full",
      "h-4",
      "bg-red-100",
      "rounded"
    ]);

var text$1 = Cx.cx([
      "w-full",
      "h-4",
      "bg-red-200",
      "rounded"
    ]);

var projectTitle$1 = Cx.cx([
      "w-full",
      "h-8",
      "bg-red-400",
      "rounded"
    ]);

var projectDates$1 = Cx.cx([
      "w-1/2",
      "h-4",
      "bg-red-200",
      "rounded"
    ]);

var projectDescription$1 = Cx.cx([
      "w-full",
      "h-4",
      "bg-red-100",
      "rounded"
    ]);

var container$8 = Cx.cx([
      "flex",
      "justify-between",
      "py-2",
      "px-6",
      "text-sm",
      "rounded-t",
      "text-red-800",
      "bg-red-200",
      "items-center"
    ]);

var Status$2 = {
  container: container$8
};

var container$9 = Cx.cx([
      "flex",
      "justify-between"
    ]);

var label$1 = Cx.cx([
      "h-2",
      "w-1/3",
      "bg-red-400",
      "rounded"
    ]);

var number$1 = Cx.cx([
      "h-2",
      "w-3",
      "bg-red-300",
      "rounded"
    ]);

var Stat$1 = {
  container: container$9,
  label: label$1,
  number: number$1
};

var $$Error = {
  container: container$7,
  rightContainer: rightContainer$1,
  title: title$4,
  text: text$1,
  projectTitle: projectTitle$1,
  projectDates: projectDates$1,
  projectDescription: projectDescription$1,
  Status: Status$2,
  Stat: Stat$1
};

var Empty = {
  NoProjects: NoProjects,
  $$Error: $$Error
};

var createUpdate = "text-xs";

export {
  container ,
  project ,
  innerContainer ,
  leftContainer ,
  rightContainer ,
  people ,
  dateRange ,
  estimatedDates ,
  details ,
  title ,
  createUpdate ,
  description ,
  stats ,
  stat ,
  statLabel ,
  Status ,
  Users ,
  Empty ,
}
/* container Not a pure module */
