// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexMapModalCss from "./LocationsIndexMapModalCss.res.js";
import * as Outline from "@heroicons/react/24/outline";

function LocationsIndexMapModal(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx(Outline.GlobeAltIcon, {
                                                            className: LocationsIndexMapModalCss.icon
                                                          }),
                                                      className: LocationsIndexMapModalCss.iconContainer
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx("div", {
                                                              children: "Map Coming Back Soon",
                                                              className: LocationsIndexMapModalCss.dialogTitle
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsx("p", {
                                                                    children: "Our map is temporarily unavailable as we're performing updates. Come back soon!",
                                                                    className: LocationsIndexMapModalCss.message
                                                                  }),
                                                              className: LocationsIndexMapModalCss.messageTextContainer
                                                            })
                                                      ],
                                                      className: LocationsIndexMapModalCss.messageContainer
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Button.make, {
                                                    size: "MD",
                                                    color: "Teal",
                                                    onClick: props.onClick,
                                                    children: "Close"
                                                  }),
                                              className: LocationsIndexMapModalCss.buttonContainer
                                            })
                                      ],
                                      className: LocationsIndexMapModalCss.dialogPanel
                                    }),
                                className: LocationsIndexMapModalCss.innerContainer
                              }),
                          className: LocationsIndexMapModalCss.container
                        }),
                    className: LocationsIndexMapModalCss.dialogBackdrop
                  }),
              className: LocationsIndexMapModalCss.dialog
            });
}

var Css;

var make = LocationsIndexMapModal;

export {
  Css ,
  make ,
}
/* Button Not a pure module */
