// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var iconContainer = Cx.cx(["mx-auto flex size-20 items-center justify-center rounded-full bg-red-100"]);

var messageContainer = Cx.cx(["mt-3 text-center sm:mt-5"]);

var dialogTitle = Cx.cx([
      "text-base",
      "font-semibold",
      "text-gray-900"
    ]);

var buttonContainer = Cx.cx(["flex gap-2 justify-center mt-5 sm:mt-6"]);

var messageTextContainer = "mt-2";

var message = "text-sm text-gray-500";

export {
  iconContainer ,
  messageContainer ,
  dialogTitle ,
  messageTextContainer ,
  message ,
  buttonContainer ,
}
/* iconContainer Not a pure module */
