// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as LocationsIndexCss from "../../styles/LocationsIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function LocationsIndexLocality(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: Js_string.slice(0, 30, props.name),
                            className: LocationsIndexCss.link,
                            href: props.href
                          }),
                      className: LocationsIndexCss.name
                    }),
                JsxRuntime.jsx("div", {
                      children: String(props.totalLocations),
                      className: LocationsIndexCss.totalLocations
                    })
              ],
              className: LocationsIndexCss.wrapper
            });
}

var Css;

var make = LocationsIndexLocality;

export {
  Css ,
  make ,
}
/* LocationsIndexCss Not a pure module */
