// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "relative",
      "text-center",
      "flex",
      "flex-col",
      "items-center",
      "py-20"
    ]);

var icon = Cx.cx(["h-12"]);

var heading = Cx.cx([
      "text-white!",
      "mt-10",
      "mb-0"
    ]);

var subheading = Cx.cx([
      "text-white",
      "mb-12",
      "font-semibold",
      "md:text-4xl",
      "text-2xl"
    ]);

export {
  wrapper ,
  icon ,
  heading ,
  subheading ,
}
/* wrapper Not a pure module */
