// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "rounded",
      "border",
      "border-gray-100",
      "p-2",
      "hover:border-purple-100",
      "hover:shadow-lg",
      "hover:shadow-purple-500/20"
    ]);

var details = Cx.cx([
      "flex",
      "flex-col",
      "gap-1"
    ]);

var providerName = Cx.cx([
      "text-xs",
      "text-gray-500"
    ]);

var name = Cx.cx([
      "text",
      "font-medium",
      "hover:text-purple"
    ]);

var sponsored = Cx.cx([
      "uppercase",
      "italic",
      "text-gray-500",
      "text-xs"
    ]);

var container$1 = Cx.cx([
      "relative",
      "w-full",
      "rounded",
      "overflow-hidden"
    ]);

var picture = Cx.cx([
      "w-full",
      "rounded"
    ]);

var logo = Cx.cx([
      "absolute",
      "flex",
      "items-center",
      "justify-center",
      "top-2",
      "left-2",
      "rounded",
      "bg-white",
      "p-2",
      "w-24",
      "shadow-md",
      "shadow-gray-500/20"
    ]);

var agreement = Cx.cx([
      "absolute",
      "top-2",
      "right-2",
      "text-green-600",
      "size-6"
    ]);

var $$Image = {
  container: container$1,
  picture: picture,
  logo: logo,
  agreement: agreement
};

var fullAddress = providerName;

export {
  container ,
  details ,
  providerName ,
  name ,
  sponsored ,
  fullAddress ,
  $$Image ,
}
/* container Not a pure module */
