// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var table = Cx.cx([
      "mb-5",
      "table-auto",
      "w-full"
    ]);

var tableWrapper = Cx.cx([
      "overflow-x-auto",
      "min-h-64"
    ]);

var selected = Cx.cx([
      "grid",
      "items-center"
    ]);

var controlCell = Cx.cx([
      "flex",
      "items-center",
      "gap-1",
      "font-semibold",
      "text-gray-700"
    ]);

var tableLink = Cx.cx([
      "text-teal-700",
      "font-semibold",
      "hover:underline"
    ]);

var fetchError = Cx.cx([
      "text-red-600",
      "text-center"
    ]);

var spinner = "min-h-[150px]";

var pagination = "mb-10";

var tableCellWithMinWidth = "min-w-48";

export {
  table ,
  tableWrapper ,
  selected ,
  controlCell ,
  spinner ,
  tableLink ,
  fetchError ,
  pagination ,
  tableCellWithMinWidth ,
}
/* table Not a pure module */
