// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var changePasswordContainer = Cx.cx([
      "lg:p-60",
      "md:p-40",
      "py-24",
      "px-4",
      "sm:mt-15"
    ]);

var container = Cx.cx([
      "max-w-4xl",
      "mx-auto",
      "md:max-w-4xl",
      "sm:max-w-3xl",
      "xs:max-w-xs"
    ]);

var formContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var flexContainer = Cx.cx([
      "flex",
      "md:flex-row",
      "flex-col",
      "justify-between",
      "gap-4"
    ]);

var linkMuted = Cx.cx([
      "text-gray-700",
      "text-sm",
      "border-b border-dashed border-gray-400"
    ]);

var hoverTransition = Cx.cx([
      "transition-all",
      "duration-300",
      "ease-in-out"
    ]);

export {
  changePasswordContainer ,
  container ,
  formContainer ,
  flexContainer ,
  linkMuted ,
  hoverTransition ,
}
/* changePasswordContainer Not a pure module */
