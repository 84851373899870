// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as $$Image from "./Image.res.js";
import * as Video from "./Video.res.js";
import * as ApiKey from "./ApiKey.res.js";
import * as Directory from "./Directory.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProviderUser from "./ProviderUser.res.js";
import * as ProviderDraft from "./ProviderDraft.res.js";
import * as ProviderFeature from "./ProviderFeature.res.js";
import * as ProviderSetting from "./ProviderSetting.res.js";
import * as DirectoryService from "./DirectoryService.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as ProjectSettingCategory from "./ProjectSettingCategory.res.js";
import * as DataCenterCertification from "./DataCenterCertification.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          logo: js.logo,
          totalLocations: js.totalLocations,
          totalAllLocations: js.totalAllLocations,
          totalProducts: js.totalProducts,
          url: js.url,
          agreement: js.agreement,
          description: Caml_option.nullable_to_opt(js.description),
          slug: js.slug,
          showAgreementCheck: js.showAgreementCheck,
          active: js.active,
          supportNumber: js.supportNumber,
          managersCount: js.managersCount,
          master: js.master
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalAllLocations: field.required("totalAllLocations", Json_Decode$JsonCombinators.$$int),
              totalProducts: field.required("totalProducts", Json_Decode$JsonCombinators.$$int),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              agreement: field.required("agreement", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              showAgreementCheck: field.required("showAgreementCheck", Json_Decode$JsonCombinators.bool),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              supportNumber: field.required("supportNumber", Json_Decode$JsonCombinators.string),
              managersCount: field.required("managersCount", Json_Decode$JsonCombinators.$$int),
              master: field.required("master", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toJson(active) {
  if (active === "Active") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Active") {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromJs$1(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromBool(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

var Active = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs$1,
  fromBool: fromBool
};

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          agreement: js.agreement,
          logo: js.logo,
          totalLocations: js.totalLocations,
          totalProducts: js.totalProducts,
          description: js.description,
          yearFounded: js.yearFounded,
          numberEmployees: js.numberEmployees,
          phoneNumber: js.phoneNumber,
          hqFullAddress: js.hqFullAddress,
          showAgreementCheck: js.showAgreementCheck,
          slug: js.slug
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              agreement: field.required("agreement", Json_Decode$JsonCombinators.bool),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalProducts: field.required("totalProducts", Json_Decode$JsonCombinators.$$int),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              yearFounded: field.required("yearFounded", Json_Decode$JsonCombinators.string),
              numberEmployees: field.required("numberEmployees", Json_Decode$JsonCombinators.string),
              phoneNumber: field.required("phoneNumber", Json_Decode$JsonCombinators.string),
              hqFullAddress: field.required("hqFullAddress", Json_Decode$JsonCombinators.string),
              showAgreementCheck: field.required("showAgreementCheck", Json_Decode$JsonCombinators.bool),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Summary = {
  fromJs: fromJs$2,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$3(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          active: js.active,
          companyType: js.companyType,
          tickerSymbol: js.tickerSymbol,
          numberEmployees: js.numberEmployees,
          yearFounded: js.yearFounded,
          hqFullAddress: js.hqFullAddress,
          displayDialCode: js.displayDialCode,
          displayPhoneNumber: js.displayPhoneNumber,
          supportPhoneNumber: js.supportPhoneNumber,
          websiteUrl: js.websiteUrl,
          description: js.description,
          logo: js.logo,
          projectWebhookUrl: js.projectWebhookUrl,
          isApproved: js.isApproved,
          lastApprovedOn: $$Date.Naive.fromString(js.lastApprovedOn),
          images: Belt_Array.map(js.images, $$Image.fromJs),
          videos: Belt_Array.map(js.videos, Video.fromJs),
          providerUsers: ProviderUser.EditProvider.fromJs(js.providerUsers),
          apiKeys: Belt_Array.map(js.apiKeys, ApiKey.fromJs),
          settings: ProviderSetting.fromJs(js.settings),
          directories: Belt_Array.map(js.directories, Directory.EditProvider.fromJs),
          currentDraft: ProviderDraft.fromJs(js.currentDraft),
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          features: Belt_Array.map(js.features, ProviderFeature.Edit.fromJs),
          projectSettingCategories: Belt_Array.map(js.projectSettingCategories, ProjectSettingCategory.EditProvider.fromJs),
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              companyType: field.required("companyType", Json_Decode$JsonCombinators.string),
              tickerSymbol: field.required("tickerSymbol", Json_Decode$JsonCombinators.string),
              numberEmployees: field.required("numberEmployees", Json_Decode$JsonCombinators.string),
              yearFounded: field.required("yearFounded", Json_Decode$JsonCombinators.string),
              hqFullAddress: field.required("hqFullAddress", Json_Decode$JsonCombinators.string),
              displayDialCode: field.required("displayDialCode", Json_Decode$JsonCombinators.string),
              displayPhoneNumber: field.required("displayPhoneNumber", Json_Decode$JsonCombinators.string),
              supportPhoneNumber: field.required("supportPhoneNumber", Json_Decode$JsonCombinators.string),
              websiteUrl: field.required("websiteUrl", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              projectWebhookUrl: field.required("projectWebhookUrl", Json_Decode$JsonCombinators.string),
              isApproved: field.required("isApproved", Json_Decode$JsonCombinators.bool),
              lastApprovedOn: field.required("lastApprovedOn", $$Date.Naive.decoder),
              images: field.required("images", Json_Decode$JsonCombinators.array($$Image.decoder)),
              videos: field.required("videos", Json_Decode$JsonCombinators.array(Video.decoder)),
              providerUsers: field.required("providerUsers", ProviderUser.EditProvider.decoder),
              apiKeys: field.required("apiKeys", Json_Decode$JsonCombinators.array(ApiKey.decoder)),
              settings: field.required("settings", ProviderSetting.decoder),
              directories: field.required("directories", Json_Decode$JsonCombinators.array(Directory.EditProvider.decoder)),
              currentDraft: field.required("currentDraft", ProviderDraft.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              features: field.required("features", Json_Decode$JsonCombinators.array(ProviderFeature.Edit.decoder)),
              projectSettingCategories: field.required("projectSettingCategories", Json_Decode$JsonCombinators.array(ProjectSettingCategory.EditProvider.decoder)),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

function viewToString(x) {
  if (x === "Live") {
    return "Live View";
  } else {
    return "Draft View";
  }
}

var Live = {
  viewToString: viewToString
};

function fromJs$4(js) {
  return {
          id: js.id,
          providerId: js.providerId,
          tsdId: js.tsdId,
          tsdName: js.tsdName,
          preferred: js.preferred,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              tsdId: field.required("tsdId", ID.decoder),
              tsdName: field.required("tsdName", Json_Decode$JsonCombinators.string),
              preferred: field.required("preferred", Json_Decode$JsonCombinators.bool),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

function fromJs$5(js) {
  return {
          providerId: js.providerId,
          providerTsds: Belt_Array.map(js.providerTsds, fromJs$4),
          totalProviderTsds: js.totalProviderTsds,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providerId: field.required("providerId", ID.decoder),
              providerTsds: field.required("providerTsds", Json_Decode$JsonCombinators.array(decoder$3)),
              totalProviderTsds: field.required("totalProviderTsds", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

function toString$1(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString$1
};

var SortBy = {};

function page(pageNum) {
  return "page=" + String(pageNum);
}

function sortBy(sortBy$1) {
  var variant = sortBy$1.NAME;
  if (variant === "CreatedAt") {
    return "&sort_by=provider_tsds.created_at&sort_direction=" + toString$1(sortBy$1.VAL);
  } else if (variant === "Preferred") {
    return "&sort_by=provider_tsds.preferred&sort_direction=" + toString$1(sortBy$1.VAL);
  } else if (variant === "UpdatedAt") {
    return "&sort_by=provider_tsds.updated_at&sort_direction=" + toString$1(sortBy$1.VAL);
  } else {
    return "&sort_by=tsds.name&sort_direction=" + toString$1(sortBy$1.VAL);
  }
}

var Index = {
  fromJs: fromJs$5,
  decoder: decoder$4,
  fromJson: fromJson$4,
  SortDirection: SortDirection,
  SortBy: SortBy,
  page: page,
  sortBy: sortBy
};

function fromJs$6(js) {
  return {
          id: js.id,
          name: js.name
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

function query(queryString) {
  return Belt_Option.getWithDefault(Belt_Option.map(queryString, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function fromJs$7(js) {
  return {
          tsds: Belt_Array.map(js.tsds, fromJs$6)
        };
}

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              tsds: field.required("tsds", Json_Decode$JsonCombinators.array(decoder$5))
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var Tsds = {
  fromJs: fromJs$7,
  decoder: decoder$6,
  fromJson: fromJson$6
};

var Filter = {
  fromJs: fromJs$6,
  decoder: decoder$5,
  fromJson: fromJson$5,
  query: query,
  Tsds: Tsds
};

var Tsd = {
  Filter: Filter
};

var ProviderTsd = {
  fromJs: fromJs$4,
  decoder: decoder$3,
  fromJson: fromJson$3,
  Index: Index,
  Tsd: Tsd
};

var Edit = {
  fromJs: fromJs$3,
  decoder: decoder$2,
  fromJson: fromJson$2,
  Live: Live,
  ProviderTsd: ProviderTsd
};

function fromJs$8(js) {
  return {
          id: js.id,
          name: js.name,
          logo: js.logo
        };
}

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$7(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$7));
}

function empty() {
  return {
          message: "",
          providerId: undefined
        };
}

function toJson$1(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "message",
                input.message
              ],
              [
                "provider_id",
                ID.toJson(Belt_Option.getExn(input.providerId))
              ]
            ]);
}

var NoBidInput = {
  empty: empty,
  toJson: toJson$1
};

var Simple = {
  fromJs: fromJs$8,
  decoder: decoder$7,
  fromJson: fromJson$7,
  NoBidInput: NoBidInput,
  SetPreparingStatusInput: undefined
};

function fromJs$9(js) {
  return {
          id: js.id,
          name: js.name,
          selected: js.selected
        };
}

var decoder$8 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              selected: field.required("selected", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$8(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$8));
}

var ResourceProvider = {
  fromJs: fromJs$9,
  decoder: decoder$8,
  fromJson: fromJson$8
};

function fromJs$10(js) {
  return {
          name: js.name,
          slug: js.slug,
          totalLocations: js.totalLocations
        };
}

var decoder$9 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$9(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$9));
}

var Sidebar = {
  fromJs: fromJs$10,
  decoder: decoder$9,
  fromJson: fromJson$9
};

function fromJs$11(js) {
  return {
          providers: Belt_Array.map(js.providers, (function (provider) {
                  return fromJs$10(provider);
                })),
          totalProviders: js.totalProviders,
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          offset: js.offset
        };
}

var decoder$10 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providers: field.required("providers", Json_Decode$JsonCombinators.array(decoder$9)),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$10(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$10));
}

var SidebarTable = {
  fromJs: fromJs$11,
  decoder: decoder$10,
  fromJson: fromJson$10
};

var decoder$11 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$11(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$11));
}

var Slug = {
  decoder: decoder$11,
  fromJson: fromJson$11
};

function fromJs$12(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          isApproved: js.isApproved,
          master: js.master,
          slug: js.slug,
          totalLocations: js.totalLocations,
          providerTsdsCount: js.providerTsdsCount,
          managers: js.managers,
          products: js.products,
          createdAt: $$Date.Naive.fromString(js.createdAt)
        };
}

var decoder$12 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              isApproved: field.required("isApproved", Json_Decode$JsonCombinators.bool),
              master: field.required("master", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              providerTsdsCount: field.required("providerTsdsCount", Json_Decode$JsonCombinators.$$int),
              managers: field.required("managers", Json_Decode$JsonCombinators.$$int),
              products: field.required("products", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder)
            };
    });

function fromJson$12(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$12));
}

function toString$2(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection$1 = {
  toString: toString$2
};

var SortBy$1 = {};

function page$1(pageNum) {
  return "page=" + String(pageNum);
}

function query$1(query$2) {
  return Belt_Option.getWithDefault(Belt_Option.map(query$2, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function sortBy$1(sortBy$2) {
  var variant = sortBy$2.NAME;
  if (variant === "Locations") {
    return "&sort_by=locations_count&sort_direction=" + toString$2(sortBy$2.VAL);
  } else if (variant === "CreatedAt") {
    return "&sort_by=providers.created_at&sort_direction=" + toString$2(sortBy$2.VAL);
  } else if (variant === "IsApproved") {
    return "&sort_by=is_approved&sort_direction=" + toString$2(sortBy$2.VAL);
  } else if (variant === "Managers") {
    return "&sort_by=provider_users_counter_cache&sort_direction=" + toString$2(sortBy$2.VAL);
  } else if (variant === "Name") {
    return "&sort_by=name&sort_direction=" + toString$2(sortBy$2.VAL);
  } else if (variant === "ProviderTsdsCount") {
    return "&sort_by=providers.provider_tsds_count&sort_direction=" + toString$2(sortBy$2.VAL);
  } else {
    return "&sort_by=active&sort_direction=" + toString$2(sortBy$2.VAL);
  }
}

function permissions(permissions$1) {
  return permissions$1.map(function (permission) {
                return "&settings[]=" + ProviderSetting.Field.toFieldName(permission);
              }).join("");
}

var Index$1 = {
  SortDirection: SortDirection$1,
  SortBy: SortBy$1,
  page: page$1,
  query: query$1,
  sortBy: sortBy$1,
  permissions: permissions
};

var Dashboard = {
  fromJs: fromJs$12,
  decoder: decoder$12,
  fromJson: fromJson$12,
  Index: Index$1
};

function fromJs$13(js) {
  return {
          id: js.id,
          name: js.name
        };
}

var decoder$13 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$13(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$13));
}

var Filter$1 = {
  fromJs: fromJs$13,
  decoder: decoder$13,
  fromJson: fromJson$13
};

function fromJs$14(js) {
  return {
          name: js.name,
          slug: js.slug,
          logo: js.logo,
          totalLocations: js.totalLocations
        };
}

var decoder$14 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$14(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$14));
}

var ListItem = {
  fromJs: fromJs$14,
  decoder: decoder$14,
  fromJson: fromJson$14
};

function fromJs$15(js) {
  return {
          providers: Belt_Array.map(js.providers, fromJs$14)
        };
}

var decoder$15 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providers: field.required("providers", Json_Decode$JsonCombinators.array(decoder$14))
            };
    });

function fromJson$15(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$15));
}

var List = {
  fromJs: fromJs$15,
  decoder: decoder$15,
  fromJson: fromJson$15
};

var decoder$16 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$16(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$16));
}

var Show = {
  decoder: decoder$16,
  fromJson: fromJson$16
};

var Locality = {
  Show: Show
};

function fromJs$16(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug
        };
}

var decoder$17 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$17(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$17));
}

var LocationBrochure = {
  fromJs: fromJs$16,
  decoder: decoder$17,
  fromJson: fromJson$17
};

function fromJs$17(js) {
  return {
          id: js.id,
          name: js.name,
          fullAddress: js.fullAddress,
          sponsoredGlobal: js.sponsoredGlobal,
          sponsoredCountry: js.sponsoredCountry,
          sponsoredState: js.sponsoredState,
          sponsoredCity: js.sponsoredCity,
          url: js.url,
          providerId: js.providerId,
          providerName: js.providerName,
          providerLogo: js.providerLogo,
          providerAgreement: js.providerAgreement,
          productsCount: js.productsCount,
          minPrice: Belt_Option.getExn(Belt_Float.fromString(js.minPrice)),
          maxPrice: Belt_Option.getExn(Belt_Float.fromString(js.maxPrice)),
          imageUrl: js.imageUrl
        };
}

var decoder$18 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              sponsoredGlobal: field.required("sponsoredGlobal", Json_Decode$JsonCombinators.bool),
              sponsoredCountry: field.required("sponsoredCountry", Json_Decode$JsonCombinators.bool),
              sponsoredState: field.required("sponsoredState", Json_Decode$JsonCombinators.bool),
              sponsoredCity: field.required("sponsoredCity", Json_Decode$JsonCombinators.bool),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              providerAgreement: field.required("providerAgreement", Json_Decode$JsonCombinators.bool),
              productsCount: field.required("productsCount", Json_Decode$JsonCombinators.$$int),
              minPrice: field.required("minPrice", Json_Decode$JsonCombinators.$$float),
              maxPrice: field.required("maxPrice", Json_Decode$JsonCombinators.$$float),
              imageUrl: field.required("imageUrl", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$18(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$18));
}

var $$Location = {
  fromJs: fromJs$17,
  decoder: decoder$18,
  fromJson: fromJson$18
};

function fromJs$18(js) {
  return {
          locations: Belt_Array.map(js.locations, fromJs$17),
          totalLocations: js.totalLocations,
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          offset: js.offset
        };
}

var decoder$19 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              locations: field.required("locations", Json_Decode$JsonCombinators.array(decoder$18)),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$19(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$19));
}

var Locations = {
  fromJs: fromJs$18,
  decoder: decoder$19,
  fromJson: fromJson$19
};

function facilityFromJs(js) {
  return {
          name: js.name,
          slug: js.slug,
          live: js.live
        };
}

function salesManagerFromJs(js) {
  return {
          name: js.name,
          title: js.title,
          image: js.image
        };
}

function fromJs$19(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          hqFullAddress: js.hqFullAddress,
          yearFounded: js.yearFounded,
          logo: js.logo,
          slug: js.slug,
          displayPhoneNumber: js.displayPhoneNumber,
          showAgreementCheck: js.showAgreementCheck,
          agreement: js.agreement,
          numberEmployees: js.numberEmployees,
          certifications: Belt_Array.map(js.certifications, DataCenterCertification.Show.fromJs),
          salesManager: Belt_Option.map(Caml_option.nullable_to_opt(js.salesManager), salesManagerFromJs),
          locationsCountActive: js.locationsCountActive,
          locations: fromJs$18(js.locations),
          features: Belt_Array.map(js.features, ProviderFeature.fromJs),
          directoryServices: Belt_Array.map(js.directoryServices, DirectoryService.ShowIndex.fromJs),
          bareMetalDirectory: js.bareMetalDirectory
        };
}

var facilityDecoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              live: field.required("active", Json_Decode$JsonCombinators.bool)
            };
    });

function facilityFromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, facilityDecoder));
}

var salesManagerDecoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              name: field.required("name", Json_Decode$JsonCombinators.string),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.string)
            };
    });

function salesManagerFromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, salesManagerDecoder));
}

var decoder$20 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              hqFullAddress: field.required("hqFullAddress", Json_Decode$JsonCombinators.string),
              yearFounded: field.required("yearFounded", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              displayPhoneNumber: field.required("displayPhoneNumber", Json_Decode$JsonCombinators.string),
              showAgreementCheck: field.required("showAgreementCheck", Json_Decode$JsonCombinators.bool),
              agreement: field.required("agreement", Json_Decode$JsonCombinators.bool),
              numberEmployees: field.required("numberEmployees", Json_Decode$JsonCombinators.string),
              certifications: field.required("certifications", Json_Decode$JsonCombinators.array(DataCenterCertification.Show.decoder)),
              salesManager: field.required("salesManager", Json_Decode$JsonCombinators.option(salesManagerDecoder)),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              locations: field.required("locations", decoder$19),
              features: field.required("features", Json_Decode$JsonCombinators.array(ProviderFeature.decoder)),
              directoryServices: field.required("directoryServices", Json_Decode$JsonCombinators.array(DirectoryService.ShowIndex.decoder)),
              bareMetalDirectory: field.required("bareMetalDirectory", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$20(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$20));
}

var Show$1 = {
  facilityFromJs: facilityFromJs,
  salesManagerFromJs: salesManagerFromJs,
  fromJs: fromJs$19,
  facilityDecoder: facilityDecoder,
  facilityFromJson: facilityFromJson,
  salesManagerDecoder: salesManagerDecoder,
  salesManagerFromJson: salesManagerFromJson,
  decoder: decoder$20,
  fromJson: fromJson$20
};

function fromJs$20(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          salesManager: Belt_Option.map(Caml_option.nullable_to_opt(js.salesManager), salesManagerFromJs)
        };
}

var decoder$21 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              salesManager: field.required("salesManager", Json_Decode$JsonCombinators.option(salesManagerDecoder))
            };
    });

function fromJson$21(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$21));
}

var ProviderBrochure = {
  fromJs: fromJs$20,
  decoder: decoder$21,
  fromJson: fromJson$21
};

function toBoolString(t) {
  if (t === "Approved") {
    return "true";
  } else {
    return "false";
  }
}

var ApprovalStatus = {
  toBoolString: toBoolString
};

function fromJs$21(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          hqFullAddress: js.hqFullAddress,
          yearFounded: js.yearFounded,
          logo: js.logo,
          slug: js.slug,
          displayPhoneNumber: js.displayPhoneNumber,
          showAgreementCheck: js.showAgreementCheck,
          agreement: js.agreement,
          numberEmployees: js.numberEmployees,
          salesManager: Belt_Option.map(Caml_option.nullable_to_opt(js.salesManager), salesManagerFromJs),
          locationsCountActive: js.locationsCountActive,
          bareMetalDirectory: js.bareMetalDirectory
        };
}

var decoder$22 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              hqFullAddress: field.required("hqFullAddress", Json_Decode$JsonCombinators.string),
              yearFounded: field.required("yearFounded", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              displayPhoneNumber: field.required("displayPhoneNumber", Json_Decode$JsonCombinators.string),
              showAgreementCheck: field.required("showAgreementCheck", Json_Decode$JsonCombinators.bool),
              agreement: field.required("agreement", Json_Decode$JsonCombinators.bool),
              numberEmployees: field.required("numberEmployees", Json_Decode$JsonCombinators.string),
              salesManager: field.required("salesManager", Json_Decode$JsonCombinators.option(salesManagerDecoder)),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              bareMetalDirectory: field.required("bareMetalDirectory", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$22(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$22));
}

var LocationsIndex = {
  fromJs: fromJs$21,
  decoder: decoder$22,
  fromJson: fromJson$22
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  Active ,
  Summary ,
  Edit ,
  Simple ,
  ResourceProvider ,
  Sidebar ,
  SidebarTable ,
  Slug ,
  Dashboard ,
  Filter$1 as Filter,
  ListItem ,
  List ,
  Locality ,
  LocationBrochure ,
  $$Location ,
  Locations ,
  Show$1 as Show,
  ProviderBrochure ,
  ApprovalStatus ,
  LocationsIndex ,
}
/* decoder Not a pure module */
