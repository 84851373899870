// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronTitleCss from "./HomeJumbotronTitleCss.res.js";

function HomeJumbotronTitle(props) {
  return JsxRuntime.jsx("div", {
              children: props.title,
              className: HomeJumbotronTitleCss.title
            });
}

var Css;

var make = HomeJumbotronTitle;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
