// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../Link/A.res.js";
import * as Char from "../../../../libs/Char.res.js";
import * as Routes_Tsd from "../../../../routes/common/Routes_Tsd.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_City from "../../../../routes/common/Routes_City.res.js";
import * as Routes_Lead from "../../../../routes/common/Routes_Lead.res.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as Routes_Order from "../../../../routes/common/Routes_Order.res.js";
import * as Routes_State from "../../../../routes/common/Routes_State.res.js";
import * as Routes_Agency from "../../../../routes/common/Routes_Agency.res.js";
import * as Routes_Country from "../../../../routes/common/Routes_Country.res.js";
import * as Routes_Product from "../../../../routes/common/Routes_Product.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as Routes_Location from "../../../../routes/common/Routes_Location.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as Routes_Resource from "../../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_AgencyAgent from "../../../../routes/common/Routes_AgencyAgent.res.js";
import * as Routes_ProviderTsd from "../../../../routes/common/Routes_ProviderTsd.res.js";
import * as MobileNavigationCss from "./MobileNavigationCss.res.js";
import * as Routes_SuspiciousIp from "../../../../routes/common/Routes_SuspiciousIp.res.js";
import * as Routes_SuspiciousEmail from "../../../../routes/common/Routes_SuspiciousEmail.res.js";

function MobileSignedInNav(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var userData = props.userData;
  var match = userData.role;
  var tmp;
  tmp = match === "Admin" ? JsxRuntime.jsx("li", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_User.Personal.Dashboard.notifications,
                className: MobileNavigationCss.groupLinkSubItem,
                children: "Notifications"
              })
        }) : null;
  var match$1 = userData.role;
  var tmp$1;
  var exit = 0;
  switch (match$1) {
    case "Admin" :
        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Providers",
                      className: MobileNavigationCss.submenuTitle
                    }),
                JsxRuntime.jsxs("ul", {
                      children: [
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsxs(A.make, {
                                    href: Routes_Provider.Dashboard.index,
                                    className: MobileNavigationCss.groupLinkSubItem,
                                    children: [
                                      "Profiles ",
                                      JsxRuntime.jsx(Char.Ampersand.make, {}),
                                      " Listings"
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: Routes_Project.Dashboard.index,
                                    className: MobileNavigationCss.groupLinkSubItem,
                                    children: "All Projects"
                                  })
                            }),
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: Routes_Project.Dashboard.providerIndex,
                                    className: MobileNavigationCss.groupLinkSubItem,
                                    children: "Provider Projects"
                                  })
                            }),
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: Routes_Project.Dashboard.conciergeIndex,
                                    className: MobileNavigationCss.groupLinkSubItem,
                                    children: "Concierge Projects"
                                  })
                            }),
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: Routes_Product.Dashboard.index,
                                    className: MobileNavigationCss.groupLinkSubItem,
                                    children: "Marketplace Products"
                                  })
                            }),
                        JsxRuntime.jsx("li", {
                              children: JsxRuntime.jsx(A.make, {
                                    href: Routes_Order.Dashboard.index,
                                    className: MobileNavigationCss.groupLinkSubItem,
                                    children: "Marketplace Orders"
                                  })
                            })
                      ],
                      className: MobileNavigationCss.roleDashboardContainer
                    })
              ]
            });
        break;
    case "Provider" :
        tmp$1 = JsxRuntime.jsx("ul", {
              children: props.isConcierge ? (
                  isNotOnlyProviderMember ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsxs(A.make, {
                                        href: Routes_Provider.Dashboard.index,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: [
                                          "Profiles ",
                                          JsxRuntime.jsx(Char.Ampersand.make, {}),
                                          " Listings"
                                        ]
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.providerIndex,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Provider Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.conciergeIndex,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Concierge Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Product.Dashboard.index,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Marketplace Products"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Order.Dashboard.index,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Marketplace Orders"
                                      })
                                })
                          ]
                        }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.providerIndex,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Provider Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.conciergeIndex,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Concierge Projects"
                                      })
                                })
                          ]
                        })
                ) : (
                  isNotOnlyProviderMember ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsxs(A.make, {
                                        href: Routes_Provider.Dashboard.index,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: [
                                          "Profiles ",
                                          JsxRuntime.jsx(Char.Ampersand.make, {}),
                                          " Listings"
                                        ]
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Project.Dashboard.providerIndex,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Provider Projects"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Product.Dashboard.index,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Marketplace Products"
                                      })
                                }),
                            JsxRuntime.jsx("li", {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Order.Dashboard.index,
                                        className: MobileNavigationCss.groupLinkSubItem,
                                        children: "Marketplace Orders"
                                      })
                                })
                          ]
                        }) : JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Project.Dashboard.providerIndex,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Provider Projects"
                              })
                        })
                ),
              className: MobileNavigationCss.roleDashboardContainer
            });
        break;
    case "User" :
    case "Visitor" :
        tmp$1 = null;
        break;
    case "Concierge" :
    case "Agent" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsx("li", {
          children: JsxRuntime.jsx(A.make, {
                href: Routes_Project.Dashboard.conciergeIndex,
                className: MobileNavigationCss.groupLinkSubItem,
                children: "Concierge Projects"
              })
        });
  }
  var match$2 = userData.role;
  var tmp$2;
  tmp$2 = match$2 === "Admin" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: "General Admin",
                  className: MobileNavigationCss.submenuTitle
                }),
            JsxRuntime.jsxs("ul", {
                  children: [
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_BlogPost.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "News"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Resource.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Resources"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_User.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Users"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_User.Dashboard.cloudRack,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "CloudRack™ Users"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Lead.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Leads"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_SuspiciousEmail.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Suspicious Email"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_SuspiciousIp.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Suspicious IPs"
                              })
                        })
                  ],
                  className: MobileNavigationCss.roleDashboardContainer
                }),
            JsxRuntime.jsx("div", {
                  children: "Locations",
                  className: MobileNavigationCss.submenuTitle
                }),
            JsxRuntime.jsxs("ul", {
                  children: [
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Location.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Locations"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Country.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Countries"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_State.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "States"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_City.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Cities"
                              })
                        })
                  ],
                  className: MobileNavigationCss.roleDashboardContainer
                }),
            JsxRuntime.jsx("div", {
                  children: "TSDs & Agencies",
                  className: MobileNavigationCss.submenuTitle
                }),
            JsxRuntime.jsxs("ul", {
                  children: [
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Tsd.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "TSDs"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_Agency.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Agencies"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_AgencyAgent.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Agents"
                              })
                        }),
                    JsxRuntime.jsx("li", {
                          children: JsxRuntime.jsx(A.make, {
                                href: Routes_ProviderTsd.Dashboard.index,
                                className: MobileNavigationCss.groupLinkSubItem,
                                children: "Provider TSDs"
                              })
                        })
                  ],
                  className: MobileNavigationCss.roleDashboardContainer
                })
          ]
        }) : null;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("ul", {
                        children: [
                          JsxRuntime.jsx("div", {
                                children: "Personal",
                                className: MobileNavigationCss.submenuTitle
                              }),
                          JsxRuntime.jsxs("ul", {
                                children: [
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_User.Personal.Dashboard.cloudRack,
                                              className: MobileNavigationCss.groupLinkSubItem,
                                              children: "CloudRack™"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_Project.index,
                                              className: MobileNavigationCss.groupLinkSubItem,
                                              children: "Projects"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_User.Personal.Dashboard.orders,
                                              className: MobileNavigationCss.groupLinkSubItem,
                                              children: "Orders"
                                            })
                                      }),
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_User.Personal.Dashboard.invitations,
                                              className: MobileNavigationCss.groupLinkSubItem,
                                              children: "Invitations"
                                            })
                                      }),
                                  tmp,
                                  JsxRuntime.jsx("li", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_User.Personal.Dashboard.edit,
                                              className: MobileNavigationCss.groupLinkSubItem,
                                              children: "Edit Profile"
                                            })
                                      })
                                ],
                                className: MobileNavigationCss.roleDashboardContainer
                              }),
                          tmp$1,
                          tmp$2
                        ]
                      }))
            });
}

var Css;

var make = MobileSignedInNav;

export {
  Css ,
  make ,
}
/* A Not a pure module */
