// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as User from "../../../models/User.res.js";

function exec(userId, pageNum, sortBy, query) {
  var page = User.Dashboard.Show.AgencyAgents.page(pageNum);
  var query$1 = User.Dashboard.Show.AgencyAgents.query(query);
  var sortBy$1 = User.Dashboard.Show.AgencyAgents.sortBy(sortBy);
  return Rest.$$fetch("/dashboard/users/" + ID.toString(userId) + "/agency_agents?" + page + query$1 + sortBy$1, "Get", {
              NAME: "Json",
              VAL: User.Dashboard.Show.AgencyAgents.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
