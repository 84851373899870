// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Project from "../../../models/Project.res.js";

function exec(query) {
  var query$1 = Project.Dashboard.Index.query(query);
  return Rest.$$fetch("/dashboard/projects/project_concierges?" + query$1, "Get", {
              NAME: "Json",
              VAL: Project.Dashboard.ProjectConcierge.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
