// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var title = Cx.cx([
      "text-white",
      "text-lg",
      "uppercase"
    ]);

export {
  title ,
}
/* title Not a pure module */
