// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";

function exec(providerId, pageNum, sortBy) {
  var page = Provider.Edit.ProviderTsd.Index.page(pageNum);
  var sortBy$1 = Provider.Edit.ProviderTsd.Index.sortBy(sortBy);
  return Rest.$$fetch("/dashboard/providers/" + ID.toString(providerId) + "/provider_tsds?" + page + sortBy$1, "Get", {
              NAME: "Json",
              VAL: Provider.Edit.ProviderTsd.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
