// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var icon = Cx.cx([
      "absolute",
      "top-0",
      "left-0",
      "bottom-0",
      "right-0",
      "z-10",
      "flex",
      "items-center",
      "justify-center",
      "w-[44px]",
      "border-r",
      "border-solid",
      "border-gray-200"
    ]);

var input = Cx.cx(["!pl-[58px]"]);

var wrapper = "relative";

export {
  wrapper ,
  icon ,
  input ,
}
/* icon Not a pure module */
