// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "overflow-x-auto",
      "no-scrollbar"
    ]);

export {
  wrapper ,
}
/* wrapper Not a pure module */
