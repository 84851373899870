// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardTabsCss from "../../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardLeadShowDetailsHeader from "./components/DashboardLeadShowDetailsHeader.res.js";
import * as DashboardLeadShowDetailsCoreData from "./components/DashboardLeadShowDetailsCoreData.res.js";
import * as DashboardLeadShowDetailsPipedriveData from "./components/DashboardLeadShowDetailsPipedriveData.res.js";
import * as DashboardLeadShowDetailsSourceLeadData from "./components/DashboardLeadShowDetailsSourceLeadData.res.js";

function DashboardLeadShowDetails(props) {
  var lead = props.lead;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardLeadShowDetailsHeader.make, {
                      lead: lead
                    }),
                JsxRuntime.jsx(DashboardLeadShowDetailsCoreData.make, {
                      lead: lead
                    }),
                JsxRuntime.jsx(DashboardLeadShowDetailsPipedriveData.make, {
                      lead: lead
                    }),
                JsxRuntime.jsx(DashboardLeadShowDetailsSourceLeadData.make, {
                      lead: lead
                    })
              ],
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var $$Highlight;

var make = DashboardLeadShowDetails;

export {
  Css ,
  $$Highlight ,
  make ,
}
/* DashboardTabsCss Not a pure module */
