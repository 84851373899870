// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "basis-full",
      "lg:basis-1/4",
      "flex-grow-0",
      "flex-shrink-0",
      "shadow-md",
      "lg:shadow-lg",
      "transition-all ease-in-out",
      "data-[closed]:opacity-0",
      "data-[enter]:duration-300 data-[enter]:data-[closed]:-translate-x-full",
      "data-[leave]:duration-300 data-[leave]:data-[closed]:-translate-x-full"
    ]);

var header = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "bg-blue-100",
      "p-6"
    ]);

var title = Cx.cx([
      "flex",
      "flex-row",
      "justify-between",
      "text-lg",
      "text-gray-700",
      "font-medium"
    ]);

var description = Cx.cx([
      "text-sm",
      "text-gray-700"
    ]);

var searchContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "w-full"
    ]);

var searchBody = Cx.cx([
      "flex",
      "flex-col",
      "p-4",
      "bg-white",
      "drop-shadow-md",
      "rounded",
      "w-full",
      "max-h-48",
      "overflow-scroll",
      "z-[1000]"
    ]);

var searchItem = Cx.cx([
      "flex",
      "flex-col",
      "text-xs",
      "border-b",
      "border-gray-100",
      "last:border-none",
      "py-2",
      "cursor-pointer"
    ]);

var ownerNameContainer = Cx.cx([
      "flex",
      "justify-between"
    ]);

var ownerName = Cx.cx(["font-semibold"]);

var selectedOwners = Cx.cx([
      "flex",
      "flex-wrap",
      "gap-2"
    ]);

var container$1 = Cx.cx([
      "flex",
      "gap-2",
      "justify-between",
      "lg:items-center",
      "lg:flex-col",
      "lg:justify-start",
      "p-6",
      "shadow-md",
      "lg:shadow-lg"
    ]);

var icon = Cx.cx([
      "size-6",
      "text-gray-700",
      "hover:text-purple",
      "cursor-pointer"
    ]);

var filtersCount = Cx.cx([
      "text-gray-300",
      "font-semibold"
    ]);

var Icon = {
  container: container$1,
  icon: icon,
  filtersCount: filtersCount
};

var container$2 = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "p-6",
      "border-b",
      "border-gray-200",
      "last:border-none"
    ]);

var titleContainer = Cx.cx([
      "flex",
      "flex-row",
      "justify-between",
      "items-center"
    ]);

var title$1 = Cx.cx([
      "font-medium",
      "text-gray-700",
      "text-lg"
    ]);

var chevron = Cx.cx([
      "size-4",
      "cursor-pointer"
    ]);

var close = Cx.cx([
      "cursor-pointer",
      "size-6"
    ]);

var Section = {
  container: container$2,
  titleContainer: titleContainer,
  title: title$1,
  dropdown: "text-gray-300",
  chevron: chevron,
  close: close
};

var container$3 = Cx.cx([
      "flex",
      "flex-row",
      "gap-2",
      "items-center"
    ]);

var Checkbox = {
  container: container$3
};

var ownerEmail = "text-gray";

var selectedOwner = "cursor-pointer";

export {
  container ,
  header ,
  title ,
  description ,
  searchContainer ,
  searchBody ,
  searchItem ,
  ownerNameContainer ,
  ownerName ,
  ownerEmail ,
  selectedOwners ,
  selectedOwner ,
  Icon ,
  Section ,
  Checkbox ,
}
/* container Not a pure module */
