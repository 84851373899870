// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var badge = Cx.cx([
      "inline-flex",
      "items-center",
      "justify-center",
      "rounded",
      "px-3",
      "py-1",
      "text-xs",
      "text-white"
    ]);

var draftBadge = Cx.cx([
      badge,
      "bg-purple"
    ]);

var expiredBadge = Cx.cx([
      badge,
      "bg-gray-lighter"
    ]);

var archivedBadge = Cx.cx([
      badge,
      "bg-gray-blue"
    ]);

export {
  badge ,
  draftBadge ,
  expiredBadge ,
  archivedBadge ,
}
/* badge Not a pure module */
