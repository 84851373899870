// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../styleguide/components/Link/A.res.js";
import * as Badge from "../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Table from "../../../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Api_Tsd from "../../../../../../api/tsds/Api_Tsd.res.js";
import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../../../styleguide/components/Table/TableRow.res.js";
import * as TableBody from "../../../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../../../styleguide/icons/IconSorting.res.js";
import * as Routes_User from "../../../../../../routes/common/Routes_User.res.js";
import * as SearchField from "../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Agency from "../../../../../../routes/common/Routes_Agency.res.js";
import * as TableHeaderCell from "../../../../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardTabsCss from "../../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as DashboardIndexCss from "../../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardExportModal from "../../../../../../styleguide/dashboard/components/modals/export-csv/DashboardExportModal.res.js";
import * as DashboardTsdShowAgencyAgentEdit from "./DashboardTsdShowAgencyAgentEdit.res.js";
import * as DashboardTsdShowAgencyAgentsOptions from "./DashboardTsdShowAgencyAgentsOptions.res.js";

var initialState = {
  status: "FetchingAgencyAgents",
  currentPage: 1,
  totalPages: 1,
  sortBy: {
    NAME: "LastName",
    VAL: "Asc"
  },
  search: "",
  editAgentModelOpen: false,
  agencyAgent: undefined,
  csvModalOpen: false
};

function DashboardTsdShowAgencyAgents(props) {
  var tsd = props.tsd;
  var container = React.useRef(null);
  var searchAgencyAgents = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchAgencyAgents" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_Tsd.Dashboard.Show.AgencyAgent.index(tsd.id, state.currentPage, state.sortBy, tmp), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedAgencyAgentsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardTsdShowAgencyAgents",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "DashboardTsdShowAgencyAgents.make"
                                          }, "Dashboard::FetchAgencyAgents::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailAgencyAgentsFetch");
                                    }));
                            })
                        };
              case "FailAgencyAgentsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedAgencyAgentsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            editAgentModelOpen: state.editAgentModelOpen,
                            agencyAgent: state.agencyAgent,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "ToggleCsvModal" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            editAgentModelOpen: state.editAgentModelOpen,
                            agencyAgent: state.agencyAgent,
                            csvModalOpen: !state.csvModalOpen
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedAgencyAgentsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.agencyAgents
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            editAgentModelOpen: state.editAgentModelOpen,
                            agencyAgent: state.agencyAgent,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingAgencyAgents") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingAgencyAgents",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              editAgentModelOpen: state.editAgentModelOpen,
                              agencyAgent: state.agencyAgent,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchAgencyAgents");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingAgencyAgents") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "Email") {
                    var match$2 = state.sortBy;
                    tmp = typeof match$2 === "object" && match$2.NAME === "Email" && match$2.VAL === "Desc" ? ({
                          NAME: "Email",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Email",
                          VAL: "Desc"
                        });
                  } else if (sort === "AgencyName") {
                    var match$3 = state.sortBy;
                    tmp = typeof match$3 === "object" && match$3.NAME === "AgencyName" && match$3.VAL === "Desc" ? ({
                          NAME: "AgencyName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "AgencyName",
                          VAL: "Desc"
                        });
                  } else if (sort === "FirstName") {
                    var match$4 = state.sortBy;
                    tmp = typeof match$4 === "object" && match$4.NAME === "FirstName" && match$4.VAL === "Desc" ? ({
                          NAME: "FirstName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "FirstName",
                          VAL: "Desc"
                        });
                  } else if (sort === "IsPrimary") {
                    var match$5 = state.sortBy;
                    tmp = typeof match$5 === "object" && match$5.NAME === "IsPrimary" && match$5.VAL === "Desc" ? ({
                          NAME: "IsPrimary",
                          VAL: "Asc"
                        }) : ({
                          NAME: "IsPrimary",
                          VAL: "Desc"
                        });
                  } else if (sort === "LastName") {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "LastName" && match$6.VAL === "Desc" ? ({
                          NAME: "LastName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "LastName",
                          VAL: "Desc"
                        });
                  } else {
                    var match$7 = state.sortBy;
                    tmp = typeof match$7 === "object" && match$7.NAME === "Active" && match$7.VAL === "Desc" ? ({
                          NAME: "Active",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Active",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingAgencyAgents",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            editAgentModelOpen: state.editAgentModelOpen,
                            agencyAgent: state.agencyAgent,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              param.dispatch("FetchAgencyAgents");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            editAgentModelOpen: state.editAgentModelOpen,
                            agencyAgent: state.agencyAgent,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: searchAgencyAgents
                        };
              case "PerformSearch" :
                  var match$8 = state.status;
                  if (typeof match$8 !== "object" && match$8 === "FetchingAgencyAgents") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingAgencyAgents",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              editAgentModelOpen: state.editAgentModelOpen,
                              agencyAgent: state.agencyAgent,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchAgencyAgents");
                              })
                          };
                  }
              case "ToggleEditAgentModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            editAgentModelOpen: !state.editAgentModelOpen,
                            agencyAgent: action._0,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchAgencyAgents");
        }), []);
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var match$6 = state.sortBy;
  var match$7 = state.sortBy;
  var agencyAgents = state.status;
  var tmp$1;
  if (typeof agencyAgents !== "object") {
    tmp$1 = agencyAgents === "FetchingAgencyAgents" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardIndexCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search."
                })
          });
  } else {
    var agencyAgents$1 = agencyAgents._0;
    tmp$1 = agencyAgents$1.length !== 0 ? Belt_Array.mapWithIndex(agencyAgents$1, (function (index, agencyAgent) {
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_User.Dashboard.edit(agencyAgent.userId),
                                        children: agencyAgent.userFirstName
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_User.Dashboard.edit(agencyAgent.userId),
                                        children: agencyAgent.userLastName
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_User.Dashboard.edit(agencyAgent.userId),
                                        children: agencyAgent.userEmail
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(A.make, {
                                        href: Routes_Agency.Dashboard.edit(agencyAgent.agencyId),
                                        children: agencyAgent.agencyName
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: agencyAgent.active ? JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Green",
                                          children: "Active"
                                        }) : JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Gray",
                                          children: "Inactive"
                                        })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: agencyAgent.isPrimary ? JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Blue",
                                          children: "Primary"
                                        }) : JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Gray",
                                          children: "Secondary"
                                        })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsx(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardIndexCss.dropdownBody,
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            className: DashboardIndexCss.dropdownBodyRow,
                                                            children: JsxRuntime.jsx("a", {
                                                                  children: "Edit",
                                                                  className: DashboardIndexCss.dropdownBodyLink,
                                                                  href: "#",
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "ToggleEditAgentModalOpen",
                                                                            _0: agencyAgent
                                                                          });
                                                                    })
                                                                })
                                                          })
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No agency agents were found."
                })
          });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(SearchField.make, {
                              id: "agency-agents-search",
                              value: state.search,
                              placeholder: "Search by Agent Name, Agency or Email",
                              inputClassName: DashboardIndexCss.searchField,
                              onChange: (function ($$event) {
                                  dispatch({
                                        TAG: "UpdateSearchInput",
                                        _0: $$event.target.value
                                      });
                                })
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(DashboardTsdShowAgencyAgentsOptions.make, {
                                    tsdId: tsd.id,
                                    pageNum: state.currentPage,
                                    query: tmp,
                                    sortBy: state.sortBy,
                                    onClick: (function (param) {
                                        dispatch("ToggleCsvModal");
                                      })
                                  })
                            })
                      ],
                      className: DashboardTabsCss.Tab.searchContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(Table.make, {
                              children: [
                                JsxRuntime.jsx(TableHeader.make, {
                                      children: JsxRuntime.jsxs(TableRow.make, {
                                            children: [
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "FirstName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "First Name",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$2 === "object" && match$2.NAME === "FirstName" ? match$2.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "LastName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Last Name",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$3 === "object" && match$3.NAME === "LastName" ? match$3.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Email"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Email",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$4 === "object" && match$4.NAME === "Email" ? match$4.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "AgencyName"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Agency",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$5 === "object" && match$5.NAME === "AgencyName" ? match$5.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "Active"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Active?",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$6 === "object" && match$6.NAME === "Active" ? match$6.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch({
                                                                    TAG: "SortBy",
                                                                    _0: "IsPrimary"
                                                                  });
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Primary?",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$7 === "object" && match$7.NAME === "IsPrimary" ? match$7.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardIndexCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: ""
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(TableBody.make, {
                                      children: tmp$1
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: DashboardIndexCss.pagination
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    }),
                state.editAgentModelOpen ? JsxRuntime.jsx(DashboardTsdShowAgencyAgentEdit.make, {
                        tsd: tsd,
                        agencyAgent: Belt_Option.getExn(state.agencyAgent),
                        onClick: (function (param) {
                            dispatch({
                                  TAG: "ToggleEditAgentModalOpen",
                                  _0: undefined
                                });
                          })
                      }) : null,
                state.csvModalOpen ? JsxRuntime.jsx(DashboardExportModal.make, {
                        title: "Agents CSV Export",
                        message: "Please check your email for your agents export.",
                        buttonText: "Close",
                        onClick: (function (param) {
                            dispatch("ToggleCsvModal");
                          })
                      }) : null
              ],
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var CssIndex;

var make = DashboardTsdShowAgencyAgents;

export {
  Css ,
  CssIndex ,
  initialState ,
  make ,
}
/* A Not a pure module */
