// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Project from "../../../models/Project.res.js";

function exec(pageNum, sortBy, query, categories, statuses, userRoles, providerIds, conciergeIds, userIds) {
  var page = Project.Dashboard.Index.page(pageNum);
  var query$1 = Project.Dashboard.Index.query(query);
  var sortBy$1 = Project.Dashboard.Index.sortBy(sortBy);
  var categories$1 = Project.Dashboard.Index.categories(categories);
  var statuses$1 = Project.Dashboard.Index.statuses(statuses);
  var userIds$1 = Project.Dashboard.Index.userIds(userIds);
  var providerIds$1 = Project.Dashboard.Index.providerIds(providerIds);
  var conciergeIds$1 = Project.Dashboard.Index.conciergeIds(conciergeIds);
  var userRoles$1 = Project.Dashboard.Index.userRoles(userRoles);
  return Rest.$$fetch("/dashboard/projects?" + page + "&per_page=5" + query$1 + sortBy$1 + categories$1 + statuses$1 + userIds$1 + providerIds$1 + conciergeIds$1 + userRoles$1, "Get", {
              NAME: "Json",
              VAL: Project.Dashboard.Index.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
