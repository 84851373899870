// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "flex",
      "md:flex-col",
      "flex-row",
      "max-md:h-auto",
      "max-md:mb-8"
    ]);

var wrapperBlogCategory = Cx.cx([
      "flex",
      "flex-col",
      "md:w-[260px]",
      "w-full"
    ]);

var featuredImage = Cx.cx([
      "md:h-[215px]",
      "md:w-full",
      "object-cover",
      "bg-no-repeat",
      "bg-center",
      "rounded",
      "mb-5",
      "w-25",
      "h-19",
      "max-md:mr-5",
      "max-md:mb-0"
    ]);

var featuredImageBlogCategory = Cx.cx([
      "md:h-[195px]",
      "max:md:w-full",
      "max:md:mb-4",
      "max:md:mr-0"
    ]);

var details = Cx.cx([
      "flex",
      "flex-col",
      "max:sm:flex-col-reverse",
      "max-sm:justify-end"
    ]);

var detailsBlogCategory = Cx.cx([
      "flex",
      "flex-col",
      "max-sm:justify-start",
      "max-sm:max-w-full"
    ]);

var title = Cx.cx([
      "text-sm",
      "md:text-base",
      "font-semibold",
      "text-black",
      "mb-0"
    ]);

var titleBlogCategory = Cx.cx([
      "max:md:text-sm",
      "text-black",
      "mb-0"
    ]);

var date = Cx.cx([
      "text-gray-700",
      "text-sm",
      "mb-1"
    ]);

var body = Cx.cx(["hidden"]);

var bodyBlogCategory = Cx.cx(["max:sm:hidden"]);

export {
  wrapper ,
  wrapperBlogCategory ,
  featuredImage ,
  featuredImageBlogCategory ,
  details ,
  detailsBlogCategory ,
  title ,
  titleBlogCategory ,
  date ,
  body ,
  bodyBlogCategory ,
}
/* wrapper Not a pure module */
