// Generated by ReScript, PLEASE EDIT WITH CARE

import * as HorizontalScroll from "../../../../../../../styleguide/components/HorizontalScroll/HorizontalScroll.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronTitle from "../title/HomeJumbotronTitle.res.js";
import * as HomeJumbotronBoxesCss from "./HomeJumbotronBoxesCss.res.js";

function HomeJumbotronBoxes(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(HomeJumbotronTitle.make, {
                      title: props.title
                    }),
                JsxRuntime.jsx(HorizontalScroll.make, {
                      children: JsxRuntime.jsx("div", {
                            children: props.children,
                            className: HomeJumbotronBoxesCss.boxes
                          }),
                      className: HomeJumbotronBoxesCss.horizontalScroll
                    })
              ],
              className: HomeJumbotronBoxesCss.container
            });
}

var Css;

var make = HomeJumbotronBoxes;

export {
  Css ,
  make ,
}
/* HorizontalScroll Not a pure module */
