// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardTabsCss from "../../../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";

function DashboardLeadShowDetailsSourceLeadData(props) {
  var leadable = props.lead.leadable;
  if (leadable === undefined) {
    return null;
  }
  var x = leadable.providerName;
  var x$1 = leadable.providerName;
  var x$2 = leadable.providerName;
  var x$3 = leadable.providerName;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Source Lead Information",
                      className: DashboardTabsCss.Tab.label
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              background: "Gray",
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Provider",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x === "" ? "Not available" : leadable.providerName,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "City",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x$1 === "" ? "Not available" : leadable.cityName,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              background: "Gray",
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "State",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x$2 === "" ? "Not available" : leadable.stateName,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Country",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x$3 === "" ? "Not available" : leadable.countryName,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            })
                      ],
                      className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                    })
              ],
              className: DashboardTabsCss.Tab.Section.container
            });
}

var Css;

var $$Highlight;

var make = DashboardLeadShowDetailsSourceLeadData;

export {
  Css ,
  $$Highlight ,
  make ,
}
/* DashboardTabsCss Not a pure module */
