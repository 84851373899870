// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexContentCss from "../../../styles/common/LocationsIndexContentCss.res.js";
import * as LocationsIndexGlobalContent from "./LocationsIndexGlobalContent.res.js";
import * as LocationsIndexSidebarCallout from "../../../../common/locations-index-sidebar-callout/LocationsIndexSidebarCallout.res.js";
import * as LocationsIndexContentTopProviders from "./LocationsIndexContentTopProviders.res.js";

function LocationsIndexContentAbout(props) {
  var totalProviders = props.totalProviders;
  var totalLocations = props.totalLocations;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Colocation".toUpperCase(),
                                      className: LocationsIndexContentCss.serviceCategory
                                    }),
                                JsxRuntime.jsx("h2", {
                                      children: "About Global Data Centers Market",
                                      className: LocationsIndexContentCss.About.title
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "The Global Data Centers Market includes a total of\n            " + String(totalLocations) + " data center" + (
                                        totalLocations === 1 ? "" : "s"
                                      ) + " and\n            " + String(totalProviders) + " data center\n            provider" + (
                                        totalProviders === 1 ? "" : "s"
                                      ) + ".",
                                      className: LocationsIndexContentCss.About.subtitle
                                    })
                              ],
                              className: LocationsIndexContentCss.About.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(props.totalCountries),
                                              className: LocationsIndexContentCss.About.Stat.number
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Countries",
                                              className: LocationsIndexContentCss.About.Stat.name
                                            })
                                      ],
                                      className: LocationsIndexContentCss.About.Stat.container
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(props.totalCities),
                                              className: LocationsIndexContentCss.About.Stat.number
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Cities",
                                              className: LocationsIndexContentCss.About.Stat.name
                                            })
                                      ],
                                      className: LocationsIndexContentCss.About.Stat.container
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(totalLocations),
                                              className: LocationsIndexContentCss.About.Stat.number
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Data Centers",
                                              className: LocationsIndexContentCss.About.Stat.name
                                            })
                                      ],
                                      className: LocationsIndexContentCss.About.Stat.container
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(totalProviders),
                                              className: LocationsIndexContentCss.About.Stat.number
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Providers",
                                              className: LocationsIndexContentCss.About.Stat.name
                                            })
                                      ],
                                      className: LocationsIndexContentCss.About.Stat.container
                                    })
                              ],
                              className: LocationsIndexContentCss.About.stats
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(LocationsIndexGlobalContent.make, {}),
                              className: LocationsIndexContentCss.container
                            })
                      ],
                      className: LocationsIndexContentCss.About.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(LocationsIndexSidebarCallout.make, {
                              title: "Looking for Colocation? Our Experts are Ready to Help!",
                              buttonTitle: "Book a Call!"
                            }),
                        JsxRuntime.jsx(LocationsIndexContentTopProviders.make, {
                              topProviders: props.topProviders
                            })
                      ],
                      className: LocationsIndexContentCss.TopProviders.container
                    })
              ]
            });
}

var Css;

var make = LocationsIndexContentAbout;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
