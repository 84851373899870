// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-row-reverse",
      "flex-start",
      "justify-end",
      "gap-3",
      "items-center",
      "lg:flex-row"
    ]);

var reset = "size-6";

var resetDisabled = Cx.cx([
      reset,
      "text-gray-200"
    ]);

var resetEnabled = Cx.cx([
      reset,
      "text-gray",
      "hover:text-teal",
      "cursor-pointer"
    ]);

var field = "min-w-72";

export {
  container ,
  reset ,
  resetDisabled ,
  resetEnabled ,
  field ,
}
/* container Not a pure module */
