// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var adminLink = Cx.cx(["ml-auto"]);

var Nav;

export {
  Nav ,
  adminLink ,
}
/* adminLink Not a pure module */
