// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../Link/A.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ServiceSectionProviderBoxCss from "./ServiceSectionProviderBoxCss.res.js";

function ServiceSectionProviderBox(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            src: props.logo,
                            width: "160"
                          }),
                      className: ServiceSectionProviderBoxCss.logo
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: props.description,
                            className: ServiceSectionProviderBoxCss.description
                          })
                    }),
                JsxRuntime.jsx(A.make, {
                      href: props.link,
                      className: ServiceSectionProviderBoxCss.link,
                      children: "View Details"
                    })
              ],
              className: ServiceSectionProviderBoxCss.box
            });
}

var Css;

var make = ServiceSectionProviderBox;

export {
  Css ,
  make ,
}
/* A Not a pure module */
