// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Provider from "../../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexContentCss from "../../../styles/common/LocationsIndexContentCss.res.js";

function LocationsIndexContentTopProviders(props) {
  var topProviders = props.topProviders;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(H3.make, {
                      className: LocationsIndexContentCss.TopProviders.Sidebar.title,
                      children: [
                        JsxRuntime.jsx("span", {
                              children: "Global".toUpperCase(),
                              className: LocationsIndexContentCss.TopProviders.Sidebar.localityName
                            }),
                        JsxRuntime.jsx("br", {}),
                        "Top " + String(topProviders.length) + "\n        Colocation Providers"
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(topProviders, (function (provider) {
                              var match = provider.locationsCountActive;
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("img", {
                                                        className: LocationsIndexContentCss.TopProviders.Sidebar.Providers.Provider.image,
                                                        alt: provider.name,
                                                        src: provider.logo
                                                      }),
                                                  className: LocationsIndexContentCss.TopProviders.Sidebar.Providers.Provider.logo
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("a", {
                                                          children: provider.name,
                                                          className: LocationsIndexContentCss.TopProviders.Sidebar.Providers.Provider.name,
                                                          href: Routes_Provider.show(provider.slug)
                                                        }),
                                                    JsxRuntime.jsx("span", {
                                                          children: match !== 1 ? String(provider.locationsCountActive) + " locations" : "1 location",
                                                          className: LocationsIndexContentCss.TopProviders.Sidebar.Providers.Provider.totalLocations
                                                        })
                                                  ],
                                                  className: LocationsIndexContentCss.TopProviders.Sidebar.Providers.Provider.data
                                                })
                                          ],
                                          className: LocationsIndexContentCss.TopProviders.Sidebar.Providers.Provider.container
                                        }, provider.slug);
                            })),
                      className: LocationsIndexContentCss.TopProviders.Sidebar.Providers.container
                    })
              ],
              className: LocationsIndexContentCss.TopProviders.Sidebar.container
            });
}

var Css;

var make = LocationsIndexContentTopProviders;

export {
  Css ,
  make ,
}
/* H3 Not a pure module */
