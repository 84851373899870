// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "hidden",
      "sm:flex",
      "sm:justify-between",
      "sm:pt-7",
      "sm:px-7",
      "xl:px-32",
      "xl:justify-end"
    ]);

var buttons = Cx.cx([
      "isolate",
      "inline-flex",
      "rounded-md",
      "shadow-sm",
      "border",
      "border-gray-300"
    ]);

var container$1 = Cx.cx([
      "relative",
      "inline-flex",
      "items-center",
      "px-3",
      "py-2",
      "text-sm",
      "font-semibold",
      "ring-1",
      "ring-inset",
      "ring-gray-200",
      "hover:bg-gray-50",
      "focus:z-10"
    ]);

var left = Cx.cx([
      container$1,
      "rounded-l-md"
    ]);

var middle = Cx.cx([
      container$1,
      "-ml-px"
    ]);

var right = Cx.cx([
      container$1,
      "-ml-px",
      "rounded-r-md"
    ]);

var active = Cx.cx([
      "text-gray-900",
      "bg-gray-100"
    ]);

var inactive = Cx.cx([
      "text-gray",
      "bg-white"
    ]);

var Button = {
  container: container$1,
  left: left,
  middle: middle,
  right: right,
  active: active,
  inactive: inactive
};

export {
  container ,
  buttons ,
  Button ,
}
/* container Not a pure module */
