// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../../../../../libs/ID.res.js";
import * as Lead from "../../../../../../../models/Lead.res.js";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../../../../../styleguide/icons/IconSelect.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardDropdownOptionsCss from "../../../../../../../styleguide/dashboard/components/options/DashboardDropdownOptionsCss.res.js";

function DashboardLeadShowRelatedLeadsOptions(props) {
  var page = Lead.Dashboard.Index.page(props.pageNum);
  var query = Lead.Dashboard.Index.query(props.query);
  var sortBy = Lead.Dashboard.Index.sortBy(props.sortBy);
  var leadTypes = Lead.Dashboard.Index.leadTypes(props.leadTypes);
  var dates = Lead.Dashboard.Index.dates(props.startDate, props.endDate);
  var url = "/api/v1/dashboard/leads/" + ID.toString(props.leadId) + "/export_related_leads.csv?" + page + sortBy + query + leadTypes + dates;
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsx(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: DashboardDropdownOptionsCss.popoverBody,
                      children: JsxRuntime.jsxs(A.make, {
                            href: url,
                            className: DashboardDropdownOptionsCss.popoverBodyLink,
                            onClick: props.toggleCsvModal,
                            children: [
                              "Download CSV",
                              JsxRuntime.jsx(IconSelect.make, {
                                    size: "MD",
                                    color: "LightestGray",
                                    className: DashboardDropdownOptionsCss.popoverBodyLinkIcon
                                  })
                            ]
                          })
                    })
              ]
            });
}

var Css;

var make = DashboardLeadShowRelatedLeadsOptions;

export {
  Css ,
  make ,
}
/* A Not a pure module */
