// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as SearchField from "../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as DashboardIndexCss from "../../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Outline from "@heroicons/react/24/outline";
import * as DashboardProjectsIndexSearchCss from "../../styles/shared/DashboardProjectsIndexSearchCss.res.js";
import * as DashboardProjectsIndexProjectsCss from "../../styles/shared/DashboardProjectsIndexProjectsCss.res.js";

function DashboardProjectsIndexHeader(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      className: DashboardIndexCss.title,
                      children: "Manage Projects"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        props.resetDisabled ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Outline.ArrowPathIcon, {
                                      className: DashboardProjectsIndexSearchCss.resetDisabled
                                    }),
                                title: "Reset Filters"
                              }) : JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Outline.ArrowPathIcon, {
                                      className: DashboardProjectsIndexSearchCss.resetEnabled
                                    }),
                                title: "Reset Filters",
                                onClick: props.reset
                              }),
                        JsxRuntime.jsx(SearchField.make, {
                              id: "projects-search",
                              value: props.search,
                              placeholder: "Search projects",
                              inputClassName: DashboardProjectsIndexSearchCss.field,
                              onChange: props.onChange
                            })
                      ],
                      className: DashboardProjectsIndexSearchCss.container
                    })
              ],
              className: DashboardProjectsIndexProjectsCss.header
            });
}

var Css;

var ProjectsCss;

var make = DashboardProjectsIndexHeader;

export {
  Css ,
  ProjectsCss ,
  make ,
}
/* H1 Not a pure module */
