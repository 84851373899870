// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var badge = Cx.cx([
      "inline-flex",
      "gap-2",
      "items-center",
      "rounded-sm",
      "font-medium",
      "w-auto"
    ]);

var gray = Cx.cx([
      badge,
      "bg-gray-100",
      "text-gray-700"
    ]);

var red = Cx.cx([
      badge,
      "bg-red-100",
      "text-red-600"
    ]);

var green = Cx.cx([
      badge,
      "bg-green-100",
      "text-green-600"
    ]);

var blue = Cx.cx([
      badge,
      "bg-blue-100",
      "text-blue-700"
    ]);

var yellow = Cx.cx([
      badge,
      "bg-yellow-100",
      "text-yellow-900"
    ]);

var purple = Cx.cx([
      badge,
      "bg-purple-100",
      "text-purple-900"
    ]);

var xs = Cx.cx([
      "px-2 py-1",
      "text-xs"
    ]);

var small = Cx.cx([
      "px-2 py-1",
      "text-sm"
    ]);

var medium = Cx.cx([
      "px-3 py-2",
      "text-base"
    ]);

var large = Cx.cx([
      "px-4 py-3",
      "text-lg"
    ]);

var colocation = Cx.cx([
      badge,
      "bg-blue-100",
      "text-blue-700"
    ]);

var internet = Cx.cx([
      badge,
      "bg-red-100",
      "text-red-600"
    ]);

var cloud = Cx.cx([
      badge,
      "bg-green-100",
      "text-green-600"
    ]);

var network = Cx.cx([
      badge,
      "bg-teal-100",
      "text-teal-700"
    ]);

var bareMetal = Cx.cx([
      badge,
      "bg-purple-100",
      "text-purple-900"
    ]);

var $$default = Cx.cx([
      badge,
      "bg-gray-100",
      "text-gray-700"
    ]);

var Project = {
  colocation: colocation,
  internet: internet,
  cloud: cloud,
  network: network,
  bareMetal: bareMetal,
  $$default: $$default
};

var admin = Cx.cx([
      badge,
      "bg-blue-100",
      "text-blue-700"
    ]);

var agent = Cx.cx([
      badge,
      "bg-red-100",
      "text-red-600"
    ]);

var provider = Cx.cx([
      badge,
      "bg-green-100",
      "text-green-600"
    ]);

var user = Cx.cx([
      badge,
      "bg-teal-100",
      "text-teal-700"
    ]);

var concierge = Cx.cx([
      badge,
      "bg-purple-100",
      "text-purple-900"
    ]);

var visitor = Cx.cx([
      badge,
      "bg-gray-100",
      "text-gray-700"
    ]);

var Role = {
  admin: admin,
  agent: agent,
  provider: provider,
  user: user,
  concierge: concierge,
  visitor: visitor
};

var User = {
  Role: Role
};

var disabled = "opacity-25";

export {
  badge ,
  gray ,
  red ,
  green ,
  blue ,
  yellow ,
  purple ,
  xs ,
  small ,
  medium ,
  large ,
  disabled ,
  Project ,
  User ,
}
/* badge Not a pure module */
