// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var grid = Cx.cx([
      "m-0",
      "p-0",
      "w-full",
      "gap-2"
    ]);

var row = Cx.cx([
      "mb-8",
      "my-0"
    ]);

var fileSizeText = Cx.cx([
      "mr-12",
      "text-gray-700",
      "text-sm",
      "leading-5",
      "text-right"
    ]);

var fileNameText = Cx.cx([
      "text-black",
      "text-sm",
      "font-medium",
      "leading-5",
      "ml-2.5"
    ]);

var documentRow = Cx.cx([
      "py-3",
      "border-t",
      "border-b",
      "border-gray-300",
      "flex",
      "justify-between",
      "last:mb-2.5"
    ]);

var labelContainer = "pl-0";

var buttonCancelContainer = "pl-2";

var buttonCreateContainer = "pl-2";

var uploadDocumentButtonSvg = "mr-2.5";

var container = "flex";

var iconView = "mr-4";

var privateDownload = "mr-4";

var iconDelete = "cursor-pointer";

var flexContainer = "flex";

var editor = "mb-0";

export {
  grid ,
  row ,
  fileSizeText ,
  fileNameText ,
  labelContainer ,
  buttonCancelContainer ,
  buttonCreateContainer ,
  uploadDocumentButtonSvg ,
  container ,
  iconView ,
  privateDownload ,
  iconDelete ,
  flexContainer ,
  documentRow ,
  editor ,
}
/* grid Not a pure module */
