// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as BannerProviderIcons from "../../../../../styleguide/components/BannerProviderIcons/BannerProviderIcons.res.js";
import * as HomeProviderIconsCss from "./HomeProviderIconsCss.res.js";

function HomeProviderIcons(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(BannerProviderIcons.make, {
                    context: "Homepage"
                  }),
              className: HomeProviderIconsCss.icons
            });
}

var Css;

var make = HomeProviderIcons;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
