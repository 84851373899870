// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Api_Project from "../../../../api/projects/Api_Project.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as DashboardIndexCss from "../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardExportModal from "../../../../styleguide/dashboard/components/modals/export-csv/DashboardExportModal.res.js";
import * as DashboardProjectsIndexCss from "./styles/DashboardProjectsIndexCss.res.js";
import * as DashboardProjectsIndexHeader from "./components/header/DashboardProjectsIndexHeader.res.js";
import * as DashboardProjectsIndexFilters from "./components/filters/DashboardProjectsIndexFilters.res.js";
import * as DashboardProjectsIndexProjects from "./components/projects/DashboardProjectsIndexProjects.res.js";
import * as DashboardProjectsIndexSubheader from "./components/subheader/DashboardProjectsIndexSubheader.res.js";
import * as DashboardProjectsIndexFilterTags from "./components/filter-tags/DashboardProjectsIndexFilterTags.res.js";
import * as DashboardProjectsIndexProjectsCss from "./styles/shared/DashboardProjectsIndexProjectsCss.res.js";
import * as DashboardProjectsIndexEmptyProject from "./components/empty-project/DashboardProjectsIndexEmptyProject.res.js";
import * as DashboardProjectsIndexErrorProject from "./components/empty-project/DashboardProjectsIndexErrorProject.res.js";

var initialState_sortBy = {
  NAME: "CreatedAt",
  VAL: "Desc"
};

var initialState_categories = [];

var initialState_statuses = [];

var initialState_userRoles = [];

var initialState_userIds = [];

var initialState_providerIds = [];

var initialState_conciergeIds = [];

var initialState = {
  status: "FetchingProjects",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  csvProjectsOpen: false,
  csvProvidersProjectsOpen: false,
  filtersOpen: false,
  totalProjects: 0,
  resetDisabled: true,
  resetCounter: 0,
  categories: initialState_categories,
  statuses: initialState_statuses,
  userRoles: initialState_userRoles,
  userIds: initialState_userIds,
  providerIds: initialState_providerIds,
  conciergeIds: initialState_conciergeIds
};

function DashboardProjectsIndex$default(props) {
  var container = React.useRef(null);
  var searchProjects = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProjects" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_Project.Dashboard.index(state.currentPage, state.sortBy, tmp, state.categories, state.statuses, state.userRoles, state.providerIds, state.conciergeIds, state.userIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProjectsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProjectsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "DashboardProjectsIndex.default"
                                          }, "Dashboard::FetchProjects::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProjectsFetch");
                                    }));
                            })
                        };
              case "FailProjectsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedProjectsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          }
                        };
              case "ToggleProjectsCsvModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: !state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          }
                        };
              case "ToggleProvidersProjectsCsvModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: !state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          }
                        };
              case "ToggleFilters" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: !state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          }
                        };
              case "EnableReset" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: false,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProjects",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: {
                              NAME: "CreatedAt",
                              VAL: "Desc"
                            },
                            search: "",
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: true,
                            resetCounter: state.resetCounter + 1 | 0,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProjectsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.projects
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: res.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingProjects") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProjects",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvProjectsOpen: state.csvProjectsOpen,
                              csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                              filtersOpen: state.filtersOpen,
                              totalProjects: state.totalProjects,
                              resetDisabled: state.resetDisabled,
                              resetCounter: state.resetCounter,
                              categories: state.categories,
                              statuses: state.statuses,
                              userRoles: state.userRoles,
                              userIds: state.userIds,
                              providerIds: state.providerIds,
                              conciergeIds: state.conciergeIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProjects");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingProjects") {
                    return "NoUpdate";
                  }
                  var match$2 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProjects",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: match$2.VAL === "Desc" ? ({
                                  NAME: "CreatedAt",
                                  VAL: "Asc"
                                }) : ({
                                  NAME: "CreatedAt",
                                  VAL: "Desc"
                                }),
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: false,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: searchProjects
                        };
              case "PerformSearch" :
                  var match$3 = state.status;
                  if (typeof match$3 !== "object" && match$3 === "FetchingProjects") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProjects",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvProjectsOpen: state.csvProjectsOpen,
                              csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                              filtersOpen: state.filtersOpen,
                              totalProjects: state.totalProjects,
                              resetDisabled: state.resetDisabled,
                              resetCounter: state.resetCounter,
                              categories: state.categories,
                              statuses: state.statuses,
                              userRoles: state.userRoles,
                              userIds: state.userIds,
                              providerIds: state.providerIds,
                              conciergeIds: state.conciergeIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProjects");
                              })
                          };
                  }
              case "UpdateCategories" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: action._0,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              case "UpdateStatuses" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: action._0,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              case "UpdateUserRoles" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: action._0,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              case "UpdateUserIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: action._0,
                            providerIds: state.providerIds,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: action._0,
                            conciergeIds: state.conciergeIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              case "UpdateConciergeIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvProjectsOpen: state.csvProjectsOpen,
                            csvProvidersProjectsOpen: state.csvProvidersProjectsOpen,
                            filtersOpen: state.filtersOpen,
                            totalProjects: state.totalProjects,
                            resetDisabled: state.resetDisabled,
                            resetCounter: state.resetCounter,
                            categories: state.categories,
                            statuses: state.statuses,
                            userRoles: state.userRoles,
                            userIds: state.userIds,
                            providerIds: state.providerIds,
                            conciergeIds: action._0
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProjects");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchProjects");
        }), []);
  var projects = state.status;
  var tmp;
  tmp = typeof projects !== "object" ? (
      projects === "FetchingProjects" ? JsxRuntime.jsx(DashboardProjectsIndexEmptyProject.make, {
              emptyType: "Searching",
              reset: (function (param) {
                  dispatch("Reset");
                })
            }) : JsxRuntime.jsx(DashboardProjectsIndexErrorProject.make, {
              reset: (function (param) {
                  dispatch("Reset");
                })
            })
    ) : JsxRuntime.jsx(DashboardProjectsIndexProjects.make, {
          projects: projects._0,
          reset: (function (param) {
              dispatch("Reset");
            })
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardProjectsIndexFilters.make, {
                      filtersOpen: state.filtersOpen,
                      resetCounter: state.resetCounter,
                      enableReset: (function () {
                          dispatch("EnableReset");
                        }),
                      updateStatuses: (function (statuses) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateStatuses",
                                  _0: statuses
                                });
                          };
                        }),
                      updateCategories: (function (categories) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateCategories",
                                  _0: categories
                                });
                          };
                        }),
                      updateUserRoles: (function (userRoles) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateUserRoles",
                                  _0: userRoles
                                });
                          };
                        }),
                      updateOwnerIds: (function (ownerIds) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateUserIds",
                                  _0: ownerIds
                                });
                          };
                        }),
                      updateProviderIds: (function (providerIds) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateProviderIds",
                                  _0: providerIds
                                });
                          };
                        }),
                      updateConciergeIds: (function (conciergeIds) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateConciergeIds",
                                  _0: conciergeIds
                                });
                          };
                        }),
                      onClick: (function (param) {
                          dispatch("ToggleFilters");
                        })
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(DashboardProjectsIndexFilterTags.make, {
                              categories: state.categories,
                              statuses: state.statuses,
                              userRoles: state.userRoles
                            }),
                        JsxRuntime.jsx(DashboardProjectsIndexHeader.make, {
                              search: state.search,
                              resetDisabled: state.resetDisabled,
                              reset: (function (param) {
                                  dispatch("Reset");
                                }),
                              onChange: (function ($$event) {
                                  dispatch({
                                        TAG: "UpdateSearchInput",
                                        _0: $$event.target.value
                                      });
                                })
                            }),
                        JsxRuntime.jsx(DashboardProjectsIndexSubheader.make, {
                              sortBy: state.sortBy,
                              query: state.search,
                              categories: state.categories,
                              statuses: state.statuses,
                              providerIds: state.providerIds,
                              conciergeIds: state.conciergeIds,
                              userIds: state.userIds,
                              totalProjects: state.totalProjects,
                              sort: (function (param) {
                                  dispatch({
                                        TAG: "SortBy",
                                        _0: "CreatedAt"
                                      });
                                }),
                              exportByProject: (function (param) {
                                  dispatch("ToggleProjectsCsvModalOpen");
                                }),
                              exportByProvidersProject: (function (param) {
                                  dispatch("ToggleProvidersProjectsCsvModalOpen");
                                })
                            }),
                        tmp,
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (x) {
                                        dispatch({
                                              TAG: "UpdatePage",
                                              _0: x
                                            });
                                      })
                                  }),
                              className: DashboardIndexCss.pagination
                            })
                      ],
                      className: DashboardProjectsIndexProjectsCss.container
                    }),
                state.csvProjectsOpen ? JsxRuntime.jsx(DashboardExportModal.make, {
                        title: "Export by Project CSV",
                        message: "Please check your email for your projects export.",
                        buttonText: "Close",
                        onClick: (function (param) {
                            dispatch("ToggleProjectsCsvModalOpen");
                          })
                      }) : null,
                state.csvProvidersProjectsOpen ? JsxRuntime.jsx(DashboardExportModal.make, {
                        title: "Export by Providers CSV",
                        message: "Please check your email for your projects export.",
                        buttonText: "Close",
                        onClick: (function (param) {
                            dispatch("ToggleProvidersProjectsCsvModalOpen");
                          })
                      }) : null
              ],
              className: DashboardProjectsIndexCss.container
            });
}

var Css;

var ProjectsCss;

var $$default = DashboardProjectsIndex$default;

export {
  Css ,
  ProjectsCss ,
  initialState ,
  $$default as default,
}
/* Hooks Not a pure module */
