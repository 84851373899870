// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Pdf from "./Pdf.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          description: js.description,
          contract: js.contract,
          contractStart: js.contractStart,
          contractResource: Pdf.Resource.fromJs(js.contractResource),
          contractDownload: js.contractDownload,
          contractAttached: js.contractAttached,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              contract: field.required("contract", Json_Decode$JsonCombinators.string),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              contractResource: field.required("contractResource", Pdf.Resource.decoder),
              contractDownload: field.required("contractDownload", Json_Decode$JsonCombinators.string),
              contractAttached: field.required("contractAttached", Json_Decode$JsonCombinators.bool),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          active: js.active,
          contractStart: js.contractStart,
          contractResource: Pdf.Resource.fromJs(js.contractResource),
          contractDownload: js.contractDownload,
          contractAttached: js.contractAttached,
          tsdId: Belt_Option.map(Caml_option.nullable_to_opt(js.tsdId), (function (prim) {
                  return prim;
                })),
          tsdName: Caml_option.nullable_to_opt(js.tsdName),
          createdAt: js.createdAt
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              contractResource: field.required("contractResource", Pdf.Resource.decoder),
              contractDownload: field.required("contractDownload", Json_Decode$JsonCombinators.string),
              contractAttached: field.required("contractAttached", Json_Decode$JsonCombinators.bool),
              tsdId: field.required("tsdId", Json_Decode$JsonCombinators.option(ID.decoder)),
              tsdName: field.required("tsdName", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Edit = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          contractStart: js.contractStart,
          tsdId: Belt_Option.map(Caml_option.nullable_to_opt(js.tsdId), (function (prim) {
                  return prim;
                })),
          tsdName: Caml_option.nullable_to_opt(js.tsdName),
          createdAt: js.createdAt
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              tsdId: field.required("tsdId", Json_Decode$JsonCombinators.option(ID.decoder)),
              tsdName: field.required("tsdName", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

function fromJs$3(js) {
  return {
          id: js.id,
          name: js.name
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

function fromJs$4(js) {
  return {
          tsds: Belt_Array.map(js.tsds, fromJs$3)
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              tsds: field.required("tsds", Json_Decode$JsonCombinators.array(decoder$3))
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var Index = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  fromJson: fromJson$4
};

var Select = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$3,
  Index: Index
};

var Tsd = {
  Select: Select
};

var Dashboard = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2,
  Tsd: Tsd
};

function fromJs$5(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          active: js.active,
          contractStart: js.contractStart,
          contractResource: Pdf.Resource.fromJs(js.contractResource),
          contractDownload: js.contractDownload,
          contractAttached: js.contractAttached,
          tsdId: Belt_Option.map(Caml_option.nullable_to_opt(js.tsdId), (function (prim) {
                  return prim;
                })),
          tsdName: Caml_option.nullable_to_opt(js.tsdName),
          totalAgents: js.totalAgents,
          createdAt: js.createdAt
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              contractResource: field.required("contractResource", Pdf.Resource.decoder),
              contractDownload: field.required("contractDownload", Json_Decode$JsonCombinators.string),
              contractAttached: field.required("contractAttached", Json_Decode$JsonCombinators.bool),
              tsdId: field.required("tsdId", Json_Decode$JsonCombinators.option(ID.decoder)),
              tsdName: field.required("tsdName", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              totalAgents: field.required("totalAgents", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

function fromJs$6(js) {
  return {
          id: js.id,
          userId: js.userId,
          userFirstName: js.userFirstName,
          userLastName: js.userLastName,
          userEmail: js.userEmail,
          agencyId: js.agencyId,
          agencyName: js.agencyName,
          active: js.active,
          isPrimary: js.isPrimary
        };
}

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              userId: field.required("userId", ID.decoder),
              userFirstName: field.required("userFirstName", Json_Decode$JsonCombinators.string),
              userLastName: field.required("userLastName", Json_Decode$JsonCombinators.string),
              userEmail: field.required("userEmail", Json_Decode$JsonCombinators.string),
              agencyId: field.required("agencyId", ID.decoder),
              agencyName: field.required("agencyName", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              isPrimary: field.required("isPrimary", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var AgencyAgent = {
  fromJs: fromJs$6,
  decoder: decoder$6,
  fromJson: fromJson$6
};

function fromJs$7(js) {
  return {
          agencyId: js.agencyId,
          agencyAgents: Belt_Array.map(js.agencyAgents, fromJs$6),
          totalAgencyAgents: js.totalAgencyAgents,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              agencyId: field.required("agencyId", ID.decoder),
              agencyAgents: field.required("agencyAgents", Json_Decode$JsonCombinators.array(decoder$6)),
              totalAgencyAgents: field.required("totalAgencyAgents", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

var AgencyAgents = {
  fromJs: fromJs$7,
  decoder: decoder$7
};

var Show = {
  fromJs: fromJs$5,
  decoder: decoder$5,
  fromJson: fromJson$5,
  AgencyAgent: AgencyAgent,
  AgencyAgents: AgencyAgents
};

var Pdf$1;

export {
  fromJs ,
  decoder ,
  fromJson ,
  Edit ,
  Dashboard ,
  Show ,
  Pdf$1 as Pdf,
}
/* decoder Not a pure module */
