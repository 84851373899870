// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../../../../models/Role.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../../styleguide/components/Pagination/Pagination.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexPreloadedSearchLocation from "./components/LocationsIndexPreloadedSearchLocation.res.js";
import * as LocationsIndexPreloadedSearchLocationsCss from "../../styles/common/LocationsIndexPreloadedSearchLocationsCss.res.js";

function LocationsIndexPreloadedSearchLocations(props) {
  var index = props.index;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(index.preloadedSearchLocations, (function (idx, preloadedSearchLocation) {
                              return JsxRuntime.jsx(LocationsIndexPreloadedSearchLocation.make, {
                                          preloadedSearchLocation: preloadedSearchLocation,
                                          userRole: Role.fromString(index.userRole)
                                        }, String(idx));
                            })),
                      className: LocationsIndexPreloadedSearchLocationsCss.Locations.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Pagination.make, {
                              currentPage: index.currentPage,
                              totalPages: index.totalPages,
                              onPageClick: props.updatePage
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Showing " + String(index.offset + 1 | 0) + "\n          to " + String(index.offset + index.preloadedSearchLocations.length | 0) + "\n          of " + String(index.totalPreloadedSearchLocations),
                              className: ""
                            })
                      ],
                      className: LocationsIndexPreloadedSearchLocationsCss.Pagination.container
                    })
              ],
              className: LocationsIndexPreloadedSearchLocationsCss.container
            });
}

var Css;

var make = LocationsIndexPreloadedSearchLocations;

export {
  Css ,
  make ,
}
/* Role Not a pure module */
