// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchLeadsDashboardRequest from "./requests/FetchLeadsDashboardRequest.res.js";
import * as FetchLeadDashboardShowUserLeadsRequest from "./requests/FetchLeadDashboardShowUserLeadsRequest.res.js";

var Lead = {
  index: FetchLeadDashboardShowUserLeadsRequest.exec
};

var User = {
  Lead: Lead
};

var Show = {
  User: User
};

var Dashboard = {
  index: FetchLeadsDashboardRequest.exec,
  Show: Show
};

export {
  Dashboard ,
}
/* FetchLeadsDashboardRequest Not a pure module */
