// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "relative",
      "text-center",
      "flex",
      "flex-col",
      "items-center",
      "py-20"
    ]);

var heading = Cx.cx([
      "text-white",
      "mb-7",
      "px-12",
      "sm:px-0"
    ]);

var icon = Cx.cx(["h-12"]);

var subheading = Cx.cx([
      "text-white",
      "md:text-2xl",
      "md:mb-14",
      "lg:px-[280px]",
      "px-7",
      "text-base",
      "mb-10"
    ]);

var providerIcons = Cx.cx([
      "mt-10",
      "mb-0",
      "hidden",
      "md:block"
    ]);

export {
  wrapper ,
  heading ,
  icon ,
  subheading ,
  providerIcons ,
}
/* wrapper Not a pure module */
