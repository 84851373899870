// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexContentCss from "../../styles/common/LocationsIndexContentCss.res.js";
import * as LocationsIndexContentAbout from "./components/LocationsIndexContentAbout.res.js";

function LocationsIndexContentNew(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(LocationsIndexContentAbout.make, {
                    totalLocations: props.totalLocations,
                    totalProviders: props.totalProviders,
                    totalCountries: props.totalCountries,
                    totalCities: props.totalCities,
                    topProviders: props.topProviders
                  }),
              className: LocationsIndexContentCss.locationsContentContainer
            });
}

var Css;

var make = LocationsIndexContentNew;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
