// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as $$Date from "../../../../../../../libs/Date.res.js";
import * as Badge from "../../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Project from "../../../../../../../models/Project.res.js";
import * as Routes_Project from "../../../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProjectsIndexProjectCss from "../../../styles/shared/DashboardProjectsIndexProjectCss.res.js";
import * as DashboardProjectsIndexProjectUsers from "./project/components/DashboardProjectsIndexProjectUsers.res.js";
import * as DashboardProjectsIndexProjectStatusBar from "./project/components/DashboardProjectsIndexProjectStatusBar.res.js";

function DashboardProjectsIndexProject(props) {
  var project = props.project;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardProjectsIndexProjectStatusBar.make, {
                      project: project
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Estimated Project Dates",
                                              className: DashboardProjectsIndexProjectCss.dateRange
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: $$Date.Helpers.formatMonDayYear(project.startDate) + "\n              - " + $$Date.Helpers.formatMonDayYear(project.endDate),
                                              className: DashboardProjectsIndexProjectCss.estimatedDates
                                            })
                                      ],
                                      className: DashboardProjectsIndexProjectCss.people
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Badge.Project.make, {
                                                    category: project.category,
                                                    children: Project.ProjectType.toLabel(project.category)
                                                  })
                                            }),
                                        JsxRuntime.jsx(A.make, {
                                              href: Routes_Project.showHashid(project.hashid),
                                              className: DashboardProjectsIndexProjectCss.title,
                                              children: project.businessName + ": " + project.title
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Created: " + $$Date.Helpers.formatMonDayYear(project.createdAt) + "\n              • Updated: " + $$Date.Helpers.formatMonDayYear(project.updatedAt),
                                              className: DashboardProjectsIndexProjectCss.createUpdate
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: project.description,
                                              className: DashboardProjectsIndexProjectCss.description
                                            })
                                      ],
                                      className: DashboardProjectsIndexProjectCss.details
                                    })
                              ],
                              className: DashboardProjectsIndexProjectCss.leftContainer
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("label", {
                                                    children: "Total Propsals:",
                                                    className: DashboardProjectsIndexProjectCss.statLabel
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(project.proposalsCount)
                                                  })
                                            ],
                                            className: DashboardProjectsIndexProjectCss.stat
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("label", {
                                                    children: "Total Providers:",
                                                    className: DashboardProjectsIndexProjectCss.statLabel
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(project.providersProjectsCount)
                                                  })
                                            ],
                                            className: DashboardProjectsIndexProjectCss.stat
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("label", {
                                                    children: "Requested:",
                                                    className: DashboardProjectsIndexProjectCss.statLabel
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(project.providersProjectsRegistrationRequestedCount)
                                                  })
                                            ],
                                            className: DashboardProjectsIndexProjectCss.stat
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("label", {
                                                    children: "Registered:",
                                                    className: DashboardProjectsIndexProjectCss.statLabel
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(project.providersProjectsRegisteredCount)
                                                  })
                                            ],
                                            className: DashboardProjectsIndexProjectCss.stat
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("label", {
                                                    children: "Denied:",
                                                    className: DashboardProjectsIndexProjectCss.statLabel
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(project.providersProjectsRegistrationDeniedCount)
                                                  })
                                            ],
                                            className: DashboardProjectsIndexProjectCss.stat
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("label", {
                                                    children: "Other:",
                                                    className: DashboardProjectsIndexProjectCss.statLabel
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: String(project.providersProjectsRegistrationOtherCount)
                                                  })
                                            ],
                                            className: DashboardProjectsIndexProjectCss.stat
                                          })
                                    ],
                                    className: DashboardProjectsIndexProjectCss.stats
                                  }),
                              className: DashboardProjectsIndexProjectCss.rightContainer
                            })
                      ],
                      className: DashboardProjectsIndexProjectCss.project
                    }),
                JsxRuntime.jsx(DashboardProjectsIndexProjectUsers.make, {
                      owner: project.owner,
                      conciergesCount: project.concierges.length,
                      agent: project.agent
                    })
              ],
              className: Cx.cx([
                    DashboardProjectsIndexProjectCss.container,
                    DashboardProjectsIndexProjectCss.Status.border(project.status)
                  ])
            });
}

var Css;

var make = DashboardProjectsIndexProject;

export {
  Css ,
  make ,
}
/* A Not a pure module */
