// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateAgencyAgentDashboardRequest from "./requests/CreateAgencyAgentDashboardRequest.res.js";
import * as FetchAgencyAgentsDashboardRequest from "./requests/FetchAgencyAgentsDashboardRequest.res.js";
import * as UpdateAgencyAgentDashboardRequest from "./requests/UpdateAgencyAgentDashboardRequest.res.js";
import * as FetchAgencyAgentsDashboardUsersFilterRequest from "./requests/FetchAgencyAgentsDashboardUsersFilterRequest.res.js";
import * as FetchAgencyAgentsDashboardAgenciesFilterRequest from "./requests/FetchAgencyAgentsDashboardAgenciesFilterRequest.res.js";

var Dashboard = {
  index: FetchAgencyAgentsDashboardRequest.exec,
  create: CreateAgencyAgentDashboardRequest.exec,
  update: UpdateAgencyAgentDashboardRequest.exec,
  usersFilter: FetchAgencyAgentsDashboardUsersFilterRequest.exec,
  agenciesFilter: FetchAgencyAgentsDashboardAgenciesFilterRequest.exec
};

export {
  Dashboard ,
}
/* CreateAgencyAgentDashboardRequest Not a pure module */
