// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexMapModal from "./components/map-modal/LocationsIndexMapModal.res.js";
import * as LocationsIndexViewButtonsCss from "../../styles/common/LocationsIndexViewButtonsCss.res.js";

var initialState = {
  mapModalOpen: false
};

function reducer(state, action) {
  return {
          mapModalOpen: !state.mapModalOpen
        };
}

function LocationsIndexViewButtonsNew(props) {
  var updateView = props.updateView;
  var view = props.view;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("span", {
                      children: [
                        JsxRuntime.jsx("button", {
                              children: "Grid",
                              className: Cx.cx([
                                    LocationsIndexViewButtonsCss.Button.left,
                                    view === "Grid" ? LocationsIndexViewButtonsCss.Button.active : LocationsIndexViewButtonsCss.Button.inactive
                                  ]),
                              onClick: (function (param) {
                                  if (view !== "Grid") {
                                    return updateView("Grid");
                                  }
                                  
                                })
                            }),
                        JsxRuntime.jsx("button", {
                              children: "On Map",
                              className: Cx.cx([
                                    LocationsIndexViewButtonsCss.Button.right,
                                    view === "Map" ? LocationsIndexViewButtonsCss.Button.active : LocationsIndexViewButtonsCss.Button.inactive
                                  ]),
                              onClick: (function (param) {
                                  if (view !== "Map") {
                                    updateView("Map");
                                  }
                                  dispatch("ToggleMapModal");
                                })
                            })
                      ],
                      className: LocationsIndexViewButtonsCss.buttons
                    }),
                match[0].mapModalOpen ? JsxRuntime.jsx(LocationsIndexMapModal.make, {
                        onClick: (function (param) {
                            dispatch("ToggleMapModal");
                          })
                      }) : null
              ],
              className: LocationsIndexViewButtonsCss.container
            });
}

var Css;

var make = LocationsIndexViewButtonsNew;

export {
  Css ,
  initialState ,
  reducer ,
  make ,
}
/* react Not a pure module */
