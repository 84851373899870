// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../../../../../models/Role.res.js";
import * as Badge from "../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Project from "../../../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProjectsIndexProjectsCss from "../../styles/shared/DashboardProjectsIndexProjectsCss.res.js";

function DashboardProjectsIndexFilterTags(props) {
  var userRoles = props.userRoles;
  var statuses = props.statuses;
  var categories = props.categories;
  return JsxRuntime.jsxs("div", {
              children: [
                categories.length !== 0 ? Belt_Array.mapWithIndex(categories, (function (index, category) {
                          return JsxRuntime.jsx(Badge.Span.make, {
                                      color: "Gray",
                                      children: Project.ProjectType.toLabel(Project.ProjectType.fromString(category))
                                    }, String(index));
                        })) : JsxRuntime.jsx(Badge.Span.make, {
                        color: "Gray",
                        children: "All Project Categories"
                      }),
                statuses.length !== 0 ? Belt_Array.mapWithIndex(statuses, (function (index, status) {
                          return JsxRuntime.jsx(Badge.Span.make, {
                                      color: "Blue",
                                      children: Project.Status.toString(Project.Status.fromString(status))
                                    }, String(index));
                        })) : JsxRuntime.jsx(Badge.Span.make, {
                        color: "Blue",
                        children: "All Project Statuses"
                      }),
                userRoles.length !== 0 ? Belt_Array.mapWithIndex(userRoles, (function (index, role) {
                          return JsxRuntime.jsx(Badge.Span.make, {
                                      color: "Purple",
                                      children: Role.toCapital(Role.fromString(role))
                                    }, String(index));
                        })) : JsxRuntime.jsx(Badge.Span.make, {
                        color: "Purple",
                        children: "All User Roles"
                      })
              ],
              className: DashboardProjectsIndexProjectsCss.Tags.container
            });
}

var Css;

var make = DashboardProjectsIndexFilterTags;

export {
  Css ,
  make ,
}
/* Role Not a pure module */
