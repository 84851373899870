// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var box = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "justify-between",
      "gap-2.5",
      "w-[280px]",
      "rounded",
      "py-10",
      "px-5",
      "bg-white"
    ]);

var logo = Cx.cx([
      "flex",
      "flex-col",
      "justify-center",
      "min-h-[60px]"
    ]);

var description = Cx.cx([
      "text-sm",
      "text-gray-700"
    ]);

var link = Cx.cx([
      "font-medium",
      "text-sm",
      "hover:no-underline",
      "hover:text-purple"
    ]);

export {
  box ,
  logo ,
  description ,
  link ,
}
/* box Not a pure module */
