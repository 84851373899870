// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var approvalStatusContainer = Cx.cx([
      "flex",
      "items-center",
      "mr-auto",
      "shrink-0"
    ]);

var approvalTextContainer = Cx.cx([
      "flex",
      "flex-col",
      "md:text-right",
      "text-start",
      "text-gray-700",
      "md:mr-2.5",
      "mr-0",
      "text-sm"
    ]);

var approvedText = Cx.cx([
      "font-semibold",
      "text-teal"
    ]);

var pendingText = Cx.cx([
      "font-semibold",
      "text-yellow"
    ]);

var errorText = Cx.cx([
      "font-semibold",
      "text-red"
    ]);

var approvalIcon = "mr-2.5";

export {
  approvalStatusContainer ,
  approvalTextContainer ,
  approvedText ,
  pendingText ,
  errorText ,
  approvalIcon ,
}
/* approvalStatusContainer Not a pure module */
