// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Cloudinary from "../../../../libs/Cloudinary.res.js";
import * as BlogPostTileCss from "./BlogPostTileCss.res.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as LazyBackgroundImage from "../../../../styleguide/components/LazyBackgroundImage/LazyBackgroundImage.res.js";

function cleanText(text) {
  return Js_string.replaceByRe(/(<([^>]+)>)|(&.{1,6};)/ig, "", text);
}

function buildUrl(src) {
  return Cloudinary.Url.build("Auto", {
              hd: "Dpr",
              tl: {
                hd: "Quality",
                tl: {
                  hd: {
                    TAG: "FetchFormat",
                    _0: "Auto"
                  },
                  tl: {
                    hd: {
                      TAG: "Height",
                      _0: 300
                    },
                    tl: /* [] */0
                  }
                }
              }
            }, src);
}

var Helpers = {
  cleanText: cleanText,
  buildUrl: buildUrl
};

function BlogPostTile(props) {
  var __deferImage = props.deferImage;
  var __pageType = props.pageType;
  var blogPost = props.blogPost;
  var pageType = __pageType !== undefined ? __pageType : "Home";
  var deferImage = __deferImage !== undefined ? __deferImage : false;
  var tmp;
  if (deferImage) {
    var tmp$1;
    tmp$1 = pageType === "Home" ? Cx.cx([
            BlogPostTileCss.featuredImage,
            "lozad"
          ]) : Cx.cx([
            BlogPostTileCss.featuredImageBlogCategory,
            "lozad"
          ]);
    tmp = JsxRuntime.jsx(LazyBackgroundImage.make, {
          src: buildUrl(blogPost.featuredImage),
          height: 300,
          className: tmp$1
        });
  } else {
    var tmp$2;
    tmp$2 = pageType === "Home" ? BlogPostTileCss.featuredImage : BlogPostTileCss.featuredImageBlogCategory;
    tmp = JsxRuntime.jsx("div", {
          className: tmp$2,
          style: {
            backgroundImage: "url(" + (buildUrl(blogPost.featuredImage) + ")")
          }
        });
  }
  var tmp$3;
  tmp$3 = pageType === "Home" ? BlogPostTileCss.title : BlogPostTileCss.titleBlogCategory;
  var tmp$4;
  tmp$4 = pageType === "Home" ? BlogPostTileCss.body : Cx.cx([
          BlogPostTileCss.body,
          BlogPostTileCss.bodyBlogCategory
        ]);
  var tmp$5;
  tmp$5 = pageType === "Home" ? BlogPostTileCss.details : BlogPostTileCss.detailsBlogCategory;
  var tmp$6;
  tmp$6 = pageType === "Home" ? BlogPostTileCss.wrapper : BlogPostTileCss.wrapperBlogCategory;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(A.make, {
                      href: blogPost.url,
                      children: tmp
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: Format(blogPost.publishedAt, "d MMM yyyy"),
                              className: BlogPostTileCss.date
                            }),
                        JsxRuntime.jsxs(A.make, {
                              href: blogPost.url,
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: blogPost.title,
                                      className: tmp$3
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: cleanText(blogPost.body),
                                      className: tmp$4
                                    })
                              ]
                            })
                      ],
                      className: tmp$5
                    })
              ],
              className: tmp$6
            });
}

var Css;

var make = BlogPostTile;

export {
  Css ,
  Helpers ,
  make ,
}
/* A Not a pure module */
