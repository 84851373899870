// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Control from "../../../../../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../../../../../styleguide/components/Popover/Popover.res.js";
import * as Dropdown from "../../../../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Routes_Project from "../../../../../../../../../routes/common/Routes_Project.res.js";
import * as DashboardIndexCss from "../../../../../../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Solid from "@heroicons/react/24/solid";
import * as DashboardProjectsIndexProjectCss from "../../../../../styles/shared/DashboardProjectsIndexProjectCss.res.js";

function DashboardProjectsIndexProjectStatusBar(props) {
  var project = props.project;
  var match = project.status;
  var tmp;
  switch (match) {
    case "Draft" :
        tmp = "Project is still in draft.";
        break;
    case "Active" :
        tmp = "Project is currently active.";
        break;
    case "Inactive" :
        tmp = "Project is no longer active.";
        break;
    case "Finalized" :
        tmp = "Project has been finalized!";
        break;
    case "Review" :
        tmp = "Project requires review before activation.";
        break;
    
  }
  var url = project.pipedriveLeadUrl;
  var url$1 = project.pipedriveDealUrl;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs(Popover.make, {
                            children: [
                              JsxRuntime.jsx(Popover.Trigger.make, {
                                    children: JsxRuntime.jsx(Control.make, {
                                          children: JsxRuntime.jsx(Solid.EllipsisHorizontalCircleIcon, {
                                                className: "size-6"
                                              })
                                        })
                                  }),
                              JsxRuntime.jsxs(Dropdown.Body.make, {
                                    position: {
                                      TAG: "Below",
                                      _0: "RightEdge"
                                    },
                                    className: DashboardIndexCss.dropdownBody,
                                    children: [
                                      JsxRuntime.jsx(Control.make, {
                                            className: DashboardIndexCss.dropdownBodyRow,
                                            children: JsxRuntime.jsx("a", {
                                                  children: "View",
                                                  className: DashboardIndexCss.dropdownBodyLink,
                                                  href: Routes_Project.Dashboard.show(project.hashid)
                                                })
                                          }),
                                      JsxRuntime.jsx(Control.make, {
                                            className: DashboardIndexCss.dropdownBodyRow,
                                            children: JsxRuntime.jsx("a", {
                                                  children: "Edit",
                                                  className: DashboardIndexCss.dropdownBodyLink,
                                                  href: Routes_Project.Dashboard.edit(project.hashid)
                                                })
                                          }),
                                      JsxRuntime.jsx(Control.make, {
                                            className: DashboardIndexCss.dropdownBodyRow,
                                            children: JsxRuntime.jsx("a", {
                                                  children: "Send Proposal",
                                                  className: DashboardIndexCss.dropdownBodyLink,
                                                  href: Routes_Project.Dashboard.proposals(project.hashid)
                                                })
                                          }),
                                      JsxRuntime.jsx(Control.make, {
                                            className: DashboardIndexCss.dropdownBodyRow,
                                            children: url !== undefined && url !== "" ? JsxRuntime.jsx("a", {
                                                    children: "Pipedrive Lead",
                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                    href: url
                                                  }) : null
                                          }),
                                      JsxRuntime.jsx(Control.make, {
                                            className: DashboardIndexCss.dropdownBodyRow,
                                            children: url$1 !== undefined && url$1 !== "" ? JsxRuntime.jsx("a", {
                                                    children: "Pipedrive Deal",
                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                    href: url$1
                                                  }) : null
                                          })
                                    ]
                                  })
                            ]
                          }),
                      className: DashboardProjectsIndexProjectCss.Status.dropdownContainer
                    })
              ],
              className: Cx.cx([
                    DashboardProjectsIndexProjectCss.Status.container,
                    DashboardProjectsIndexProjectCss.Status.statusToClass(project.status)
                  ])
            });
}

var Css;

var make = DashboardProjectsIndexProjectStatusBar;

export {
  Css ,
  make ,
}
/* Control Not a pure module */
