// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as JumbotronEnzuCss from "./JumbotronEnzuCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ScheduleModalServices from "../../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as UnderscoreFancyHeader from "../../../../../../styleguide/components/UnderscoreFancy/UnderscoreFancyHeader.res.js";
import * as JumbotronProviderIcons from "../../../../../../styleguide/components/JumbotronProviderIcons/JumbotronProviderIcons.res.js";
import LogoEnzuNamePng from "Images/companies/logo-enzu-name.png";

function JumbotronEnzu(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            alt: "Enzu Logo",
                            src: LogoEnzuNamePng
                          })
                    }),
                JsxRuntime.jsx(H1.make, {
                      className: JumbotronEnzuCss.heading,
                      children: "Enzu IaaS Bare Metal Cloud Servers"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(UnderscoreFancyHeader.make, {
                            children: "Get 1 Month Free!",
                            color: "Teal"
                          }),
                      className: JumbotronEnzuCss.subheading
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Clear",
                            ghost: true,
                            expanded: true,
                            onClick: (function (_event) {
                                setIsScheduleDemoModalOpen(function (param) {
                                      return true;
                                    });
                              }),
                            children: "Schedule a Demo"
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(JumbotronProviderIcons.make, {
                            context: "Enzu"
                          }),
                      className: JumbotronEnzuCss.providerIcons
                    }),
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule an Enzu Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about Enzu bare metal solutions.",
                        buttonTitle: "Submit",
                        leadType: "EnzuBareMetal"
                      }) : null
              ],
              className: JumbotronEnzuCss.wrapper
            });
}

var Css;

var make = JumbotronEnzu;

export {
  Css ,
  make ,
}
/* H1 Not a pure module */
