// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProjectsIndexProject from "./components/DashboardProjectsIndexProject.res.js";
import * as DashboardProjectsIndexProjectsCss from "../../styles/shared/DashboardProjectsIndexProjectsCss.res.js";
import * as DashboardProjectsIndexEmptyProject from "../empty-project/DashboardProjectsIndexEmptyProject.res.js";

function DashboardProjectsIndexProjects(props) {
  var projects = props.projects;
  var x = projects.length;
  return JsxRuntime.jsx("div", {
              children: x === 0 ? JsxRuntime.jsx(DashboardProjectsIndexEmptyProject.make, {
                      reset: props.reset
                    }) : JsxRuntime.jsx(JsxRuntime.Fragment, {
                      children: Caml_option.some(Belt_Array.mapWithIndex(projects, (function (index, project) {
                                  return JsxRuntime.jsx(DashboardProjectsIndexProject.make, {
                                              project: project
                                            }, String(index));
                                })))
                    }),
              className: DashboardProjectsIndexProjectsCss.projects
            });
}

var Css;

var make = DashboardProjectsIndexProjects;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
