// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var testimonials = Cx.cx([
      "grid",
      "gap-10",
      "items-center",
      "lg:grid-cols-3",
      "md:grid-cols-2",
      "grid-cols-1",
      "max:sm:w-full",
      "md:text-left",
      "text-center",
      "md:p-20",
      "p-10"
    ]);

export {
  testimonials ,
}
/* testimonials Not a pure module */
