// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var separator = Cx.cx([
      "flex",
      "flex-1",
      "h-[1px]",
      "max-w-[100%]",
      "bg-gray-300"
    ]);

var separatorWithTitle = Cx.cx([
      "flex",
      "flex-row",
      "justify-center",
      "items-center"
    ]);

var separatorBox = Cx.cx([
      "text-gray",
      "font-medium",
      "text-sm",
      "uppercase",
      "tracking-wider",
      "border",
      "text-center",
      "border-solid",
      "border-gray-300",
      "rounded-lg",
      "px-7",
      "py-1.5"
    ]);

export {
  separator ,
  separatorWithTitle ,
  separatorBox ,
}
/* separator Not a pure module */
