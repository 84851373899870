// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../../styleguide/components/Link/Link.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_User from "../../../../../routes/common/Routes_User.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UsersDashboardNavbarScss from "./UsersDashboardNavbar.scss";

var css = UsersDashboardNavbarScss;

function UsersDashboardNavbar(props) {
  var id = props.id;
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_User.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Users"
                                }),
                            className: Cx.cx([
                                  css.linkContainer,
                                  selectedCategory === "Users" ? css.underlinedLink : ""
                                ])
                          }),
                      selectedCategory === "Show" ? (
                          id !== undefined ? JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Link.make, {
                                        href: Routes_User.Dashboard.show(Caml_option.valFromOption(id)),
                                        className: css.linkItem,
                                        children: "View User"
                                      }),
                                  className: Cx.cx([
                                        css.linkContainer,
                                        css.underlinedLink
                                      ])
                                }) : null
                        ) : (
                          selectedCategory === "User" && id !== undefined ? JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(Link.make, {
                                        href: Routes_User.Dashboard.edit(Caml_option.valFromOption(id)),
                                        className: css.linkItem,
                                        children: "Edit User"
                                      }),
                                  className: Cx.cx([
                                        css.linkContainer,
                                        css.underlinedLink
                                      ])
                                }) : null
                        )
                    ],
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = UsersDashboardNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
