// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var barWrapper = Cx.cx([
      "w-full",
      "bg-white",
      "shadow-[0_2px_3px_-1px_rgba(0,0,0,0.25)]",
      "relative",
      "px-2.5"
    ]);

var barContainer = Cx.cx([
      "flex",
      "no-scrollbar",
      "justify-start",
      "items-center",
      "mx-auto",
      "overflow-x-hidden",
      "overflow-y-hidden",
      "max-md:px-7",
      "max-sm:px-4",
      "max-md:overflow-x-auto",
      "lg:max-w-screen-lg"
    ]);

var linkItem = Cx.cx([
      "whitespace-nowrap",
      "text-sm",
      "text-gray-700",
      "font-medium",
      "line-height-5",
      "block",
      "py-4",
      "hover:text-black",
      "hover:no-underline",
      "group-[.underlinedLink]:text-black",
      "group-[.underlinedLink]:no-underline"
    ]);

var underlinedLink = Cx.cx([
      "border-b-4",
      "border-solid",
      "border-purple",
      "hover:text-purple",
      "hover:no-underline",
      "group",
      "underlinedLink"
    ]);

var linkContainer = "mr-6";

export {
  barWrapper ,
  barContainer ,
  linkContainer ,
  linkItem ,
  underlinedLink ,
}
/* barWrapper Not a pure module */
