// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../../libs/Date.res.js";
import * as Role from "../../../../../models/Role.res.js";
import * as Badge from "../../../../../styleguide/components/Badge/Badge.res.js";
import * as DashboardTabsCss from "../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";

function DashboardUserShowDetails(props) {
  var user = props.user;
  var match = user.description;
  var tmp = match === "" ? "No description available." : user.description;
  var match$1 = user.company;
  var tmp$1 = match$1 === "" ? "Not Provider" : user.company;
  var match$2 = user.jobTitle;
  var tmp$2 = match$2 === "" ? "Not Provided" : user.jobTitle;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Created: " + $$Date.Helpers.formatMonthDayYear(user.createdAt)
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "•"
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Last Updated: " + $$Date.Helpers.formatMonthDayYear(user.updatedAt)
                                    })
                              ],
                              className: DashboardTabsCss.Tab.Section.dateDetails
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                user.approved ? JsxRuntime.jsx(Badge.Span.make, {
                                        color: "Green",
                                        children: "Approved"
                                      }) : JsxRuntime.jsx(Badge.Span.make, {
                                        color: "Red",
                                        children: "Blocked"
                                      }),
                                user.apiEnabled ? JsxRuntime.jsx(Badge.Span.make, {
                                        color: "Blue",
                                        children: "API Enabled"
                                      }) : null,
                                user.superAdmin ? JsxRuntime.jsx(Badge.Span.make, {
                                        color: "Yellow",
                                        children: "Super Admin"
                                      }) : null,
                                user.editUsers ? JsxRuntime.jsx(Badge.Span.make, {
                                        color: "Purple",
                                        children: "Edit Users"
                                      }) : null,
                                !user.apiEnabled && !user.superAdmin && !user.editUsers ? JsxRuntime.jsx(Badge.Span.make, {
                                        color: "Gray",
                                        children: "No Enhanced Permissions"
                                      }) : null
                              ],
                              className: DashboardTabsCss.Tab.tags
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "About",
                                      className: DashboardTabsCss.Tab.label
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: tmp,
                                      className: DashboardTabsCss.Tab.Section.p
                                    })
                              ],
                              className: DashboardTabsCss.Tab.Section.description
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "User Details",
                              className: DashboardTabsCss.Tab.label
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Gray",
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Email",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: user.email,
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Phone",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: user.dialCode + " " + user.phone,
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Gray",
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Role",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: Role.toCapital(Role.fromString(user.role)),
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Company",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$1,
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Gray",
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Job Title",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$2,
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    })
                              ],
                              className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    })
              ],
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var $$Highlight;

var make = DashboardUserShowDetails;

export {
  Css ,
  $$Highlight ,
  make ,
}
/* Date Not a pure module */
