// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-6",
      "basis-0",
      "flex-grow",
      "flex-shrink-0",
      "py-6",
      "px-10"
    ]);

var categories = Cx.cx([
      "flex",
      "gap-2"
    ]);

var header = Cx.cx([
      "flex",
      "flex-col",
      "gap-2",
      "lg:flex-row",
      "lg:justify-between",
      "lg:items-center"
    ]);

var projects = Cx.cx([
      "flex",
      "flex-col",
      "gap-6"
    ]);

var container$1 = Cx.cx([
      "flex",
      "flex-wrap",
      "gap-2"
    ]);

var Tags = {
  container: container$1
};

export {
  container ,
  categories ,
  header ,
  projects ,
  Tags ,
}
/* container Not a pure module */
