// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../../../libs/ID.res.js";
import * as Label from "../../../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Control from "../../../../../../../../styleguide/components/Control/Control.res.js";
import * as $$Promise from "../../../../../../../../bindings/Promise.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Textarea from "../../../../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as IconDelete from "../../../../../../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../../../../../../loggers/SentryLogger.res.js";
import * as ProviderFeature from "../../../../../../../../models/ProviderFeature.res.js";
import * as FieldWithCounter from "../../../../../../../../styleguide/forms/FieldWithCounter/FieldWithCounter.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderFeature from "../../../../../../../../api/provider-features/Api_ProviderFeature.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as ProviderEditContainersCss from "../../../../common/ProviderEditContainersCss.res.js";

var validators_requestedName = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.requestedName.length;
      if (x !== 0) {
        if (x < 3) {
          return {
                  TAG: "Error",
                  _0: "Name must be longer than 3 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.requestedName
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Name is required."
              };
      }
    })
};

var validators_requestedDescription = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.requestedDescription.length;
      if (x !== 0) {
        if (x < 10) {
          return {
                  TAG: "Error",
                  _0: "Description must be longer than 10 characters."
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: input.requestedDescription
                };
        }
      } else {
        return {
                TAG: "Error",
                _0: "Description is required."
              };
      }
    })
};

var validators = {
  requestedName: validators_requestedName,
  requestedDescription: validators_requestedDescription
};

function initialFieldsStatuses(_input) {
  return {
          requestedName: "Pristine",
          requestedDescription: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            requestedName: "Pristine",
            requestedDescription: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.requestedName;
  var tmp;
  tmp = typeof match !== "object" ? validators.requestedName.validate(input) : match._0;
  var match$1 = fieldsStatuses.requestedDescription;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.requestedDescription.validate(input) : match$1._0;
  var requestedNameResult = tmp;
  var requestedNameResult$1;
  if (requestedNameResult.TAG === "Ok") {
    var requestedDescriptionResult = tmp$1;
    if (requestedDescriptionResult.TAG === "Ok") {
      return {
              TAG: "Valid",
              output: {
                requestedName: requestedNameResult._0,
                requestedDescription: requestedDescriptionResult._0
              },
              fieldsStatuses: {
                requestedName: {
                  TAG: "Dirty",
                  _0: requestedNameResult,
                  _1: "Shown"
                },
                requestedDescription: {
                  TAG: "Dirty",
                  _0: requestedDescriptionResult,
                  _1: "Shown"
                }
              },
              collectionsStatuses: undefined
            };
    }
    requestedNameResult$1 = requestedNameResult;
  } else {
    requestedNameResult$1 = requestedNameResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            requestedName: {
              TAG: "Dirty",
              _0: requestedNameResult$1,
              _1: "Shown"
            },
            requestedDescription: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurRequestedNameField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.requestedName, validators_requestedName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  requestedName: status,
                                  requestedDescription: init.requestedDescription
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurRequestedDescriptionField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.requestedDescription, validators_requestedDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  requestedName: init.requestedName,
                                  requestedDescription: status
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateRequestedNameField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.requestedName, state.submissionStatus, validators_requestedName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            requestedName: status,
                                            requestedDescription: init.requestedDescription
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateRequestedDescriptionField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.requestedDescription, state.submissionStatus, validators_requestedDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            requestedName: init.requestedName,
                                            requestedDescription: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                requestedName: "Pristine",
                                requestedDescription: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                requestedName: "Pristine",
                                requestedDescription: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateRequestedName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateRequestedNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateRequestedDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateRequestedDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurRequestedName: (function () {
              dispatch("BlurRequestedNameField");
            }),
          blurRequestedDescription: (function () {
              dispatch("BlurRequestedDescriptionField");
            }),
          requestedNameResult: Formality.exposeFieldResult(state.fieldsStatuses.requestedName),
          requestedDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.requestedDescription),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.requestedName;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.requestedDescription;
              if (typeof tmp$1 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var FeatureForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditFeatureForm(props) {
  var updateTimeout = props.updateTimeout;
  var updateProvider = props.updateProvider;
  var feature = props.feature;
  var live = props.live;
  var initialInput = React.useMemo((function () {
          return {
                  requestedName: feature.requestedName,
                  requestedDescription: feature.requestedDescription
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_ProviderFeature.update({
                    id: feature.id,
                    name: feature.name,
                    description: feature.description,
                    requestedName: output.requestedName,
                    requestedDescription: output.requestedDescription,
                    approved: feature.approved
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditFeatureForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditFeatureForm.make"
                              }, "UpdateLocationDraft::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  var res = x._0;
                  updateProvider(res);
                  var id = feature.id;
                  var feature$1 = Belt_Option.getExn(Js_array.find((function (f) {
                              return Caml_obj.equal(id, f.id);
                            }), res.features));
                  cb.notifyOnSuccess({
                        requestedName: feature$1.requestedName,
                        requestedDescription: feature$1.requestedDescription
                      });
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  children: field._0
                });
    } else {
      return null;
    }
  };
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: String(props.index + 1 | 0) + ".",
                                        className: ProviderEditContainersCss.featureCounter
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Control.make, {
                                              className: ProviderEditContainersCss.featureDeleteIconControl,
                                              onClick: (function (param) {
                                                  $$Promise.wait(Api_ProviderFeature.destroy({
                                                            id: feature.id
                                                          }), (function (x) {
                                                          if (x.TAG === "Ok") {
                                                            return updateProvider(x._0);
                                                          } else {
                                                            return SentryLogger.error1({
                                                                        rootModule: "ProviderEditFeatureForm",
                                                                        subModulePath: /* [] */0,
                                                                        value: "make",
                                                                        fullPath: "ProviderEditFeatureForm.make"
                                                                      }, "ProviderEditFeatures::Destroy::Error", [
                                                                        "Error",
                                                                        x._0
                                                                      ]);
                                                          }
                                                        }));
                                                }),
                                              children: JsxRuntime.jsx(IconDelete.make, {
                                                    size: "LG",
                                                    color: "LightGray"
                                                  })
                                            }),
                                        className: ProviderEditContainersCss.featureDeleteIcon
                                      })
                                ],
                                className: ProviderEditContainersCss.wrapperFeatureFields
                              }),
                          JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(FieldWithCounter.make, {
                                              value: feature.name,
                                              limit: ProviderFeature.nameMaxLength,
                                              label: JsxRuntime.jsx(Label.make, {
                                                    forId: "provider-form--feature--" + (ID.toString(feature.id) + "--name"),
                                                    children: "Title"
                                                  }),
                                              field: JsxRuntime.jsx(TextField.make, {
                                                    id: "provider-form--feature--" + (ID.toString(feature.id) + "--name"),
                                                    value: live === "Live" ? feature.name : form.input.requestedName,
                                                    disabled: live === "Live" || form.submitting ? true : false,
                                                    className: form.input.requestedName === feature.name ? "" : ProviderEditContainersCss.fieldChanged,
                                                    onChange: (function ($$event) {
                                                        var value = $$event.target.value;
                                                        form.updateRequestedName((function (input, value) {
                                                                return {
                                                                        requestedName: value,
                                                                        requestedDescription: input.requestedDescription
                                                                      };
                                                              }), value);
                                                        updateTimeout(setTimeout((function () {
                                                                    form.submit();
                                                                  }), 900));
                                                      })
                                                  })
                                            }),
                                        fieldError(form.requestedNameResult)
                                      ]
                                    })
                              }),
                          JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(FieldWithCounter.make, {
                                              value: feature.description,
                                              limit: ProviderFeature.descriptionMaxLength,
                                              label: JsxRuntime.jsx(Label.make, {
                                                    forId: "provider-form--feature--" + (ID.toString(feature.id) + "--description"),
                                                    children: "Description"
                                                  }),
                                              field: JsxRuntime.jsx(Textarea.make, {
                                                    id: "provider-form--feature--" + (ID.toString(feature.id) + "--description"),
                                                    value: live === "Live" ? feature.description : form.input.requestedDescription,
                                                    rows: 3,
                                                    disabled: live === "Live" || form.submitting ? true : false,
                                                    className: form.input.requestedDescription === feature.description ? "" : ProviderEditContainersCss.fieldChanged,
                                                    onChange: (function ($$event) {
                                                        var value = $$event.target.value;
                                                        form.updateRequestedDescription((function (input, value) {
                                                                return {
                                                                        requestedName: input.requestedName,
                                                                        requestedDescription: value
                                                                      };
                                                              }), value);
                                                        updateTimeout(setTimeout((function () {
                                                                    form.submit();
                                                                  }), 900));
                                                      })
                                                  })
                                            }),
                                        fieldError(form.requestedDescriptionResult)
                                      ]
                                    })
                              })
                        ],
                        className: ProviderEditContainersCss.featureFields
                      }))
            });
}

var Css;

var make = ProviderEditFeatureForm;

export {
  Css ,
  FeatureForm ,
  make ,
}
/* ID Not a pure module */
