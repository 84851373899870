// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UpdateUserRequest from "./requests/UpdateUserRequest.res.js";
import * as FetchUserDashboardRequest from "./requests/FetchUserDashboardRequest.res.js";
import * as UserChangePasswordRequest from "./requests/UserChangePasswordRequest.res.js";
import * as UserForgotPasswordRequest from "./requests/UserForgotPasswordRequest.res.js";
import * as FetchUsersDashboardRequest from "./requests/FetchUsersDashboardRequest.res.js";
import * as ResendConfirmationEmailRequest from "../requests/ResendConfirmationEmailRequest.res.js";
import * as UpdateUserRoleDashboardRequest from "./requests/UpdateUserRoleDashboardRequest.res.js";
import * as UserDashboardUpdateUserRequest from "./requests/UserDashboardUpdateUserRequest.res.js";
import * as UpdateDailyLeadsDashboardRequest from "./requests/UpdateDailyLeadsDashboardRequest.res.js";
import * as UpdateUserApprovedDashboardRequest from "./requests/UpdateUserApprovedDashboardRequest.res.js";
import * as UpdateUserApiEnabledDashboardRequest from "./requests/UpdateUserApiEnabledDashboardRequest.res.js";
import * as CreateUserDashboardShowAgencyAgentRequest from "./requests/CreateUserDashboardShowAgencyAgentRequest.res.js";
import * as FetchUserDashboardShowAgencyAgentsRequest from "./requests/FetchUserDashboardShowAgencyAgentsRequest.res.js";
import * as UpdateUserDashboardShowAgencyAgentRequest from "./requests/UpdateUserDashboardShowAgencyAgentRequest.res.js";
import * as UpdateUserSignupDistributionDashboardRequest from "./requests/UpdateUserSignupDistributionDashboardRequest.res.js";

var AgencyAgent = {
  index: FetchUserDashboardShowAgencyAgentsRequest.exec,
  update: UpdateUserDashboardShowAgencyAgentRequest.exec,
  create: CreateUserDashboardShowAgencyAgentRequest.exec
};

var Show = {
  AgencyAgent: AgencyAgent
};

var Dashboard = {
  Show: Show
};

var dashboard = FetchUsersDashboardRequest.exec;

var fetchUserDashboard = FetchUserDashboardRequest.exec;

var updateApprovedDashboard = UpdateUserApprovedDashboardRequest.exec;

var updateApiEnabledDashboard = UpdateUserApiEnabledDashboardRequest.exec;

var updateRoleDashboard = UpdateUserRoleDashboardRequest.exec;

var updateDailyLeadsDashboard = UpdateDailyLeadsDashboardRequest.exec;

var updateSignupDistributionDashboard = UpdateUserSignupDistributionDashboardRequest.exec;

var update = UpdateUserRequest.exec;

var updateDashboard = UserDashboardUpdateUserRequest.exec;

var changePassword = UserChangePasswordRequest.exec;

var forgotPassword = UserForgotPasswordRequest.exec;

var resendConfirmationEmail = ResendConfirmationEmailRequest.exec;

export {
  dashboard ,
  fetchUserDashboard ,
  updateApprovedDashboard ,
  updateApiEnabledDashboard ,
  updateRoleDashboard ,
  updateDailyLeadsDashboard ,
  updateSignupDistributionDashboard ,
  update ,
  updateDashboard ,
  changePassword ,
  forgotPassword ,
  resendConfirmationEmail ,
  Dashboard ,
}
/* UpdateUserRequest Not a pure module */
