// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Checkbox from "../../../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function DashboardProjectsIndexFiltersCheckbox(props) {
  var __className = props.className;
  var id = props.id;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Checkbox.make, {
                      id: id,
                      size: "SM",
                      checked: props.checked,
                      onChange: props.onChange
                    }),
                JsxRuntime.jsx("label", {
                      children: props.title,
                      className: "",
                      htmlFor: id
                    })
              ],
              className: className
            });
}

var make = DashboardProjectsIndexFiltersCheckbox;

export {
  make ,
}
/* Checkbox Not a pure module */
