// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "justify-center",
      "gap-5",
      "w-full"
    ]);

var boxes = Cx.cx([
      "flex",
      "md:justify-center",
      "gap-5",
      "overflow-x-auto",
      "justify-start",
      "md:flex-wrap"
    ]);

var horizontalScroll = Cx.cx([
      "flex",
      "flex-row",
      "gap-5",
      "overflow-x-auto",
      "justify-center",
      "md:overflow-x-visible",
      "md:no-scrollbar"
    ]);

export {
  container ,
  boxes ,
  horizontalScroll ,
}
/* container Not a pure module */
