// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var wrapper = Cx.cx([
      "relative",
      "text-center",
      "flex",
      "flex-col",
      "items-center",
      "py-20"
    ]);

var heading = Cx.cx([
      "text-white!",
      "mb-7",
      "px-12",
      "sm:px-0"
    ]);

var subheading = Cx.cx([
      "text-white",
      "md:text-2xl",
      "md:mb-14",
      "lg:px-[280px]",
      "px-7",
      "text-base",
      "mb-10"
    ]);

var providers = Cx.cx([
      "flex",
      "w-full",
      "md:px-20",
      "items-center",
      "justify-center",
      "px-7"
    ]);

export {
  wrapper ,
  heading ,
  subheading ,
  providers ,
}
/* wrapper Not a pure module */
