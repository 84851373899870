// Generated by ReScript, PLEASE EDIT WITH CARE


var container = "md:py-10 md:px-20 p-4";

var pageTitle = "mb-8";

export {
  container ,
  pageTitle ,
}
/* No side effect */
