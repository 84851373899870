// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as HomeJumbotronBox from "./components/box/HomeJumbotronBox.res.js";
import * as HomeJumbotronCss from "./HomeJumbotronCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronBoxes from "./components/boxes/HomeJumbotronBoxes.res.js";
import * as HomeJumbotronTitle from "./components/title/HomeJumbotronTitle.res.js";
import * as HomeJumbotronSearch from "./components/search/HomeJumbotronSearch.res.js";
import Servers_darkSvg from "Images/svgs/servers_dark.svg";
import Building_darkSvg from "Images/svgs/building_dark.svg";
import Employees_darkSvg from "Images/svgs/employees_dark.svg";
import Get_pricing_darkSvg from "Images/svgs/get_pricing_dark.svg";
import JumbotronHeaderDotsSvg from "./assets/jumbotron-header-dots.svg";

var headerDotsSvg = JumbotronHeaderDotsSvg;

function HomeJumbotron(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx(HomeJumbotronTitle.make, {
                                  title: "Find a Data Center"
                                }),
                            JsxRuntime.jsx(H1.make, {
                                  className: HomeJumbotronCss.heading,
                                  children: "Search " + props.totalLocations + " Data Centers"
                                }),
                            JsxRuntime.jsx(HomeJumbotronSearch.make, {}),
                            JsxRuntime.jsxs(HomeJumbotronBoxes.make, {
                                  title: "How Datacenters.com Helps You",
                                  children: [
                                    JsxRuntime.jsx(HomeJumbotronBox.make, {
                                          title: "Find Capacity",
                                          message: "Get help finding scarce capacity in competive data center markets.",
                                          icon: Servers_darkSvg
                                        }),
                                    JsxRuntime.jsx(HomeJumbotronBox.make, {
                                          title: "Get Pricing",
                                          message: "Custom pricing direct from providers for colocation, bare metal, cloud and more.",
                                          icon: Get_pricing_darkSvg
                                        }),
                                    JsxRuntime.jsx(HomeJumbotronBox.make, {
                                          title: "Engage Providers",
                                          message: "Work directly with providers to provision services and get the best deal.",
                                          icon: Building_darkSvg
                                        }),
                                    JsxRuntime.jsx(HomeJumbotronBox.make, {
                                          title: "Expert Engineering",
                                          message: "Leverage our team's years of experience in the data center industry.",
                                          icon: Employees_darkSvg
                                        })
                                  ]
                                })
                          ],
                          className: HomeJumbotronCss.wrapper
                        }),
                    className: HomeJumbotronCss.backgroundImage,
                    style: {
                      backgroundImage: "url(" + headerDotsSvg + ")"
                    }
                  }),
              className: HomeJumbotronCss.jumbotron
            });
}

var Css;

var make = HomeJumbotron;

export {
  Css ,
  headerDotsSvg ,
  make ,
}
/* headerDotsSvg Not a pure module */
