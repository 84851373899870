// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as LazyImage from "../LazyImage/LazyImage.res.js";
import * as TestimonialCss from "./TestimonialCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function reducer(state, action) {
  return {
          moreClick: !state.moreClick
        };
}

var initialState = {
  moreClick: false
};

function Testimonial(props) {
  var companyLogo = props.companyLogo;
  var __showMore = props.showMore;
  var __classNameAuthor = props.classNameAuthor;
  var __classNameText = props.classNameText;
  var __className = props.className;
  var description = props.description;
  var name = props.name;
  var className = __className !== undefined ? __className : "";
  var classNameText = __classNameText !== undefined ? __classNameText : "";
  var classNameAuthor = __classNameAuthor !== undefined ? __classNameAuthor : "";
  var showMore = __showMore !== undefined ? __showMore : true;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var subString = Js_string.slice(0, 140, description);
  return JsxRuntime.jsxs("div", {
              children: [
                companyLogo !== undefined ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(LazyImage.make, {
                              src: companyLogo,
                              height: 50
                            }),
                        className: TestimonialCss.companyLogo
                      }) : null,
                match[0].moreClick === false && description.length > 140 && showMore ? JsxRuntime.jsxs("div", {
                        children: [
                          subString,
                          "...",
                          JsxRuntime.jsx("a", {
                                children: " more info",
                                className: TestimonialCss.link,
                                onClick: (function (_event) {
                                    dispatch("Click");
                                  })
                              })
                        ],
                        className: Cx.cx([
                              TestimonialCss.text,
                              classNameText
                            ])
                      }) : JsxRuntime.jsx("div", {
                        children: description,
                        className: Cx.cx([
                              TestimonialCss.text,
                              classNameText
                            ])
                      }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("img", {
                                    alt: name,
                                    src: props.imageUrl
                                  }),
                              className: TestimonialCss.avatar
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: name,
                                      className: Cx.cx([
                                            TestimonialCss.name,
                                            classNameAuthor
                                          ])
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: props.role,
                                      className: Cx.cx([
                                            TestimonialCss.from,
                                            classNameAuthor
                                          ])
                                    })
                              ],
                              className: TestimonialCss.detail
                            })
                      ],
                      className: TestimonialCss.profile
                    })
              ],
              className: Cx.cx([className])
            });
}

var Css;

var make = Testimonial;

export {
  Css ,
  reducer ,
  initialState ,
  make ,
}
/* react Not a pure module */
