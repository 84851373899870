// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Routes_User from "../../../../../../../routes/common/Routes_User.res.js";
import * as DashboardTabsCss from "../../../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";

function DashboardLeadShowDetailsCoreData(props) {
  var lead = props.lead;
  var x = lead.name;
  var x$1 = lead.company;
  var x$2 = lead.phone;
  var x$3 = lead.email;
  var x$4 = lead.message;
  var x$5 = lead.referrer;
  var x$6 = lead.other;
  var user = lead.user;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Core Lead Data",
                      className: DashboardTabsCss.Tab.label
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              background: "Gray",
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Name",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x === "" ? "No name provided." : lead.name,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Company",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x$1 === "" ? "No company provided." : lead.company,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              background: "Gray",
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Phone",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x$2 === "" ? "No phone provided." : lead.phone,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Email",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x$3 === "" ? "No email provided." : lead.email,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              background: "Gray",
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Message",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: x$4 === "" ? "No message provided." : lead.message,
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Lead Source",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: Caml_obj.equal(x$5, "") ? "Lead source not available." : JsxRuntime.jsx("a", {
                                              children: "Click here",
                                              className: DashboardTabsCss.Tab.$$Highlight.link,
                                              href: Belt_Option.getExn(lead.referrer),
                                              target: "_blank"
                                            }),
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Other",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: Caml_obj.equal(x$6, "") ? "No other details provided." : Belt_Option.getExn(lead.other),
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "User",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: user !== undefined ? JsxRuntime.jsx("a", {
                                              children: user.firstName + " " + user.lastName,
                                              className: DashboardTabsCss.Tab.$$Highlight.link,
                                              href: Routes_User.Dashboard.show(user.id),
                                              target: "_blank"
                                            }) : "No user association.",
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            })
                      ],
                      className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                    })
              ],
              className: DashboardTabsCss.Tab.Section.container
            });
}

var Css;

var $$Highlight;

var make = DashboardLeadShowDetailsCoreData;

export {
  Css ,
  $$Highlight ,
  make ,
}
/* Routes_User Not a pure module */
