// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../libs/ID.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchField from "../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Api_AgencyAgent from "../../../../../api/agency-agents/Api_AgencyAgent.res.js";
import * as ResponseMessage from "../../../../../styleguide/forms/ResponseMessage/ResponseMessage.res.js";
import * as ActiveModelError from "../../../../../models/ActiveModelError.res.js";
import * as DashboardFormCss from "../../../../../styleguide/dashboard/components/forms/DashboardFormCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_AgencyAgent from "../../../../../routes/common/Routes_AgencyAgent.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as DashboardModalContainer from "../../../../../styleguide/dashboard/components/modals/container/DashboardModalContainer.res.js";
import * as DashboardAgencyAgentsNewCss from "./DashboardAgencyAgentsNewCss.res.js";

var validators_userId = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.userId;
      if (x.trim() === "0") {
        return {
                TAG: "Error",
                _0: "A user is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.userId
              };
      }
    })
};

var validators_agencyId = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.agencyId;
      if (x.trim() === "0") {
        return {
                TAG: "Error",
                _0: "An agency is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.agencyId
              };
      }
    })
};

var validators = {
  userId: validators_userId,
  agencyId: validators_agencyId,
  isPrimary: undefined,
  active: undefined
};

function initialFieldsStatuses(_input) {
  return {
          userId: "Pristine",
          agencyId: "Pristine",
          isPrimary: "Pristine",
          active: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            userId: "Pristine",
            agencyId: "Pristine",
            isPrimary: "Pristine",
            active: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.userId;
  var tmp;
  tmp = typeof match !== "object" ? validators.userId.validate(input) : match._0;
  var match$1 = fieldsStatuses.agencyId;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.agencyId.validate(input) : match$1._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.isPrimary
  };
  var match_0$1 = {
    TAG: "Ok",
    _0: input.active
  };
  var userIdResult = tmp;
  var userIdResult$1;
  if (userIdResult.TAG === "Ok") {
    var agencyIdResult = tmp$1;
    if (agencyIdResult.TAG === "Ok") {
      var isPrimaryResult = match_0;
      if (isPrimaryResult.TAG === "Ok") {
        var activeResult = match_0$1;
        if (activeResult.TAG === "Ok") {
          return {
                  TAG: "Valid",
                  output: {
                    userId: userIdResult._0,
                    agencyId: agencyIdResult._0,
                    isPrimary: isPrimaryResult._0,
                    active: activeResult._0
                  },
                  fieldsStatuses: {
                    userId: {
                      TAG: "Dirty",
                      _0: userIdResult,
                      _1: "Shown"
                    },
                    agencyId: {
                      TAG: "Dirty",
                      _0: agencyIdResult,
                      _1: "Shown"
                    },
                    isPrimary: {
                      TAG: "Dirty",
                      _0: isPrimaryResult,
                      _1: "Hidden"
                    },
                    active: {
                      TAG: "Dirty",
                      _0: activeResult,
                      _1: "Hidden"
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        userIdResult$1 = userIdResult;
      } else {
        userIdResult$1 = userIdResult;
      }
    } else {
      userIdResult$1 = userIdResult;
    }
  } else {
    userIdResult$1 = userIdResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            userId: {
              TAG: "Dirty",
              _0: userIdResult$1,
              _1: "Shown"
            },
            agencyId: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            isPrimary: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            },
            active: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurUserIdField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.userId, validators_userId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  userId: status,
                                  agencyId: init.agencyId,
                                  isPrimary: init.isPrimary,
                                  active: init.active
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurAgencyIdField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.agencyId, validators_agencyId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  userId: init.userId,
                                  agencyId: status,
                                  isPrimary: init.isPrimary,
                                  active: init.active
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurIsPrimaryField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.isPrimary, state.fieldsStatuses.isPrimary, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  userId: init.userId,
                                  agencyId: init.agencyId,
                                  isPrimary: status,
                                  active: init.active
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurActiveField" :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.active, state.fieldsStatuses.active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  userId: init.userId,
                                  agencyId: init.agencyId,
                                  isPrimary: init.isPrimary,
                                  active: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateUserIdField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.userId, state.submissionStatus, validators_userId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            userId: status,
                                            agencyId: init.agencyId,
                                            isPrimary: init.isPrimary,
                                            active: init.active
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateAgencyIdField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.agencyId, state.submissionStatus, validators_agencyId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            userId: init.userId,
                                            agencyId: status,
                                            isPrimary: init.isPrimary,
                                            active: init.active
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateIsPrimaryField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.isPrimary, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            userId: init.userId,
                                            agencyId: init.agencyId,
                                            isPrimary: status,
                                            active: init.active
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateActiveField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            userId: init.userId,
                                            agencyId: init.agencyId,
                                            isPrimary: init.isPrimary,
                                            active: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                userId: "Pristine",
                                agencyId: "Pristine",
                                isPrimary: "Pristine",
                                active: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                userId: "Pristine",
                                agencyId: "Pristine",
                                isPrimary: "Pristine",
                                active: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateUserId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateUserIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateAgencyId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateAgencyIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateIsPrimary: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateIsPrimaryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateActiveField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurUserId: (function () {
              dispatch("BlurUserIdField");
            }),
          blurAgencyId: (function () {
              dispatch("BlurAgencyIdField");
            }),
          blurIsPrimary: (function () {
              dispatch("BlurIsPrimaryField");
            }),
          blurActive: (function () {
              dispatch("BlurActiveField");
            }),
          userIdResult: Formality.exposeFieldResult(state.fieldsStatuses.userId),
          agencyIdResult: Formality.exposeFieldResult(state.fieldsStatuses.agencyId),
          isPrimaryResult: Formality.exposeFieldResult(state.fieldsStatuses.isPrimary),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.userId;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.agencyId;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.isPrimary;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.active;
              if (typeof tmp$3 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var AgencyAgentNewForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function DashboardAgencyAgentsEdit(props) {
  var agencyAgent = props.agencyAgent;
  var match = React.useState(function () {
        return [];
      });
  var setMessages = match[1];
  var messages = match[0];
  var initialInput = React.useMemo((function () {
          return {
                  userId: ID.toString(agencyAgent.userId),
                  agencyId: ID.toString(agencyAgent.agencyId),
                  isPrimary: agencyAgent.isPrimary,
                  active: agencyAgent.active
                };
        }), []);
  var initialState_userStatus = {
    TAG: "ReadyUsers",
    _0: []
  };
  var initialState_users = [];
  var initialState_user = {
    id: agencyAgent.userId,
    firstName: agencyAgent.userFirstName,
    lastName: agencyAgent.userLastName,
    email: agencyAgent.userEmail
  };
  var initialState_agencyStatus = {
    TAG: "ReadyAgencies",
    _0: []
  };
  var initialState_agencies = [];
  var initialState_agency = {
    id: agencyAgent.agencyId,
    name: agencyAgent.agencyName
  };
  var initialState = {
    userSearch: "",
    userStatus: initialState_userStatus,
    users: initialState_users,
    user: initialState_user,
    agencySearch: "",
    agencyStatus: initialState_agencyStatus,
    agencies: initialState_agencies,
    agency: initialState_agency
  };
  var searchUsers = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformUserSearch",
                _0: param.state.userSearch
              });
        }));
  var searchAgencies = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformAgencySearch",
                _0: param.state.agencySearch
              });
        }));
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_AgencyAgent.Dashboard.update(agencyAgent.id, output.userId, output.agencyId, output.active, output.isPrimary), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_AgencyAgent.Dashboard.index);
                  }
                  var match = x._0;
                  if (typeof match === "object") {
                    var errors = match.VAL;
                    SentryLogger.error1({
                          rootModule: "DashboardAgencyAgentsEdit",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "DashboardAgencyAgentsEdit.make"
                        }, "AgencyAgentUpdate::Error", [
                          "Error",
                          errors
                        ]);
                    setMessages(function (_prev) {
                          return errors.activeModelErrors;
                        });
                    return cb.notifyOnFailure();
                  }
                  SentryLogger.error1({
                        rootModule: "DashboardAgencyAgentsEdit",
                        subModulePath: /* [] */0,
                        value: "make",
                        fullPath: "DashboardAgencyAgentsEdit.make"
                      }, "AgencyAgencyUpdate::Error", [
                        "Error",
                        "Unknown Error"
                      ]);
                  cb.notifyOnFailure();
                }));
        }));
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchUsers" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_AgencyAgent.Dashboard.usersFilter(state.userSearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedUsersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardAgencyAgentsEdit",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "DashboardAgencyAgentsEdit.make"
                                          }, "FailUsersFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailUsersFetch");
                                    }));
                            })
                        };
              case "FailUsersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            userSearch: state.userSearch,
                            userStatus: "FailedUsersFetch",
                            users: state.users,
                            user: state.user,
                            agencySearch: state.agencySearch,
                            agencyStatus: state.agencyStatus,
                            agencies: state.agencies,
                            agency: state.agency
                          }
                        };
              case "FetchAgencies" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_AgencyAgent.Dashboard.agenciesFilter(state.agencySearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedAgenciesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardAgencyAgentsEdit",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "DashboardAgencyAgentsEdit.make"
                                          }, "FailAgenciesFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailAgenciesFetch");
                                    }));
                            })
                        };
              case "FailAgenciesFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            userSearch: state.userSearch,
                            userStatus: state.userStatus,
                            users: state.users,
                            user: state.user,
                            agencySearch: state.agencySearch,
                            agencyStatus: "FailedAgenciesFetch",
                            agencies: state.agencies,
                            agency: state.agency
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedUsersFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            userSearch: state.userSearch,
                            userStatus: {
                              TAG: "ReadyUsers",
                              _0: res.users
                            },
                            users: res.users,
                            user: state.user,
                            agencySearch: state.agencySearch,
                            agencyStatus: state.agencyStatus,
                            agencies: state.agencies,
                            agency: state.agency
                          }
                        };
              case "UpdateUserSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            userSearch: action._0,
                            userStatus: state.userStatus,
                            users: state.users,
                            user: state.user,
                            agencySearch: state.agencySearch,
                            agencyStatus: state.agencyStatus,
                            agencies: state.agencies,
                            agency: state.agency
                          },
                          _1: searchUsers
                        };
              case "PerformUserSearch" :
                  var match = state.userStatus;
                  if (typeof match !== "object" && match === "FetchingUsers") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.userSearch) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              userSearch: state.userSearch,
                              userStatus: "FetchingUsers",
                              users: state.users,
                              user: state.user,
                              agencySearch: state.agencySearch,
                              agencyStatus: state.agencyStatus,
                              agencies: state.agencies,
                              agency: state.agency
                            },
                            _1: (function (param) {
                                param.dispatch("FetchUsers");
                              })
                          };
                  }
              case "UpdateUser" :
                  var user = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            userSearch: state.userSearch,
                            userStatus: state.userStatus,
                            users: state.users,
                            user: user,
                            agencySearch: state.agencySearch,
                            agencyStatus: state.agencyStatus,
                            agencies: state.agencies,
                            agency: state.agency
                          },
                          _1: (function (param) {
                              var id = user.id;
                              form.updateUserId((function (input, value) {
                                      return {
                                              userId: value,
                                              agencyId: input.agencyId,
                                              isPrimary: input.isPrimary,
                                              active: input.active
                                            };
                                    }), ID.toString(id));
                            })
                        };
              case "SucceedAgenciesFetch" :
                  var res$1 = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            userSearch: state.userSearch,
                            userStatus: state.userStatus,
                            users: state.users,
                            user: state.user,
                            agencySearch: state.agencySearch,
                            agencyStatus: {
                              TAG: "ReadyAgencies",
                              _0: res$1.agencies
                            },
                            agencies: res$1.agencies,
                            agency: state.agency
                          }
                        };
              case "UpdateAgencySearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            userSearch: state.userSearch,
                            userStatus: state.userStatus,
                            users: state.users,
                            user: state.user,
                            agencySearch: action._0,
                            agencyStatus: state.agencyStatus,
                            agencies: state.agencies,
                            agency: state.agency
                          },
                          _1: searchAgencies
                        };
              case "PerformAgencySearch" :
                  var match$1 = state.agencyStatus;
                  if (typeof match$1 !== "object" && match$1 === "FetchingAgencies") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.agencySearch) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              userSearch: state.userSearch,
                              userStatus: state.userStatus,
                              users: state.users,
                              user: state.user,
                              agencySearch: state.agencySearch,
                              agencyStatus: "FetchingAgencies",
                              agencies: state.agencies,
                              agency: state.agency
                            },
                            _1: (function (param) {
                                param.dispatch("FetchAgencies");
                              })
                          };
                  }
              case "UpdateAgency" :
                  var agency = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            userSearch: state.userSearch,
                            userStatus: state.userStatus,
                            users: state.users,
                            user: state.user,
                            agencySearch: state.agencySearch,
                            agencyStatus: state.agencyStatus,
                            agencies: state.agencies,
                            agency: agency
                          },
                          _1: (function (param) {
                              var id = agency.id;
                              form.updateAgencyId((function (input, value) {
                                      return {
                                              userId: input.userId,
                                              agencyId: value,
                                              isPrimary: input.isPrimary,
                                              active: input.active
                                            };
                                    }), ID.toString(id));
                            })
                        };
              
            }
          }
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ResponseMessage.make, {
                  messageType: "Error",
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match$2 = state.userStatus;
  var tmp;
  var exit = 0;
  if (typeof match$2 !== "object" && match$2 !== "FetchingUsers") {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var user = state.user;
    tmp = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: DashboardAgencyAgentsNewCss.dropdownTrigger,
                  iconSize: "XS",
                  iconColor: "Gray",
                  children: user !== undefined ? user.firstName + " " + user.lastName + " (" + user.email + ")" : "Select a user."
                }),
            JsxRuntime.jsxs(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardAgencyAgentsNewCss.dropdownBody,
                  children: [
                    JsxRuntime.jsx(SearchField.make, {
                          id: "users-search",
                          value: state.userSearch,
                          placeholder: "User Name or Email",
                          className: DashboardAgencyAgentsNewCss.search,
                          inputClassName: DashboardAgencyAgentsNewCss.searchInput,
                          onChange: (function ($$event) {
                              dispatch({
                                    TAG: "UpdateUserSearchInput",
                                    _0: $$event.target.value
                                  });
                            }),
                          showSearchIcon: false
                        }),
                    Belt_Array.map(state.users, (function (user) {
                            var id = "user-search-filter--user-" + ID.toString(user.id);
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: user.firstName + " " + user.lastName + " (" + user.email + ")",
                                              className: DashboardAgencyAgentsNewCss.dropdownUser,
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "UpdateUser",
                                                        _0: user
                                                      });
                                                })
                                            }),
                                        className: DashboardAgencyAgentsNewCss.dropdownItem
                                      }, id);
                          }))
                  ]
                })
          ]
        });
  }
  var match$3 = state.agencyStatus;
  var tmp$1;
  var exit$1 = 0;
  if (typeof match$3 !== "object" && match$3 !== "FetchingAgencies") {
    tmp$1 = null;
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    var agency = state.agency;
    tmp$1 = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: DashboardAgencyAgentsNewCss.dropdownTrigger,
                  iconSize: "XS",
                  iconColor: "Gray",
                  children: agency !== undefined ? agency.name : "Select an agency."
                }),
            JsxRuntime.jsxs(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardAgencyAgentsNewCss.dropdownBody,
                  children: [
                    JsxRuntime.jsx(SearchField.make, {
                          id: "users-search",
                          value: state.agencySearch,
                          placeholder: "Agency Name",
                          className: DashboardAgencyAgentsNewCss.search,
                          inputClassName: DashboardAgencyAgentsNewCss.searchInput,
                          onChange: (function ($$event) {
                              dispatch({
                                    TAG: "UpdateAgencySearchInput",
                                    _0: $$event.target.value
                                  });
                            }),
                          showSearchIcon: false
                        }),
                    Belt_Array.map(state.agencies, (function (agency) {
                            var id = "agency-search-filter--agency-" + ID.toString(agency.id);
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: agency.name,
                                              className: DashboardAgencyAgentsNewCss.dropdownUser,
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "UpdateAgency",
                                                        _0: agency
                                                      });
                                                })
                                            }),
                                        className: DashboardAgencyAgentsNewCss.dropdownItem
                                      }, id);
                          }))
                  ]
                })
          ]
        });
  }
  var match$4 = form.status;
  var tmp$2;
  if (typeof match$4 !== "object" || match$4.TAG !== "SubmissionFailed") {
    tmp$2 = null;
  } else {
    var x = messages.length;
    tmp$2 = JsxRuntime.jsx(ResponseMessage.make, {
          messageType: "Error",
          children: x > 0 ? JsxRuntime.jsx(ActiveModelError.List.make, {
                  errors: messages
                }) : JsxRuntime.jsx("p", {
                  children: "Something went wrong."
                })
        });
  }
  return JsxRuntime.jsx(DashboardModalContainer.make, {
              children: JsxRuntime.jsxs("form", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: "Edit Agent",
                            className: DashboardAgencyAgentsNewCss.title
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Label.make, {
                                    forId: "agencyAgent--userID",
                                    className: DashboardFormCss.required,
                                    children: "User"
                                  }),
                              tmp,
                              fieldError(form.userIdResult),
                              JsxRuntime.jsx(Label.make, {
                                    forId: "agencyAgent--agencyId",
                                    className: DashboardFormCss.required,
                                    children: "Agency"
                                  }),
                              tmp$1,
                              fieldError(form.agencyIdResult),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "agencyAgent--isPrimary",
                                                    className: DashboardFormCss.required,
                                                    children: "Primary Agent?"
                                                  }),
                                              JsxRuntime.jsx(Toggle.make, {
                                                    on: form.input.isPrimary,
                                                    size: "MD",
                                                    onChange: (function () {
                                                        form.updateIsPrimary((function (input, value) {
                                                                return {
                                                                        userId: input.userId,
                                                                        agencyId: input.agencyId,
                                                                        isPrimary: value,
                                                                        active: input.active
                                                                      };
                                                              }), !form.input.isPrimary);
                                                      })
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "agencyAgent--active",
                                                    className: DashboardFormCss.required,
                                                    children: "Active?"
                                                  }),
                                              JsxRuntime.jsx(Toggle.make, {
                                                    on: form.input.active,
                                                    size: "MD",
                                                    onChange: (function () {
                                                        form.updateActive((function (input, value) {
                                                                return {
                                                                        userId: input.userId,
                                                                        agencyId: input.agencyId,
                                                                        isPrimary: input.isPrimary,
                                                                        active: value
                                                                      };
                                                              }), !form.input.active);
                                                      })
                                                  })
                                            ]
                                          })
                                    ],
                                    className: DashboardAgencyAgentsNewCss.toggles
                                  })
                            ],
                            className: DashboardAgencyAgentsNewCss.fields
                          }),
                      JsxRuntime.jsx("div", {
                            children: tmp$2
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Button.make, {
                                    size: "MD",
                                    color: "White",
                                    onClick: props.onClick,
                                    children: "Cancel"
                                  }),
                              JsxRuntime.jsx(Button.make, {
                                    id: "editAgentButton",
                                    size: "MD",
                                    color: "Orange",
                                    disabled: form.submitting,
                                    busy: form.submitting,
                                    submit: true,
                                    children: "Save Agent"
                                  })
                            ],
                            className: DashboardAgencyAgentsNewCss.buttonContainer
                          })
                    ],
                    className: DashboardAgencyAgentsNewCss.formContainer,
                    onSubmit: (function ($$event) {
                        $$event.preventDefault();
                        form.submit();
                      })
                  })
            });
}

var Css;

var FormCss;

var make = DashboardAgencyAgentsEdit;

export {
  Css ,
  FormCss ,
  AgencyAgentNewForm ,
  make ,
}
/* ID Not a pure module */
