// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../../styleguide/components/Heading/H1.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as JumbotronZenlayerCss from "./JumbotronZenlayerCss.res.js";
import * as ScheduleModalServices from "../../../../common/schedule-modal-services/ScheduleModalServices.res.js";
import * as UnderscoreFancyHeader from "../../../../../../styleguide/components/UnderscoreFancy/UnderscoreFancyHeader.res.js";
import * as JumbotronProviderIcons from "../../../../../../styleguide/components/JumbotronProviderIcons/JumbotronProviderIcons.res.js";
import ZenlayerIconSvg from "../../assets/zenlayer-icon.svg";

var zenlayerIcon = ZenlayerIconSvg;

function JumbotronZenlayer(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsScheduleDemoModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            className: JumbotronZenlayerCss.icon,
                            alt: "Zenlayer",
                            src: zenlayerIcon
                          })
                    }),
                JsxRuntime.jsx(H1.make, {
                      className: JumbotronZenlayerCss.heading,
                      children: "Zenlayer Bare Metal Cloud"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(UnderscoreFancyHeader.make, {
                            children: "Get VIP Access!",
                            color: "Teal"
                          }),
                      className: JumbotronZenlayerCss.subheading
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Clear",
                            ghost: true,
                            expanded: true,
                            onClick: (function (_event) {
                                setIsScheduleDemoModalOpen(function (param) {
                                      return true;
                                    });
                              }),
                            children: "Schedule a Demo"
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(JumbotronProviderIcons.make, {
                            context: "Zenlayer"
                          }),
                      className: JumbotronZenlayerCss.providerIcons
                    }),
                match[0] ? JsxRuntime.jsx(ScheduleModalServices.make, {
                        onClose: (function () {
                            setIsScheduleDemoModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        title: "Schedule a Zenlayer Demo",
                        subtitle: "Submit the form to schedule a demo and learn more about Zenlayer bare metal solutions.",
                        buttonTitle: "Submit",
                        leadType: "ZenlayerBareMetal"
                      }) : null
              ],
              className: JumbotronZenlayerCss.wrapper
            });
}

var Css;

var make = JumbotronZenlayer;

export {
  zenlayerIcon ,
  Css ,
  make ,
}
/* zenlayerIcon Not a pure module */
