// extracted by mini-css-extract-plugin
export var backgroundImage = "BareMetalIndex__backgroundImage__YFuV7";
export var column = "BareMetalIndex__column__UpZ08";
export var detailsContent = "BareMetalIndex__detailsContent__qd0lu";
export var detailsSection = "BareMetalIndex__detailsSection__skTlM";
export var detailsSidebar = "BareMetalIndex__detailsSidebar__h7rq4";
export var faqSection = "BareMetalIndex__faqSection__CSrmD";
export var flex = "BareMetalIndex__flex__Y9dWP";
export var flexColumn = "BareMetalIndex__flexColumn__GF1xv";
export var gap1 = "BareMetalIndex__gap1__fuAAd";
export var gap2 = "BareMetalIndex__gap2__gsRC4";
export var gap3 = "BareMetalIndex__gap3__mfLZv";
export var gap4 = "BareMetalIndex__gap4__HfpUV";
export var gap5 = "BareMetalIndex__gap5__lsU4F";
export var getStartedSection = "BareMetalIndex__getStartedSection__SQsm5";
export var highlightsSection = "BareMetalIndex__highlightsSection__TQ9V9";
export var jumbotronSection = "BareMetalIndex__jumbotronSection__iT04U";
export var providerIconsSection = "BareMetalIndex__providerIconsSection__lz9Hb";
export var row = "BareMetalIndex__row__Nv8nq";
export var wideContainer = "BareMetalIndex__wideContainer__Jzo_h";
export var wrapper = "BareMetalIndex__wrapper__bvy2I";