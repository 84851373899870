// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cloudinary from "../../../../../libs/Cloudinary.res.js";
import * as Testimonial from "../../../../../styleguide/components/Testimonial/Testimonial.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ServicesSeparatorBlock from "../../../../services/ServicesSeparatorBlock.res.js";
import * as HomeTestimonialsSectionCss from "./HomeTestimonialsSectionCss.res.js";

function HomeTestimonialsSection(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ServicesSeparatorBlock.make, {
                      title: "Clients and Partners"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Testimonial.make, {
                              name: "Dustin Young",
                              role: "EVP of Global Channels, Enzu",
                              description: "IT automation is developing all around us from our talking refrigerators to self-driving cars. Businesses are rushing to understand and reap the benefits from automation.",
                              imageUrl: Cloudinary.imageUrl("v1682611362/static/dustin_young_enzu")
                            }),
                        JsxRuntime.jsx(Testimonial.make, {
                              name: "Chad Markle",
                              role: "Sales Director, Centersquare",
                              description: "We are happy to partner with Datacenters.com with its forward thinking, industry changing, global user experience being a great fit for our products.",
                              imageUrl: Cloudinary.imageUrl("f_auto/ia9cf2fwfj0jp3fbfwuu")
                            }),
                        JsxRuntime.jsx(Testimonial.make, {
                              name: "Glenn Kuczer",
                              role: "CEO, Systemworks, LLC",
                              description: "Hivelocity dedicated servers have provided a rock solid solution to our Police DTS Cloud Application. The uptime and high performance of these dedicated servers have allowed us to concentrate on the development of our product and growth of our business!",
                              imageUrl: Cloudinary.imageUrl("v1696443358/static/glenn-kuczer")
                            })
                      ],
                      className: HomeTestimonialsSectionCss.testimonials
                    })
              ]
            });
}

var Css;

var make = HomeTestimonialsSection;

export {
  Css ,
  make ,
}
/* Cloudinary Not a pure module */
