// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../../libs/ID.res.js";
import * as Badge from "../../../../styleguide/components/Badge/Badge.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Table from "../../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Spinner from "../../../../styleguide/components/Spinner/Spinner.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as Routes_Tsd from "../../../../routes/common/Routes_Tsd.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Api_ProviderTsd from "../../../../api/provider-tds/Api_ProviderTsd.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as TableHeaderCell from "../../../../styleguide/components/Table/TableHeaderCell.res.js";
import Format from "date-fns/format";
import * as DashboardIndexCss from "../../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardExportModal from "../../../../styleguide/dashboard/components/modals/export-csv/DashboardExportModal.res.js";
import * as DashboardProviderTsdsOptions from "./components/DashboardProviderTsdsOptions.res.js";

var initialState = {
  status: "FetchingProviderTsds",
  currentPage: 1,
  totalPages: 1,
  sortBy: {
    NAME: "ProviderName",
    VAL: "Asc"
  },
  search: "",
  csvModalOpen: false
};

function DashboardProviderTsdsIndex$default(props) {
  var container = React.useRef(null);
  var searchProviderTsds = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProviderTsds" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_ProviderTsd.Dashboard.index(state.currentPage, state.sortBy, tmp), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProviderTsdsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProviderTsdsIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "DashboardProviderTsdsIndex.default"
                                          }, "Dashboard::FetchProviderTsds::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProviderTsdsFetch");
                                    }));
                            })
                        };
              case "FailProviderTsdsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedProviderTsdsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen
                          }
                        };
              case "ToggleCsvModalOpen" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: !state.csvModalOpen
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProviderTsdsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.providerTsds
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingProviderTsds") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProviderTsds",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviderTsds");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var sort = action._0;
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingProviderTsds") {
                    return "NoUpdate";
                  }
                  var tmp;
                  if (sort === "CreatedAt") {
                    var match$2 = state.sortBy;
                    tmp = typeof match$2 === "object" && match$2.NAME === "CreatedAt" && match$2.VAL === "Desc" ? ({
                          NAME: "CreatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "CreatedAt",
                          VAL: "Desc"
                        });
                  } else if (sort === "ProviderName") {
                    var match$3 = state.sortBy;
                    tmp = typeof match$3 === "object" && match$3.NAME === "ProviderName" && match$3.VAL === "Desc" ? ({
                          NAME: "ProviderName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "ProviderName",
                          VAL: "Desc"
                        });
                  } else if (sort === "Preferred") {
                    var match$4 = state.sortBy;
                    tmp = typeof match$4 === "object" && match$4.NAME === "Preferred" && match$4.VAL === "Desc" ? ({
                          NAME: "Preferred",
                          VAL: "Asc"
                        }) : ({
                          NAME: "Preferred",
                          VAL: "Desc"
                        });
                  } else if (sort === "UpdatedAt") {
                    var match$5 = state.sortBy;
                    tmp = typeof match$5 === "object" && match$5.NAME === "UpdatedAt" && match$5.VAL === "Desc" ? ({
                          NAME: "UpdatedAt",
                          VAL: "Asc"
                        }) : ({
                          NAME: "UpdatedAt",
                          VAL: "Desc"
                        });
                  } else {
                    var match$6 = state.sortBy;
                    tmp = typeof match$6 === "object" && match$6.NAME === "TsdName" && match$6.VAL === "Desc" ? ({
                          NAME: "TsdName",
                          VAL: "Asc"
                        }) : ({
                          NAME: "TsdName",
                          VAL: "Desc"
                        });
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProviderTsds",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: tmp,
                            search: state.search,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviderTsds");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            csvModalOpen: state.csvModalOpen
                          },
                          _1: searchProviderTsds
                        };
              case "PerformSearch" :
                  var match$7 = state.status;
                  if (typeof match$7 !== "object" && match$7 === "FetchingProviderTsds") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProviderTsds",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              csvModalOpen: state.csvModalOpen
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviderTsds");
                              })
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchProviderTsds");
        }), []);
  var match$1 = state.search;
  var tmp = match$1 === "" ? undefined : state.search;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var match$6 = state.sortBy;
  var providerTsds = state.status;
  var tmp$1;
  if (typeof providerTsds !== "object") {
    tmp$1 = providerTsds === "FetchingProviderTsds" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardIndexCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search"
                })
          });
  } else {
    var providerTsds$1 = providerTsds._0;
    tmp$1 = providerTsds$1.length !== 0 ? Belt_Array.mapWithIndex(providerTsds$1, (function (index, providerTsd) {
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: providerTsd.providerName,
                                        className: DashboardIndexCss.tableLink,
                                        href: Routes_Provider.Dashboard.edit(ID.toString(providerTsd.providerId))
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("a", {
                                        children: providerTsd.tsdName,
                                        className: DashboardIndexCss.tableLink,
                                        href: Routes_Tsd.Dashboard.edit(providerTsd.tsdId)
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: providerTsd.preferred ? JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Green",
                                          children: "Preferred"
                                        }) : JsxRuntime.jsx(Badge.Span.make, {
                                          color: "Blue",
                                          children: "Alternate"
                                        })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format(new Date(providerTsd.createdAt), "MMM dd, yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: Format(new Date(providerTsd.updatedAt), "MMM dd, yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardIndexCss.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit Provider",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: Routes_Provider.Dashboard.edit(ID.toString(providerTsd.providerId))
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardIndexCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit TSD",
                                                                    className: DashboardIndexCss.dropdownBodyLink,
                                                                    href: Routes_Tsd.Dashboard.edit(providerTsd.tsdId)
                                                                  })
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, String(index));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardIndexCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No agents were found."
                })
          });
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsxs(Container.make, {
                                className: DashboardIndexCss.layout,
                                setRef: Caml_option.some(container),
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(H1.make, {
                                              className: DashboardIndexCss.title,
                                              children: "Manage Provider TSDs"
                                            }),
                                        className: DashboardIndexCss.header
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(SearchField.make, {
                                                id: "provider-tsds-search",
                                                value: state.search,
                                                placeholder: "Search by Provider Name or TSD Name",
                                                inputClassName: DashboardIndexCss.searchField,
                                                onChange: (function ($$event) {
                                                    dispatch({
                                                          TAG: "UpdateSearchInput",
                                                          _0: $$event.target.value
                                                        });
                                                  })
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(DashboardProviderTsdsOptions.make, {
                                                      pageNum: state.currentPage,
                                                      query: tmp,
                                                      sortBy: state.sortBy,
                                                      toggleCsvModal: (function (param) {
                                                          dispatch("ToggleCsvModalOpen");
                                                        })
                                                    })
                                              })
                                        ],
                                        className: DashboardIndexCss.subHeader
                                      }),
                                  JsxRuntime.jsxs(Table.make, {
                                        className: DashboardIndexCss.table,
                                        children: [
                                          JsxRuntime.jsx(TableHeader.make, {
                                                children: JsxRuntime.jsxs(TableRow.make, {
                                                      children: [
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "ProviderName"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Provider",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$2 === "object" && match$2.NAME === "ProviderName" ? match$2.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "TsdName"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "TSD",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$3 === "object" && match$3.NAME === "TsdName" ? match$3.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "Preferred"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Preferred?",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$4 === "object" && match$4.NAME === "Preferred" ? match$4.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "CreatedAt"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Created At",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$5 === "object" && match$5.NAME === "CreatedAt" ? match$5.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: JsxRuntime.jsx(Control.make, {
                                                                    onClick: (function (param) {
                                                                        dispatch({
                                                                              TAG: "SortBy",
                                                                              _0: "UpdatedAt"
                                                                            });
                                                                      }),
                                                                    children: JsxRuntime.jsxs("span", {
                                                                          children: [
                                                                            "Updated At",
                                                                            JsxRuntime.jsx(IconSorting.make, {
                                                                                  size: "XXS",
                                                                                  direction: typeof match$6 === "object" && match$6.NAME === "UpdatedAt" ? match$6.VAL : undefined
                                                                                })
                                                                          ],
                                                                          className: DashboardIndexCss.controlCell
                                                                        })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(TableHeaderCell.make, {
                                                              children: ""
                                                            })
                                                      ]
                                                    })
                                              }),
                                          JsxRuntime.jsx(TableBody.make, {
                                                children: tmp$1
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Pagination.make, {
                                              currentPage: state.currentPage,
                                              totalPages: state.totalPages,
                                              onPageClick: (function (x) {
                                                  dispatch({
                                                        TAG: "UpdatePage",
                                                        _0: x
                                                      });
                                                })
                                            }),
                                        className: DashboardIndexCss.pagination
                                      })
                                ]
                              }),
                          state.csvModalOpen ? JsxRuntime.jsx(DashboardExportModal.make, {
                                  title: "Provider & TSD CSV Export",
                                  message: "Please check your email for your provider with TSDs export.",
                                  buttonText: "Close",
                                  onClick: (function (param) {
                                      dispatch("ToggleCsvModalOpen");
                                    })
                                }) : null
                        ],
                        className: DashboardIndexCss.pageContainer
                      }))
            });
}

var Css;

var $$default = DashboardProviderTsdsIndex$default;

export {
  Css ,
  initialState ,
  $$default as default,
}
/* H1 Not a pure module */
