// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_User from "../../../../routes/common/Routes_User.res.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as DashboardTabs from "../../../../styleguide/dashboard/components/tabs/DashboardTabs.res.js";
import * as DashboardShowCss from "../../../../styleguide/dashboard/components/views/show/DashboardShowCss.res.js";
import * as DashboardTabBody from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabBody.res.js";
import * as DashboardTabMenu from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenu.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabMenuItem from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenuItem.res.js";
import * as UsersDashboardNavbar from "../common/navbar/UsersDashboardNavbar.res.js";
import * as DashboardUserShowDetails from "./components/DashboardUserShowDetails.res.js";
import * as DashboardUserShowAgencyAgents from "./components/DashboardUserShowAgencyAgents.res.js";

function reducer(_state, action) {
  return {
          currentTab: action._0
        };
}

var initialState = {
  currentTab: "Details"
};

function DashboardUserShow$default(props) {
  var user = props.user;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.currentTab;
  var tmp;
  tmp = match$1 === "Details" ? "Selected" : "NotSelected";
  var match$2 = state.currentTab;
  var tmp$1;
  tmp$1 = match$2 === "Details" ? "NotSelected" : "Selected";
  var match$3 = state.currentTab;
  var tmp$2;
  tmp$2 = match$3 === "Details" ? JsxRuntime.jsx(DashboardUserShowDetails.make, {
          user: user
        }) : JsxRuntime.jsx(DashboardUserShowAgencyAgents.make, {
          user: user
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(UsersDashboardNavbar.make, {
                      selectedCategory: "Show",
                      id: Caml_option.some(user.id)
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsxs("h1", {
                                                  children: [
                                                    JsxRuntime.jsx("span", {
                                                          children: "User Details",
                                                          className: DashboardShowCss.titleSpan
                                                        }),
                                                    user.firstName + " " + user.lastName
                                                  ],
                                                  className: DashboardShowCss.title
                                                }),
                                            className: DashboardShowCss.heading
                                          }),
                                      JsxRuntime.jsx("a", {
                                            children: "Edit",
                                            className: DashboardShowCss.editLink,
                                            href: Routes_User.Dashboard.edit(user.id)
                                          })
                                    ],
                                    className: DashboardShowCss.header
                                  }),
                              JsxRuntime.jsxs(DashboardTabs.make, {
                                    children: [
                                      JsxRuntime.jsxs(DashboardTabMenu.make, {
                                            children: [
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Details",
                                                    tabType: tmp,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "Details"
                                                            });
                                                      })
                                                  }),
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Agents",
                                                    tabType: tmp$1,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "AgencyAgents"
                                                            });
                                                      }),
                                                    id: "userAgencyAgents"
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(DashboardTabBody.make, {
                                            children: tmp$2
                                          })
                                    ]
                                  })
                            ],
                            className: DashboardCss.bodyContainer
                          })
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var Tabs;

var TabMenu;

var TabMenuItem;

var TabBody;

var $$default = DashboardUserShow$default;

export {
  Css ,
  Tabs ,
  TabMenu ,
  TabMenuItem ,
  TabBody ,
  reducer ,
  initialState ,
  $$default as default,
}
/* react Not a pure module */
