// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as Form from "../../../../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../../../../styleguide/forms/Label/Label.res.js";
import * as Quill from "../../../../../../bindings/Quill.res.js";
import * as React from "react";
import * as Editor from "../../../../../../styleguide/forms/Editor/Editor.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Provider from "../../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditFeatures from "./components/feature/ProviderEditFeatures.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as ProviderEditContainersCss from "../../common/ProviderEditContainersCss.res.js";

function reducer(state, action) {
  return {
          editorState: Editor.State.compose(state.editorState, action._0)
        };
}

var validators = {
  description: {
    strategy: "OnFirstSuccessOrFirstBlur",
    validate: (function (input) {
        var x = input.description.length;
        if (x !== 0) {
          if (x < 50) {
            return {
                    TAG: "Error",
                    _0: "Name must be longer than " + String(50) + " characters."
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: input.description
                  };
          }
        } else {
          return {
                  TAG: "Error",
                  _0: "Name is required."
                };
        }
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          description: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            description: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.description;
  var match$1;
  match$1 = typeof match !== "object" ? validators.description.validate(input) : match._0;
  if (match$1.TAG === "Ok") {
    return {
            TAG: "Valid",
            output: {
              description: match$1._0
            },
            fieldsStatuses: {
              description: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: "Invalid",
            fieldsStatuses: {
              description: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurDescriptionField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.description, validators.description, (function (status) {
                          return {
                                  description: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateDescriptionField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.description, state.submissionStatus, validators.description, (function (status) {
                                    return {
                                            description: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                description: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                description: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurDescription: (function () {
              dispatch("BlurDescriptionField");
            }),
          descriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.description),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.description;
              if (typeof tmp !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ProviderAboutForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function ProviderEditAbout(props) {
  var updateProvider = props.updateProvider;
  var live = props.live;
  var provider = props.provider;
  var initialInput = React.useMemo((function () {
          return {
                  description: provider.currentDraft.description
                };
        }), []);
  var initialState = {
    editorState: Editor.State.make()
  };
  var containerRef = React.useRef(null);
  var editorRef = React.useRef(undefined);
  var liveContainerRef = React.useRef(null);
  var liveEditorRef = React.useRef(undefined);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var form = useForm(initialInput, (function (output, cb) {
          var init = provider.currentDraft;
          $$Promise.wait(Api_Provider.updateDraft(provider.currentDraft.id, {
                    id: init.id,
                    name: init.name,
                    companyType: init.companyType,
                    tickerSymbol: init.tickerSymbol,
                    numberEmployees: init.numberEmployees,
                    yearFounded: init.yearFounded,
                    hqFullAddress: init.hqFullAddress,
                    displayDialCode: init.displayDialCode,
                    displayPhoneNumber: init.displayPhoneNumber,
                    supportPhoneNumber: init.supportPhoneNumber,
                    websiteUrl: init.websiteUrl,
                    description: output.description.trim(),
                    logo: init.logo,
                    projectWebhookUrl: init.projectWebhookUrl,
                    updatedAt: init.updatedAt,
                    metaTitle: init.metaTitle,
                    metaDescription: init.metaDescription
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "ProviderEditAbout",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "ProviderEditAbout.make"
                              }, "UpdateLocationDraft::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                  var res = x._0;
                  updateProvider(res);
                  cb.notifyOnSuccess({
                        description: res.currentDraft.description
                      });
                }));
        }));
  React.useEffect((function () {
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                      return Quill.make("Type here...", undefined, undefined, Quill.providerToolbarOptions, {}, undefined, container);
                    })));
          editor.clipboard.dangerouslyPasteHTML(0, provider.currentDraft.description);
          var submitForm = Debounce.make(900, (function () {
                  editor.disable();
                  form.submit();
                  Debounce.make(1000, (function () {
                            editor.enable();
                          }))();
                }));
          var listener = function (delta, param) {
            dispatch({
                  TAG: "SetEditorState",
                  _0: delta
                });
            form.updateDescription((function (_input, value) {
                    return {
                            description: value
                          };
                  }), document.querySelector("#draftEditor div.ql-editor").innerHTML);
            submitForm();
          };
          editorRef.current = Caml_option.some(editor);
          editor.on("text-change", listener);
          return (function () {
                    editor.off("text-change", listener);
                  });
        }), []);
  React.useEffect((function () {
          var element = document.querySelector("#liveEditor div.ql-editor");
          var toolbar = document.querySelector("#liveEditor div.ql-toolbar");
          if (toolbar === null) {
            
          } else {
            Belt_Option.getExn((toolbar == null) ? undefined : Caml_option.some(toolbar)).remove();
          }
          if (element === null) {
            
          } else {
            Belt_Option.getExn((element == null) ? undefined : Caml_option.some(element)).remove();
          }
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(liveContainerRef.current), (function (container) {
                      return Quill.make("Type here...", true, undefined, Quill.providerToolbarOptions, {}, undefined, container);
                    })));
          editor.clipboard.dangerouslyPasteHTML(0, provider.description);
          liveEditorRef.current = Caml_option.some(editor);
        }), [live]);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Describe the Company"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Form.make, {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "edit-provider-form--description",
                                                  children: "About the Company"
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(Editor.make, {
                                                                ref: liveContainerRef
                                                              }),
                                                          hidden: live === "Draft",
                                                          id: "liveEditor"
                                                        }),
                                                    JsxRuntime.jsx("div", {
                                                          children: JsxRuntime.jsx(Editor.make, {
                                                                ref: containerRef
                                                              }),
                                                          hidden: live === "Live",
                                                          id: "draftEditor"
                                                        })
                                                  ],
                                                  className: Cx.cx([provider.description === form.input.description ? "" : ProviderEditContainersCss.fieldChanged])
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: "This section will be featured prominently on your company's page.\n                Please note that links and special formatting will be removed.",
                                                  className: ProviderEditContainersCss.subText
                                                })
                                          ],
                                          className: ProviderEditContainersCss.fieldRow
                                        })
                                  })
                            })
                      ],
                      className: ProviderEditContainersCss.generalContainer
                    }),
                JsxRuntime.jsx(ProviderEditFeatures.make, {
                      provider: provider,
                      live: live,
                      updateProvider: updateProvider
                    })
              ],
              className: ProviderEditContainersCss.container
            });
}

var Css;

var descriptionLength = 50;

var make = ProviderEditAbout;

export {
  Css ,
  descriptionLength ,
  reducer ,
  ProviderAboutForm ,
  make ,
}
/* H3 Not a pure module */
