// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../styleguide/components/Link/A.res.js";
import * as Control from "../../../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../../../styleguide/components/Popover/Popover.res.js";
import * as Project from "../../../../../../models/Project.res.js";
import * as Dropdown from "../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Solid from "@heroicons/react/24/solid";
import * as DashboardProjectsIndexSubheaderCss from "../../styles/shared/DashboardProjectsIndexSubheaderCss.res.js";

function DashboardProjectsIndexSubheader(props) {
  var sort = props.sort;
  var userIds = props.userIds;
  var conciergeIds = props.conciergeIds;
  var providerIds = props.providerIds;
  var statuses = props.statuses;
  var categories = props.categories;
  var query = props.query;
  var sortBy = props.sortBy;
  var exportByProjects = Project.Dashboard.Index.exportUrl(query, sortBy, categories, statuses, userIds, providerIds, conciergeIds, undefined);
  var exportByProvidersProjects = Project.Dashboard.Index.exportUrl(query, sortBy, categories, statuses, userIds, providerIds, conciergeIds, "ByProvider");
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: String(props.totalProjects) + " Projects"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: sortBy.VAL === "Desc" ? "Newest" : "Oldest"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs(Popover.make, {
                                    children: [
                                      JsxRuntime.jsx(Popover.Trigger.make, {
                                            children: JsxRuntime.jsx(Control.make, {
                                                  children: JsxRuntime.jsx(Solid.Bars3BottomRightIcon, {
                                                        className: sortBy.VAL === "Desc" ? DashboardProjectsIndexSubheaderCss.icon : DashboardProjectsIndexSubheaderCss.iconInverse
                                                      })
                                                })
                                          }),
                                      JsxRuntime.jsxs(Dropdown.Body.make, {
                                            position: {
                                              TAG: "Below",
                                              _0: "RightEdge"
                                            },
                                            className: DashboardProjectsIndexSubheaderCss.Dropdown.body,
                                            children: [
                                              JsxRuntime.jsx(Control.make, {
                                                    children: JsxRuntime.jsx("div", {
                                                          children: "Sort Projects",
                                                          className: DashboardProjectsIndexSubheaderCss.Dropdown.title
                                                        })
                                                  }),
                                              JsxRuntime.jsx(Control.make, {
                                                    onClick: sort,
                                                    children: JsxRuntime.jsx("div", {
                                                          children: "Newest",
                                                          className: sortBy.VAL === "Desc" ? DashboardProjectsIndexSubheaderCss.Dropdown.linkSelected : DashboardProjectsIndexSubheaderCss.Dropdown.link
                                                        })
                                                  }),
                                              JsxRuntime.jsx(Control.make, {
                                                    onClick: sort,
                                                    children: JsxRuntime.jsx("div", {
                                                          children: "Oldest",
                                                          className: sortBy.VAL === "Desc" ? DashboardProjectsIndexSubheaderCss.Dropdown.link : DashboardProjectsIndexSubheaderCss.Dropdown.linkSelected
                                                        })
                                                  })
                                            ]
                                          })
                                    ]
                                  }),
                              title: "Sort options"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs(Popover.make, {
                                    children: [
                                      JsxRuntime.jsx(Popover.Trigger.make, {
                                            children: JsxRuntime.jsx(Control.make, {
                                                  children: JsxRuntime.jsx(Solid.FolderArrowDownIcon, {
                                                        className: DashboardProjectsIndexSubheaderCss.icon
                                                      })
                                                })
                                          }),
                                      JsxRuntime.jsxs(Dropdown.Body.make, {
                                            position: {
                                              TAG: "Below",
                                              _0: "RightEdge"
                                            },
                                            className: DashboardProjectsIndexSubheaderCss.Dropdown.body,
                                            children: [
                                              JsxRuntime.jsx(Control.make, {
                                                    children: JsxRuntime.jsx("div", {
                                                          children: "Export Projects",
                                                          className: DashboardProjectsIndexSubheaderCss.Dropdown.title
                                                        })
                                                  }),
                                              JsxRuntime.jsx(Control.make, {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: exportByProjects,
                                                          className: DashboardProjectsIndexSubheaderCss.Dropdown.link,
                                                          onClick: props.exportByProject,
                                                          children: "By Project"
                                                        })
                                                  }),
                                              JsxRuntime.jsx(Control.make, {
                                                    children: JsxRuntime.jsx(A.make, {
                                                          href: exportByProvidersProjects,
                                                          className: DashboardProjectsIndexSubheaderCss.Dropdown.link,
                                                          onClick: props.exportByProvidersProject,
                                                          children: "By Provider"
                                                        })
                                                  })
                                            ]
                                          })
                                    ]
                                  }),
                              title: "Download options"
                            })
                      ],
                      className: DashboardProjectsIndexSubheaderCss.controls
                    })
              ],
              className: DashboardProjectsIndexSubheaderCss.container
            });
}

var Css;

var make = DashboardProjectsIndexSubheader;

export {
  Css ,
  make ,
}
/* A Not a pure module */
