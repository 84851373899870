// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-8",
      "p-7",
      "bg-gray-50",
      "rounded",
      "mb-7",
      "sm:rounded-none",
      "sm:mb-0",
      "xl:py-20",
      "xl:px-32"
    ]);

var title = Cx.cx([
      "text-xl",
      "font-semibold"
    ]);

var container$1 = Cx.cx([
      "grid",
      "grid-cols-1",
      "text-sm",
      "gap-y-4",
      "gap-x-8",
      "sm:grid-cols-2",
      "md:grid-cols-3",
      "lg:grid-cols-4"
    ]);

var container$2 = Cx.cx([
      "flex",
      "justify-between",
      "gap-2",
      "border-b",
      "border-gray-100",
      "pb-4",
      "font-semibold"
    ]);

var name = Cx.cx([
      "text-black",
      "hover:text-teal",
      "cursor-pointer"
    ]);

var totalLocations = Cx.cx([
      "text-gray",
      "font-normal"
    ]);

var link = Cx.cx([
      "text-teal",
      "hover:text-purple",
      "cursor-pointer"
    ]);

var Item = {
  container: container$2,
  name: name,
  totalLocations: totalLocations,
  link: link
};

var TopList = {
  container: container$1,
  Item: Item
};

function truncateName(name) {
  if (name.length >= 20) {
    return Js_string.concat("...", Js_string.slice(0, 20, name));
  } else {
    return name;
  }
}

export {
  container ,
  title ,
  TopList ,
  truncateName ,
}
/* container Not a pure module */
