// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ServiceSectionCss from "./ServiceSectionCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function bgToClass(background) {
  switch (background) {
    case "White" :
        return ServiceSectionCss.white;
    case "Gray" :
        return ServiceSectionCss.gray;
    case "Blue" :
        return ServiceSectionCss.blue;
    
  }
}

function ServiceSection(props) {
  var children = props.children;
  var className = props.className;
  var __contentDirection = props.contentDirection;
  var __background = props.background;
  var background = __background !== undefined ? __background : "White";
  var contentDirection = __contentDirection !== undefined ? __contentDirection : "Row";
  var $$class = className !== undefined ? className : "";
  var tmp;
  tmp = contentDirection === "Row" ? JsxRuntime.jsx("div", {
          children: children,
          className: Cx.cx([
                ServiceSectionCss.contentRow,
                $$class
              ])
        }) : JsxRuntime.jsx("div", {
          children: children,
          className: Cx.cx([
                ServiceSectionCss.contentColumn,
                $$class
              ])
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: props.title,
                      className: ServiceSectionCss.title
                    }),
                JsxRuntime.jsx("h3", {
                      children: props.subtitle,
                      className: ServiceSectionCss.subtitle
                    }),
                tmp
              ],
              className: Cx.cx([
                    ServiceSectionCss.serviceSection,
                    bgToClass(background)
                  ])
            });
}

var Css;

var make = ServiceSection;

export {
  Css ,
  bgToClass ,
  make ,
}
/* ServiceSectionCss Not a pure module */
