// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as React from "react";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as DashboardTabs from "../../../../styleguide/dashboard/components/tabs/DashboardTabs.res.js";
import * as DashboardShowCss from "../../../../styleguide/dashboard/components/views/show/DashboardShowCss.res.js";
import * as DashboardTabBody from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabBody.res.js";
import * as DashboardTabMenu from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenu.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardLeadsNavbar from "../common/DashboardLeadsNavbar.res.js";
import * as DashboardTabMenuItem from "../../../../styleguide/dashboard/components/tabs/components/DashboardTabMenuItem.res.js";
import * as DashboardLeadShowDetails from "./components/details/DashboardLeadShowDetails.res.js";
import * as DashboardLeadShowRelatedLeads from "./components/related-leads/DashboardLeadShowRelatedLeads.res.js";

function reducer(_state, action) {
  return {
          currentTab: action._0
        };
}

var initialState = {
  currentTab: "Details"
};

function DashboardLeadShow$default(props) {
  var lead = props.lead;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var leadTitle = function (lead) {
    var x = lead.leadable;
    if (x !== undefined) {
      return "Lead #" + ID.toString(lead.id) + " - " + x.title;
    } else {
      return "Lead #" + ID.toString(lead.id);
    }
  };
  var match$1 = state.currentTab;
  var tmp;
  tmp = match$1 === "Details" ? "Selected" : "NotSelected";
  var match$2 = state.currentTab;
  var tmp$1;
  tmp$1 = match$2 === "Details" ? "NotSelected" : "Selected";
  var match$3 = state.currentTab;
  var tmp$2;
  tmp$2 = match$3 === "Details" ? JsxRuntime.jsx(DashboardLeadShowDetails.make, {
          lead: lead
        }) : JsxRuntime.jsx(DashboardLeadShowRelatedLeads.make, {
          lead: lead,
          leadTypes: props.leadTypes
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardLeadsNavbar.make, {
                      currentLink: "Show",
                      leadId: Caml_option.some(lead.id),
                      leadName: leadTitle(lead)
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs("h1", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: "Lead Details",
                                                        className: DashboardShowCss.titleSpan
                                                      }),
                                                  leadTitle(lead)
                                                ],
                                                className: DashboardShowCss.title
                                              }),
                                          className: DashboardShowCss.heading
                                        }),
                                    className: DashboardShowCss.header
                                  }),
                              JsxRuntime.jsxs(DashboardTabs.make, {
                                    children: [
                                      JsxRuntime.jsxs(DashboardTabMenu.make, {
                                            children: [
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Details",
                                                    tabType: tmp,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "Details"
                                                            });
                                                      }),
                                                    id: "details"
                                                  }),
                                              JsxRuntime.jsx(DashboardTabMenuItem.make, {
                                                    title: "Related Leads",
                                                    tabType: tmp$1,
                                                    onClick: (function (param) {
                                                        dispatch({
                                                              TAG: "SetCurrentTab",
                                                              _0: "UserLeads"
                                                            });
                                                      }),
                                                    id: "relatedLeads"
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(DashboardTabBody.make, {
                                            children: tmp$2
                                          })
                                    ]
                                  })
                            ],
                            className: DashboardCss.bodyContainer
                          })
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var Tabs;

var TabMenu;

var TabMenuItem;

var TabBody;

var $$default = DashboardLeadShow$default;

export {
  Css ,
  Tabs ,
  TabMenu ,
  TabMenuItem ,
  TabBody ,
  reducer ,
  initialState ,
  $$default as default,
}
/* ID Not a pure module */
