// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Provider from "../../../models/Provider.res.js";
import * as RestBrowser from "../../RestBrowser.res.js";
import * as ActiveModelError from "../../../models/ActiveModelError.res.js";

function exec(providerId, tsdId, preferred) {
  var formData = new FormData();
  formData.append("tsd_id", tsdId);
  formData.append("preferred", preferred);
  return RestBrowser.$$fetch("/dashboard/providers/" + providerId + "/provider_tsds", formData, {
              NAME: "Json",
              VAL: Provider.Edit.ProviderTsd.Index.decoder
            }, {
              NAME: "Message",
              VAL: ActiveModelError.Errors.decoder
            }, "Post", undefined);
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  exec ,
}
/* Provider Not a pure module */
