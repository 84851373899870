// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var serviceSection = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "justify-center",
      "md:gap-10",
      "md:p-16",
      "p-10",
      "gap-5",
      "text-center",
      "group"
    ]);

var title = Cx.cx([
      "group-[.bg-services-section-gradient]:text-white",
      "text-3xl",
      "font-semibold",
      "text-center",
      "mb-0",
      "leading-10"
    ]);

var subtitle = Cx.cx([
      "group-[.bg-services-section-gradient]:text-gray-200",
      "text-center",
      "font-normal",
      "text-lg",
      "max-w-[780px]",
      "mb-0",
      "text-gray-700"
    ]);

var generalContent = Cx.cx([
      "flex",
      "md:flex-row",
      "flex-wrap",
      "gap-4",
      "flex-col",
      "justify-center"
    ]);

var contentRow = Cx.cx([
      "justify-center",
      generalContent
    ]);

var contentColumn = Cx.cx([
      "flex-col",
      generalContent
    ]);

var white = Cx.cx(["bg-white"]);

var gray = Cx.cx(["bg-gray-100"]);

var blue = Cx.cx([
      "bg-services-section-gradient",
      "text-white"
    ]);

export {
  serviceSection ,
  title ,
  subtitle ,
  generalContent ,
  contentRow ,
  contentColumn ,
  white ,
  gray ,
  blue ,
}
/* serviceSection Not a pure module */
