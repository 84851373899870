// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../../../styleguide/components/Heading/H3.res.js";
import * as ID from "../../../../../../libs/ID.res.js";
import * as Video from "../../../../../../models/Video.res.js";
import * as React from "react";
import * as Button from "../../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Api_Provider from "../../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as Globals from "Common/globals";
import * as CloudinaryWidget from "../../../../../../bindings/CloudinaryWidget.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderEditImageForm from "./ProviderEditImageForm.res.js";
import * as ProviderEditVideoForm from "./ProviderEditVideoForm.res.js";
import * as ProviderEditContainersCss from "../../common/ProviderEditContainersCss.res.js";
import * as ReactCloudinaryUploadWidget from "react-cloudinary-upload-widget";
import * as ProviderEditAssetsTabVideoLinkForm from "./ProviderEditAssetsTabVideoLinkForm.res.js";

function reducer(state, action) {
  switch (action.TAG) {
    case "ToggleDestroyConfirm" :
        var id = action._1;
        if (action._0 === "Image") {
          return {
                  destroyImageConfirm: !state.destroyImageConfirm,
                  destroyVideoConfirm: state.destroyVideoConfirm,
                  currentVideo: state.currentVideo,
                  currentImage: id,
                  timeout: state.timeout,
                  videos: state.videos,
                  newVideos: state.newVideos,
                  savedVideos: state.savedVideos,
                  provider: state.provider
                };
        } else {
          return {
                  destroyImageConfirm: state.destroyImageConfirm,
                  destroyVideoConfirm: !state.destroyVideoConfirm,
                  currentVideo: id,
                  currentImage: state.currentImage,
                  timeout: state.timeout,
                  videos: state.videos,
                  newVideos: state.newVideos,
                  savedVideos: state.savedVideos,
                  provider: state.provider
                };
        }
    case "SetTimeOut" :
        return {
                destroyImageConfirm: state.destroyImageConfirm,
                destroyVideoConfirm: state.destroyVideoConfirm,
                currentVideo: state.currentVideo,
                currentImage: state.currentImage,
                timeout: action._0,
                videos: state.videos,
                newVideos: state.newVideos,
                savedVideos: state.savedVideos,
                provider: state.provider
              };
    case "StoreAddedLinkedVideo" :
        return {
                destroyImageConfirm: state.destroyImageConfirm,
                destroyVideoConfirm: state.destroyVideoConfirm,
                currentVideo: state.currentVideo,
                currentImage: state.currentImage,
                timeout: state.timeout,
                videos: state.videos,
                newVideos: state.newVideos,
                savedVideos: state.savedVideos,
                provider: action._0
              };
    
  }
}

function ProviderEditAssets(props) {
  var updateProvider = props.updateProvider;
  var live = props.live;
  var provider = props.provider;
  var videos = Video.normalize(provider.videos);
  var initialState_videos = [];
  var initialState_newVideos = {
    index: [],
    dict: undefined
  };
  var initialState_savedVideos = {
    index: videos.index,
    dict: Belt_Map.map(videos.dict, (function (video) {
            return [
                    video,
                    "StandBy"
                  ];
          }))
  };
  var initialState = {
    destroyImageConfirm: false,
    destroyVideoConfirm: false,
    currentVideo: undefined,
    currentImage: undefined,
    timeout: undefined,
    videos: initialState_videos,
    newVideos: initialState_newVideos,
    savedVideos: initialState_savedVideos,
    provider: provider
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var updateTimeout = function (timeout) {
    var match = state.timeout;
    if (match !== undefined) {
      clearTimeout(Belt_Option.getExn(state.timeout));
      return dispatch({
                  TAG: "SetTimeOut",
                  _0: Caml_option.some(timeout)
                });
    } else {
      return dispatch({
                  TAG: "SetTimeOut",
                  _0: Caml_option.some(timeout)
                });
    }
  };
  var match$1 = provider.images;
  var match$2 = provider.videos;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              children: "Add Images & Videos"
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      "Click to Upload Images and Videos",
                                      JsxRuntime.jsx(ReactCloudinaryUploadWidget.Widget, {
                                            sources: ["local"],
                                            cloudName: Globals.CloudinaryCloudName,
                                            apiKey: Globals.CloudinaryAPIKey,
                                            uploadPreset: Globals.CloudinaryUploadPreset,
                                            accepts: "application/json",
                                            contentType: "application/json",
                                            folder: "providers/" + provider.slug + "/images",
                                            style: {
                                              color: "white",
                                              border: "1px solid",
                                              borderColor: "#18c3bb",
                                              borderRadius: "3px",
                                              fontWeight: "600",
                                              backgroundColor: "#18c3bb",
                                              padding: "8px 20px",
                                              fontSize: "14px"
                                            },
                                            cropping: false,
                                            multiple: true,
                                            autoClose: true,
                                            onSuccess: (function (result) {
                                                console.log("RESULT:", CloudinaryWidget.fromJson(result));
                                                var content = CloudinaryWidget.fromJson(result);
                                                var match = content.info.format;
                                                var exit = 0;
                                                switch (match) {
                                                  case "jpeg" :
                                                  case "jpg" :
                                                  case "png" :
                                                      exit = 2;
                                                      break;
                                                  case "avi" :
                                                  case "mov" :
                                                  case "mp4" :
                                                  case "wmv" :
                                                      exit = 1;
                                                      break;
                                                  default:
                                                    exit = 2;
                                                }
                                                switch (exit) {
                                                  case 1 :
                                                      return $$Promise.wait(Api_Provider.createVideo(provider.id, {
                                                                      video: content
                                                                    }), (function (x) {
                                                                    if (x.TAG === "Ok") {
                                                                      return updateProvider(x._0);
                                                                    } else {
                                                                      return SentryLogger.error1({
                                                                                  rootModule: "ProviderEditAssets",
                                                                                  subModulePath: /* [] */0,
                                                                                  value: "make",
                                                                                  fullPath: "ProviderEditAssets.make"
                                                                                }, "CreateProviderVideo::Error", [
                                                                                  "Error",
                                                                                  x._0
                                                                                ]);
                                                                    }
                                                                  }));
                                                  case 2 :
                                                      return $$Promise.wait(Api_Provider.createImage(provider.id, {
                                                                      image: content
                                                                    }), (function (x) {
                                                                    if (x.TAG === "Ok") {
                                                                      return updateProvider(x._0);
                                                                    } else {
                                                                      return SentryLogger.error1({
                                                                                  rootModule: "ProviderEditAssets",
                                                                                  subModulePath: /* [] */0,
                                                                                  value: "make",
                                                                                  fullPath: "ProviderEditAssets.make"
                                                                                }, "CreateProviderImage::Error", [
                                                                                  "Error",
                                                                                  x._0
                                                                                ]);
                                                                    }
                                                                  }));
                                                  
                                                }
                                              })
                                          })
                                    ],
                                    className: ProviderEditContainersCss.uploaderInner
                                  }),
                              className: ProviderEditContainersCss.uploader
                            }),
                        JsxRuntime.jsx(ProviderEditAssetsTabVideoLinkForm.make, {
                              providerId: provider.id,
                              videos: state.videos,
                              addLinkedVideo: (function (provider) {
                                  dispatch({
                                        TAG: "StoreAddedLinkedVideo",
                                        _0: provider
                                      });
                                }),
                              updateProvider: updateProvider
                            })
                      ],
                      className: ProviderEditContainersCss.generalContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        match$1.length !== 0 ? JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(H3.make, {
                                        children: "Images"
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: Belt_Array.mapWithIndex(provider.images, (function (index, image) {
                                                return JsxRuntime.jsx(ProviderEditImageForm.make, {
                                                            provider: provider,
                                                            image: image,
                                                            live: live,
                                                            updateProvider: updateProvider,
                                                            toggleDestroy: (function (id) {
                                                                dispatch({
                                                                      TAG: "ToggleDestroyConfirm",
                                                                      _0: "Image",
                                                                      _1: Caml_option.some(id)
                                                                    });
                                                              }),
                                                            updateTimeout: (function (timeout) {
                                                                updateTimeout(timeout);
                                                              })
                                                          }, "image--" + String(index) + "--" + ID.toString(image.id));
                                              })),
                                        className: ProviderEditContainersCss.imagesGrid
                                      })
                                ],
                                className: ProviderEditContainersCss.imageContainer
                              }) : null,
                        match$2.length !== 0 ? JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsx(H3.make, {
                                        children: "Videos"
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: Belt_Array.mapWithIndex(provider.videos, (function (index, video) {
                                                return JsxRuntime.jsx(ProviderEditVideoForm.make, {
                                                            provider: provider,
                                                            video: video,
                                                            live: live,
                                                            updateProvider: updateProvider,
                                                            toggleDestroy: (function (id) {
                                                                dispatch({
                                                                      TAG: "ToggleDestroyConfirm",
                                                                      _0: "Video",
                                                                      _1: Caml_option.some(id)
                                                                    });
                                                              }),
                                                            updateTimeout: (function (timeout) {
                                                                updateTimeout(timeout);
                                                              })
                                                          }, "video--" + String(index) + "--" + ID.toString(video.id));
                                              })),
                                        className: ProviderEditContainersCss.imagesGrid
                                      })
                                ],
                                className: ProviderEditContainersCss.imageContainer
                              }) : null,
                        state.destroyImageConfirm ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Are you sure?",
                                              className: ProviderEditContainersCss.deleteConfirmationText
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Image will be deleted permanently.",
                                              className: ProviderEditContainersCss.deleteConfirmationMessage
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Gray",
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "ToggleDestroyConfirm",
                                                                _0: "Image",
                                                                _1: undefined
                                                              });
                                                        }),
                                                      children: "Cancel"
                                                    }),
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Pink",
                                                      onClick: (function (param) {
                                                          var providerId = provider.id;
                                                          var imageId = Belt_Option.getExn(state.currentImage);
                                                          $$Promise.wait(Api_Provider.deleteImage(providerId, imageId), (function (x) {
                                                                  if (x.TAG !== "Ok") {
                                                                    return SentryLogger.error1({
                                                                                rootModule: "ProviderEditAssets",
                                                                                subModulePath: /* [] */0,
                                                                                value: "make",
                                                                                fullPath: "ProviderEditAssets.make"
                                                                              }, "DeleteProviderImage::Error", [
                                                                                "Error",
                                                                                x._0
                                                                              ]);
                                                                  }
                                                                  updateProvider(x._0);
                                                                  dispatch({
                                                                        TAG: "ToggleDestroyConfirm",
                                                                        _0: "Image",
                                                                        _1: undefined
                                                                      });
                                                                }));
                                                        }),
                                                      children: "Yes, delete it"
                                                    })
                                              ],
                                              className: ProviderEditContainersCss.deleteConfirmationButtons
                                            })
                                      ],
                                      className: ProviderEditContainersCss.deleteConfirmationDialog
                                    }),
                                className: ProviderEditContainersCss.deleteConfirmationOverlay,
                                onClick: (function ($$event) {
                                    $$event.stopPropagation();
                                  })
                              }) : null,
                        state.destroyVideoConfirm ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Are you sure?",
                                              className: ProviderEditContainersCss.deleteConfirmationText
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Video will be deleted permanently.",
                                              className: ProviderEditContainersCss.deleteConfirmationMessage
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Gray",
                                                      onClick: (function (param) {
                                                          dispatch({
                                                                TAG: "ToggleDestroyConfirm",
                                                                _0: "Video",
                                                                _1: undefined
                                                              });
                                                        }),
                                                      children: "Cancel"
                                                    }),
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Pink",
                                                      onClick: (function (param) {
                                                          var providerId = provider.id;
                                                          var videoId = Belt_Option.getExn(state.currentVideo);
                                                          $$Promise.wait(Api_Provider.deleteVideo(providerId, videoId), (function (x) {
                                                                  if (x.TAG !== "Ok") {
                                                                    return SentryLogger.error1({
                                                                                rootModule: "ProviderEditAssets",
                                                                                subModulePath: /* [] */0,
                                                                                value: "make",
                                                                                fullPath: "ProviderEditAssets.make"
                                                                              }, "DeleteProviderImage::Error", [
                                                                                "Error",
                                                                                x._0
                                                                              ]);
                                                                  }
                                                                  updateProvider(x._0);
                                                                  dispatch({
                                                                        TAG: "ToggleDestroyConfirm",
                                                                        _0: "Video",
                                                                        _1: undefined
                                                                      });
                                                                }));
                                                        }),
                                                      children: "Yes, delete it"
                                                    })
                                              ],
                                              className: ProviderEditContainersCss.deleteConfirmationButtons
                                            })
                                      ],
                                      className: ProviderEditContainersCss.deleteConfirmationDialog
                                    }),
                                className: ProviderEditContainersCss.deleteConfirmationOverlay,
                                onClick: (function ($$event) {
                                    $$event.stopPropagation();
                                  })
                              }) : null
                      ],
                      className: ProviderEditContainersCss.generalContainer
                    })
              ],
              className: ProviderEditContainersCss.container
            });
}

var Css;

var make = ProviderEditAssets;

export {
  Css ,
  reducer ,
  make ,
}
/* H3 Not a pure module */
