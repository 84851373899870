// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var deleteConfirmationOverlay = Cx.cx([
      "fixed",
      "top-0",
      "bottom-0",
      "right-0",
      "left-0",
      "flex",
      "items-center",
      "justify-center",
      "text-white",
      "bg-opacity-50",
      "bg-gray-800",
      "z-[1000]"
    ]);

var deleteConfirmationDialog = Cx.cx([
      "w-[300px]",
      "h-[130px]",
      "bg-white",
      "rounded-lg",
      "p-5",
      "flex",
      "flex-col",
      "justify-between"
    ]);

var deleteConfirmationText = Cx.cx([
      "font-bold",
      "text-black",
      "text-2xl"
    ]);

var deleteConfirmationButtons = Cx.cx([
      "flex",
      "justify-between"
    ]);

var Dashboard;

export {
  Dashboard ,
  deleteConfirmationOverlay ,
  deleteConfirmationDialog ,
  deleteConfirmationText ,
  deleteConfirmationButtons ,
}
/* deleteConfirmationOverlay Not a pure module */
