// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Picture from "../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../../../../models/Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Solid from "@heroicons/react/24/solid";
import * as LocationsIndexPreloadedSearchLocationCss from "../../../styles/common/LocationsIndexPreloadedSearchLocationCss.res.js";

function LocationsIndexPreloadedSearchLocation(props) {
  var preloadedSearchLocation = props.preloadedSearchLocation;
  var tmp;
  var exit = 0;
  switch (props.userRole) {
    case "Admin" :
    case "Concierge" :
    case "Agent" :
        exit = 1;
        break;
    default:
      tmp = null;
  }
  if (exit === 1) {
    tmp = preloadedSearchLocation.providerAgreement ? JsxRuntime.jsx(Solid.CheckBadgeIcon, {
            className: LocationsIndexPreloadedSearchLocationCss.$$Image.agreement,
            strokeWidth: 1,
            stroke: "white"
          }) : null;
  }
  return JsxRuntime.jsxs("a", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Picture.make, {
                                    src: preloadedSearchLocation.providerLogo,
                                    large: [
                                      80,
                                      50
                                    ],
                                    crop: "Fit",
                                    className: ""
                                  }),
                              className: LocationsIndexPreloadedSearchLocationCss.$$Image.logo
                            }),
                        tmp,
                        JsxRuntime.jsx(Picture.make, {
                              src: preloadedSearchLocation.mainImageUrl,
                              large: [
                                600,
                                360
                              ],
                              className: LocationsIndexPreloadedSearchLocationCss.$$Image.picture,
                              fallbackSrc: $$Location.imageNotFoundUrl
                            })
                      ],
                      className: LocationsIndexPreloadedSearchLocationCss.$$Image.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: preloadedSearchLocation.providerName,
                              className: LocationsIndexPreloadedSearchLocationCss.providerName
                            }),
                        JsxRuntime.jsx("div", {
                              children: preloadedSearchLocation.name,
                              className: LocationsIndexPreloadedSearchLocationCss.name
                            }),
                        JsxRuntime.jsx("div", {
                              children: preloadedSearchLocation.fullAddress,
                              className: LocationsIndexPreloadedSearchLocationCss.fullAddress
                            }),
                        preloadedSearchLocation.sponsoredCity ? JsxRuntime.jsx("div", {
                                children: "Sponsored",
                                className: LocationsIndexPreloadedSearchLocationCss.sponsored
                              }) : null
                      ],
                      className: LocationsIndexPreloadedSearchLocationCss.details
                    })
              ],
              className: LocationsIndexPreloadedSearchLocationCss.container,
              href: preloadedSearchLocation.url
            });
}

var Css;

var make = LocationsIndexPreloadedSearchLocation;

export {
  Css ,
  make ,
}
/* Picture Not a pure module */
