// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Pdf from "./Pdf.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          description: js.description,
          contract: js.contract,
          contractStart: js.contractStart,
          contractResource: Pdf.Resource.fromJs(js.contractResource),
          contractDownload: js.contractDownload,
          contractAttached: js.contractAttached,
          agenciesCount: js.agenciesCount,
          homeUrl: js.homeUrl,
          adminUrl: js.adminUrl,
          username: js.username,
          encryptedPassword: js.encryptedPassword,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              contract: field.required("contract", Json_Decode$JsonCombinators.string),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              contractResource: field.required("contractResource", Pdf.Resource.decoder),
              contractDownload: field.required("contractDownload", Json_Decode$JsonCombinators.string),
              contractAttached: field.required("contractAttached", Json_Decode$JsonCombinators.bool),
              agenciesCount: field.required("agenciesCount", Json_Decode$JsonCombinators.$$int),
              homeUrl: field.required("homeUrl", Json_Decode$JsonCombinators.string),
              adminUrl: field.required("adminUrl", Json_Decode$JsonCombinators.string),
              username: field.required("username", Json_Decode$JsonCombinators.string),
              encryptedPassword: field.required("encryptedPassword", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Edit = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          description: js.description,
          contract: js.contract,
          contractStart: js.contractStart,
          contractResource: Pdf.Resource.fromJs(js.contractResource),
          contractDownload: js.contractDownload,
          contractAttached: js.contractAttached,
          agenciesCount: js.agenciesCount,
          agencyAgentsCount: js.agencyAgentsCount,
          homeUrl: js.homeUrl,
          adminUrl: js.adminUrl,
          username: js.username,
          encryptedPassword: js.encryptedPassword,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              contract: field.required("contract", Json_Decode$JsonCombinators.string),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              contractResource: field.required("contractResource", Pdf.Resource.decoder),
              contractDownload: field.required("contractDownload", Json_Decode$JsonCombinators.string),
              contractAttached: field.required("contractAttached", Json_Decode$JsonCombinators.bool),
              agenciesCount: field.required("agenciesCount", Json_Decode$JsonCombinators.$$int),
              agencyAgentsCount: field.required("agencyAgentsCount", Json_Decode$JsonCombinators.$$int),
              homeUrl: field.required("homeUrl", Json_Decode$JsonCombinators.string),
              adminUrl: field.required("adminUrl", Json_Decode$JsonCombinators.string),
              username: field.required("username", Json_Decode$JsonCombinators.string),
              encryptedPassword: field.required("encryptedPassword", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          agencyAgentsCount: js.agencyAgentsCount
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              agencyAgentsCount: field.required("agencyAgentsCount", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Agency = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$3(js) {
  return {
          tsdId: js.tsdId,
          agencies: Belt_Array.map(js.agencies, fromJs$2),
          totalAgencies: js.totalAgencies,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              tsdId: field.required("tsdId", ID.decoder),
              agencies: field.required("agencies", Json_Decode$JsonCombinators.array(decoder$2)),
              totalAgencies: field.required("totalAgencies", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Agencies = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function fromJs$4(js) {
  return {
          id: js.id,
          userId: js.userId,
          userFirstName: js.userFirstName,
          userLastName: js.userLastName,
          userEmail: js.userEmail,
          agencyId: js.agencyId,
          agencyName: js.agencyName,
          active: js.active,
          isPrimary: js.isPrimary
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              userId: field.required("userId", ID.decoder),
              userFirstName: field.required("userFirstName", Json_Decode$JsonCombinators.string),
              userLastName: field.required("userLastName", Json_Decode$JsonCombinators.string),
              userEmail: field.required("userEmail", Json_Decode$JsonCombinators.string),
              agencyId: field.required("agencyId", ID.decoder),
              agencyName: field.required("agencyName", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              isPrimary: field.required("isPrimary", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var AgencyAgent = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  fromJson: fromJson$4
};

function fromJs$5(js) {
  return {
          tsdId: js.tsdId,
          agencyAgents: Belt_Array.map(js.agencyAgents, fromJs$4),
          totalAgencyAgents: js.totalAgencyAgents,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          perPage: js.perPage
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              tsdId: field.required("tsdId", ID.decoder),
              agencyAgents: field.required("agencyAgents", Json_Decode$JsonCombinators.array(decoder$4)),
              totalAgencyAgents: field.required("totalAgencyAgents", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

var AgencyAgents = {
  fromJs: fromJs$5,
  decoder: decoder$5
};

function fromJs$6(js) {
  return {
          id: js.id,
          providerId: js.providerId,
          providerName: js.providerName,
          preferred: js.preferred,
          createdAt: js.createdAt,
          updatedAt: js.updatedAt
        };
}

var decoder$6 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              preferred: field.required("preferred", Json_Decode$JsonCombinators.bool),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$6));
}

var ProviderTsd = {
  fromJs: fromJs$6,
  decoder: decoder$6,
  fromJson: fromJson$5
};

function fromJs$7(js) {
  return {
          tsdId: js.tsdId,
          providerTsds: Belt_Array.map(js.providerTsds, fromJs$6),
          totalProviderTsds: js.totalProviderTsds,
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          perPage: js.perPage
        };
}

var decoder$7 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              tsdId: field.required("tsdId", ID.decoder),
              providerTsds: field.required("providerTsds", Json_Decode$JsonCombinators.array(decoder$6)),
              totalProviderTsds: field.required("totalProviderTsds", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$6(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$7));
}

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

function page(pageNum) {
  return "page=" + String(pageNum);
}

function query(query$1) {
  return Belt_Option.getWithDefault(Belt_Option.map(query$1, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function sortBy(sortBy$1) {
  var variant = sortBy$1.NAME;
  if (variant === "ProviderName") {
    return "&sort_by=providers.name&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "Preferred") {
    return "&sort_by=provider_tsds.preferred&sort_direction=" + toString(sortBy$1.VAL);
  } else if (variant === "UpdatedAt") {
    return "&sort_by=provider_tsds.updated_at&sort_direction=" + toString(sortBy$1.VAL);
  } else {
    return "&sort_by=provider_tsds.created_at&sort_direction=" + toString(sortBy$1.VAL);
  }
}

var ProviderTsds = {
  fromJs: fromJs$7,
  decoder: decoder$7,
  fromJson: fromJson$6,
  SortDirection: SortDirection,
  SortBy: SortBy,
  page: page,
  query: query,
  sortBy: sortBy
};

var Show = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1,
  Agency: Agency,
  Agencies: Agencies,
  AgencyAgent: AgencyAgent,
  AgencyAgents: AgencyAgents,
  ProviderTsd: ProviderTsd,
  ProviderTsds: ProviderTsds
};

function fromJs$8(js) {
  return {
          id: js.id,
          name: js.name,
          active: js.active,
          contractStart: js.contractStart,
          agenciesCount: js.agenciesCount,
          providerTsdsCount: js.providerTsdsCount,
          createdAt: js.createdAt
        };
}

var decoder$8 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              contractStart: field.required("contractStart", Json_Decode$JsonCombinators.string),
              agenciesCount: field.required("agenciesCount", Json_Decode$JsonCombinators.$$int),
              providerTsdsCount: field.required("providerTsdsCount", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$7(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$8));
}

function fromJs$9(js) {
  return {
          tsds: Belt_Array.map(js.tsds, fromJs$8),
          totalTsds: js.totalTsds,
          currentPage: js.currentPage,
          totalPages: js.totalPages,
          perPage: js.perPage
        };
}

var decoder$9 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              tsds: field.required("tsds", Json_Decode$JsonCombinators.array(decoder$8)),
              totalTsds: field.required("totalTsds", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              perPage: field.required("perPage", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$8(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$9));
}

function toString$1(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection$1 = {
  toString: toString$1
};

var SortBy$1 = {};

function page$1(pageNum) {
  return "pages=" + String(pageNum);
}

function query$1(query$2) {
  return Belt_Option.getWithDefault(Belt_Option.map(query$2, (function (x) {
                    return "&query=" + encodeURIComponent(x);
                  })), "");
}

function sortBy$1(sortBy$2) {
  var variant = sortBy$2.NAME;
  if (variant === "TotalAgencies") {
    return "&sort_by=tsds.agencies_count&sort_direction=" + toString$1(sortBy$2.VAL);
  } else if (variant === "CreatedAt") {
    return "&sort_by=tsds.created_at&sort_direction=" + toString$1(sortBy$2.VAL);
  } else if (variant === "ContractStart") {
    return "&sort_by=tsds.contract_start&sort_direction=" + toString$1(sortBy$2.VAL);
  } else if (variant === "TotalProviderTsds") {
    return "&sort_by=tsds.provider_tsds_count&sort_direction=" + toString$1(sortBy$2.VAL);
  } else if (variant === "Name") {
    return "&sort_by=tsds.name&sort_direction=" + toString$1(sortBy$2.VAL);
  } else {
    return "&sort_by=tsds.active&sort_direction=" + toString$1(sortBy$2.VAL);
  }
}

var Index = {
  fromJs: fromJs$9,
  decoder: decoder$9,
  fromJson: fromJson$8,
  SortDirection: SortDirection$1,
  SortBy: SortBy$1,
  page: page$1,
  query: query$1,
  sortBy: sortBy$1
};

var Dashboard = {
  fromJs: fromJs$8,
  decoder: decoder$8,
  fromJson: fromJson$7,
  Index: Index
};

var Pdf$1;

export {
  fromJs ,
  decoder ,
  fromJson ,
  Edit ,
  Show ,
  Dashboard ,
  Pdf$1 as Pdf,
}
/* decoder Not a pure module */
