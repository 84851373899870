// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../../../../../../utils/Url.res.js";
import * as Hooks from "../../../../../../../libs/Hooks.res.js";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as TextField from "../../../../../../../styleguide/forms/TextField/TextField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HomeJumbotronSearchCss from "./HomeJumbotronSearchCss.res.js";

function HomeJumbotronSearch(props) {
  var match = Hooks.useReducer({
        query: ""
      }, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    query: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsx(TextField.make, {
                      id: "search",
                      value: state.query,
                      placeholder: "Search a provider, city, state or country.",
                      wrapperClassName: HomeJumbotronSearchCss.search,
                      onChange: (function ($$event) {
                          dispatch({
                                TAG: "SetSearch",
                                _0: $$event.target.value
                              });
                        })
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "MD",
                      color: "Primary",
                      visuallyDisabled: false,
                      buttonClassName: HomeJumbotronSearchCss.searchButton,
                      onClick: (function (param) {
                          Url.visit("/locations?query=" + encodeURIComponent(state.query));
                        }),
                      children: "Search"
                    })
              ],
              className: HomeJumbotronSearchCss.container,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Url.visit("/locations?query=" + encodeURIComponent(state.query));
                })
            });
}

var Css;

var make = HomeJumbotronSearch;

export {
  Css ,
  make ,
}
/* Hooks Not a pure module */
