// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Badge from "../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProjectsIndexProjectCss from "../../styles/shared/DashboardProjectsIndexProjectCss.res.js";

function DashboardProjectsIndexErrorProject(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Something went wrong. Click to reset your search.",
                      className: DashboardProjectsIndexProjectCss.Empty.$$Error.Status.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("div", {
                                                    className: DashboardProjectsIndexProjectCss.Empty.$$Error.title
                                                  }),
                                              className: DashboardProjectsIndexProjectCss.dateRange
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.$$Error.text
                                            })
                                      ],
                                      className: DashboardProjectsIndexProjectCss.people
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Badge.Span.make, {
                                                    color: "Red",
                                                    children: "Error"
                                                  })
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.$$Error.projectTitle
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.$$Error.projectDates
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.$$Error.projectDescription
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.$$Error.projectDescription
                                            })
                                      ],
                                      className: DashboardProjectsIndexProjectCss.details
                                    })
                              ],
                              className: DashboardProjectsIndexProjectCss.leftContainer
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: Belt_Array.init(6, (function (index) {
                                            return JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx("div", {
                                                                className: DashboardProjectsIndexProjectCss.Empty.$$Error.Stat.label
                                                              }),
                                                          JsxRuntime.jsx("div", {
                                                                className: DashboardProjectsIndexProjectCss.Empty.$$Error.Stat.number
                                                              })
                                                        ],
                                                        className: DashboardProjectsIndexProjectCss.stat
                                                      }, String(index));
                                          })),
                                    className: DashboardProjectsIndexProjectCss.stats
                                  }),
                              className: DashboardProjectsIndexProjectCss.Empty.$$Error.rightContainer
                            })
                      ],
                      className: DashboardProjectsIndexProjectCss.project
                    })
              ],
              className: DashboardProjectsIndexProjectCss.Empty.$$Error.container,
              onClick: props.reset
            });
}

var Css;

var make = DashboardProjectsIndexErrorProject;

export {
  Css ,
  make ,
}
/* Badge Not a pure module */
