// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Tsd from "../../../models/Tsd.res.js";
import * as Rest from "../../Rest.res.js";

function exec(tsdId, pageNum, sortBy, query) {
  var page = Tsd.Show.ProviderTsds.page(pageNum);
  var query$1 = Tsd.Show.ProviderTsds.query(query);
  var sortBy$1 = Tsd.Show.ProviderTsds.sortBy(sortBy);
  return Rest.$$fetch("/dashboard/tsds/" + ID.toString(tsdId) + "/provider_tsds?" + page + query$1 + sortBy$1, "Get", {
              NAME: "Json",
              VAL: Tsd.Show.ProviderTsds.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
