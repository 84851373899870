// Generated by ReScript, PLEASE EDIT WITH CARE


var SelectedOption = {};

var tabs = [
  {
    tab: "ProviderDetails",
    title: "Provider Details"
  },
  {
    tab: "Locations",
    title: "Locations"
  },
  {
    tab: "Api",
    title: "API Manager"
  },
  {
    tab: "People",
    title: "People"
  },
  {
    tab: "Settings",
    title: "Settings"
  }
];

var Tab = {
  tabs: tabs
};

var Nav = {
  SelectedOption: SelectedOption,
  Tab: Tab
};

var SelectedOption$1 = {};

var tabs$1 = [
  {
    tab: "GeneralInfo",
    title: "General Info"
  },
  {
    tab: "About",
    title: "About"
  },
  {
    tab: "Services",
    title: "Services"
  },
  {
    tab: "Assets",
    title: "Assets"
  }
];

var Tab$1 = {
  tabs: tabs$1
};

var GeneralInfoNav = {
  SelectedOption: SelectedOption$1,
  Tab: Tab$1
};

var SelectedOption$2 = {};

var tabs$2 = [
  {
    tab: "All",
    title: "Permissions"
  },
  {
    tab: "ProjectSettings",
    title: "Project Settings"
  },
  {
    tab: "TsdSettings",
    title: "TSD Settings"
  }
];

var Tab$2 = {
  tabs: tabs$2
};

var SettingsNav = {
  SelectedOption: SelectedOption$2,
  Tab: Tab$2
};

var SelectedOption$3 = {};

var tabs$3 = [
  {
    tab: "GeneralInfo",
    title: "General Info"
  },
  {
    tab: "About",
    title: "About"
  },
  {
    tab: "Certifications",
    title: "Certifications"
  },
  {
    tab: "Assets",
    title: "Assets"
  },
  {
    tab: "Settings",
    title: "Settings"
  }
];

var Tab$3 = {
  tabs: tabs$3
};

var LocationNav = {
  SelectedOption: SelectedOption$3,
  Tab: Tab$3
};

export {
  Nav ,
  GeneralInfoNav ,
  SettingsNav ,
  LocationNav ,
}
/* No side effect */
