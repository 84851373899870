// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductStatusBadgeCss from "./DashboardProductStatusBadgeCss.res.js";

function DashboardProductStatusBadge(props) {
  switch (props.status) {
    case "Draft" :
        return JsxRuntime.jsx("div", {
                    children: "Draft",
                    className: Cx.cx([
                          DashboardProductStatusBadgeCss.badge,
                          DashboardProductStatusBadgeCss.draftBadge
                        ])
                  });
    case "Expired" :
        return JsxRuntime.jsx("div", {
                    children: "Expired",
                    className: Cx.cx([
                          DashboardProductStatusBadgeCss.badge,
                          DashboardProductStatusBadgeCss.expiredBadge
                        ])
                  });
    case "Archived" :
        return JsxRuntime.jsx("div", {
                    children: "Archived",
                    className: Cx.cx([
                          DashboardProductStatusBadgeCss.badge,
                          DashboardProductStatusBadgeCss.archivedBadge
                        ])
                  });
    
  }
}

var Css;

var make = DashboardProductStatusBadge;

export {
  Css ,
  make ,
}
/* react/jsx-runtime Not a pure module */
