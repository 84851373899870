// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as LocationsIndexCss from "../../styles/LocationsIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function LocationsIndexProvider(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("a", {
                            children: Js_string.slice(0, 30, props.name),
                            className: LocationsIndexCss.link,
                            href: Routes_Provider.show(props.slug)
                          }),
                      className: LocationsIndexCss.name
                    }),
                JsxRuntime.jsx("div", {
                      children: String(props.totalLocations),
                      className: LocationsIndexCss.totalLocations
                    })
              ],
              className: LocationsIndexCss.wrapper
            });
}

var Css;

var make = LocationsIndexProvider;

export {
  Css ,
  make ,
}
/* Routes_Provider Not a pure module */
