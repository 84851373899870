// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Badge from "../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProjectsIndexProjectCss from "../../styles/shared/DashboardProjectsIndexProjectCss.res.js";

function DashboardProjectsIndexEmptyProject(props) {
  var __emptyType = props.emptyType;
  var emptyType = __emptyType !== undefined ? __emptyType : "NoProjects";
  var tmp;
  tmp = emptyType === "NoProjects" ? "No projects found. Click here to reset search." : "Searching for projects...";
  var tmp$1;
  tmp$1 = emptyType === "NoProjects" ? "Example Project" : "Searching";
  var tmp$2;
  tmp$2 = emptyType === "NoProjects" ? DashboardProjectsIndexProjectCss.Empty.NoProjects.container : Cx.cx([
          "animate-pulse",
          DashboardProjectsIndexProjectCss.Empty.NoProjects.container
        ]);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: DashboardProjectsIndexProjectCss.Empty.NoProjects.Status.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("div", {
                                                    className: DashboardProjectsIndexProjectCss.Empty.NoProjects.title
                                                  }),
                                              className: DashboardProjectsIndexProjectCss.dateRange
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.NoProjects.text
                                            })
                                      ],
                                      className: DashboardProjectsIndexProjectCss.people
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Badge.Span.make, {
                                                    color: "Gray",
                                                    children: tmp$1
                                                  })
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.NoProjects.projectTitle
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.NoProjects.projectDates
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.NoProjects.projectDescription
                                            }),
                                        JsxRuntime.jsx("div", {
                                              className: DashboardProjectsIndexProjectCss.Empty.NoProjects.projectDescription
                                            })
                                      ],
                                      className: DashboardProjectsIndexProjectCss.details
                                    })
                              ],
                              className: DashboardProjectsIndexProjectCss.leftContainer
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: Belt_Array.init(6, (function (index) {
                                            return JsxRuntime.jsxs("div", {
                                                        children: [
                                                          JsxRuntime.jsx("div", {
                                                                className: DashboardProjectsIndexProjectCss.Empty.NoProjects.Stat.label
                                                              }),
                                                          JsxRuntime.jsx("div", {
                                                                className: DashboardProjectsIndexProjectCss.Empty.NoProjects.Stat.number
                                                              })
                                                        ],
                                                        className: DashboardProjectsIndexProjectCss.stat
                                                      }, String(index));
                                          })),
                                    className: DashboardProjectsIndexProjectCss.stats
                                  }),
                              className: DashboardProjectsIndexProjectCss.rightContainer
                            })
                      ],
                      className: DashboardProjectsIndexProjectCss.project
                    })
              ],
              className: tmp$2,
              onClick: props.reset
            });
}

var Css;

var make = DashboardProjectsIndexEmptyProject;

export {
  Css ,
  make ,
}
/* Badge Not a pure module */
