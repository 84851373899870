// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../../../../libs/Date.res.js";
import * as Badge from "../../../../../../../styleguide/components/Badge/Badge.res.js";
import * as DashboardTabsCss from "../../../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function DashboardLeadShowDetailsHeader(props) {
  var lead = props.lead;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: "Created: " + $$Date.Helpers.formatMonthDayYear(lead.createdAt)
                          }),
                      className: DashboardTabsCss.Tab.Section.dateDetails
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Badge.Span.make, {
                            color: "Blue",
                            size: "SM",
                            children: "Type: " + lead.leadType
                          }),
                      className: DashboardTabsCss.Tab.tags
                    })
              ],
              className: DashboardTabsCss.Tab.Section.container
            });
}

var Css;

var $$Highlight;

var make = DashboardLeadShowDetailsHeader;

export {
  Css ,
  $$Highlight ,
  make ,
}
/* Date Not a pure module */
