// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DashboardTabsCss from "../../../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";

function DashboardLeadShowDetailsPipedriveData(props) {
  var lead = props.lead;
  var url = lead.pipedriveLeadUrl;
  var url$1 = lead.pipedriveDealUrl;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: "Pipedrive Data",
                      className: DashboardTabsCss.Tab.label
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              background: "Gray",
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Lead",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: url !== undefined && url !== "" ? JsxRuntime.jsx("a", {
                                              children: "Click here",
                                              className: DashboardTabsCss.Tab.$$Highlight.link,
                                              href: url,
                                              target: "_blank"
                                            }) : "No Pipedrive Lead",
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Deal",
                                      className: DashboardTabsCss.Tab.$$Highlight.label
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: url$1 !== undefined && url$1 !== "" ? JsxRuntime.jsx("a", {
                                              children: "Click here ",
                                              className: DashboardTabsCss.Tab.$$Highlight.link,
                                              href: url$1,
                                              target: "_blank"
                                            }) : "No Pipedrive Deal",
                                      className: DashboardTabsCss.Tab.$$Highlight.data
                                    })
                              ]
                            })
                      ],
                      className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                    })
              ],
              className: DashboardTabsCss.Tab.Section.container
            });
}

var Css;

var $$Highlight;

var make = DashboardLeadShowDetailsPipedriveData;

export {
  Css ,
  $$Highlight ,
  make ,
}
/* DashboardTabsCss Not a pure module */
