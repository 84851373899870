// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var shadow = "shadow-[0_2px_3px_-1px_rgba(0,0,0,0.25)]";

var barWrapperGeneral = Cx.cx([
      "w-full",
      "bg-transparent",
      "relative",
      "px-4"
    ]);

var barWrapper = Cx.cx([
      "w-full",
      "bg-transparent",
      "relative",
      "px-4",
      shadow
    ]);

var barContainer = Cx.cx([
      "flex",
      "no-scrollbar",
      "justify-start",
      "items-center",
      "-mb-1",
      "mx-auto",
      "overflow-x-hidden",
      "overflow-y-hidden",
      "max-md:px-7",
      "max-sm:px-4",
      "max-md:overflow-x-auto",
      "lg:max-w-screen-lg"
    ]);

var linkContainer = Cx.cx([
      "border-b-4",
      "border-transparent",
      "mr-6"
    ]);

var linkItem = Cx.cx([
      "whitespace-nowrap",
      "text-sm",
      "font-semibold",
      "line-height-5",
      "block",
      "py-5",
      "hover:text-teal",
      "hover:no-underline!"
    ]);

var normalLinkItem = Cx.cx([
      "text-gray-400",
      linkItem
    ]);

var underlinedLinkContainer = Cx.cx([
      "border-b-4",
      "border-purple",
      "mr-6"
    ]);

var underlinedLinkItem = Cx.cx([
      "text-black!",
      "no-underline!",
      linkItem
    ]);

var subnavContainer = Cx.cx([
      "flex",
      "flex-col",
      "items-center",
      "justify-center",
      "w-full",
      "bg-gray-50",
      "border-b-4",
      "border-gray-200",
      "border-solid",
      "mt-1"
    ]);

var headerContainer = Cx.cx([
      "mx-auto",
      "w-full",
      "lg:max-w-screen-lg",
      "py-4",
      "md:px-4",
      "px-8",
      "lg:px-0"
    ]);

var viewLive = Cx.cx([
      "flex",
      "justify-center",
      "items-center",
      "text-gray-700",
      "mb-1",
      "text-sm",
      "font-medium",
      "leading-5",
      "md:ml-auto",
      "shrink-0"
    ]);

var tabItem = Cx.cx([
      "text-gray-700",
      "mb-1",
      "text-sm",
      "font-medium",
      "leading-5",
      "hover:text-teal"
    ]);

export {
  shadow ,
  barWrapperGeneral ,
  barWrapper ,
  barContainer ,
  linkContainer ,
  linkItem ,
  normalLinkItem ,
  underlinedLinkContainer ,
  underlinedLinkItem ,
  subnavContainer ,
  headerContainer ,
  viewLive ,
  tabItem ,
}
/* barWrapperGeneral Not a pure module */
