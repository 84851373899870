// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Agency from "../../../models/Agency.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

function exec(agencyId, pageNum, sortBy, query) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "Email" ? "&sort_by=users.email&sort_direction=" + toString(sortBy.VAL) : (
      variant === "AgencyName" ? "&sort_by=agencies.name&sort_direction=" + toString(sortBy.VAL) : (
          variant === "FirstName" ? "&sort_by=users.first_name&sort_direction=" + toString(sortBy.VAL) : (
              variant === "IsPrimary" ? "&sort_by=agency_agents.is_primary&sort_direction=" + toString(sortBy.VAL) : (
                  variant === "LastName" ? "&sort_by=users.last_name&sort_direction=" + toString(sortBy.VAL) : "&sort_by=agency_agents.active&sort_direction=" + toString(sortBy.VAL)
                )
            )
        )
    );
  return Rest.$$fetch("/dashboard/agencies/" + ID.toString(agencyId) + "/agency_agents?" + (page + (query$1 + sortBy$1)), "Get", {
              NAME: "Json",
              VAL: Agency.Show.AgencyAgents.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
