// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var section = Cx.cx([
      "bg-white",
      "md:py-[100px]",
      "md:px-0",
      "py-20",
      "px-12",
      "flex",
      "flex-col",
      "items-center"
    ]);

var title = Cx.cx([
      "text-center",
      "mb-4",
      "text-lg",
      "max:md:text-xl"
    ]);

var description = Cx.cx([
      "text-center",
      "md:text-lg",
      "text-gray-500",
      "text-base",
      "mb-0"
    ]);

var allNews = Cx.cx([
      "block",
      "text-center",
      "md:text-lg",
      "text-base",
      "md:mb-10",
      "mb-7",
      "underline",
      "hover:text-purple"
    ]);

var container = "container";

export {
  section ,
  container ,
  title ,
  description ,
  allNews ,
}
/* section Not a pure module */
