// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../../libs/ID.res.js";
import * as Hooks from "../../../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../../../bindings/Promise.res.js";
import * as Dropdown from "../../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchField from "../../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as Api_Provider from "../../../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as ResponseMessage from "../../../../../../../styleguide/forms/ResponseMessage/ResponseMessage.res.js";
import * as ActiveModelError from "../../../../../../../models/ActiveModelError.res.js";
import * as DashboardFormCss from "../../../../../../../styleguide/dashboard/components/forms/DashboardFormCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as DashboardModalContainer from "../../../../../../../styleguide/dashboard/components/modals/container/DashboardModalContainer.res.js";
import * as DashboardProviderProviderTsdNewCss from "./DashboardProviderProviderTsdNewCss.res.js";

var initialState_status = {
  TAG: "ReadyTsds",
  _0: []
};

var initialState_tsds = [];

var initialState = {
  search: "",
  status: initialState_status,
  tsds: initialState_tsds,
  tsd: undefined
};

var validators_providerId = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.providerId;
      if (x.trim() === "0") {
        return {
                TAG: "Error",
                _0: "A provider is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.providerId
              };
      }
    })
};

var validators_tsdId = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.tsdId;
      if (x.trim() === "0") {
        return {
                TAG: "Error",
                _0: "A TSD is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.tsdId
              };
      }
    })
};

var validators = {
  providerId: validators_providerId,
  tsdId: validators_tsdId,
  preferred: undefined
};

function initialFieldsStatuses(_input) {
  return {
          providerId: "Pristine",
          tsdId: "Pristine",
          preferred: "Pristine"
        };
}

function initialState$1(input) {
  return {
          input: input,
          fieldsStatuses: {
            providerId: "Pristine",
            tsdId: "Pristine",
            preferred: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.providerId;
  var tmp;
  tmp = typeof match !== "object" ? validators.providerId.validate(input) : match._0;
  var match$1 = fieldsStatuses.tsdId;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.tsdId.validate(input) : match$1._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.preferred
  };
  var providerIdResult = tmp;
  var providerIdResult$1;
  if (providerIdResult.TAG === "Ok") {
    var tsdIdResult = tmp$1;
    if (tsdIdResult.TAG === "Ok") {
      var preferredResult = match_0;
      if (preferredResult.TAG === "Ok") {
        return {
                TAG: "Valid",
                output: {
                  providerId: providerIdResult._0,
                  tsdId: tsdIdResult._0,
                  preferred: preferredResult._0
                },
                fieldsStatuses: {
                  providerId: {
                    TAG: "Dirty",
                    _0: providerIdResult,
                    _1: "Shown"
                  },
                  tsdId: {
                    TAG: "Dirty",
                    _0: tsdIdResult,
                    _1: "Shown"
                  },
                  preferred: {
                    TAG: "Dirty",
                    _0: preferredResult,
                    _1: "Hidden"
                  }
                },
                collectionsStatuses: undefined
              };
      }
      providerIdResult$1 = providerIdResult;
    } else {
      providerIdResult$1 = providerIdResult;
    }
  } else {
    providerIdResult$1 = providerIdResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            providerId: {
              TAG: "Dirty",
              _0: providerIdResult$1,
              _1: "Shown"
            },
            tsdId: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            preferred: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState$1(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurProviderIdField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.providerId, validators_providerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  providerId: status,
                                  tsdId: init.tsdId,
                                  preferred: init.preferred
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurTsdIdField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.tsdId, validators_tsdId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  providerId: init.providerId,
                                  tsdId: status,
                                  preferred: init.preferred
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurPreferredField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.preferred, state.fieldsStatuses.preferred, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  providerId: init.providerId,
                                  tsdId: init.tsdId,
                                  preferred: status
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState$1(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateProviderIdField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.providerId, state.submissionStatus, validators_providerId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            providerId: status,
                                            tsdId: init.tsdId,
                                            preferred: init.preferred
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateTsdIdField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.tsdId, state.submissionStatus, validators_tsdId, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            providerId: init.providerId,
                                            tsdId: status,
                                            preferred: init.preferred
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdatePreferredField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.preferred, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            providerId: init.providerId,
                                            tsdId: init.tsdId,
                                            preferred: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                providerId: "Pristine",
                                tsdId: "Pristine",
                                preferred: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                providerId: "Pristine",
                                tsdId: "Pristine",
                                preferred: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateProviderId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateProviderIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateTsdId: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateTsdIdField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updatePreferred: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdatePreferredField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurProviderId: (function () {
              dispatch("BlurProviderIdField");
            }),
          blurTsdId: (function () {
              dispatch("BlurTsdIdField");
            }),
          blurPreferred: (function () {
              dispatch("BlurPreferredField");
            }),
          providerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.providerId),
          tsdIdResult: Formality.exposeFieldResult(state.fieldsStatuses.tsdId),
          preferredResult: Formality.exposeFieldResult(state.fieldsStatuses.preferred),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.providerId;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.tsdId;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.preferred;
              if (typeof tmp$2 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ProviderTsdNewForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState$1,
  validateForm: validateForm,
  useForm: useForm
};

function DashboardProviderProviderTsdNew(props) {
  var updateProviderTsds = props.updateProviderTsds;
  var toggleModal = props.toggleModal;
  var providerId = props.providerId;
  var match = React.useState(function () {
        return [];
      });
  var setMessages = match[1];
  var messages = match[0];
  var initialInput = React.useMemo((function () {
          return {
                  providerId: ID.toString(providerId),
                  tsdId: "0",
                  preferred: false
                };
        }), []);
  var search = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_Provider.Dashboard.Edit.ProviderTsd.create(output.providerId, output.tsdId, output.preferred), (function (x) {
                  if (x.TAG === "Ok") {
                    toggleModal();
                    return updateProviderTsds(x._0);
                  }
                  var match = x._0;
                  if (typeof match === "object") {
                    var errors = match.VAL;
                    SentryLogger.error1({
                          rootModule: "DashboardProviderProviderTsdNew",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "DashboardProviderProviderTsdNew.make"
                        }, "ProviderTsdCreate::Error", [
                          "Error",
                          errors
                        ]);
                    setMessages(function (_prev) {
                          return errors.activeModelErrors;
                        });
                    return cb.notifyOnFailure();
                  }
                  SentryLogger.error1({
                        rootModule: "DashboardProviderProviderTsdNew",
                        subModulePath: /* [] */0,
                        value: "make",
                        fullPath: "DashboardProviderProviderTsdNew.make"
                      }, "ProviderTsdCreate::Error", [
                        "Error",
                        "Unknown Error"
                      ]);
                  cb.notifyOnFailure();
                }));
        }));
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchTsds") {
              return {
                      TAG: "SideEffects",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          $$Promise.wait(Api_Provider.Dashboard.Edit.ProviderTsd.tsdsFilter(ID.toString(providerId), state.search), (function (x) {
                                  if (x.TAG === "Ok") {
                                    return dispatch({
                                                TAG: "SucceedTsdsFetch",
                                                _0: x._0
                                              });
                                  }
                                  SentryLogger.error1({
                                        rootModule: "DashboardProviderProviderTsdNew",
                                        subModulePath: /* [] */0,
                                        value: "make",
                                        fullPath: "DashboardProviderProviderTsdNew.make"
                                      }, "FailTsdsFetch::Error", [
                                        "Error",
                                        x._0
                                      ]);
                                  dispatch("FailTsdsFetch");
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        search: state.search,
                        status: "FailedTsdsFetch",
                        tsds: state.tsds,
                        tsd: state.tsd
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "SucceedTsdsFetch" :
                var res = action._0;
                return {
                        TAG: "Update",
                        _0: {
                          search: state.search,
                          status: {
                            TAG: "ReadyTsds",
                            _0: res.tsds
                          },
                          tsds: res.tsds,
                          tsd: state.tsd
                        }
                      };
            case "UpdateSearchInput" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          search: action._0,
                          status: state.status,
                          tsds: state.tsds,
                          tsd: state.tsd
                        },
                        _1: search
                      };
            case "PerformSearch" :
                var match = state.status;
                if (typeof match !== "object" && match === "FetchingTsds") {
                  return "NoUpdate";
                }
                if (action._0 !== state.search) {
                  return "NoUpdate";
                } else {
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            search: state.search,
                            status: "FetchingTsds",
                            tsds: state.tsds,
                            tsd: state.tsd
                          },
                          _1: (function (param) {
                              param.dispatch("FetchTsds");
                            })
                        };
                }
            case "UpdateTsd" :
                var tsd = action._0;
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          search: state.search,
                          status: state.status,
                          tsds: state.tsds,
                          tsd: tsd
                        },
                        _1: (function (param) {
                            var id = tsd.id;
                            form.updateTsdId((function (input, value) {
                                    return {
                                            providerId: input.providerId,
                                            tsdId: value,
                                            preferred: input.preferred
                                          };
                                  }), ID.toString(id));
                          })
                      };
            
          }
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ResponseMessage.make, {
                  messageType: "Error",
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match$2 = state.status;
  var tmp;
  var exit = 0;
  if (typeof match$2 !== "object" && match$2 !== "FetchingTsds") {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tsd = state.tsd;
    tmp = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: DashboardProviderProviderTsdNewCss.dropdownTrigger,
                  iconSize: "XS",
                  iconColor: "Gray",
                  children: tsd !== undefined ? tsd.name : "Select a TSD."
                }),
            JsxRuntime.jsxs(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: DashboardProviderProviderTsdNewCss.dropdownBody,
                  children: [
                    JsxRuntime.jsx(SearchField.make, {
                          id: "tsds-search",
                          value: state.search,
                          placeholder: "TSD Name",
                          className: DashboardProviderProviderTsdNewCss.search,
                          inputClassName: DashboardProviderProviderTsdNewCss.searchInput,
                          onChange: (function ($$event) {
                              dispatch({
                                    TAG: "UpdateSearchInput",
                                    _0: $$event.target.value
                                  });
                            }),
                          showSearchIcon: false
                        }),
                    Belt_Array.map(state.tsds, (function (tsd) {
                            var id = "providerTsd-search-filter--tsd-" + ID.toString(tsd.id);
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: tsd.name,
                                              className: DashboardProviderProviderTsdNewCss.dropdownUser,
                                              onClick: (function (param) {
                                                  dispatch({
                                                        TAG: "UpdateTsd",
                                                        _0: tsd
                                                      });
                                                })
                                            }),
                                        className: DashboardProviderProviderTsdNewCss.dropdownItem
                                      }, id);
                          }))
                  ]
                })
          ]
        });
  }
  var match$3 = form.status;
  var tmp$1;
  if (typeof match$3 !== "object" || match$3.TAG !== "SubmissionFailed") {
    tmp$1 = null;
  } else {
    var x = messages.length;
    tmp$1 = JsxRuntime.jsx(ResponseMessage.make, {
          messageType: "Error",
          children: x > 0 ? JsxRuntime.jsx(ActiveModelError.List.make, {
                  errors: messages
                }) : JsxRuntime.jsx("p", {
                  children: "Something went wrong."
                })
        });
  }
  return JsxRuntime.jsx(DashboardModalContainer.make, {
              children: JsxRuntime.jsxs("form", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: "New TSD",
                            className: DashboardProviderProviderTsdNewCss.title
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Label.make, {
                                    forId: "providerTsd--tsdId",
                                    className: DashboardFormCss.required,
                                    children: "TSD"
                                  }),
                              tmp,
                              fieldError(form.tsdIdResult),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "providerTsd--preferred",
                                                  className: DashboardFormCss.required,
                                                  children: "Preferred?"
                                                }),
                                            JsxRuntime.jsx(Toggle.make, {
                                                  on: form.input.preferred,
                                                  size: "MD",
                                                  onChange: (function () {
                                                      form.updatePreferred((function (input, value) {
                                                              return {
                                                                      providerId: input.providerId,
                                                                      tsdId: input.tsdId,
                                                                      preferred: value
                                                                    };
                                                            }), !form.input.preferred);
                                                    })
                                                })
                                          ]
                                        }),
                                    className: DashboardProviderProviderTsdNewCss.toggles
                                  })
                            ],
                            className: DashboardProviderProviderTsdNewCss.fields
                          }),
                      JsxRuntime.jsx("div", {
                            children: tmp$1
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Button.make, {
                                    size: "MD",
                                    color: "White",
                                    onClick: props.onClick,
                                    children: "Cancel"
                                  }),
                              JsxRuntime.jsx(Button.make, {
                                    id: "createProviderTsdButton",
                                    size: "MD",
                                    color: "Orange",
                                    disabled: form.submitting,
                                    busy: form.submitting,
                                    submit: true,
                                    children: "Create TSD"
                                  })
                            ],
                            className: DashboardProviderProviderTsdNewCss.buttonContainer
                          })
                    ],
                    className: DashboardProviderProviderTsdNewCss.formContainer,
                    onSubmit: (function ($$event) {
                        $$event.preventDefault();
                        form.submit();
                      })
                  })
            });
}

var Css;

var FormCss;

var make = DashboardProviderProviderTsdNew;

export {
  Css ,
  FormCss ,
  initialState ,
  ProviderTsdNewForm ,
  make ,
}
/* ID Not a pure module */
