// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as $$Date from "../../../../../libs/Date.res.js";
import * as Badge from "../../../../../styleguide/components/Badge/Badge.res.js";
import * as Routes_Tsd from "../../../../../routes/common/Routes_Tsd.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DashboardTabsCss from "../../../../../styleguide/dashboard/components/tabs/DashboardTabsCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTabsHighlight from "../../../../../styleguide/dashboard/components/tabs/components/DashboardTabsHighlight.res.js";

function DashboardAgencyShowDetails(props) {
  var agency = props.agency;
  var match = agency.description;
  var tmp = match === "" ? "No description available." : agency.description;
  var match$1 = agency.tsdId;
  var match$2 = agency.tsdName;
  var tmp$1;
  if (match$1 !== undefined) {
    var id = Caml_option.valFromOption(match$1);
    tmp$1 = match$2 !== undefined ? JsxRuntime.jsx(A.make, {
            href: Routes_Tsd.Dashboard.show(id),
            children: match$2
          }) : JsxRuntime.jsx(A.make, {
            href: Routes_Tsd.Dashboard.show(id),
            children: "Click to View."
          });
  } else {
    tmp$1 = "No TSD has been selected.";
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                "Contract Started:\n           " + $$Date.Helpers.formatMonthDayYear(agency.contractStart),
                                agency.active ? JsxRuntime.jsx(Badge.Span.make, {
                                        id: "agencyActive",
                                        color: "Green",
                                        children: "Active"
                                      }) : JsxRuntime.jsx(Badge.Span.make, {
                                        id: "agencyActive",
                                        color: "Gray",
                                        children: "Inactive"
                                      }),
                                agency.contractAttached ? JsxRuntime.jsx(Badge.Span.make, {
                                        id: "agencyContractDownload",
                                        color: "Blue",
                                        children: JsxRuntime.jsx("a", {
                                              children: "Download",
                                              href: agency.contractDownload,
                                              target: "_blank"
                                            })
                                      }) : JsxRuntime.jsx(Badge.Span.make, {
                                        id: "agencyContractDownload",
                                        color: "Blue",
                                        enabled: false,
                                        children: "Download"
                                      })
                              ],
                              className: DashboardTabsCss.Tab.Section.dateDetails
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "About",
                                      className: DashboardTabsCss.Tab.label
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: tmp,
                                      className: DashboardTabsCss.Tab.Section.p
                                    })
                              ],
                              className: DashboardTabsCss.Tab.Section.description
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Administrative Details",
                              className: DashboardTabsCss.Tab.label
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Gray",
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Master (TSD)",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: tmp$1,
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(DashboardTabsHighlight.make, {
                                      background: "Transparent",
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Total Agents",
                                              className: DashboardTabsCss.Tab.$$Highlight.label
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: String(agency.totalAgents),
                                              className: DashboardTabsCss.Tab.$$Highlight.data
                                            })
                                      ]
                                    })
                              ],
                              className: DashboardTabsCss.Tab.$$Highlight.highlightsContainer
                            })
                      ],
                      className: DashboardTabsCss.Tab.Section.container
                    })
              ],
              className: DashboardTabsCss.Tab.container
            });
}

var Css;

var $$Highlight;

var make = DashboardAgencyShowDetails;

export {
  Css ,
  $$Highlight ,
  make ,
}
/* A Not a pure module */
