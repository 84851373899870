// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var label = Cx.cx([
      "m-0",
      "break-words",
      "hyphens-auto"
    ]);

var dropdown = Cx.cx([
      "py-2",
      "!text-sm",
      "cursor-pointer",
      "min-w-40"
    ]);

var dropdownBody = Cx.cx([
      "grid",
      "grid-cols-[minmax(160px,1fr)]",
      "grid-flow-row",
      "auto-rows-max",
      "flex-col",
      "gap-1",
      "pt-0",
      "pb-0",
      "max-h-64",
      "overflow-y-auto",
      "min-w-72",
      "text-sm"
    ]);

var dropdownBodyRow = Cx.cx([
      "grid",
      "grid-cols-[max-content_1fr]",
      "gap-1",
      "items-center",
      "py-1",
      "border-b",
      "border-gray-100",
      "last:border-b-0"
    ]);

var searchField = Cx.cx([
      "py-2",
      "pl-15",
      "md:min-w-72",
      "w-full"
    ]);

var wrapper = Cx.cx([
      "flex",
      "flex-row",
      "gap-2",
      "items-center"
    ]);

var dropdownBodyLink = "hover:text-teal";

var dropdownBodyLinkDisabled = "text-gray-400";

export {
  label ,
  dropdown ,
  dropdownBodyLink ,
  dropdownBodyLinkDisabled ,
  dropdownBody ,
  dropdownBodyRow ,
  searchField ,
  wrapper ,
}
/* label Not a pure module */
