// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RestBrowser from "../../RestBrowser.res.js";
import * as ActiveModelError from "../../../models/ActiveModelError.res.js";

function exec(userId, agencyId, active, isPrimary) {
  var formData = new FormData();
  formData.append("user_id", userId);
  formData.append("agency_id", agencyId);
  formData.append("active", active);
  formData.append("is_primary", isPrimary);
  return RestBrowser.$$fetch("/dashboard/agency_agents", formData, "Empty", {
              NAME: "Message",
              VAL: ActiveModelError.Errors.decoder
            }, "Post", undefined);
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  exec ,
}
/* RestBrowser Not a pure module */
