// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../../../../styleguide/components/Link/A.res.js";
import * as Role from "../../../../../../../../../models/Role.res.js";
import * as Badge from "../../../../../../../../../styleguide/components/Badge/Badge.res.js";
import * as Picture from "../../../../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Cloudinary from "../../../../../../../../../libs/Cloudinary.res.js";
import * as Routes_User from "../../../../../../../../../routes/common/Routes_User.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Solid from "@heroicons/react/24/solid";
import * as DashboardProjectsIndexProjectCss from "../../../../../styles/shared/DashboardProjectsIndexProjectCss.res.js";

function DashboardProjectsIndexProjectUsers(props) {
  var agent = props.agent;
  var conciergesCount = props.conciergesCount;
  var owner = props.owner;
  var match = owner.profilePic;
  var tmp;
  if (match === "") {
    tmp = JsxRuntime.jsx(Solid.UserIcon, {
          className: DashboardProjectsIndexProjectCss.Users.Concierge.icon
        });
  } else {
    var profileImage = Cloudinary.imageUrl(owner.profilePic);
    tmp = JsxRuntime.jsx(Picture.make, {
          src: profileImage,
          large: [
            40,
            40
          ],
          crop: "Thumb",
          className: DashboardProjectsIndexProjectCss.Users.image
        });
  }
  var tmp$1;
  if (agent !== undefined) {
    var match$1 = agent.profilePic;
    var tmp$2;
    if (match$1 === "") {
      tmp$2 = JsxRuntime.jsx(Solid.UserIcon, {
            className: DashboardProjectsIndexProjectCss.Users.Concierge.icon
          });
    } else {
      var profileImage$1 = Cloudinary.imageUrl(agent.profilePic);
      tmp$2 = JsxRuntime.jsx(Picture.make, {
            src: profileImage$1,
            large: [
              40,
              40
            ],
            crop: "Thumb",
            className: DashboardProjectsIndexProjectCss.Users.image
          });
    }
    tmp$1 = JsxRuntime.jsxs("div", {
          children: [
            tmp$2,
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("div", {
                          children: "Primary Concierge",
                          className: DashboardProjectsIndexProjectCss.Users.Owner.title
                        }),
                    JsxRuntime.jsx(A.make, {
                          href: Routes_User.Dashboard.show(agent.userId),
                          className: DashboardProjectsIndexProjectCss.Users.Owner.userLink,
                          children: agent.fullName
                        })
                  ],
                  className: DashboardProjectsIndexProjectCss.Users.Owner.details
                })
          ],
          className: DashboardProjectsIndexProjectCss.Users.Owner.container
        });
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        tmp,
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Owner",
                                      className: DashboardProjectsIndexProjectCss.Users.Owner.title
                                    }),
                                JsxRuntime.jsx(A.make, {
                                      href: Routes_User.Dashboard.show(owner.id),
                                      className: DashboardProjectsIndexProjectCss.Users.Owner.userLink,
                                      children: owner.fullName
                                    })
                              ],
                              className: DashboardProjectsIndexProjectCss.Users.Owner.details
                            }),
                        JsxRuntime.jsx(Badge.User.Role.make, {
                              role: Role.fromString(owner.role),
                              size: "XS"
                            })
                      ],
                      className: DashboardProjectsIndexProjectCss.Users.Owner.container
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        tmp$1,
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Solid.UsersIcon, {
                                      className: DashboardProjectsIndexProjectCss.Users.Concierge.icon
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Concierges",
                                              className: DashboardProjectsIndexProjectCss.Users.Concierge.title
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: conciergesCount < 1 ? "No concierges assigned." : String(conciergesCount)
                                            })
                                      ],
                                      className: DashboardProjectsIndexProjectCss.Users.Concierge.details
                                    })
                              ],
                              className: DashboardProjectsIndexProjectCss.Users.Owner.container
                            })
                      ],
                      className: DashboardProjectsIndexProjectCss.Users.Concierge.container
                    })
              ],
              className: DashboardProjectsIndexProjectCss.Users.users
            });
}

var Css;

var make = DashboardProjectsIndexProjectUsers;

export {
  Css ,
  make ,
}
/* A Not a pure module */
